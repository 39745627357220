const actionTypes = {
  SetEmployees: "customers/SET_EMPLOYEES",
  SetSelectedEmployee: "customers/SET_SELECTED_EMPLOYEE",
  SetRefreshing: "customers/SET_REFRESHING",
};

const initialState = {
  data: [],
  selectedEmployee: undefined,
  isRefreshing: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetEmployees:
      return { ...state, data: action.payload.employees };
    case actionTypes.SetSelectedEmployee:
      return { ...state, selectedEmployee: action.payload.selectedEmployee };
    case actionTypes.SetRefreshing:
      return { ...state, isRefreshing: action.payload.isRefreshing };
    default:
      return state;
  }
};

export const actions = {
  setEmployees: (employees) => ({
    type: actionTypes.SetEmployees,
    payload: { employees },
  }),
  setSelectedEmployee: (selectedEmployee) => ({
    type: actionTypes.SetSelectedEmployee,
    payload: { selectedEmployee },
  }),
  employeeRefresh: (isRefreshing) => ({
    type: actionTypes.SetRefreshing,
    payload: { isRefreshing },
  }),
};
