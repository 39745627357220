/* eslint-disable react/prop-types, react/destructuring-assignment */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import KTToggle from "../../_assets/js/toggle";
import * as builder from "../../ducks/builder";
import { ReactComponent as AngleDoubleLeftIcon } from "../assets/layout-svg-icons/Angle-double-left.svg";
import { ReactComponent as AngleDoubleRightIcon } from "../assets/layout-svg-icons/Angle-double-right.svg";
import { getLandingPagePath } from "../../utils/utils";

class Brand extends React.Component {
  ktToggleRef = React.createRef();

  componentDidMount() {
    // eslint-disable-next-line no-new
    new KTToggle(this.ktToggleRef.current, this.props.toggleOptions);
  }

  render() {
    const landingPagePath = getLandingPagePath(this.props.permissions);

    return (
      <div
        className={`aside__brand grid__item ${this.props.brandClasses}`}
        id="aside_brand"
      >
        <div className="aside__brand-logo">
          <Link to={landingPagePath}>
            <img className="img-fluid" alt="logo" src={this.props.headerLogo} />
          </Link>
        </div>

        {this.props.asideSelfMinimizeToggle && (
          <div className="aside__brand-tools">
            <button
              type="button"
              ref={this.ktToggleRef}
              className="aside__brand-aside-toggler"
              id="aside_toggler"
            >
              <span>
                <AngleDoubleLeftIcon />
              </span>
              <span>
                <AngleDoubleRightIcon />
              </span>
            </button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  document.querySelector("link[id='favicon']").href =
    builder.selectors.getFavicon(store);

  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: "brand",
      toString: true,
    }),
    asideSelfMinimizeToggle: objectPath.get(
      store.builder.layoutConfig,
      "aside.self.minimize.toggle",
    ),
    headerLogo: builder.selectors.getLogo(store),
    headerStickyLogo: builder.selectors.getStickyLogo(store),
    toggleOptions: {
      target: "body",
      targetState: "aside--minimize",
      togglerState: "aside__brand-aside-toggler--active",
    },
    permissions: store.userPermissions.data.permissions,
  };
};

export default connect(mapStateToProps)(Brand);
