/* eslint-disable react/prop-types, react/destructuring-assignment */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dropdown, Image } from "react-bootstrap";
import * as integrations from "../../../ducks/integrations";
import { OFFICE365, GWS } from "../../../../app/pages/home/engage/utils";

// List of allowed integrations
const integrationsList = [OFFICE365, GWS];

function IntegrationsButton(props) {
  let { isChildLoading, integrationsAllowed, isSecure } = props; // eslint-disable-line prefer-const
  if (!integrationsAllowed) {
    integrationsAllowed = integrationsList; // all the integrations are allowed
  }

  // Redux / Global state
  const dispatch = useDispatch();
  const customerIntegrations = useSelector(
    ({ integrations }) => integrations.integrations, // eslint-disable-line no-shadow
  );
  const selectedIntegration = useSelector(
    ({ integrations }) => integrations.selectedIntegration, // eslint-disable-line no-shadow
  );

  // Used on click of integration in dropdown and also in other places
  const onIntegrationSelect = (integration) => {
    dispatch(integrations.actions.setSelectedIntegration(integration));
  };

  // Choose the selected integration based on customer integrations
  useEffect(() => {
    if (customerIntegrations?.length) {
      // Build hash for choosing
      const hash = {};
      let index = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const integration of customerIntegrations) {
        hash[integration.IntegrationApplication.application_name] = index;
        index++; // eslint-disable-line no-plusplus
      }

      // Action function for setting
      // eslint-disable-next-line no-inner-declarations
      function setSelectedIntegration(integrationConstant) {
        const integrationIndex = hash[integrationConstant];
        const integration = customerIntegrations[integrationIndex];
        onIntegrationSelect(integration);
      }

      // In all cases, if microsoft office is integrated set it as selected
      if (hash[OFFICE365] !== undefined || isSecure) {
        if (integrationsAllowed.includes(OFFICE365)) {
          setSelectedIntegration(OFFICE365);
        }
      }
      // otherwise set google workspace if set
      else if (hash[GWS] !== undefined && isSecure === undefined) {
        // check if google workspace is allowed
        if (integrationsAllowed.includes(GWS)) {
          setSelectedIntegration(GWS);
        }
      }
      // Otherwise
      else {
        // set whatever else is integrated thats in the integrationsAllowed for this select
        // eslint-disable-next-line no-restricted-syntax
        for (const key in Object.keys(hash)) {
          if (integrationsAllowed.includes(key)) {
            setSelectedIntegration(key);
            break;
          }
        }
      }
    }
  }, [customerIntegrations]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isChildLoading) {
    return (
      <Button
        variant="light"
        className="spinner spinner--center spinner--md spinner--primary"
      />
    );
  }

  return (
    <Dropdown drop="down" alignRight>
      {selectedIntegration && (
        <Dropdown.Toggle variant="light" className="font-color-2">
          <Image
            src={selectedIntegration.IntegrationApplication?.icon_path}
            style={{
              width: 20,
              height: 20,
            }}
            className="mr-3"
          />
          <span
            className="nav__link"
            style={{ minWidth: 125, textAlign: "left" }}
          >
            <span className="nav__link nav__link-text font-color-2">
              {selectedIntegration.IntegrationApplication?.display_name}
            </span>
          </span>
        </Dropdown.Toggle>
      )}
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        {customerIntegrations.map(
          (integration, index) =>
            integrationsAllowed.includes(
              integration.IntegrationApplication.application_name,
            ) && (
              <Dropdown.Item
                key={index} // eslint-disable-line react/no-array-index-key
                className="nav__item"
                onClick={() => onIntegrationSelect(integration)}
                style={{ minWidth: 200 }}
              >
                <Image
                  src={integration.IntegrationApplication.icon_path}
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  className="mr-3"
                />
                <span className="nav__link">
                  <span className="nav__link nav__link-text">
                    {integration.IntegrationApplication?.display_name}
                  </span>
                </span>
              </Dropdown.Item>
            ),
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default IntegrationsButton;
