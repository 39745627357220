/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useEffect, useState, lazy, Suspense } from "react"; // eslint-disable-line no-unused-vars
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as subheader from "../../_augmentt/ducks/subheader";
import { ToastContext } from "../context/ToastContext";
import { EULAContext } from "../context/EULAContext";
import { LayoutContextProvider, LayoutSplashScreen } from "../../_augmentt";
import Layout from "../../_augmentt/layout/Layout";
import AuthPage from "../pages/auth/AuthPage";
import ErrorPage from "../pages/errors/ErrorPage";
import LogoutPage from "../pages/auth/Logout";
import EULA from "../pages/auth/EULA";
import { checkAgreement } from "../crud/users.crud";
import { getLandingPagePath } from "../../_augmentt/utils/utils";

const SetupMFA = lazy(() => import("../pages/auth/SetupMFA"));
const HomePage = lazy(() => import("../pages/home/HomePage"));
const SignupPage = lazy(() => import("../pages/signup/SignupPage"));
const TrialExpired = lazy(() => import("../pages/trial-expired/TrialExpired"));
const CbIntegrations = lazy(() => import("../pages/home/CbIntegrations"));
const SSO = lazy(() => import("../pages/home/SSO"));

// If a user is redirected to the Login component from any paths other than
// /auth/login or /error or /logout then they should be brought back there after login.
// This map allows us to store all the non-redirectable options in a way that doesn't make
// the app iterate through a whole collection on render when this takes place, it just
// returns a simple boolean that tells the AuthPage whether or not this path should be
// excluded from the redirect logic.
const PATHS_TO_EXCLUDE_FROM_REDIRECT = new Map([
  ["/auth/login", true],
  ["/error", true],
  ["/logout", true],
]);

export const Routes = withRouter(({ history }) => {
  const [, setEULAAccepted] = useState(false);
  const [toastInfo, setToastInfo] = useState(ToastContext);
  const [eula, setEULA] = useState(EULAContext);
  const [latestEULAVersion, setLatestEULAVersion] = useState();

  const permissions = useSelector(
    ({ userPermissions }) => userPermissions?.data?.permissions,
  );
  const currentUser = useSelector(({ user }) => user.data);
  const license = useSelector(({ licenses }) => licenses.data);
  const hasValidSubscription = useSelector(
    ({ meta }) => meta.hasValidSubscription,
  );
  // eslint-disable-next-line no-shadow
  const { menuConfig } = useSelector(({ builder: { menuConfig } }) => ({
    menuConfig,
  }));

  const redirectNav = localStorage.getItem("redirectUrl");

  const [isChildLoading, setChildLoading] = useState(false);

  const landingPagePath = getLandingPagePath(permissions);

  const dispatch = useDispatch();

  const { search } = useLocation();

  useEffect(() => {
    if (window.location.pathname !== "/error") {
      if (currentUser) {
        // eslint-disable-next-line no-use-before-define
        redirect();
        // eslint-disable-next-line no-use-before-define
        checkEULA();

        // No valid subscriptions found
        if (hasValidSubscription === false) {
          history.push("/trial-expired", { user: currentUser });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, hasValidSubscription]);

  /**
   * Check if the EULA is signed, show it if so
   */
  const checkEULA = async () => {
    try {
      const result = await checkAgreement(currentUser);
      const isSigned = result.data.agreementSigned;

      setLatestEULAVersion(result.data.latestVersion);

      if (isSigned) {
        setEULAAccepted(true);
        setEULA({ show: false });
      } else {
        // Show EULA if not accepted
        setEULA({ show: true });
        dispatch(
          subheader.actions.setGlobalValue({
            redirectedFromLogin: true,
          }),
        );
      }
    } catch (e) {
      console.error("Error checking EULA!");
    }
  };

  const showSplashScreen = () => <LayoutSplashScreen visible />;

  const redirect = () => {
    // eslint-disable-next-line no-console
    console.log("redirectUrl:", redirectNav);

    if (
      ["/auth/login", "/signup", "/trial-expired"].some((x) =>
        window.location.pathname.match(x),
      )
    ) {
      // eslint-disable-next-line no-console
      console.log(
        "Reached redirect() window pathname match:",
        window.location.pathname,
      );

      dispatch(
        subheader.actions.setGlobalValue({
          redirectedFromLogin: true,
        }),
      );

      // If a user is redirected to the Login component from any paths other than
      // /auth/login or /error or /logout then they should be brought back there after login
      if (redirectNav) {
        // Clean up the value from Local Storage so it is only ever present if a redirect is imminent
        localStorage.removeItem("redirectUrl");
        history.push(redirectNav);
      } else {
        history.push(landingPagePath);
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(
        "Reached redirect() did not match pathname:",
        window.location.pathname,
      );
    }
  };

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <ToastContext.Provider value={[toastInfo, setToastInfo]}>
        <Switch>
          {window.location.pathname === "/sso" && <SSO history={history} />}

          {window.location.pathname === "/signup" && (
            <SignupPage history={history} />
          )}

          {window.location.pathname === "/auth/setup-MFA" && (
            <SetupMFA history={history} />
          )}
          {(!currentUser ||
            currentUser?.challengeName === "NEW_PASSWORD_REQUIRED" ||
            currentUser?.preferredMFA === "NOMFA") && (
            <>
              <AuthPage
                redirectUrl={
                  !PATHS_TO_EXCLUDE_FROM_REDIRECT.get(window.location.pathname)
                    ? window.location.pathname
                    : false
                }
                navSearchParams={search?.length > 0 ? search : false}
              />

              <Redirect to="/auth/login" />
            </>
          )}

          <Route path="/error" component={ErrorPage} />
          <Route path="/logout" component={LogoutPage} />
          <Route path="/trial-expired" component={TrialExpired} />
          <Route path="/cb-integrations" component={CbIntegrations} />

          {currentUser && hasValidSubscription === false && (
            <Redirect to="/trial-expired" />
          )}

          {currentUser && license && (
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            <EULAContext.Provider value={[eula, setEULA]}>
              {eula.show && (
                <EULA
                  show
                  latestVersion={latestEULAVersion}
                  history={history}
                />
              )}
              <Layout isChildLoading={isChildLoading}>
                <Suspense fallback={showSplashScreen()}>
                  <HomePage
                    setChildLoading={setChildLoading}
                    history={history}
                  />
                </Suspense>
              </Layout>
            </EULAContext.Provider>
          )}

          <LayoutSplashScreen
            visible={
              (currentUser &&
                (window.location.pathname === "/" ||
                  window.location.pathname === "/auth/login")) ||
              (window.location.hostname === "app.test.augmentt.com" &&
                (window.location.pathname === "/" ||
                  window.location.pathname === "/auth/login"))
            }
          />
        </Switch>
      </ToastContext.Provider>
    </LayoutContextProvider>
  );
});
