import { uniqueId } from "lodash";

const actionTypes = {
  InitTable: "table/INIT_TABLE",
  FetchTable: "table/FETCH_TABLE",
  SetTableLoading: "table/SET_TABLE_LOADING",
  FetchTableSuccess: "table/FETCH_TABLE_SUCCESS",
  FetchTableError: "table/FETCH_TABLE_ERROR",
  TerminateTable: "table/TERMINATE_TABLE",
  SetSelectedRows: "table/SET_SELECTED_ROWS",
};

export const INITIAL_STATE = {
  totalSize: 0,
  sortable: [],
  filterable: [],
  data: [],
  selected: [],
  meta: {},
  loading: false,
  error: null,
  remote: { pagination: true, filter: true, sort: true },
  changeKey: null,
  // internal state
  page: 1,
  sizePerPage: 10,
  sortField: undefined,
  sortOrder: undefined,
  filters: {},
};

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.InitTable:
      return {
        [action.tableKey]: {
          ...INITIAL_STATE,
          ...action.payload,
        },
      };
    case actionTypes.FetchTable:
      return {
        [action.tableKey]: {
          ...state[action.tableKey],
          ...action.payload,
          filters: {
            ...state[action.tableKey].filters,
            ...action.payload.filters,
          },
          changeKey: uniqueId(),
        },
      };
    case actionTypes.SetTableLoading:
      return {
        [action.tableKey]: {
          ...state[action.tableKey],
          loading: action.payload.loading,
        },
      };
    case actionTypes.FetchTableSuccess:
      return {
        [action.tableKey]: {
          ...state[action.tableKey],
          data: action.payload.data,
          totalSize: action.payload.totalSize,
          sortable: action.payload.sortable,
          filterable: action.payload.filterable,
          meta: action.payload.meta,
          loading: false,
          error: null,
        },
      };
    case actionTypes.FetchTableError:
      return {
        [action.tableKey]: {
          ...INITIAL_STATE,
          error: action.payload.error,
        },
      };
    case actionTypes.TerminateTable:
      return {
        [action.tableKey]: {},
      };
    case actionTypes.SetSelectedRows: {
      return {
        [action.tableKey]: {
          ...state[action.tableKey],
          selected: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export const actions = {
  initTable: ({ tableKey, initialState = {} }) => ({
    type: actionTypes.InitTable,
    tableKey,
    payload: initialState,
  }),
  fetchTable: ({ tableKey, newState = {} }) => ({
    type: actionTypes.FetchTable,
    tableKey,
    payload: newState,
  }),
  setTableLoading: ({ tableKey, loading }) => ({
    type: actionTypes.SetTableLoading,
    tableKey,
    payload: {
      loading,
    },
  }),
  fetchTableSuccess: ({
    tableKey,
    data,
    totalSize,
    sortable,
    filterable,
    meta,
  }) => ({
    type: actionTypes.FetchTableSuccess,
    tableKey,
    payload: {
      data,
      totalSize,
      sortable,
      filterable,
      meta,
    },
  }),
  fetchTableError: ({ tableKey, error }) => ({
    type: actionTypes.FetchTableError,
    tableKey,
    payload: {
      error,
    },
  }),
  terminateTable: ({ tableKey }) => ({
    type: actionTypes.TerminateTable,
    tableKey,
  }),
  setSelectedRows: ({ tableKey, selected }) => ({
    type: actionTypes.SetSelectedRows,
    tableKey,
    payload: selected,
  }),
};
