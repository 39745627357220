import { omit } from "lodash";
import { LICENSE } from "../../../util/auth.constants";

export const GWS = "GWS";
export const OFFICE365 = "Office365";
export const ENGAGE_TABLE_KEY = "engage";

export const MODAL_NAMES = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  POPOUT: "POPOUT",
  SECURE: "SECURE",
  INTEGRATION_MANAGER: "INTEGRATION_MANAGER",
  DISABLE_MFA: "DISABLE_MFA",
  ADD_LICENSE: "ADD_LICENSE",
  ADD_LICENSE_SELECT: "ADD_LICENSE_SELECT",
  MANAGE_LICENSES: "MANAGE_LICENSES",
  OFFBOARD_USER: "OFFBOARD_USER",
  UNBLOCK_SIGNIN: "UNBLOCK_SIGNIN", // has to match name on src/app/pages/home/ThreatAlerts/useIntegrationModals.js
  BLOCK_SIGNIN: "BLOCK_SIGNIN", // has to match name on src/app/pages/home/ThreatAlerts/useIntegrationModals.js
  REVOKE_SESSIONS: "REVOKE_SESSIONS",
  INTEGRATION_SIGN_IN: "INTEGRATION_SIGN_IN",
  POLICY_POPOUT: "POLICY_POPOUT",
};

export const hasDiscover = (licenses) =>
  licenses.some(
    (license) =>
      license === "nfr-discover-essentials" ||
      license === "discover-essentials" ||
      license === "discover-premium",
  );

export const hasEngage = (licenses) =>
  licenses.some(
    (license) =>
      license === "nfr-engage-essentials" ||
      license === "engage-essentials" ||
      license === "engage-premium",
  );

export const hasSecureEssentials = (licenses) =>
  licenses.some((license) => license === "secure-essentials");

export const hasSecurePremium = (licenses) =>
  licenses.some(
    (license) =>
      license === "nfr-secure-essentials" || license === "secure-premium",
  );

export const DISCOVER_MODULES = LICENSE.filter(
  (license) => license.licenseType === "discover",
);

export const ENGAGE_MODULES = LICENSE.filter(
  (license) => license.licenseType === "engage",
);

export const SECURE_MODULES = LICENSE.filter(
  (license) => license.licenseType === "secure",
);

export const isFeatureFlagEnabled = (featureFlags, featureName) =>
  (featureFlags || []).some((ff) => ff.feature === featureName && ff.enabled);

export const isSecureFeatureFlagEnabled = (featureFlags) => {
  const secureFFEnabled = isFeatureFlagEnabled(
    featureFlags,
    "secure-licensing",
  );
  const testAccountFFEnabled = isFeatureFlagEnabled(
    featureFlags,
    "test-account",
  );
  return secureFFEnabled || testAccountFFEnabled;
};

const removeCountFromOptions = (options) =>
  options.map((option) => omit(option, ["count"]));

const removeCountFromFilter = (filter) => ({
  ...filter,
  options: filter.options ? removeCountFromOptions(filter.options) : undefined,
});

export const removeCountFromColumns = (columns) =>
  columns.map((column) => ({
    ...column,
    filter: column.filter ? removeCountFromFilter(column.filter) : undefined,
  }));

// transforms redux params before sending them in the request
export const transformParams = () => (params) => ({
  ...params,
  sortField: params.sortField === "name" ? "first_name" : params.sortField, // sorting by merge fields is not supported yet
});

// integration
export const mergeIntegrationData = ({
  data = [],
  selectedIntegrationType,
}) => {
  const groupPath =
    selectedIntegrationType === OFFICE365 ? "m365Groups" : "groups";

  return data.map((item) => ({
    ...item,
    integration: {
      groups: item?.[groupPath]?.map((item) => item.name) ?? [], // eslint-disable-line no-shadow
      licenseType: item?.licenseType?.map((item) => item.displayName) ?? [], // eslint-disable-line no-shadow
    },
  }));
};

export const getIntegrationOptions = ({
  selectedIntegration = {},
  path,
  labelPath,
}) => {
  const integrationOptions = {
    __noOption: {
      value: "",
      count: 0,
      employeeIds: [],
    },
  };

  Object.values(selectedIntegration).forEach((integration) => {
    const data = integration?.[path] ?? [];

    data.forEach((item) => {
      if (integrationOptions[item.id]) {
        integrationOptions[item.id].count += 1;
        integrationOptions[item.id].employeeIds.push(integration.employeeId);
      } else {
        integrationOptions[item.id] = {
          value: item.id,
          label: item[labelPath],
          employeeIds: [integration.employeeId],
          count: 1,
        };
      }
    });

    if (!data?.length) {
      integrationOptions.__noOption.count += 1; // eslint-disable-line no-underscore-dangle
      integrationOptions.__noOption.employeeIds.push(integration.employeeId); // eslint-disable-line no-underscore-dangle
    }
  });

  // eslint-disable-next-line no-underscore-dangle
  if (integrationOptions.__noOption.count === 0) {
    delete integrationOptions.__noOption; // eslint-disable-line no-underscore-dangle
  }

  return Object.values(integrationOptions);
};

export const getEmployeesIdsFromIntegrationFilter = (selectedOptions) => {
  if (!Array.isArray(selectedOptions)) {
    return [];
  }

  const employeeIds =
    selectedOptions?.map((option) => option.employeeIds)?.flat() ?? [];
  return Array.from(new Set(employeeIds));
};

export const getFilteredEmployeesArrays = (
  allLicensedEmployees,
  selectedIntegrationName,
  allEmployees,
) => {
  let augmenttAndNoLicenseO365Employee = [];
  const noLicenseEmployee = []; // Employees with no integration licenses, are not authorized for engage, and are not admins
  const allLicensedNonAdminEmployees = []; // Employees with valid integration licenses, are NOT admins, and have valid engage licenses

  // Loop through the list of all employees from the integration
  for (let i = 0; i < allLicensedEmployees?.length; i += 1) {
    const employee = allLicensedEmployees[i];
    let allowedToShow = true; // Assume they're licensed unless otherwise checked in switch
    switch (selectedIntegrationName) {
      case "Office365":
        // In the case of microsoft, they can only show up in engage if they have a license
        allowedToShow = employee.licenseType?.length;
        break;
      case "GWS":
        // Comment, google workspace, all users are displayed due to potential cloud identity users
        break;
      default:
        break;
    } // If you need todo a specific check here for the integration regarding licenses feel free.
    // If employee has a license, add to licensed employees array
    if (allowedToShow && !employee.isAdmin && employee.isAuthorizedForEngage) {
      // Employees with integration licenses
      allLicensedNonAdminEmployees.push(employee);
    }
    // M365 license manager
    else if (selectedIntegrationName === "Office365" && !employee.isAdmin) {
      noLicenseEmployee.push(employee); // Employees without licenses
    }
  }
  let temp = [];
  temp = allEmployees.filter(
    (t) =>
      !allLicensedEmployees?.some((eld) => t.employeeId === eld.employeeId),
  );

  // O365 Employees with no License + Augmentt Employee + Employees who have been Disabled(in Azure) but not removed from Augmentt.
  augmenttAndNoLicenseO365Employee = noLicenseEmployee;
  temp.forEach((x) => {
    augmenttAndNoLicenseO365Employee.push(x);
  });

  return {
    augmenttAndO365EmployeeWithNoLicense: augmenttAndNoLicenseO365Employee,
    allLicensedEmployees,
    licensedNonAdminEmployees: allLicensedNonAdminEmployees,
    originalLicensedNonAdminEmployees: allLicensedNonAdminEmployees,
  };
};
