/* eslint-disable react/prefer-stateless-function, react/prop-types */
import React from "react";

export default class MenuSection extends React.Component {
  render() {
    const { item } = this.props;
    return (
      <li className="menu__section">
        <h4 className="menu__section-text">{item.section}</h4>
        <i className="menu__section-icon flaticon-more-v2" />
      </li>
    );
  }
}
