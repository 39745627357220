/**
 * NOTE: this handlers are for DEVELOPMENT process only
 * for TEST porpouses create the handlers in the test file
 * Or mock them in a "test utils" folder if needed to be reused
 */
import { rest } from "msw";
import tableMockApis from "./tableMockApis";
import autotaskMockApis from "./autotaskMockApis";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;

function redirect(destination, statusCode) {
  return (res) => {
    res.status = statusCode;
    res.headers.set("Location", destination);
    return res;
  };
}

/**
 * This function is necessary due to the format of the API when in develop mode.
 * Which is http://localhost:3001/api?url=API_URL
 * If the request should be moked it will update the destination and redirect
 * Otherwise it will ignore and request will be handled by the backend
 */
function redirectIfMock(req, res) {
  const isMock = req.headers.get("msw-mock-api");

  if (isMock) {
    const { origin } = window.location;
    const path = req.url.href.replace(ROOT, "").replace("&", "?");
    return res(redirect(`${origin}${path}`, 301));
  }

  return req.passthrough();
}

const handlers = [
  rest.get(ROOT, redirectIfMock),
  rest.post(ROOT, redirectIfMock),
  rest.put(ROOT, redirectIfMock),
  rest.patch(ROOT, redirectIfMock),
  rest.delete(ROOT, redirectIfMock),
  rest.options(ROOT, redirectIfMock),
  ...tableMockApis,
  ...autotaskMockApis,
];

export default handlers;
