import { rest } from "msw";

const customerMappings = {
  mappings: [
    {
      id: 7,
      customer_id: 184,
      organization_id: 40,
      external_tenant_id: 2,
      external_tenant_name: "Autotask-2",
      enabled: false,
    },
    {
      id: 8,
      customer_id: 222,
      organization_id: 40,
      external_tenant_id: 1,
      external_tenant_name: "Autotask-1",
      enabled: true,
    },
  ],
  externalCustomers: [
    {
      id: 1,
      companyName: "Autotask-1",
    },
    {
      id: 2,
      companyName: "Autotask-2",
    },
    {
      id: 3,
      companyName: "Autotask-3",
    },
    {
      id: 4,
      companyName: "Autotask-4",
    },
    {
      id: 5,
      companyName: "Autotask-5",
    },
  ],
};

const ticketMappings = {
  mappings: [
    {
      id: 1,
      customer_id: null,
      organization_id: 40,
      meta_data: {
        internal: {
          priority: {
            informational: null,
            low: "priority1",
            medium: "priority2",
            high: null,
          },
          status: null,
          ticketType: null,
          source: null,
          threat: { queueID: null, issueType: null, subIssueType: null },
          posture: { queueID: null, issueType: null, subIssueType: null },
        },
        external: {
          priority: [
            { value: "priority1", label: "Priority 1" },
            { value: "priority2", label: "Priority 2" },
            { value: "priority3", label: "Priority 3" },
          ],
          status: [
            { value: "status1", label: "Status 1" },
            { value: "status2", label: "Status 2" },
            { value: "status3", label: "Status 3" },
          ],
          queueID: [
            { value: "queue1", label: "Queue 1" },
            { value: "queue2", label: "Queue 2" },
            { value: "queue3", label: "Queue 3" },
          ],
          ticketType: [
            { value: "ticketType1", label: "Ticket Type 1" },
            { value: "ticketType2", label: "Ticket Type 2" },
            { value: "ticketType3", label: "Ticket Type 3" },
          ],
          issueType: [
            { value: "issueType1", label: "Issue Type 1" },
            { value: "issueType2", label: "Issue Type 2" },
            { value: "issueType3", label: "Issue Type 3" },
          ],
          subIssueType: [
            { value: "subIssueType1", label: "Sub Issue Type 1" },
            { value: "subIssueType2", label: "Sub Issue Type 2" },
            { value: "subIssueType3", label: "Sub Issue Type 3" },
          ],
          source: [
            { value: "source1", label: "Source 1" },
            { value: "source2", label: "Source 2" },
            { value: "source3", label: "Source 3" },
          ],
        },
      },
    },
  ],
};

const getCustomerMappings = rest.get(
  "/integration/:integrationId/mappings/customer",
  (req, res, ctx) => res(ctx.json(customerMappings), ctx.delay()),
);

const getTicketMappings = rest.get(
  "/integration/:integrationId/mappings/ticket/:customerId",
  (req, res, ctx) => res(ctx.json(ticketMappings), ctx.delay()),
);

const apis = [getCustomerMappings, getTicketMappings];

export default apis;
