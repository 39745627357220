import jsPDF from "jspdf";
import { applyPlugin } from "jspdf-autotable";
import {
  getCurrentDateLongFormat,
  getNormalizedCustomerName,
} from "../../../util/string.utils";

applyPlugin(jsPDF);

function discoverPDF() {
  function loadImage(url) {
    return new Promise((resolve) => {
      if (url.indexOf("base64") > -1) {
        resolve(url);
      } else {
        // load from url
        const img = new Image();
        img.onload = () => resolve(img);
        img.src = url;
      }
    });
  }

  return (props) => {
    const { intl, data, branding } = props; // SEE ExportWidget for params

    loadImage(branding).then((logo) => {
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF("p", "mm", "a3");
      const imgProps = pdf.getImageProperties(logo);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const options = { align: "center", maxWidth: "80" };
      const xLeftMargin = 0.04 * pdf.internal.pageSize.getWidth();

      const heightOfChart = 55;
      const widthOfChart = 55;
      const chartY = 59;

      const ratio = imgProps.width / imgProps.height;

      // Add logo. Note if the logo is a portrait mode its going to look weird,
      pdf.addImage(
        logo,
        "PNG",
        10,
        10,
        pdfWidth / (imgProps.width > 500 || ratio < 2 ? 7 : 4),
        pdfHeight / (imgProps.height > 200 || ratio < 2 ? 7 : 4),
        undefined,
        "SLOW",
      );

      // Title
      let pdfTitle = document
        .getElementById("am_subheader")
        .getElementsByClassName("subheader__title")[0]?.innerText;
      if (pdfTitle === "Discover Applications") {
        pdfTitle = "SaaS Application Usage";
      }

      pdf.setFontSize(17);
      pdf.setFont("helvetica", "bold");
      pdf.text(pdfTitle, 150, 18.5, options);

      // Add company name, selected employee, date range
      let companyName = document
        .getElementById("companySelect")
        .getElementsByTagName("div")[3]?.innerText;
      let employee;
      if (companyName !== "All companies") {
        employee = document
          .getElementById("employeeSelect")
          ?.getElementsByTagName("div")[3]?.innerHTML;
      } else {
        companyName = "All Companies";
      }
      const dateRange = document
        .getElementById("daterange")
        .getElementsByTagName("div")[3]?.innerText;
      const noData = intl.formatMessage({
        id: "GENERAL.NO_DATA",
      });

      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(10);
      pdf.text(companyName, 150, 26.5, { align: "center", maxWidth: "150" });

      // If no selected employee, don't show
      if (companyName !== "All Companies" && employee) {
        pdf.text(`(${employee})`, 150, 50, options);
      }

      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.text(dateRange, 150, 31.5, { align: "center", maxWidth: "100" });

      // DISCOVER APPLICATIONS
      if (window.location.pathname === "/discovery") {
        // Apps by Approval Status
        const appsByApprovalStatusTitle = intl.formatMessage({
          id: "DASHBOARD.APPUSAGE.APPS_BY_APPROVAL_STATUS",
        });
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(11);
        pdf.text(appsByApprovalStatusTitle, xLeftMargin, 55);

        // Apps by Risk Scores
        const appsByRiskScoresTitle = intl.formatMessage({
          id: "DASHBOARD.SECURITY.APPS_BY_RISK_SCORES",
        });
        pdf.text(appsByRiskScoresTitle, 110, 55);

        // Apps by Productivity
        const appsByProductivityTitle = intl.formatMessage({
          id: "DASHBOARD.PRODUCTIVITY.APPS_BY_PRODUCTIVITY",
        });
        pdf.text(appsByProductivityTitle, 205, 55);

        // Add Apps by Approval Status chart
        const canvas = document.getElementById("jsPdfApprovalStatusChart");
        pdf.addImage(
          canvas.toDataURL("image/png", 2),
          "PNG",
          45, // x
          chartY, // y
          widthOfChart, // w
          heightOfChart, // h
          undefined,
          "SLOW",
        );

        // Add Approval Status legend
        const legendItems = document
          .getElementById("appsApprovalStatusWidget")
          .getElementsByClassName("legend")[0]
          .getElementsByTagName("li").length;

        for (let i = 0; i < legendItems; i += 1) {
          const level = document
            .getElementById("appsApprovalStatusWidget")
            .getElementsByClassName("legend")[0]
            .getElementsByTagName("li")
            [i].getElementsByTagName("span")[0]?.innerText;
          const levelNum = document
            .getElementById("appsApprovalStatusWidget")
            .getElementsByClassName("legend")[0]
            .getElementsByTagName("li")
            [i].getElementsByTagName("span")[1]?.innerText;

          // Add dots next to risk levels
          if (i === 0) {
            pdf.setFillColor(102, 202, 134);
          }
          if (i === 1) {
            pdf.setFillColor(255, 192, 0);
          }
          if (i === 2) {
            pdf.setFillColor(255, 116, 112);
          }
          if (i === 3) {
            pdf.setFillColor(239, 240, 246);
          }

          pdf.circle(14, 64 + i * 13, 1, "F");

          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(11);
          pdf.text(level, 17, 65 + i * 13, { align: "left", maxWidth: "50" });

          pdf.setFont("helvetica", "bold");
          pdf.setFontSize(12);
          pdf.text(levelNum, 17, 70 + i * 13, {
            align: "left",
            maxWidth: "25",
          });
        }

        // Add Apps by Risk Scores chart
        const canvas2 = document.getElementById("jsPDFRiskScoresChart");
        pdf.addImage(
          canvas2.toDataURL("image/png", 2),
          "PNG",
          140,
          chartY,
          widthOfChart, // w
          heightOfChart, // h
          undefined,
          "SLOW",
        );

        // Add Risk Scores legend
        const legendItems2 = document
          .getElementById("appsRiskScoresWidget")
          .getElementsByClassName("legend")[0]
          .getElementsByTagName("li").length;

        for (let i = 0; i < legendItems2; i += 1) {
          const level = document
            .getElementById("appsRiskScoresWidget")
            .getElementsByClassName("legend")[0]
            .getElementsByTagName("li")
            [i].getElementsByTagName("span")[0]?.innerText;
          const levelNum = document
            .getElementById("appsRiskScoresWidget")
            .getElementsByClassName("legend")[0]
            .getElementsByTagName("li")
            [i].getElementsByTagName("span")[1]?.innerText;

          // Add dots next to risk levels
          if (i === 0) {
            pdf.setFillColor(102, 202, 134);
          }
          if (i === 1) {
            pdf.setFillColor(255, 192, 0);
          }
          if (i === 2) {
            pdf.setFillColor(255, 116, 112);
          }
          if (i === 3) {
            pdf.setFillColor(239, 240, 246);
          }
          pdf.circle(112, 64 + i * 13, 1, "F");

          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(11);
          pdf.text(level, 115, 65 + i * 13, { align: "left", maxWidth: "50" });

          pdf.setFont("helvetica", "bold");
          pdf.setFontSize(12);
          pdf.text(levelNum, 115, 70 + i * 13, {
            align: "left",
            maxWidth: "25",
          });
        }

        // Add Apps by Productivity chart
        const canvas3 = document.getElementById("jsPDFAppsByProductivityChart");
        pdf.addImage(
          canvas3.toDataURL("image/png", 2),
          "PNG",
          225,
          chartY,
          widthOfChart, // w
          heightOfChart, // h
          undefined,
          "SLOW",
        );

        // Add Productivity legend
        const legendItems3 = document
          .getElementById("appsProductivityWidget")
          .getElementsByClassName("legend")[0]
          .getElementsByTagName("li").length;

        for (let i = 0; i < legendItems3; i += 1) {
          const level = document
            .getElementById("appsProductivityWidget")
            .getElementsByClassName("legend")[0]
            .getElementsByTagName("li")
            [i].getElementsByTagName("span")[0]?.innerText;
          const levelNum = document
            .getElementById("appsProductivityWidget")
            .getElementsByClassName("legend")[0]
            .getElementsByTagName("li")
            [i].getElementsByTagName("span")[1]?.innerText;

          // Add dots next to risk levels
          if (i === 0) {
            pdf.setFillColor(102, 202, 134);
          }
          if (i === 1) {
            pdf.setFillColor(255, 192, 0);
          }
          if (i === 2) {
            pdf.setFillColor(255, 116, 112);
          }
          if (i === 3) {
            pdf.setFillColor(239, 240, 246);
          }
          pdf.circle(207, 64 + i * 13, 1, "F");

          pdf.setFont("helvetica", "normal");
          pdf.setFontSize(11);
          pdf.text(level, 210, 65 + i * 13, { align: "left", maxWidth: "50" });

          pdf.setFont("helvetica", "bold");
          pdf.setFontSize(12);
          pdf.text(levelNum, 210, 70 + i * 13, {
            align: "left",
            maxWidth: "25",
          });
        }

        const applicationArray = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const application of data) {
          applicationArray.push(Object.values(application));
        }

        pdf.autoTable({
          head: [data?.length ? Object.keys(data[0]) : []],
          body: applicationArray,
          theme: "striped",
          startY: 130,
          headStyles: {
            fillColor: [45, 41, 86],
            textColor: 255,
            font: "Helvetica",
          },
          bodyStyles: {
            font: "Helvetica",
            cellPadding: 3,
            lineWidth: 0.1,
          },
        });

        // If no data, display no data
        if (!data?.length) {
          pdf.text(noData, 150, 130, { align: "center", maxWidth: "150" });
        }
      }

      pdf.save(
        ` ${getNormalizedCustomerName(companyName)}_${
          document
            .getElementById("am_subheader")
            .getElementsByClassName("subheader__title")[0]?.innerText
        } -${getCurrentDateLongFormat()}.pdf`,
      );
    });
  };
}

export default discoverPDF;
