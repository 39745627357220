const actionTypes = {
  SetDateRange: "daterange/SET_DATERANGE",
};

const initialState = {
  data: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetDateRange:
      return { ...state, data: action.payload.daterange };

    default:
      return state;
  }
};

export const actions = {
  setDateRange: (daterange) => ({
    type: actionTypes.SetDateRange,
    payload: { daterange },
  }),
};
