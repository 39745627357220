import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./Card.module.scss";

const propTypes = {
  /**
   * Content of the card
   */
  children: PropTypes.node,
  /**
   * Class to override default styles
   */
  className: PropTypes.string,
};

const defaultProps = {
  children: null,
  className: "",
};

function Card(props) {
  const { children, className, ...rest } = props;

  return (
    <div className={clsx(styles.card, className)} {...rest}>
      {children}
    </div>
  );
}

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
