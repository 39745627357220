import React from "react";
import { ReactComponent as ThreatAlertsIcon } from "../_assets/icons/threat-alerts.svg";

export const LANDING_PAGE_PATH = "/secure/security-posture";

const menuConfig = {
  aside: {
    self: {},
    items: [
      {
        title: "Secure",
        icon: "secure",
        page: "secure",
        translate: "MENU.SECURE",
        visible: false,
        submenu: [
          {
            title: "Alerts",
            icon: <ThreatAlertsIcon />,
            page: "secure/alerts",
            translate: "MENU.THREAT_ALERTS",
            visible: false,
          },
          {
            title: "Security Posture",
            icon: "fas fa-tasks",
            page: "secure/security-posture",
            translate: "MENU.SECURITY_POSTURE",
            visible: false,
          },
          {
            title: "Posture Templates",
            icon: "fas fa-copy",
            page: "secure/posture-templates",
            translate: "MENU.POSTURE_TEMPLATES",
            visible: false,
          },
          {
            title: "Posture Templates",
            page: "secure/posture-templates/:templateId",
            visible: false,
          },
          {
            title: "Policy Management",
            icon: "fas fa-magic",
            page: "secure/policy-templates",
            translate: "MENU.POLICY_MANAGEMENT",
            visible: false,
            preview: true,
            beta: true,
          },
          {
            title: "Multi-Factor Authentication Report",
            icon: "fas fa-unlock-alt",
            page: "secure/mfa",
            translate: "MENU.MFA_AUTHENTICATION",
            visible: false,
          },
          {
            title: "Threat Report",
            icon: "fas fa-exclamation-triangle",
            page: "secure/threats",
            translate: "MENU.THREAT_REPORT",
            visible: false,
          },
          {
            title: "Summary Report",
            icon: "fas fa-chart-line",
            page: "secure/summary",
            translate: "MENU.SUMMARY_REPORT",
            visible: false,
          },
        ],
      },
      {
        title: "Engage",
        icon: "engage",
        page: "engage",
        translate: "MENU.ENGAGE",
        visible: true,
      },
      {
        title: "License Report",
        icon: "fas fa-file-invoice-dollar fa-2x",
        page: "licensing-report",
        translate: "MENU.LICENSE_REPORT",
        visible: false,
      },
      {
        title: "License Report",
        icon: "fas fa-file-invoice-dollar fa-2x",
        page: "licensing-report-preview",
        translate: "MENU.LICENSE_REPORT",
        visible: false,
        preview: true,
      },
      {
        title: "Application Usage",
        // root: true,
        icon: "discover",
        page: "discovery",
        translate: "MENU.DISCOVER",
        submenu: [
          {
            title: "Application Usage",
            icon: "fas fa-chart-pie",
            page: "discovery",
            translate: "MENU.APP_USAGE",
            visible: true,
          },
          {
            title: "Discover Compliance",
            icon: "fas fa-clipboard-check",
            page: "discover/risk",
            translate: "MENU.DISCOVER_COMPLIANCE",
            visible: true,
          },
          {
            title: "Agent",
            icon: "fa fa-database",
            page: "discover/data",
            translate: "MENU.AGENT",
            visible: true,
          },
          {
            title: "App Catalog",
            icon: "flaticon2-browser",
            page: "discover/applications",
            translate: "SAASCONFIG.APP_CATALOG",
            visible: true,
          },
        ],
        visible: true,
      },
      {
        title: "Solutions",
        icon: "solutions",
        page: "backup",
        translate: "MENU.SOLUTIONS",
        submenu: [
          {
            title: "Backup",
            icon: "backup",
            page: "backup",
            translate: "MENU.BACKUP",
            visible: true,
          },
          {
            title: "Security Training",
            icon: "security_training",
            page: "security_training",
            translate: "MENU.SECURITY_TRAINING",
            visible: true,
          },
        ],
        visible: true,
      },
      {
        title: "Configuration",
        icon: "flaticon2-settings",
        page: "configuration",
        translate: "MENU.CONFIGURATION",
        visible: true,
        submenu: [
          {
            title: "Companies",
            icon: "fa fa-building",
            page: "companies",
            translate: "MENU.COMPANIES",
            visible: true,
          },
          {
            title: "Employees",
            icon: "fa fa-user-tie",
            page: "employees",
            translate: "MENU.EMPLOYEES_AND_DEPARTMENTS",
            visible: true,
          },
          {
            title: "Integrations",
            icon: "flaticon2-group",
            page: "integrations",
            translate: "MENU.INTEGRATIONS",
            visible: true,
          },
          {
            title: "Account Mapping",
            icon: "account",
            page: "integrations/duoaccountmapping",
            visible: false,
          },
          {
            title: "Scheduled Reports",
            icon: "fas fa-bell",
            page: "scheduled-reports",
            translate: "MENU.SCHEDULED_REPORTS",
            visible: true,
          },
          {
            title: "Mapping & Ticketing",
            page: "integrations/:integrationId/connectwise",
            visible: false,
          },
          {
            title: "Mapping & Ticketing",
            page: "integrations/:integrationId/syncro",
            visible: false,
          },
          {
            title: "Mapping & Ticketing",
            page: "integrations/:integrationId/autotask",
            visible: false,
          },
          {
            title: "Mapping & Ticketing",
            page: "integrations/:integrationId/halo",
            visible: false,
          },
          {
            title: "CSP Setup",
            beta: false,
            page: "integrations/:integrationId/microsoft-csp",
            visible: false,
          },
        ],
      },
      { separator: " " },
      {
        section: "Management",
        translate: "MENU.MANAGEMENT",
      },
      {
        title: "License Center",
        root: true,
        icon: "flaticon-medal",
        page: "licenses",
        translate: "MENU.LICENSE_CENTER",
        visible: true,
      },
      {
        title: "System Users",
        root: true,
        icon: "fa fa-user-friends",
        page: "users",
        translate: "MENU.SYSTEM_USERS",
        visible: true,
      },
      {
        title: "Billing",
        icon: "flaticon-price-tag",
        page: "billing",
        translate: "MENU.BILLING",
        bullet: "dot",
        visible: true,
      },
      {
        title: "Support",
        icon: "flaticon2-help",
        page: "support",
        translate: "MENU.SUPPORT",
        submenu: [
          {
            title: "Open Ticket",
            icon: "flaticon2-paper",
            page: "open-ticket",
            translate: "MENU.OPEN_TICKET",
            bullet: "dot",
            visible: true,
          },
          {
            title: "Chat with Support",
            icon: "flaticon2-chat",
            page: "chat",
            translate: "MENU.SUPPORT_CHAT",
            bullet: "dot",
            visible: true,
          },
          {
            title: "Knowledge base",
            icon: "flaticon2-help",
            page: "support",
            translate: "MENU.KNOWLEDGE",
            bullet: "dot",
            visible: true,
          },
          {
            title: "Sales & Marketing Resources",
            icon: "flaticon2-digital-marketing",
            page: "marketing",
            translate: "MENU.MARKETING",
            bullet: "dot",
            visible: true,
          },
        ],
        visible: true,
      },
      {
        title: "Profile",
        icon: "flaticon2-user",
        page: "user-profile",
        translate: "MENU.PROFILE",
        bullet: "dot",
        visible: false,
      },
      {
        title: "Look and Feel",
        icon: "flaticon2-browser",
        page: "look-and-feel",
        translate: "MENU.LOOKANDFEEL",
        bullet: "dot",
        visible: true,
      },
    ],
  },
};

export default menuConfig;
