import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import AddApplicationModal from "../../../../app/pages/home/modals/AddApplicationModal";
import * as customers from "../../../ducks/customers";

function AddApplicationButton() {
  // Global state
  const dispatch = useDispatch();
  const selectedCustomer = useSelector(
    // eslint-disable-next-line no-shadow
    ({ customers }) => customers.selectedCustomer,
  );
  const allowPermissions = useSelector(
    ({ userPermissions }) => userPermissions.data.permissions,
  );
  const hasManagePermission = allowPermissions.discover === "manage";
  // Local state
  const [modalAddApplicationShow, setModalAddApplicationShow] = useState(false);

  const onAddApplicationClick = () => {
    setModalAddApplicationShow(true);
  };

  const modalClose = () => {
    setModalAddApplicationShow(false);
  };

  const hasUpdated = () => {
    const updatedCustomer = { ...selectedCustomer };
    // Trigger refresh
    dispatch(customers.actions.setSelectedCustomer(updatedCustomer));
  };

  return (
    <>
      {hasManagePermission && (
        <Button
          onClick={() => onAddApplicationClick()}
          id="exportBtn"
          variant="light"
        >
          <FormattedMessage id="SAASCONFIG.ADD_APPLICATION" />
        </Button>
      )}

      <AddApplicationModal
        show={modalAddApplicationShow}
        onHide={modalClose}
        onUpdate={hasUpdated}
        customer={selectedCustomer || undefined}
      />
    </>
  );
}

export default AddApplicationButton;
