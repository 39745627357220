const actionTypes = {
  SetLinks: "externalLinks/SET_EXTERNAL_LINKS",
};

const initialState = {
  data: {
    alertsHowTo: "",
    deployUrl: "",
    integrationWithM365: "",
    exchangeIntegration: "",
    scheduleCall: "",
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetLinks:
      return { ...state, data: action.payload.links };
    default:
      return state;
  }
};

export const actions = {
  setLinks: (links) => ({
    type: actionTypes.SetLinks,
    payload: { links },
  }),
};
