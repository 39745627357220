import { Link, withRouter } from "react-router-dom";
/* eslint-disable react/prop-types, no-shadow, react/destructuring-assignment */
import React, { useContext } from "react";

import { Button } from "react-bootstrap";
import clsx from "clsx";
import objectPath from "object-path";
import { useSelector } from "react-redux";
import MenuItemText from "./MenuItemText";
import MenuSubmenu from "./MenuSubmenu"; // eslint-disable-line import/no-cycle
import { ToastContext } from "../../../app/context/ToastContext";
import { allowPage } from "../../../app/util/auth.util";
import { setPortal } from "../../../app/crud/billing.crud";

const siteName = process.env.REACT_APP_CHARGEBEE_SITE;

// Custom list of menu items, that aren't using the default menu item. use the value of (item.page)
const CUSTOM = [
  "billing",
  "setup",
  "support",
  "backup",
  "security_training",
  "open-ticket",
  "chat",
  "marketing",
];

function MenuItem(props) {
  const {
    item,
    currentUrl,
    parentItem,
    layoutConfig,
    currentUser,
    organization,
  } = props;

  // eslint-disable-next-line
  const [toastInfo, setToastInfo] = useContext(ToastContext);
  const allowPermissions = useSelector(
    ({ userPermissions }) => userPermissions.data.permissions,
  );
  const ownerChargebeeid = useSelector(
    ({ userPermissions }) => userPermissions.ownerChargebeeid,
  );
  const links = useSelector(({ externalLinks }) => externalLinks.data);

  const hasBillingManagePermission =
    allowPermissions && allowPermissions.billing === "manage";

  const isMenuRootItemIsActive = (item) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const subItem of item.submenu) {
      // eslint-disable-next-line no-use-before-define
      if (isMenuItemIsActive(subItem)) {
        return true;
      }
    }

    return false;
  };

  const isMenuItemIsActive = (item) => {
    if (item.submenu && item.page) {
      if (props.currentUrl.indexOf(item.page) !== -1) {
        return true;
      }
    }

    if (item.submenu) {
      return isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }

    return props.currentUrl.indexOf(item.page) !== -1;
  };

  const isActive = isMenuItemIsActive(item);

  const asideLeftLIRef = React.createRef();
  const isDropdown = document.body.classList.contains("aside-menu--dropdown");

  const submenuToggle = // eslint-disable-next-line no-nested-ternary
    props.item.toggle === "click"
      ? "click"
      : objectPath.get(props.item, "submenu.type") === "tabs"
        ? "tabs"
        : "hover";

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param event Event
   */
  const mouseEnter = () => {
    if (!isDropdown) {
      return;
    }

    if (props.item.submenu) {
      asideLeftLIRef.current.classList.add("menu__item--hover");
      asideLeftLIRef.current.setAttribute(
        "data-ktmenu-submenu-toggle",
        submenuToggle,
      );
    }
  };

  /**
   * Mouse Leave event
   * @param event: MouseEvent
   */
  const mouseLeave = () => {
    if (!isDropdown) {
      return;
    }

    if (props.item.submenu && submenuToggle === "hover") {
      asideLeftLIRef.current.classList.remove("menu__item--hover");
      asideLeftLIRef.current.removeAttribute("data-ktmenu-submenu-toggle");
    }
  };

  const gotoPage = () => {
    const { history } = props;
    if (history) history.push(`/${props.item.page}`);
  };

  function openChargebeePortal(user, event) {
    event.stopPropagation();
    event.preventDefault();

    // TODO: Need to make "site" an env variable as it is different for Development and Production
    const chargebeeInstance = window.Chargebee.init({ site: siteName });
    chargebeeInstance.setPortalSession(async () => {
      const result = await setPortal(ownerChargebeeid);
      return result.data;
    });

    let changed = false;

    chargebeeInstance.createChargebeePortal().open({
      subscriptionChanged() {
        changed = true;
      },
      subscriptionResumed() {
        changed = true;
      },
      subscriptionReactivated() {
        changed = true;
      },
      close() {
        chargebeeInstance.logout();
        if (changed) {
          setToastInfo({ show: true, type: "refreshWarning" });
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      },
    });
  }

  // Check if has billing access (is admin )
  const hasBillingAccess =
    !item.submenu &&
    item.page === "billing" &&
    currentUser &&
    hasBillingManagePermission;

  if (!allowPage(item.page, allowPermissions)) {
    return null;
  }

  return item && item.visible && currentUser ? (
    <li
      ref={asideLeftLIRef}
      data-placement="right"
      data-ktmenu-submenu-mode={item.mode}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      className={clsx(
        "menu__item",
        {
          "menu__item--submenu": item.submenu,
          "menu__item--open menu__item--here": isActive && item.submenu,
          "menu__item--active menu__item--here":
            isActive && !item.submenu && currentUrl.includes(item.page),
          "menu__item--icon-only": item["icon-only"],
          "btn btn-outline-primary": item.button,
          "menu__item--disabled menu__item--preview": item.disabled,
          "menu__item--preview": item.preview,
        },
        item["custom-class"],
      )}
      data-ktmenu-dropdown-toggle-class={item["dropdown-toggle-class"]}
    >
      {/* Default menu items */}
      {!item.submenu && !CUSTOM.includes(item.page) && (
        <Link to={`/${item.page}`} className="menu__link">
          <MenuItemText item={item} parentItem={parentItem} />
        </Link>
      )}

      {/* SETUP */}
      {item.page === "setup" && (
        <Link to={`/${item.page}`} className="menu__link">
          <MenuItemText item={item} parentItem={parentItem} />
        </Link>
      )}

      {/* Billing */}
      {hasBillingAccess && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span
          onClick={(event) => openChargebeePortal(currentUser, event)}
          className="menu__link"
        >
          <MenuItemText item={item} parentItem={parentItem} />
        </span>
      )}

      {/* Chat with Support */}
      {!item.submenu && item.page === "chat" && (
        <Button
          variant="link"
          className="menu__link"
          onClick={() => window.fcWidget.open()}
        >
          <MenuItemText item={item} parentItem={parentItem} />
        </Button>
      )}

      {/* Open a Ticket */}
      {!item.submenu && item.page === "open-ticket" && (
        <a
          href={links?.openTicket}
          target="_blank"
          rel="noopener noreferrer"
          className="menu__link"
        >
          <MenuItemText item={item} parentItem={parentItem} />
        </a>
      )}

      {/* Support */}
      {!item.submenu && item.page === "support" && (
        <a
          href="https://support.augmentt.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="menu__link"
        >
          <MenuItemText item={item} parentItem={parentItem} />
        </a>
      )}

      {/* Marketing */}
      {!item.submenu && item.page === "marketing" && (
        <a
          href="https://stonly.com/guide/en/business-enablement-59MneOPU1Q/Steps/2317683"
          target="_blank"
          rel="noopener noreferrer"
          className="menu__link"
        >
          <MenuItemText item={item} parentItem={parentItem} />
        </a>
      )}

      {/* Backup */}
      {!item.submenu && item.page === "backup" && (
        <a
          href={
            organization && organization?.cloudally_url
              ? organization.cloudally_url
              : "https://augmentt.com/augmentt-backup/"
          }
          target="_blank"
          rel="noopener noreferrer"
          className="menu__link"
        >
          <MenuItemText item={item} parentItem={parentItem} />
        </a>
      )}

      {/* Security Training */}
      {!item.submenu && item.page === "security_training" && (
        <a
          href={
            organization && organization?.security_training_url
              ? organization.security_training_url
              : "https://www.augmentt.com/cybersecurity-awareness-training/"
          }
          target="_blank"
          rel="noopener noreferrer"
          className="menu__link"
        >
          <MenuItemText item={item} parentItem={parentItem} />
        </a>
      )}

      {/* Discovery */}
      {item.submenu && item.page === "discovery" && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          to={`/${item.page}`}
          className="menu__link menu__toggle"
          onClick={gotoPage}
        >
          <MenuItemText item={item} parentItem={parentItem} />
        </Link>
      )}

      {/* Show licence center if admin */}
      {item.submenu && item.page === "licenses" && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link to={`/${item.page}`} className="menu__link" onClick={gotoPage}>
          <MenuItemText item={item} parentItem={parentItem} />
        </Link>
      )}

      {item.submenu &&
        item.page !== "discovery" &&
        item.page !== "licenses" && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link to={`/${item.page}`} className="menu__link menu__toggle">
            <MenuItemText item={item} parentItem={parentItem} />
          </Link>
        )}

      {/* Nested menu items */}
      {item.submenu && (
        <div className="menu__submenu">
          <span className="menu__arrow" />

          {item["custom-class"] === "menu__item--submenu-fullheight" && (
            <div className="menu__wrapper">
              <MenuSubmenu
                item={item}
                parentItem={item}
                currentUrl={currentUrl}
                currentUser={currentUser}
                organization={organization}
              />
            </div>
          )}

          {item["custom-class"] !== "menu__item--submenu-fullheight" && (
            <MenuSubmenu
              item={item}
              parentItem={item}
              currentUrl={currentUrl}
              layoutConfig={layoutConfig}
              currentUser={currentUser}
              organization={organization}
            />
          )}
        </div>
      )}
    </li>
  ) : null;
}

export default withRouter(MenuItem);
