import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.oneOf(["md", "sm", "xs"]),
};

const defaultProps = {
  size: "md",
};

function Spinner(props) {
  const { size } = props;

  return (
    <svg
      className={`spin spin-${size} align-items-center`}
      viewBox="0 0 50 50"
      role="status"
    >
      <circle
        className="path"
        cx="25"
        cy="25"
        r={size === "md" ? "20" : "15"}
        fill="none"
        strokeWidth="5"
      />
    </svg>
  );
}

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;

export default Spinner;
