import axios from "axios";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;

export const TOKEN_URL = `${ROOT}/tools/elastic/token`;
export const ELASTIC_URL = `${ROOT}/elastic`;

export function getElasticToken(elasticId) {
  return axios.post(TOKEN_URL, { elastic_id: elasticId });
}

export function getApps(
  customer,
  employee,
  pageSize,
  pageNum,
  sort,
  order,
  searchTerm,
  viewTotal,
) {
  return axios.get(`${ELASTIC_URL}/msp`, {
    params: {
      organizationId: customer.Organization
        ? customer.Organization.id
        : customer.organization,
      employee: employee?.value?.id ? employee.value.id : "*",
      customer: customer.id,
      from: pageNum === 1 ? 0 : (pageNum - 1) * pageSize,
      size: pageSize,
      sort: sort || "COMPANY_NAME",
      order: order || "asc",
      search: searchTerm || "",
      total: viewTotal,
    },
  });
}

export function getGlobalApps(
  orgId,
  pageSize,
  pageNum,
  sort,
  order,
  searchTerm,
  viewTotal,
) {
  return axios.get(`${ELASTIC_URL}/msp`, {
    params: {
      organizationId: orgId,
      employee: "*",
      customer: "*",
      from: pageNum === 1 ? 0 : (pageNum - 1) * pageSize,
      size: pageSize,
      sort: sort || "COMPANY_NAME",
      order: order || "asc",
      search: searchTerm || "",
      total: viewTotal,
    },
  });
}

export function getAppExceptions(orgId, appUrls) {
  return axios.get(`${ELASTIC_URL}/exceptions/counts`, {
    params: {
      organizationId: orgId,
      applications: [appUrls],
    },
  });
}

export function getExceptionDetails(orgId, url) {
  return axios.get(`${ELASTIC_URL}/exceptions/details`, {
    params: {
      organizationId: orgId,
      applicationUrl: url,
    },
  });
}

export function deleteCustomerException(customerId, url) {
  const body = { customerId, applicationUrl: url };
  return axios.delete(`${ELASTIC_URL}/exceptions/details`, {
    data: body,
  });
}

export function getWebTraffic(
  customer,
  employee,
  sort,
  order,
  searchTerm,
  startDate,
  endDate,
) {
  return axios.post(`${ELASTIC_URL}/webtraffic`, {
    employee: employee?.value?.id ? employee.value.id : "*",
    customer: customer === "*" ? "*" : customer.id,
    timeRange: {
      start: startDate,
      end: endDate,
    },
    sort: sort || "URL",
    order: order || "asc",
    search: searchTerm || "",
  });
}

export function getReport(
  customer,
  employee,
  department,
  jobTitle,
  risk,
  productivity,
  category,
  status,
  searchTerm,
  startDate,
  endDate,
) {
  return axios.post(`${ELASTIC_URL}/reporting`, {
    employee: employee || "*",
    department: department || "*",
    job_title: jobTitle || "*",
    customer: customer?.id ? customer.id : "*",
    timeRange: {
      start: startDate,
      end: endDate,
    },
    filter: {
      risk,
      productivity,
      category,
      status,
    },
    search: searchTerm,
  });
}

// Retrieves the company details + notes for an application
export function getCompanyDetails(url, customer) {
  return axios.post(`${ELASTIC_URL}/info`, {
    customerId: customer?.id ? customer.id : "*",
    url,
  });
}

// Create an application note
export const addApplicationNote = (url, customer, noteObject) =>
  axios.post(`${ELASTIC_URL}/info`, {
    customerId: customer?.id ? customer.id : "*",
    url,
    index: noteObject,
  });

// Update an application note
export const editApplicationNote = (url, customer, noteObject) =>
  axios.post(`${ELASTIC_URL}/info`, {
    customerId: customer?.id ? customer.id : "*",
    url,
    update: noteObject,
  });

// Delete an application note
export const deleteApplicationNote = (url, customer, note) =>
  axios.post(`${ELASTIC_URL}/info`, {
    customerId: customer?.id ? customer.id : "*",
    url,
    delete: {
      id: note.id,
    },
  });

// Creates/Updates application(s) in the customer(s) changelist
export const createApplication = (application, customer, organizationId) => {
  const apps = [];
  // eslint-disable-next-line no-param-reassign
  application = Array.isArray(application) ? application : [application];

  for (let i = 0; i < application.length; i += 1) {
    apps.push({
      companyName: (
        application[i].companyName ||
        application[i].name ||
        application[i].COMPANY_NAME
      )?.trim(),
      url: (application[i].url || application[i].URL).toLowerCase().trim(),
      category: (
        application[i].category ||
        application[i].CATEGORY ||
        "Unknown"
      )?.trim(),
      // eslint-disable-next-line radix
      approvalStatus: parseInt(
        application[i].approvalStatus ||
          application[i].status ||
          application[i].APPROVAL_STATUS ||
          0,
      ),
      // eslint-disable-next-line radix
      risk: parseInt(application[i].risk || application[i].RISK || 0),
      // eslint-disable-next-line radix
      productivity: parseInt(
        application[i].productivity || application[i].PRODUCTIVITY || 0,
      ),
      process: application[i].process ? application[i].process : [],
      identity: application[i].identity
        ? application[i].identity
        : [(application[i].url || application[i].URL).toLowerCase().trim()],
    });
  }

  const body = {
    customer:
      // eslint-disable-next-line no-nested-ternary
      customer && Array.isArray(customer)
        ? customer[0]
        : customer && customer.id
          ? customer.id
          : "*",
    organizationId,
    app: apps,
  };

  return axios.put(`${ELASTIC_URL}/msp`, body);
};

export function updateApplication(application, customer) {
  // We use the same endpoint for updating changelist data as creating
  return createApplication(application, customer, customer.organization);
}

export function updateMSPApplications(applications, customer, orgId) {
  // The `createApplication` logic is the SAME for edit
  return createApplication(applications, customer, orgId);
}

export function bulkUpdateApplications(_applications, customer) {
  const applications = _applications.map((app) => app.doc);
  // We use the same endpoint for updating bulk updating as single update
  return createApplication(applications, customer, customer.organization);
}

export function deleteApplication(application, customer) {
  const body = {
    customer: customer.id,
    organizationId: customer.Organization
      ? customer.Organization.id
      : customer.organization,
    app: application,
  };
  return axios.delete(`${ELASTIC_URL}/msp`, { data: body });
}

// TODO: Is this used?
export function deleteMSPApplication(application, customer, orgId) {
  const body = {
    organizationId: orgId,
    url: application,
  };
  return axios.delete(`${ELASTIC_URL}/msp`, body);
}

// TODO: Is this used?
export function bulkDeleteApplications(applications, customer) {
  const body = {
    customer: customer.id,
    delete: applications,
  };
  return axios.put(`${ELASTIC_URL}/masterlist`, body);
}

export function linkAppToSubscriptionManager() {
  return { data: [] };
}

export function unlinkAppFromSubscriptionManager() {
  return { data: [] };
}

export function getDaysActive(
  customerId,
  apps,
  startDate,
  endDate,
  aggregateByEmployee,
  jobTitle,
  employees,
  department,
) {
  const body = {
    customerId,
    dateRange: {
      start: startDate,
      end: endDate,
    },
    apps,
    aggregateByEmployee,
    employees: employees || "*",
    department: department || "*",
    jobTitle: jobTitle || "*",
  };
  return axios.post(`${ELASTIC_URL}/usage`, body);
}
