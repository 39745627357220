import React from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

function HowAlertsWorkButton() {
  const externalLinksList = useSelector(
    ({ externalLinks }) => externalLinks.data,
  );

  return (
    <Button
      onClick={() => window.open(externalLinksList.alertsHowTo)}
      id="alertDocumentationButton"
      variant="light"
    >
      <FormattedMessage id="ALERTS.HOW_ALERTS_WORK" />
    </Button>
  );
}

export default HowAlertsWorkButton;
