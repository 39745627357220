/* eslint-disable react/prop-types, react/destructuring-assignment */
import React, { useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import ScrollTop from "../../app/partials/layout/ScrollTop";
import ToastNotification from "../../app/partials/layout/ToastNotification";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import LayoutInitializer from "./LayoutInitializer";
import QuickPanel from "../../app/partials/layout/QuickPanel";
import Content from "./Content";
import "./assets/Base.scss";

const htmlClassService = new HTMLClassService();

function Layout({
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  layoutConfig,
  isChildLoading,
}) {
  const location = useLocation();

  htmlClassService.setConfig(layoutConfig);

  const contentCssClasses = htmlClassService.classes.content.join(" ");

  const currentUser = useSelector(({ user }) => user.data);
  const brandingInfo = useSelector(({ branding }) => branding.data);
  // eslint-disable-next-line no-shadow
  const { menuConfig } = useSelector(({ builder: { menuConfig } }) => ({
    menuConfig,
  }));

  const target = useRef(null);

  useEffect(() => {
    if (brandingInfo && brandingInfo.logo_light && brandingInfo.publish_logo) {
      layoutConfig.self.logo.light = brandingInfo.logo_light; // eslint-disable-line no-param-reassign
    } else {
      layoutConfig.self.logo.light = "/media/logos/logo-dark.png"; // eslint-disable-line no-param-reassign
    }

    if (!layoutConfig.self.favicon) {
      layoutConfig.self.favicon = LayoutConfig.self.favicon; // eslint-disable-line no-param-reassign
    }

    if (brandingInfo && brandingInfo.favicon && brandingInfo.publish_favicon) {
      layoutConfig.self.favicon.light = brandingInfo.favicon; // eslint-disable-line no-param-reassign
    } else {
      layoutConfig.self.favicon.light = "/media/logos/favicon.ico"; // eslint-disable-line no-param-reassign
    }
    // eslint-disable-next-line no-use-before-define
  }, [brandingInfo, layoutConfig]);

  useEffect(() => {
    // scroll to top after location changes
    window.scrollTo(0, 0);
  }, [location]);

  return selfLayout !== "blank" ? (
    <LayoutInitializer
      menuConfig={menuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
      {/* <!-- end:: Header Mobile --> */}

      <div className="grid grid--hor grid--root">
        {/* <!-- begin::Body --> */}
        <div className="grid__item grid__item--fluid grid grid--ver page">
          {/* <!-- begin:: Aside Left --> */}
          {asideDisplay && <AsideLeft currentUser={currentUser} />}
          {/* <!-- end:: Aside Left --> */}
          <div
            className="grid__item grid__item--fluid grid grid--hor wrapper"
            id="am_wrapper"
          >
            {/* <!-- begin:: Header READY --> */}

            <Header currentUser={currentUser} />
            {/* <!-- end:: Header --> */}

            {/* <!-- begin:: Content --> */}
            <div
              id="am_content"
              className={`content ${contentCssClasses} grid__item grid__item--fluid grid grid--hor`}
              ref={target}
            >
              {/* <!-- begin:: Content Head --> */}
              {subheaderDisplay && (
                <SubHeader isChildLoading={isChildLoading} />
              )}
              {/* <!-- end:: Content Head --> */}

              {/* <!-- begin:: Toast Notification --> */}
              <ToastNotification />
              {/* <!-- end:: Toast Notification --> */}

              {/* <!-- begin:: Content Body --> */}
              {/* TODO: add class to animate  grid--animateContent-finished */}
              <Content>{children}</Content>
              {/* <!-- end:: Content Body --> */}
            </div>
            {/* <!-- end:: Content --> */}
            <Footer />
          </div>
        </div>
        {/* <!-- end:: Body --> */}
      </div>
      <QuickPanel />
      <ScrollTop />
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="grid grid--ver grid--root">
      <Content>{children}</Content>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
  desktopHeaderDisplay: objectPath.get(
    layoutConfig,
    "header.self.fixed.desktop",
  ),
  contentContainerClasses: "",
  // contentContainerClasses: builder.selectors.getClasses(store, {
  //   path: "content_container",
  //   toString: true
  // })
});

export default connect(mapStateToProps)(Layout);
