const EXPORTDATA_ACTION = "SetExportData";
const actionTypes = {
  [EXPORTDATA_ACTION]: "exporter/SET_EXPORT_DATA",
};

const initialState = {
  outputs: [], // array of data outputs
  from: null, // pdf, what function to utilize via "STRING_TYPE" ie "DISCOVERY", "REPORTING" etc
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes[EXPORTDATA_ACTION]:
      return { ...state, ...action.payload.exportData };
    default:
      return state;
  }
};

export const actions = {
  setExportData: (exportData) => ({
    type: actionTypes[EXPORTDATA_ACTION],
    payload: { exportData },
  }),
};
