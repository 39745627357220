import axios from "axios";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;
const SSO_MICROSOFT_URL = `${ROOT}/sso/microsoft`;

// get microsoft authorize url
export const getMicrosoftAuthorizeUrl = () =>
  axios.get(`${SSO_MICROSOFT_URL}/authorize-url`);

// get microsoft authorize code
export const authorizeMicrosoftCode = (code) =>
  axios.post(`${SSO_MICROSOFT_URL}/authorize-code`, { code });
