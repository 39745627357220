import React, { useState, useEffect } from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { getOrg } from "../../../app/crud/orgs.crud";

const MenuList = (props) => {
  const { currentUrl, menuConfig, layoutConfig, currentUser } = props;

  const [organization, setOrganization] = useState();

  // Used to retrieve organization
  useEffect(() => {
    const organizationId = currentUser?.Customer?.organization;

    const getOrganization = async () => {
      try {
        const organizationObject = await getOrg(organizationId);
        if (organizationObject) {
          setOrganization(organizationObject.data);
        }
      } catch (e) {
        console.error("[MenuList] Error: ", e);
      }
    };

    if (organizationId !== undefined) {
      getOrganization();
    }
  }, [currentUser]);

  return menuConfig.aside.items.map((child, index) =>
    currentUser ? (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={`menuList${index}`}>
        {child.section && <MenuSection item={child} />}
        {child.separator && <MenuItemSeparator item={child} />}
        {child.title && (
          <MenuItem
            item={child}
            currentUrl={currentUrl}
            layoutConfig={layoutConfig}
            currentUser={currentUser}
            organization={organization}
          />
        )}
      </React.Fragment>
    ) : null,
  );
};

export default MenuList;
