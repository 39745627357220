/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import TagManager from "react-gtm-module";
import { Formik } from "formik";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import Amplify, { Auth, Cache } from "aws-amplify";
import awsConfig from "./aws-exports";
import { getUserById } from "../../crud/users.crud";
import { getBranding } from "../../crud/branding.crud";
import * as customers from "../../../_augmentt/ducks/customers";
import * as user from "../../../_augmentt/ducks/user";
import * as userPermissions from "../../../_augmentt/ducks/userPermissions";
import * as branding from "../../../_augmentt/ducks/branding";
import * as uploads from "../../../_augmentt/ducks/uploads";
import OTPModal from "./OTPModal";
import { isTestingServer, isAutomationEmail } from "../../util/env.util";
import { getMicrosoftAuthorizeUrl } from "../../crud/sso.crud";
import styles from "./Login.module.scss";

function Login(props) {
  document.title = "Augmentt";

  const { intl, history, location, captcha } = props;
  const [welcomeMsg, setWelcomeMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingMicrosoftSSO, setLoadingMicrosoftSSO] = useState(false);
  const [currentCognitoUser, setCurrentCognitoUser] = useState();
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState();
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2rem",
  });
  const [loadingMicrosoftSSOButtonStyle, setLoadingMicrosoftSSOButtonStyle] =
    useState({
      paddingRight: "2rem",
    });

  const dispatch = useDispatch();

  const tagManagerArgs = {
    gtmId: "GTM-WK2PZ3B",
  };

  Amplify.configure(awsConfig);
  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    setGreeting(); // eslint-disable-line no-use-before-define

    const params = new URLSearchParams(location.search);
    // eslint-disable-next-line no-console
    console.log("params", location.search);
    const isTrial = params.get("trial") === "true";
    const isMessage = params.get("message") === "invalid_id_token";

    if (isTrial || isMessage) {
      const values = {
        email: process.env.REACT_APP_TRIAL_USERNAME,
        password: process.env.REACT_APP_TRIAL_PASSWORD,
      };
      handleSubmit(values, isTrial); // eslint-disable-line no-use-before-define
    } else {
      history.push("/auth/login");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2rem" });
  };

  const enableMicrosoftSSOLoading = () => {
    setLoadingMicrosoftSSO(true);
    setLoadingMicrosoftSSOButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableMicrosoftSSOLoading = () => {
    setLoadingMicrosoftSSO(false);
    setLoadingMicrosoftSSOButtonStyle({ paddingRight: "2rem" });
  };

  const changePassword = () => {
    history.push("/auth/change-password");
  };

  const setupMFA = () => {
    history.push("/auth/setup-MFA");
  };

  const setGreeting = () => {
    if ("aws-amplify-cacheSession" in localStorage) {
      setWelcomeMsg(
        intl.formatMessage({
          id: "AUTH.LOGIN.WELCOMEBACK",
        }),
      );
    } else {
      setWelcomeMsg(
        intl.formatMessage({
          id: "AUTH.LOGIN.WELCOME",
        }),
      );
    }
  };

  async function handleSubmit(values, isTrial) {
    setStatus(null);
    setSubmitting(true);
    enableLoading();

    try {
      // when trial, login is done automatically through the test url
      if (!isTrial && !isTestingServer() && !isAutomationEmail(values.email)) {
        await captcha.executeAsync(); // programmatically invoke the reCAPTCHA challenge
        captcha.reset(); // resets after passing in the challange
      }
      const cognitoUser = await Auth.signIn(values.email, values.password);
      setCurrentCognitoUser(cognitoUser);
      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        // Clear last cognito user's localstorage info
        Object.entries(localStorage)
          .map((x) => x[0])
          .filter(
            (x) => x.substring(0, 30) === "CognitoIdentityServiceProvider",
          )
          .map((x) => localStorage.removeItem(x));

        // Set user info in redux store
        dispatch(user.actions.setUser(cognitoUser));

        changePassword();
      } else if (cognitoUser.challengeName === "SOFTWARE_TOKEN_MFA") {
        setShowOTPModal(true);
      } else {
        // Set session in cache to get be able to get token from interceptor
        const now = new Date();
        Cache.setItem("Session", now, {
          expires: now.setHours(now.getHours() + 1),
        });

        const userInfo = await getUserById(cognitoUser.attributes.email);
        dispatch(
          userPermissions.actions.setUserPermissions({
            customers: userInfo.data.customers,
            permissions: userInfo.data.permissions,
          }),
        );
        dispatch(
          userPermissions.actions.setOwnerChargeBeeID(
            userInfo.data.ownerChargebeeid,
          ),
        );
        const mspBranding = await getBranding(
          userInfo.data.userData.Customer.Organization.id,
        );

        // Set user info in redux store
        dispatch(user.actions.setUser(userInfo.data.userData));
        // Set branding info in redux store
        dispatch(
          branding.actions.setBranding(
            mspBranding.data || {
              logo_light: null,
              publish_logo: false,
              favicon: null,
              publish_favicon: false,
            },
          ),
        );
        // Reset saved customers in redux store
        dispatch(customers.actions.setCustomers([]));
        // Reset saved files in redux store
        dispatch(uploads.actions.setFiles([]));

        if (
          userInfo &&
          cognitoUser.preferredMFA === "NOMFA" &&
          userInfo.data.userData.Customer.MFA === true
        ) {
          dispatch(user.actions.setUser(cognitoUser));
          setupMFA();
        }
      }
      disableLoading();
      setSubmitting(false);
    } catch (e) {
      console.error(e);
      disableLoading();
      setSubmitting(false);
      setStatus(
        intl.formatMessage({
          id: "AUTH.VALIDATION.INVALID_LOGIN",
        }),
      );
    }
  }

  const modalClose = () => {
    setShowOTPModal(false);
  };

  // Refresh
  const hasUpdated = () => {
    // console.log(e);
  };

  const signInWithMicrosoft = async () => {
    setStatus(null);
    setSubmitting(true);
    enableMicrosoftSSOLoading();

    try {
      const { data } = await getMicrosoftAuthorizeUrl();
      document.location.href = data;
    } catch (e) {
      console.error(e);
      disableMicrosoftSSOLoading();
      setSubmitting(false);
      setStatus(
        intl.formatMessage({
          id: "GENERAL.ERROR",
        }),
      );
    }
  };

  return (
    <>
      <div className="login__body">
        <div className="login__form">
          <div className="login__title">
            <h3>{welcomeMsg}</h3>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }
              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              return errors;
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit, // eslint-disable-line no-shadow
            }) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <form
                noValidate
                autoComplete="off"
                className="form"
                onSubmit={handleSubmit}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSubmit(values);
                  }
                }}
              >
                {status && (
                  <Alert variant="danger">
                    <div className="alert-text">{status}</div>
                  </Alert>
                )}
                <div className="form-group">
                  <TextField
                    type="text"
                    label="Email"
                    margin="normal"
                    className="width-full"
                    id="email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="width-full"
                    id="password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>
                <div className="login__actions" style={{ margin: "1rem 0" }}>
                  <Link
                    id="forgotPasswordLink"
                    to="/auth/forgot-password"
                    className="link login__link-forgot"
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>
                </div>

                <div
                  className="login__actions"
                  style={{ justifyContent: "center" }}
                >
                  <Button
                    id="loginBtn"
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn-elevate login__btn-primary ${clsx({
                      "spinner spinner--right spinner--md spinner--light":
                        loading,
                    })} w-100 ml-0`}
                    style={loadingButtonStyle}
                  >
                    {intl.formatMessage({
                      id: "AUTH.LOGIN.BUTTON",
                    })}
                  </Button>
                </div>
                <hr data-content="OR" className={styles.divider} />
                <div
                  className="login__actions"
                  style={{ justifyContent: "center" }}
                >
                  <Button
                    id="loginBtn"
                    variant="secondary"
                    type="button"
                    onClick={signInWithMicrosoft}
                    disabled={isSubmitting}
                    className={`btn-elevate login__btn-primary ${clsx({
                      "spinner spinner--right spinner--md spinner--dark":
                        loadingMicrosoftSSO,
                    })} w-100 ml-0`}
                    style={{
                      ...loadingMicrosoftSSOButtonStyle,
                      marginLeft: "0",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        verticalAlign: "middle",
                        marginRight: "10px",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        // backgroundPosition: "50%",
                        backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23F25022' d='M0 0h9.474v9.474H0z'/%3E%3Cpath fill='%2300A4EF' d='M0 10.526h9.474V20H0z'/%3E%3Cpath fill='%237FBA00' d='M10.526 0H20v9.474h-9.474z'/%3E%3Cpath fill='%23FFB900' d='M10.526 10.526H20V20h-9.474z'/%3E%3C/g%3E%3C/svg%3E")`,
                      }}
                    />
                    <span>
                      {intl.formatMessage({
                        id: "AUTH.LOGIN.SIGN_IN_MS_SSO",
                      })}
                    </span>
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <OTPModal
        show={showOTPModal}
        onHide={modalClose}
        onUpdate={hasUpdated}
        history={history}
        cognitoUser={currentCognitoUser}
      />
    </>
  );
}

export default injectIntl(Login);
