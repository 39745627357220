const actionTypes = {
  SetIntegrations: "integrations/SET_INTEGRATIONS",
  SetSelectedIntegration: "integrations/SET_SELECTED_INTEGRATION",
};

const initialState = {
  integrations: [],
  selectedIntegration: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetIntegrations:
      return { ...state, integrations: action.payload.integrations };
    case actionTypes.SetSelectedIntegration:
      return {
        ...state,
        selectedIntegration: action.payload.selectedIntegration,
      };
    default:
      return state;
  }
};

export const actions = {
  setIntegrations: (integrations) => ({
    type: actionTypes.SetIntegrations,
    payload: { integrations },
  }),
  setSelectedIntegration: (selectedIntegration) => ({
    type: actionTypes.SetSelectedIntegration,
    payload: { selectedIntegration },
  }),
};
