import axios from "axios";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;

export const REPORTS_URL = (reportId) => `${ROOT}/report/${reportId}`;
export const REPORT_EXPORT_URL = `${ROOT}/report`;

export function fetchReportData(reportId) {
  return axios.get(REPORTS_URL(reportId));
}

export function updateReport(reportId) {
  return axios.put(REPORTS_URL(reportId));
}

export function removeReport(reportId) {
  return axios.delete(REPORTS_URL(reportId));
}

export const createReport = (
  customer,
  reportType,
  reportName,
  dateRange,
  fileExtension = "pdf",
) => {
  const payload = {
    customer,
    reportType,
    reportName,
    dateRange,
    fileExtension,
  };
  return axios.post(REPORT_EXPORT_URL, payload);
};

// Force download of file onto users computer from URL
export const onDownloadFileClick = async (
  location,
  fileName,
  fileExtension = "pdf",
  callback = () => {},
) => {
  fetch(location, { method: "GET" })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      // Create link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.${fileExtension}`);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      // Call callback function if provided
      callback();
    })
    // Call callback function if provided for error
    .catch(callback);
};
