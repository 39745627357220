/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { ReactComponent as DataSnagIcon } from "../../../../_augmentt/layout/assets/layout-svg-icons/DataSnag.svg";

export default function ErrorModal(props) {
  const { show, onHide } = props;

  const hide = () => {
    onHide();
  };

  const sendMail = () => {
    window.location =
      "mailto:support@augmentt.com?subject=Issue with file upload in application";
  };

  useEffect(() => {
    // Do something?
  });

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      show={show}
      onHide={hide}
      id="errorModal"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <FormattedMessage
            id="SETUP.ERROR_TITLE"
            defaultMessage="SETUP.ERROR_TITLE"
          />
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4">
              <span>
                <DataSnagIcon className="svg-icon modal-icon" />
              </span>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <p>
                <FormattedMessage
                  id="SETUP.ERROR_DESC1"
                  defaultMessage="SETUP.ERROR_DESC1"
                />
              </p>
              <p>
                <FormattedMessage
                  id="SETUP.ERROR_DESC2"
                  defaultMessage="SETUP.ERROR_DESC2"
                />
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hide} variant="light">
            <FormattedMessage
              id="GENERAL.CANCEL"
              defaultMessage="GENERAL.CANCEL"
            />
          </Button>
          <Button onClick={sendMail} variant="primary">
            <FormattedMessage
              id="SETUP.CONTACT_SUPPORT"
              defaultMessage="SETUP.CONTACT_SUPPORT"
            />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
