import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import CustomizeAuditModal from "../../../../app/pages/home/modals/CustomizeAuditModal";

function CustomizeAuditButton() {
  const [showCustomizeAuditModal, setShowCustomizeAuditModal] = useState(false);

  const onClick = () => {
    setShowCustomizeAuditModal(true);
  };
  const modalClose = () => {
    setShowCustomizeAuditModal(false);
  };
  return (
    <>
      <Button variant="light" onClick={onClick} id="contactBtn">
        <FormattedMessage id="SECURE.CUSTOMIZE_AUDIT" />
      </Button>
      {showCustomizeAuditModal && (
        <CustomizeAuditModal
          show={showCustomizeAuditModal}
          onHide={modalClose}
        />
      )}
    </>
  );
}

export default injectIntl(CustomizeAuditButton);
