import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { ProgressBar } from "react-bootstrap";
import { Skeleton } from "@augmentt-dev/ui-components";
import Card from "./Card";
import styles from "./ProgressCard.module.scss";

const propTypes = {
  /**
   * Number of completed items of the checklist
   */
  numComplete: PropTypes.number,
  /**
   * Number of items in the checklist
   */
  numTotal: PropTypes.number,
  /**
   * Indicates that information on card is loading
   */
  loading: PropTypes.bool,
};

const defaultProps = {
  numComplete: 0,
  numTotal: 0,
  loading: false,
};

function ProgressCard(props) {
  const { numComplete, numTotal, loading } = props;

  const percentage = (numComplete / (numTotal || 1)) * 100;

  if (loading) {
    return (
      <Card>
        <Skeleton count={2} />
      </Card>
    );
  }

  return (
    <Card data-cy="onboarding-checklist-card-progress">
      <div className="text-strong mb-3" data-cy="progress-text">
        <FormattedMessage
          id="ONBOARDING_CHECKLIST.NUM_COMPLETE"
          values={{ numComplete, numTotal }}
        />
      </div>
      <ProgressBar
        now={percentage}
        variant="success"
        className={styles.progress}
        data-cy="progress-bar"
      />
    </Card>
  );
}

ProgressCard.propTypes = propTypes;
ProgressCard.defaultProps = defaultProps;

export default ProgressCard;
