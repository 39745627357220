const actionTypes = {
  SetCurrentUploads: "uploads/SET_CURRENT_UPLOADS",
  SetFileReady: "uploads/SET_FILE_READY",
  SetFiles: "uploads/SET_FILES",
};

const initialState = {
  currentUploads: [],
  files: [],
  isFileReady: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetCurrentUploads:
      return { ...state, currentUploads: action.payload.uploads };
    case actionTypes.SetFileReady:
      return { ...state, isFileReady: action.payload.uploads };
    case actionTypes.SetFiles:
      return { ...state, files: action.payload.uploads };
    default:
      return state;
  }
};

export const actions = {
  setCurrentUploads: (uploads) => ({
    type: actionTypes.SetCurrentUploads,
    payload: { uploads },
  }),
  setFileReady: (uploads) => ({
    type: actionTypes.SetFileReady,
    payload: { uploads },
  }),
  setFiles: (uploads) => ({ type: actionTypes.SetFiles, payload: { uploads } }),
};
