import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import * as table from "../../../../_augmentt/ducks/table";
import { EMPLOYEES_TABLE_KEY } from "./utils";
import {
  getImportSettings,
  postImportSettings,
  postImportSettingsEstimate,
  putImportSettings,
  deleteImportSettings,
} from "../../../crud/employees.crud";

const fetchAutoIgnoreSettings = async (
  customerId,
  integrationType,
  setLoading,
  setImportSettings,
  setDefaultRuleSettings,
) => {
  setLoading(true);
  try {
    const response = await getImportSettings(customerId, integrationType);
    const data = get(response, "data", {});
    setImportSettings(data.rule);
    setDefaultRuleSettings(data);
  } catch (err) {
    console.error(`getImportSettings ${integrationType} error`, err);
  }
  setLoading(false);
};

const reloadEmployeesPage = (dispatch) => {
  // Tell Employee page to reload - Redux employee page
  dispatch(
    table.actions.setTableLoading({
      tableKey: EMPLOYEES_TABLE_KEY,
      loading: true,
    }),
  );
  dispatch(
    table.actions.setSelectedRows({
      tableKey: EMPLOYEES_TABLE_KEY,
      selected: table.INITIAL_STATE.selected,
    }),
  );
  dispatch(table.actions.fetchTable({ tableKey: EMPLOYEES_TABLE_KEY }));
};

const saveAutoIgnoreSettings = async (
  customerId,
  integrationType,
  setLoading,
  setImportSettings,
  params,
  dispatch,
) => {
  setLoading(true);
  try {
    const response = await postImportSettings(
      customerId,
      integrationType,
      params,
    );
    const data = get(response, "data", {});
    setImportSettings(data);
    reloadEmployeesPage(dispatch);
  } catch (err) {
    console.error(`postImportSettings ${integrationType} error`, err);
  }
  setLoading(false);
};

const updateAutoIgnoreSettings = async (
  customerId,
  integrationType,
  setLoading,
  setImportSettings,
  params,
  dispatch,
) => {
  setLoading(true);
  try {
    const response = await putImportSettings(
      customerId,
      integrationType,
      params,
    );
    const data = get(response, "data", {});
    setImportSettings(data);
    reloadEmployeesPage(dispatch);
  } catch (err) {
    console.error(`postImportSettings ${integrationType} error`, err);
  }
  setLoading(false);
};

const deleteAutoIgnoreSettings = async (
  customerId,
  integrationType,
  setLoading,
  dispatch,
) => {
  setLoading(true);
  try {
    await deleteImportSettings(customerId, integrationType);
    reloadEmployeesPage(dispatch);
  } catch (err) {
    console.error(`deleteImportSettings ${integrationType} error`, err);
  }
  setLoading(false);
};

const getEstimateRequest = async (
  customerId,
  integrationType,
  setLoading,
  setEstimate,
  params,
) => {
  setLoading(true);
  try {
    const response = await postImportSettingsEstimate(
      customerId,
      integrationType,
      params,
    );
    const data = get(response, "data", {});
    setEstimate(data);
  } catch (err) {
    console.error(`postImportSettingsEstimate ${integrationType} error`, err);
  }
  setLoading(false);
};

const useImportSettings = (customerId, integrationType) => {
  const [importSettingsLoading, setImportSettingsLoading] = useState(false);
  const [importSettings, setImportSettings] = useState({});
  const [estimateLoading, setEstimateLoading] = useState(false);
  const [defaultRuleSettings, setDefaultRuleSettings] = useState({});

  const [estimate, setEstimate] = useState({
    accounts: 0,
    selectedAccounts: 0,
    noGroupsAccounts: 0,
    noLicensesAccounts: 0,
  });
  const dispatch = useDispatch();

  // eslint-disable-next-line no-shadow
  const getImportSettings = useCallback(
    () =>
      fetchAutoIgnoreSettings(
        customerId,
        integrationType,
        setImportSettingsLoading,
        setImportSettings,
        setDefaultRuleSettings,
      ),
    [customerId, integrationType],
  );

  const saveImportSettings = useCallback(
    (params) => {
      saveAutoIgnoreSettings(
        customerId,
        integrationType,
        setImportSettingsLoading,
        setImportSettings,
        params,
        dispatch,
      );
    },
    [customerId, integrationType], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const updateImportSettings = useCallback(
    (params) => {
      updateAutoIgnoreSettings(
        customerId,
        integrationType,
        setImportSettingsLoading,
        setImportSettings,
        params,
        dispatch,
      );
    },
    [customerId, integrationType], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const deleteCustomerImportSettings = useCallback(
    () =>
      deleteAutoIgnoreSettings(
        customerId,
        integrationType,
        setImportSettingsLoading,
        dispatch,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerId, integrationType],
  );

  const getEstimate = useCallback(
    (params) => {
      getEstimateRequest(
        customerId,
        integrationType,
        setEstimateLoading,
        setEstimate,
        params,
      );
    },
    [customerId, integrationType],
  );

  return {
    importSettingsLoading,
    importSettings,
    getImportSettings,
    saveImportSettings,
    updateImportSettings,
    estimateLoading,
    estimate,
    getEstimate,
    deleteCustomerImportSettings,
    defaultRuleSettings,
  };
};

export default useImportSettings;
