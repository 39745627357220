import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getIntegrations } from "../crud/integration.crud";
import * as integrationsDuck from "../../_augmentt/ducks/integrations";

const useCustomerIntegration = ({ selectedCustomerId, defaultSelection }) => {
  const dispatch = useDispatch();
  const customerIntegration = useSelector(
    ({ integrations }) => integrations.selectedIntegration,
  );

  useEffect(() => {
    const getCustomerIntegrations = async () => {
      const result = await getIntegrations(selectedCustomerId);
      let defaultIndex = 0;
      if (defaultSelection) {
        defaultIndex =
          result?.data?.findIndex(
            (item) =>
              item.IntegrationApplication.application_name === defaultSelection,
          ) || 0;
      }
      dispatch(
        integrationsDuck.actions.setSelectedIntegration(
          result?.data?.[defaultIndex],
        ),
      );
      dispatch(integrationsDuck.actions.setIntegrations(result?.data));
    };

    if (selectedCustomerId) {
      getCustomerIntegrations();
    } else {
      dispatch(integrationsDuck.actions.setSelectedIntegration(null));
      dispatch(integrationsDuck.actions.setIntegrations([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomerId, dispatch]);

  return customerIntegration;
};

export default useCustomerIntegration;
