/* eslint-disable react/prop-types, no-unsafe-optional-chaining */
import React, { useEffect, useState, useRef, useContext } from "react";
import { Dropdown, ProgressBar } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FormattedMessage, injectIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Chart } from "chart.js";
import {
  getS3FileUploadURL,
  uploadFile,
  deleteFile,
} from "../../crud/files.crud";
import * as utils from "../../../_augmentt/utils/utils";
import * as uploads from "../../../_augmentt/ducks/uploads";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { ToastContext } from "../../context/ToastContext";

function Uploads(props) {
  const { intl } = props;

  const currentUser = useSelector(({ user }) => user.data);
  // eslint-disable-next-line no-shadow
  const currentUploads = useSelector(({ uploads }) => uploads.currentUploads);
  // eslint-disable-next-line no-shadow
  const isFileReady = useSelector(({ uploads }) => uploads.isFileReady);
  // eslint-disable-next-line no-shadow
  const files = useSelector(({ uploads }) => uploads.files);
  const [activeUploads, setActiveUploads] = useState([]);
  const [recentUploads, setRecentUploads] = useState([]);
  const [upload, setUpload] = useState();
  const [randomKey, setRandomKey] = useState();
  const [source, setCancelTokenSource] = useState();

  const dispatch = useDispatch();

  const [toastInfo, setToastInfo] = useContext(ToastContext); // eslint-disable-line no-unused-vars

  const canvasRef = useRef();
  const canvasRef2 = useRef();

  const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
    minScrollbarLength: 60,
  };

  // Set window listener
  useEffect(() => {
    window.onbeforeunload = () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const x in currentUploads) {
        // On window/site leave, delete any files in progress
        if (
          currentUploads[x].file.uploaded !==
            currentUploads[x].file.file_size &&
          currentUploads[x].file.status === ""
        ) {
          // eslint-disable-next-line no-use-before-define
          deleteThisFile(currentUploads[x].file.id);
        }
      }
      setActiveUploads([...currentUploads]);
      dispatch(uploads.actions.setCurrentUploads([...currentUploads]));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Check local storage for recent uploads, set in state if available
  useEffect(() => {
    if (currentUser) {
      // eslint-disable-next-line no-shadow
      const currentUploads = utils.getStorage(
        `am-recent-${currentUser.Customer.id}`,
      );
      // Update global state of recent uploads if found in local storage and not expired
      if (currentUploads && JSON.parse(currentUploads).length) {
        const allUploads = JSON.parse(currentUploads);
        const uploaded = [];

        for (let i = 0; i < allUploads.length; i += 1) {
          // Set status of any incomplete uploads to Failed
          if (
            allUploads[i].file.uploaded !== allUploads[i].file.file_size &&
            allUploads[i].file.status === ""
          ) {
            allUploads[i].file.status = "Failed";
          }
          uploaded.push(allUploads[i].file);
        }

        dispatch(uploads.actions.setCurrentUploads(allUploads));
        dispatch(uploads.actions.setFiles(uploaded));
        setActiveUploads(allUploads);
      } else {
        // Clean up local storage of previous recent upload entries
        utils.removeStorage(`am-recent-${currentUser.Customer.id}`);
      }
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  // Prep file for upload if file/customer/vendor info is set
  useEffect(() => {
    if (currentUser) {
      if (files && files.length && files.some((e) => !e.uploaded)) {
        // eslint-disable-next-line no-use-before-define
        prepUpload();
      }
    }
  }, [currentUser, files]); // eslint-disable-line react-hooks/exhaustive-deps

  // Start uploading prepped object
  useEffect(() => {
    if (upload) {
      // eslint-disable-next-line no-use-before-define
      assignFile();
    }
  }, [upload]); // eslint-disable-line react-hooks/exhaustive-deps

  // Start uploading prepped object
  useEffect(() => {
    if (randomKey) {
      // eslint-disable-next-line no-use-before-define
      fileUpload();
    }
  }, [randomKey]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update displayed list of uploads
  useEffect(() => {
    if (activeUploads) {
      if (activeUploads.length) {
        const recent = [...activeUploads];
        if (!files.length || files.length === recent.length) {
          setRecentUploads(recent);
        }
      }
    }
  }, [files, currentUploads, activeUploads]); // eslint-disable-line react-hooks/exhaustive-deps

  // Redraw mini chart on uploads change
  useEffect(() => {
    if (currentUploads?.length) {
      // eslint-disable-next-line no-use-before-define
      initMiniChart();
    }
  }, [activeUploads]); // eslint-disable-line react-hooks/exhaustive-deps

  // Prepare upload
  const prepUpload = () => {
    // eslint-disable-next-line no-shadow
    const source = axios.CancelToken.source();

    // eslint-disable-next-line no-shadow
    const upload = {
      file: {
        file_name: files[0].name,
        customer_name: currentUploads[0].customer.customer_name,
        file_size: files[0].size,
        status: "",
        uploaded: 0,
        cancelToken: source,
      },
      vendor: currentUploads[0].vendor,
      customer: currentUploads[0].customer,
    };

    // Copy current uploads to active uploads in local state
    setActiveUploads([...currentUploads]);
    dispatch(uploads.actions.setCurrentUploads([...currentUploads]));

    // Save new upload/key/cancelToken
    setUpload(upload);
    setCancelTokenSource(source);
  };

  // Get signed URL
  const assignFile = async () => {
    const file = {
      file_name: upload.file.file_name
        ? upload.file.file_name.substring(
            0,
            upload.file.file_name.lastIndexOf("."),
          )
        : "",
      extension: upload.file.file_name
        ? upload.file.file_name.substring(
            upload.file.file_name.lastIndexOf(".") + 1,
          )
        : "",
      vendor: upload.vendor.value,
      size: upload.file.file_size,
    };

    try {
      const response = await uploadFile(file, upload.customer.id);
      setRandomKey(response.data.key);

      if (!response.data.validVendor) {
        // Show 'Vendor not supported' toast message
        setToastInfo({ show: true, type: "vendorNotSupported" });
      } else {
        // Show 'Upload in progress' toast message
        setToastInfo({ show: true, type: "uploadInProgress" });
      }
    } catch (e) {
      setToastInfo({ show: true, type: "uploadFailed" });
      console.error(e);
    }
  };

  // Upload file
  const fileUpload = async () => {
    try {
      const token = await getS3FileUploadURL(randomKey, true);

      const uploadURL = token.data;
      const fileId = uploadURL.substring(
        uploadURL.lastIndexOf("/") + 1,
        uploadURL.lastIndexOf("."),
      );

      // UPLOAD TO S3 STORAGE BUCKET
      axios
        .put(uploadURL, files[0], {
          onUploadProgress: (progress) => {
            // Update progress in active uploads.
            upload.file.uploaded = progress.loaded;
            upload.file.file_size = progress.total;

            // Pass file id to be able to delete file if upload is cancelled.
            upload.file.id = fileId;

            // Set status to 'Uploaded' if finished
            if (progress.loaded === progress.total) {
              upload.file.status = "Uploaded";
            }
            // Update list of active uploads and their progress
            // eslint-disable-next-line no-restricted-syntax
            for (const x in currentUploads) {
              if (
                currentUploads[x].file.file_name === upload.file_name &&
                (currentUploads[x].file.size === upload.file.file_size ||
                  currentUploads[x].file.file_size === upload.file.file_size) &&
                currentUploads[x].vendor.value === upload.vendor.value &&
                currentUploads[x].customer.id === upload.customer.id
              ) {
                currentUploads[x].file = upload.file;
              }
            }

            setActiveUploads([...currentUploads]);
          },
          cancelToken: source.token,
        })
        .then(() => {
          // Update global state of recent uploads
          dispatch(uploads.actions.setCurrentUploads(activeUploads));
          setToastInfo({ show: true, type: "uploadComplete" });
          // Toggle file ready to trigger update on components watching for newly uploaded files
          dispatch(uploads.actions.setFileReady(!isFileReady));
          utils.setStorage(
            `am-recent-${currentUser.Customer.id}`,
            JSON.stringify(activeUploads),
            86400,
          );
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            setToastInfo({ show: true, type: "uploadCanceled" });
          } else {
            // Show notification, set file status to failed
            upload.file.status = "Failed";
            activeUploads[0].file = upload.file;
            // Delete created file/customer association
            // eslint-disable-next-line no-use-before-define
            deleteThisFile(activeUploads[0].file.id);
            // Update global state
            setActiveUploads([...activeUploads]);
            dispatch(uploads.actions.setCurrentUploads([...activeUploads]));

            // Clear from new uploads in global state
            const allFiles = [...files];
            const thisFile = (file) =>
              file.lastModified && file.name === upload.file.file_name;
            const index = allFiles.findIndex(thisFile);
            allFiles.splice(index, 1);
            dispatch(uploads.actions.setFiles([...allFiles]));

            // Toggle file ready to trigger update on components watching for newly uploaded files
            dispatch(uploads.actions.setFileReady(!isFileReady));
            // Save in local storage for 24 hrs
            utils.setStorage(
              `am-recent-${currentUser.Customer.id}`,
              JSON.stringify(activeUploads),
              86400,
            );
            console.error(err);
          }
        });
    } catch (e) {
      // Show notification, set file status to failed
      setToastInfo({ show: true, type: "uploadFailed" });
      upload.file.status = "Failed";
      activeUploads[0].file = upload.file;
      // Delete created file/customer association
      // eslint-disable-next-line no-use-before-define
      deleteThisFile(activeUploads[0].file.id);
      // Update global state
      setActiveUploads([...activeUploads]);
      dispatch(uploads.actions.setCurrentUploads([...activeUploads]));

      // Clear from new uploads in global state
      const allFiles = [...files];
      const thisFile = (file) =>
        file.lastModified && file.name === upload.file.file_name;
      const index = allFiles.findIndex(thisFile);
      allFiles.splice(index, 1);
      dispatch(uploads.actions.setFiles([...allFiles]));

      // Toggle file ready to trigger update on components watching for newly uploaded files
      dispatch(uploads.actions.setFileReady(!isFileReady));
      // Save in local storage for 24 hrs
      utils.setStorage(
        `am-recent-${currentUser.Customer.id}`,
        JSON.stringify(activeUploads),
        86400,
      );
      console.error(e);
    }
  };

  // Cancel file upload
  // eslint-disable-next-line no-shadow
  const cancelUpload = async (source, index) => {
    // Cancel request
    source.cancel("Request canceled.");
    // Delete file/customer association
    // eslint-disable-next-line no-use-before-define
    deleteThisFile(activeUploads[index].file.id);

    // Set status of upload to Canceled
    if (currentUploads[index] && activeUploads[index]) {
      currentUploads[index].file.status = "Canceled";
      activeUploads[index].file.status = "Canceled";
    }
    // Update file status in global state
    dispatch(uploads.actions.setCurrentUploads(currentUploads));
    setActiveUploads([...activeUploads]);

    // Store in local storage for 24 hrs
    utils.setStorage(
      `am-recent-${currentUser.Customer.id}`,
      JSON.stringify(currentUploads),
      86400,
    );
  };

  // Delete file
  const deleteThisFile = async (id) => {
    try {
      await deleteFile(id);
    } catch (e) {
      console.error(e);
    }
  };

  // Initialize mini chart
  // eslint-disable-next-line consistent-return
  const initMiniChart = () => {
    const colors = ["#3399FF", "#FFFFFF"];
    const labels = [
      intl.formatMessage({
        id: "SETUP.UPLOADED",
      }),
      intl.formatMessage({
        id: "USERPROFILE.TOTAL",
      }),
    ];

    // Get index of recent files in redux store where it's still uploading
    let fileIndex;
    for (let x = 0; x < currentUploads.length; x += 1) {
      if (
        currentUploads[x]?.file.status === "" &&
        currentUploads[x].file?.uploaded !== currentUploads[x].file?.file_size
      ) {
        fileIndex = x;
      }
    }

    // If any files are still being uploaded, use their progress for the chart, otherwise reset it
    const counts =
      currentUploads[fileIndex]?.file.status === ""
        ? [
            currentUploads[fileIndex]?.file.uploaded
              ? currentUploads[fileIndex]?.file.uploaded
              : 1,
            currentUploads[fileIndex]?.file.uploaded
              ? currentUploads[fileIndex]?.file.file_size -
                currentUploads[fileIndex]?.file.uploaded
              : 0,
          ]
        : [0, 1];

    const config = {
      type: "doughnut",
      showTooltips: false,
      data: {
        labels,
        datasets: [
          {
            label: "",
            backgroundColor: colors,
            hoverBackgroundColor: colors,
            borderWidth: 0,
            data: counts,
          },
        ],
      },
      options: {
        title: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        legend: {
          display: false,
        },
        animation: {
          duration: 0,
        },
        cutoutPercentage: 50,
        hover: {
          mode: null,
        },
        plugins: {
          datalabels: false,
        },
      },
    };

    if (canvasRef.current || canvasRef2.current) {
      const chart = new Chart(canvasRef.current, config);
      let chart2;
      if (canvasRef2.current) {
        chart2 = new Chart(canvasRef2.current, config);
      }
      return () => {
        chart.destroy();
        if (chart2) {
          chart2.destroy();
        }
      };
    }
  };

  return currentUser && currentUploads?.length ? (
    <Dropdown
      className="header__topbar-item header__topbar-item--user"
      drop="down"
      alignRight
    >
      <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-uploads">
        <div className="header__topbar-user">
          <div id="uploadProgressWidget">
            <div style={{ width: 40, height: 25 }}>
              <canvas ref={canvasRef} id="uploadProgressChart" />
            </div>
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl mt-1"
        renderOnMount
      >
        <div className="user-card user-card--skin-dark">
          <div className="user-card__name">
            <div
              className="icon-md"
              style={{
                width: 40,
                height: 25,
                display: "inline-flex",
                verticalAlign: "middle",
              }}
            >
              <canvas ref={canvasRef2} id="uploadProgressChart2" />
            </div>
            <span style={{ height: 25, verticalAlign: "middle" }}>
              <FormattedMessage id="GENERAL.UPLOADS" />
            </span>
          </div>
        </div>
        <PerfectScrollbar
          className="scroll"
          data-height="300"
          data-mobile-height="300"
          data-scroll="true"
          options={perfectScrollbarOptions}
          style={{ maxHeight: "30vh" }}
        >
          <div className="notifications">
            {recentUploads &&
              recentUploads?.length &&
              // eslint-disable-next-line no-shadow
              recentUploads.map((upload, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="notification">
                  <div className="notification__item">
                    <div className="notification__item-details">
                      <div className="notification__item-title font-bold">
                        {upload?.file?.file_name}
                      </div>
                      <div className="notification__item-time">
                        {upload?.file?.customer_name}
                      </div>
                    </div>
                    <div className="notification__item-details">
                      {/* eslint-disable no-nested-ternary */}
                      {upload?.file?.uploaded !== upload?.file?.file_size &&
                      !upload?.file?.status ? (
                        <div className="notification__progress">
                          <ProgressBar
                            variant="primary"
                            now={
                              upload?.file?.uploaded
                                ? (upload?.file?.uploaded /
                                    upload?.file?.file_size) *
                                  100
                                : 0
                            }
                            max={100}
                            label={`${(
                              (upload?.file?.uploaded /
                                upload?.file?.file_size) *
                              100
                            ).toFixed()}%`}
                          />
                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                          <span
                            className="btn icon-md fa fa-times"
                            onClick={() =>
                              cancelUpload(upload?.file?.cancelToken, index)
                            }
                          />
                        </div>
                      ) : upload?.file?.status === "Uploaded" ? (
                        <div className="font-primary align-right">
                          <FormattedMessage id="SETUP.UPLOADED" />
                        </div>
                      ) : upload?.file?.status === "Canceled" ? (
                        <div className="font-danger align-right">
                          <FormattedMessage id="GENERAL.CANCELED" />
                        </div>
                      ) : upload?.file?.status === "Failed" ? (
                        <div className="font-danger align-right">
                          <FormattedMessage id="GENERAL.FAILED" />
                        </div>
                      ) : undefined}
                      {/* eslint-enable no-nested-ternary */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </PerfectScrollbar>
      </Dropdown.Menu>
    </Dropdown>
  ) : undefined;
}

export default injectIntl(Uploads);
