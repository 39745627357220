/* eslint-disable react/prop-types, react/destructuring-assignment */
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { components } from "react-select";

function ScrollMenu(props) {
  const perfectScrollbarOptions = {
    wheelSpeed: 1,
    swipeEasing: true,
    wheelPropagation: false,
    minScrollbarLength: 60,
    scrollingThreshold: 1000,
  };

  return (
    <components.MenuList {...props}>
      <PerfectScrollbar
        className="scroll"
        data-height="240"
        data-mobile-height="240"
        data-scroll="true"
        options={perfectScrollbarOptions}
        style={{ maxHeight: 235, padding: "5px 0" }}
      >
        {props.children}
      </PerfectScrollbar>
    </components.MenuList>
  );
}

export default ScrollMenu;
