const actionTypes = {
  SetUserPermissions: "userPermissions/SET_USER_PERMISSIONS",
  SetOwnerChargeBeeID: "userPermissions/SET_OWNER_CHARGE_BEE_ID",
};

const initialState = {
  data: null,
  ownerChargebeeid: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetUserPermissions:
      return { ...state, data: action.payload.userPermissions };
    case actionTypes.SetOwnerChargeBeeID:
      return { ...state, ownerChargebeeid: action.payload.ownerChargebeeid };

    default:
      return state;
  }
};

export const actions = {
  setUserPermissions: (userPermissions) => ({
    type: actionTypes.SetUserPermissions,
    payload: { userPermissions },
  }),
  setOwnerChargeBeeID: (ownerChargebeeid) => ({
    type: actionTypes.SetOwnerChargeBeeID,
    payload: { ownerChargebeeid },
  }),
};
