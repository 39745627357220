/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Row, Form, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { getS3File } from "../../crud/files.crud";
import { EULAContext } from "../../context/EULAContext";
import { signAgreement } from "../../crud/users.crud";
import { signOut } from "../../util/auth.util";
import Spinner from "../../partials/content/Spinner";
import * as user from "../../../_augmentt/ducks/user";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

function EULA(props) {
  const { show, latestVersion } = props;

  // eslint-disable-next-line no-unused-vars
  const [eula, setEULA] = useContext(EULAContext);
  const [eulaUrl, setEULAUrl] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [status, setStatus] = useState();

  const [scrollToTop, setScrollTop] = useState();

  const dispatch = useDispatch();

  // eslint-disable-next-line no-shadow
  const currentUser = useSelector(({ user }) => user.data);

  const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
    minScrollbarLength: 60,
  };

  // Get EULA pdf on show
  useEffect(() => {
    if (show) {
      getEULA(); // eslint-disable-line no-use-before-define
    }
  }, [show]);

  // Get EULA PDF URL from S3
  const getEULA = async () => {
    try {
      const result = await getS3File("EULA_Agreement.pdf", "eula_agreement");
      setEULAUrl(result.data);
    } catch (e) {
      setStatus(
        <>
          <FormattedMessage id="GENERAL.ERROR" />
          <a
            href="https://support.augmentt.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="GENERAL.CONTACT_SUPPORT" />
          </a>
        </>,
      );
    }
  };

  // eslint-disable-next-line no-shadow
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onLoadError = ({ error }) => {
    console.error(error);
  };

  const onDecline = async () => {
    // Hide modal and sign out
    setEULA({ show: false });
    await signOut(dispatch);
  };

  const onAccept = async () => {
    const newAgreement = {
      agreementSigned: true,
      latestVersion,
    };

    currentUser.eula = newAgreement;

    try {
      const result = await signAgreement(currentUser);
      currentUser.eula = result.data;

      // Update user in redux store
      dispatch(user.actions.setUser(currentUser));
      setEULA({ show: false });
    } catch (e) {
      console.error(e.message);
      setStatus(
        <>
          <FormattedMessage id="GENERAL.ERROR" />
          <a
            href="https://support.augmentt.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="GENERAL.CONTACT_SUPPORT" />
          </a>
        </>,
      );
    }
  };

  const nextPage = () => {
    if (scrollToTop) {
      scrollToTop.scrollTop = 0;
    }
    let page = pageNumber;
    ++page; // eslint-disable-line no-plusplus
    if (pageNumber < numPages) {
      setPageNumber(page);
    }
  };

  const previousPage = () => {
    if (scrollToTop) {
      scrollToTop.scrollTop = 0;
    }
    let page = pageNumber;
    --page; // eslint-disable-line no-plusplus
    if (pageNumber > 1) {
      setPageNumber(page);
    }
  };

  return (
    <Modal
      id="eulaModal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      backdropClassName="bg-1"
      centered
      show={show}
    >
      <Form>
        <Modal.Body>
          <Row>
            <Col md="12" className="text-center">
              {status && (
                <Alert variant="danger">
                  <div className="alert-text">{status}</div>
                </Alert>
              )}
              <h3 className="font-boldest">
                <FormattedMessage id="GENERAL.EULA" />
              </h3>
              <p className="mb-4 mt-4">
                <FormattedMessage id="GENERAL.READ_AND_ACCEPT" />
              </p>
              <hr />
              <PerfectScrollbar
                className="scroll"
                data-height="400"
                data-mobile-height="400"
                data-scroll="true"
                containerRef={(ref) => {
                  setScrollTop(ref);
                }}
                options={perfectScrollbarOptions}
                style={{ maxHeight: "40vh" }}
              >
                {!eulaUrl && <Spinner />}
                <Document
                  file={eulaUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={onLoadError}
                  noData=""
                  loading={<Spinner />}
                >
                  <Page
                    loading={<Spinner />}
                    renderTextLayer={false}
                    pageNumber={pageNumber}
                  />
                </Document>
              </PerfectScrollbar>
            </Col>
          </Row>
          <p style={{ paddingTop: "15px" }}>
            {pageNumber !== 1 && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <i
                className="flaticon2-back label-icons copyIcon"
                style={{ color: "black", marginLeft: "10px" }}
                onClick={() => previousPage()}
              />
            )}
            Page {pageNumber} of {numPages}
            {pageNumber !== numPages && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <i
                className="flaticon2-next label-icons copyIcon"
                style={{ color: "black", marginLeft: "10px" }}
                onClick={() => nextPage()}
              />
            )}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onDecline} variant="light" className="font-bolder">
            <FormattedMessage id="GENERAL.I_DECLINE" />
          </Button>
          <Button onClick={onAccept} variant="primary" className="font-bolder">
            <FormattedMessage id="GENERAL.I_ACCEPT" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default EULA;
