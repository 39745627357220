/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

const { loadUserflow } = require("userflow.js");

const userFlowToken =
  process.env.REACT_APP_USER_FLOW_TOKEN || "w46hpmyl65hapbvqa3cup7qt5y";

function UserProfile(props) {
  const { showAvatar } = props;

  const currentUser = useSelector(({ user }) => user.data);

  async function trackUser() {
    loadUserflow().then((userflow) => {
      userflow.init(userFlowToken);
      userflow.identify(currentUser.id, {
        name: `${currentUser.first_name} ${currentUser.last_name}`,
        email: currentUser.email,
      });
    });
  }

  useEffect(() => {
    trackUser();
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {currentUser && (
        <Dropdown
          className="header__topbar-item header__topbar-item--user"
          drop="down"
          alignRight
        >
          <Dropdown.Toggle
            as={HeaderDropdownToggle}
            id="dropdown-toggle-user-profile"
          >
            <div className="header__topbar-user">
              {/* eslint-disable-next-line no-nested-ternary */}
              {showAvatar && currentUser.avatar ? (
                <img alt="Avatar" src={currentUser.avatar} />
              ) : showAvatar && !currentUser.avatar ? (
                <img alt="Avatar" src="/media/users/default.jpg" />
              ) : null}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
            {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            <div className="user-card user-card--skin-dark notification-item-padding-x">
              <div className="user-card__name">
                {currentUser.first_name} {currentUser.last_name}
              </div>
              <div className="user-card__badge" />
            </div>
            <div className="notification">
              <Link to="/user-profile" className="notification__item">
                <div className="notification__item-icon">
                  <i className="flaticon2-calendar-3 font-brand" />
                </div>
                <div className="notification__item-details">
                  <div className="notification__item-title font-bold">
                    Your Profile
                  </div>
                  <div className="notification__item-time">
                    Account settings and more
                  </div>
                </div>
              </Link>
              <div className="notification__custom">
                <Link
                  to="/logout"
                  className="btn btn-label-brand btn-sm btn-bold"
                >
                  Sign Out
                </Link>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}

export default UserProfile;
