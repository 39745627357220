// eslint-disable-next-line import/no-extraneous-dependencies
import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();

const EmployeesIntegrationToggleEventEmitter = {
  on: (event, fn) => eventEmitter.on(event, fn),
  once: (event, fn) => eventEmitter.once(event, fn),
  off: (event, fn) => eventEmitter.off(event, fn),
  emit: (event, payload) => eventEmitter.emit(event, payload),
};

const EMPLOYEES_INTEGRATION_TOGGLE_EVENT = "EMPLOYEES_INTEGRATION_TOGGLE_EVENT";

Object.freeze(EmployeesIntegrationToggleEventEmitter);

export {
  EmployeesIntegrationToggleEventEmitter,
  EMPLOYEES_INTEGRATION_TOGGLE_EVENT,
};
