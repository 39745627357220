/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Amplify, { Auth, Cache } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import awsConfig from "../../auth/aws-exports";
import { ReactComponent as TimerIcon } from "../../../../_augmentt/layout/assets/layout-svg-icons/Timer.svg";
import * as sessionModal from "../../../../_augmentt/ducks/sessionModal";

Amplify.configure(awsConfig);

function SessionTimeoutModal(props) {
  const { history } = props;

  const dispatch = useDispatch();

  const { show, description, refresh, idleTimer } = useSelector(
    // eslint-disable-next-line no-shadow
    ({ sessionModal }) => sessionModal,
  );

  const [counter, setCounter] = useState(60);
  const timerId = useRef(null);

  async function refreshSession() {
    try {
      idleTimer?.activate?.();
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.refreshToken, () => {
        // Refresh session in cache, then hide modal
        const now = new Date();
        Cache.setItem("Session", now, {
          expires: now.setHours(now.getHours() + 1),
        });
        // eslint-disable-next-line no-use-before-define
        hide();
      });
    } catch (e) {
      console.error("Unable to refresh Token", e);
    }
  }

  const hide = () => {
    clearInterval(timerId.current);
    timerId.current = null;
    setCounter(60);
    dispatch(sessionModal.actions.closeSessionModal());
  };

  const logout = () => {
    hide();
    history.push("/logout");
  };

  useEffect(() => {
    if (show && counter > 0 && !timerId.current) {
      timerId.current = setInterval(() => {
        if (idleTimer) {
          setCounter(Math.ceil(idleTimer.getRemainingTime() / 1000));
        } else {
          setCounter((t) => t - 1);
        }
      }, 1000);
    }
    if (!counter) {
      logout();
    }
  });

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      show={show}
      onHide={hide}
      keyboard={false}
      id="sessionTimeoutModal"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <FormattedMessage
            id="AUTH.SESSIONTIMEOUT.TITLE"
            defaultMessage="AUTH.SESSIONTIMEOUT.TITLE"
          />
        </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4">
              <span>
                <TimerIcon className="svg-icon modal-icon" />
              </span>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <p>
                {description}&nbsp;
                <FormattedMessage
                  id="AUTH.SESSIONTIMEOUT.DESC"
                  defaultMessage="AUTH.SESSIONTIMEOUT.DESC"
                />
              </p>
              <h3>
                {counter}{" "}
                <FormattedMessage
                  id="AUTH.SESSIONTIMEOUT.SECONDS"
                  defaultMessage="AUTH.SESSIONTIMEOUT.SECONDS"
                />
                .
              </h3>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={logout}
            className={clsx({ "btn-label-brand": refresh })}
            style={{ borderColor: "transparent" }}
          >
            <FormattedMessage
              id="AUTH.SESSIONTIMEOUT.SIGN_OUT_NOW"
              defaultMessage="AUTH.SESSIONTIMEOUT.SIGN_OUT_NOW"
            />
          </Button>
          {refresh && (
            // eslint-disable-next-line react/jsx-no-bind
            <Button onClick={refreshSession} variant="primary">
              <FormattedMessage
                id="AUTH.SESSIONTIMEOUT.STAY_SIGNED_IN"
                defaultMessage="AUTH.SESSIONTIMEOUT.STAY_SIGNED_IN"
              />
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default SessionTimeoutModal;
