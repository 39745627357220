import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dropdown } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  createReport,
  fetchReportData,
  onDownloadFileClick,
} from "../crud/report.crud";
import { delay } from "../../_augmentt/utils/utils";
import * as reports from "../../_augmentt/ducks/reports";
import {
  getCurrentDateLongFormat,
  getNormalizedCustomerName,
} from "../util/string.utils";

const createReportFlow = async ({ setLoading, dispatch, payload }) => {
  const { customer, reportType, reportName, dateRange, fileExtension } =
    payload;
  setLoading(true);
  dispatch(
    reports.actions.setReportError({ status: false, type: null, data: null }),
  );

  try {
    let response = await createReport(
      customer,
      reportType,
      reportName,
      dateRange,
      fileExtension,
    );

    const reportId = response?.data?.id;
    if (!reportId) {
      throw new Error("No report id!");
    }

    let complete = false;
    let retries = 20;
    while (!complete && retries > 0) {
      await delay(3000); // eslint-disable-line no-await-in-loop
      response = await fetchReportData(reportId); // eslint-disable-line no-await-in-loop
      complete = response.data.complete;
      retries -= 1;
    }

    if (!complete) {
      throw new Error("Maximum retries reached!");
    } else if (response?.data?.error || !response?.data?.url) {
      throw new Error("Error generating report!");
    }

    onDownloadFileClick(response.data.url, reportName, fileExtension);
  } catch (err) {
    console.error("An error ocurred while creating the report.", err);
    dispatch(
      reports.actions.setReportError({
        status: true,
        type: "generic",
      }),
    );
  }

  setLoading(false);
};

function ExportLicenseWidget() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const selectedCustomer = useSelector(
    ({ customers }) => customers.selectedCustomer,
  );

  const featureFlags =
    // eslint-disable-next-line no-shadow
    useSelector(({ featureFlags }) => featureFlags.data) || [];
  const scheduledReportsFlags = featureFlags.find(
    (flag) => flag.feature === "scheduled-reports",
  );

  const dateRange = useSelector(({ dates }) => dates.data);

  const onCreateReport = (fileExtension) => {
    const payload = {
      customer: selectedCustomer?.id,
      reportType: "license",
      reportName: `${getNormalizedCustomerName(
        selectedCustomer?.customer_name,
      )}_${intl.formatMessage({
        id: "MENU.LICENSE_REPORT",
      })}_${getCurrentDateLongFormat()}`,
      dateRange,
      fileExtension,
    };
    createReportFlow({ setLoading, dispatch, payload });
  };

  if (loading) {
    return (
      <Button
        variant="light"
        className="spinner spinner--center spinner--md spinner--primary"
      />
    );
  }

  return (
    <Dropdown drop="down" className="btn" style={{ padding: 0 }}>
      <Dropdown.Toggle
        variant="btn"
        className="btn-light"
        id="dropdownToggleExport"
        style={{ marginLeft: 0 }}
      >
        <FormattedMessage id="GENERAL.EXPORT" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim">
        <ul className="nav margin-t-10 margin-b-10">
          <li className="nav__item" data-toggle="collapse">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span onClick={() => onCreateReport("csv")} className="nav__link">
              <span className="nav__link nav__link-text">
                <FormattedMessage id="GENERAL.CSV" />
              </span>
            </span>
          </li>
          <li className="nav__item" data-toggle="collapse">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span onClick={() => onCreateReport("pdf")} className="nav__link">
              <span className="nav__link nav__link-text">
                <FormattedMessage id="GENERAL.PDF" />
              </span>
            </span>
          </li>
          {scheduledReportsFlags.enabled && (
            <li className="nav__item" data-toggle="collapse">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <span
                onClick={() => history.push("/scheduled-reports")}
                className="nav__link"
              >
                <span className="nav__link nav__link-text">
                  <FormattedMessage id="MENU.SCHEDULED_REPORTS" />
                </span>
              </span>
            </li>
          )}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ExportLicenseWidget;
