// Lookup for use in auth. ie hasModulePermission(MODULE.DISCOVER);
module.exports.MODULE = {
  DISCOVER: 1,
  OPTIMIZE: 2,
  ENGAGE: 3,
  DISCOVER_PREMIUM: 4,
  OPTIMIZE_PREMIUM: 5,
  ENGAGE_PREMIUM: 6,
};

module.exports.LICENSE = [
  // discover
  {
    licenseName: "nfr-discover-essentials",
    moduleNumber: 7,
    licenseType: "discover",
    parentCheck: true, // check if the customer dont have parent id
  },
  {
    licenseName: "discover-essentials",
    moduleNumber: 1,
    licenseType: "discover",
    parentCheck: false,
  },
  {
    licenseName: "discover-premium",
    moduleNumber: 4,
    licenseType: "discover",
    parentCheck: false,
  },
  // optimize
  {
    licenseName: "nfr-optimize-essentials",
    moduleNumber: 8,
    licenseType: "optimize",
    parentCheck: true,
  },
  {
    licenseName: "optimize-essentials",
    moduleNumber: 2,
    licenseType: "optimize",
    parentCheck: false,
  },
  {
    licenseName: "optimize-premium",
    moduleNumber: 5,
    licenseType: "optimize",
    parentCheck: false,
  },

  // engage

  {
    licenseName: "nfr-engage-essentials",
    moduleNumber: 9,
    licenseType: "engage",
    parentCheck: true,
  },
  {
    licenseName: "engage-essentials",
    moduleNumber: 3,
    licenseType: "engage",
    parentCheck: false,
  },
  {
    licenseName: "engage-premium",
    moduleNumber: 6,
    licenseType: "engage",
    parentCheck: false,
  },

  // secure

  {
    licenseName: "nfr-secure-essentials",
    moduleNumber: 11,
    licenseType: "secure",
    parentCheck: true,
  },
  {
    licenseName: "secure-essentials",
    moduleNumber: 10,
    licenseType: "secure",
    parentCheck: false,
  },
  {
    licenseName: "secure-premium",
    moduleNumber: 12,
    licenseType: "secure",
    parentCheck: false,
  },
];

// Below are both found in the feature mappings and map to the above modules
// ie hasRoutePermission(ROUTE.route_discovery)

// Matching the route path to the feature name
module.exports.ROUTE = {
  "/discovery": "route_discovery",
  "/data": "route_data",
  "/integrations": "route_integrations",
  "/downloads": "route_downloads",
  "/engage": "route_engage",
};

// ie hasComponentPermission(COMPONENT.component_browserExtension)
module.exports.COMPONENT = {
  component_browserExtension: "component_browserExtension",
  component_appInformation: "component_appInformation",
  component_quickbooks: "component_quickbooks",
};

// List of all plans
module.exports.PLAN = [
  "discover-essentials-monthly",
  "discover-essentials-yearly",
  "discover-premium-monthly",
  "discover-premium-yearly",
  "discover-monthly",
  "optimize-essentials-monthly",
  "optimize-essentials-yearly",
  "optimize-premium-monthly",
  "optimize-premium-yearly",
  "engage-essentials-monthly",
  "Engage-monthly",
  "engage-essentials-yearly",
  "freemium-offer-monthly",
  "freemium-offer-yearly",
];
