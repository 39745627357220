/* eslint-disable react/prop-types, react/destructuring-assignment */
import React from "react";

const HeaderDropdownToggle = React.forwardRef((props, ref) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div
    ref={ref}
    className="header__topbar-wrapper"
    onClick={(e) => {
      e.preventDefault();
      props.onClick(e);
    }}
  >
    {props.children}
  </div>
));

HeaderDropdownToggle.displayName = "HeaderDropdownToggle";
export default HeaderDropdownToggle;
