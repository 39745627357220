import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { delay } from "../../../_augmentt/utils/utils";

async function loadGtmVariables() {
  // waits for google_tag_manager to be defined
  let timeout = 1000;
  // eslint-disable-next-line no-prototype-builtins
  while (!window.hasOwnProperty("google_tag_manager") && timeout < 60 * 1000) {
    // eslint-disable-next-line no-await-in-loop
    await delay(timeout);
    timeout *= 2;
  }

  // makes sure dataLayer is defined before using it
  window.dataLayer = window.dataLayer || [];

  // signal Google Tag Manager  to retrieve the variables defined in the DOM
  window.dataLayer.push({ event: "gtmVariablesLoaded" });
}

/**
 * Provides Google Tag Manager access to required variables after they are loaded
 */
function GoogleTagManagerVars() {
  const currentUser = useSelector(({ user }) => user?.data);
  const userEmail = currentUser?.email;

  const chargebeeId = useSelector(({ meta }) => meta?.chargebeeId);

  useLayoutEffect(() => {
    if (chargebeeId && userEmail) {
      loadGtmVariables();
    }
  }, [chargebeeId, userEmail]);

  return (
    <>
      <span
        id="GTM-ChargebeeId"
        value={chargebeeId ?? null}
        className="d-none"
      />
      <span id="GTM-UserEmail" value={userEmail ?? null} className="d-none" />
    </>
  );
}

export default GoogleTagManagerVars;
