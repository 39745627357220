/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Button, Form, Col, Modal } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Amplify, { Auth, Cache } from "aws-amplify";
import awsConfig from "./aws-exports";
import { signOut } from "../../util/auth.util";
import { getUserById, checkAgreement } from "../../crud/users.crud";
import { getBranding } from "../../crud/branding.crud";
import * as customers from "../../../_augmentt/ducks/customers";
import * as user from "../../../_augmentt/ducks/user";
import * as userPermissions from "../../../_augmentt/ducks/userPermissions";
import * as branding from "../../../_augmentt/ducks/branding";

function OTPModal(props) {
  const { show, onHide, onUpdate, intl, cognitoUser } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
  });
  const [status, setStatus] = useState();

  const initialValues = {
    otp: "",
  };

  const dispatch = useDispatch();
  Amplify.configure(awsConfig);

  const schema1 = Yup.object({
    otp: Yup.string()
      .min(
        6,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_SHORT",
        }),
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      )
      .max(
        6,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_LONG",
        }),
      ),
  });

  async function handleSubmit(values) {
    enableLoading(); // eslint-disable-line no-use-before-define
    try {
      // eslint-disable-next-line no-use-before-define
      await sendMfaCode(values.otp); // CognitoUser Object without Attribute.
      const currentUserInfo = await Auth.currentUserInfo(); // For User Attributes.

      // Set session in cache to get be able to get token from interceptor
      const now = new Date();
      Cache.setItem("Session", now, {
        expires: now.setHours(now.getHours() + 1),
      });

      const userInfo = await getUserById(currentUserInfo.attributes.email);
      const agreement = await checkAgreement(userInfo.data.userData);
      const mspBranding = await getBranding(
        userInfo.data.userData.Customer.Organization.id,
      );

      userInfo.data.eula = agreement.data;

      // Set user info in redux store
      dispatch(user.actions.setUser(userInfo.data.userData));
      // Set user permissions and customer access in redux store
      dispatch(
        userPermissions.actions.setUserPermissions({
          customers: userInfo.data.customers,
          permissions: userInfo.data.permissions,
        }),
      );
      dispatch(
        userPermissions.actions.setOwnerChargeBeeID(
          userInfo.data.ownerChargebeeid,
        ),
      );
      // Set branding info in redux store
      dispatch(
        branding.actions.setBranding(
          mspBranding.data || {
            logo_light: null,
            publish_logo: false,
            favicon: null,
            publish_favicon: false,
          },
        ),
      );
      // Reset saved customers in redux store
      dispatch(customers.actions.setCustomers([]));
    } catch (err) {
      console.error(err);
      signOut(dispatch);
      setStatus(<FormattedMessage id="AUTH.GENERAL.OTP_ERROR" />);
    }

    disableLoading(); // eslint-disable-line no-use-before-define
  }

  // eslint-disable-next-line consistent-return
  async function sendMfaCode(mfaCode) {
    // window.LOG_LEVEL = 'DEBUG'
    try {
      // eslint-disable-next-line no-shadow
      const user = await Auth.confirmSignIn(
        cognitoUser,
        mfaCode,
        "SOFTWARE_TOKEN_MFA",
      );
      return user;
    } catch (error) {
      console.error(error);
      setStatus(
        <>
          <FormattedMessage id="GENERAL.ERROR" />
          <a
            href="https://support.augmentt.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="GENERAL.CONTACT_SUPPORT" />
          </a>
        </>,
      );
    }
  }

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem", paddingLeft: "2.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem", paddingLeft: "2.5rem" });
  };

  const hide = () => {
    onHide();
    setStatus();
  };

  const update = () => {
    onUpdate();
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={hide}
      onSubmit={update}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <FormattedMessage id="AUTH.GENERAL.ENTER_OTP" />
        </Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={schema1}
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          handleSubmit, // eslint-disable-line no-shadow
          handleChange,
          errors,
          values,
          touched,
        }) => (
          <Form
            onSubmit={(values) => handleSubmit(values)} // eslint-disable-line no-shadow
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleSubmit(event);
              }
            }}
          >
            <Modal.Body>
              {status && (
                <Alert variant="danger">
                  <div className="alert-text">{status}</div>
                </Alert>
              )}
              <Form.Group as={Col} md="12" controlId="otp">
                <Form.Label className="inline">
                  <FormattedMessage id="AUTH.GENERAL.ENTER_OTP" />
                </Form.Label>
                <Form.Control
                  className="inline"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "AUTH.GENERAL.OTP",
                  })}
                  value={values.otp}
                  onChange={handleChange}
                  isInvalid={touched.otp && !!errors.otp}
                  isValid={touched.otp && !errors.otp}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.otp}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button id="closeModalBtn" onClick={hide} variant="light">
                <FormattedMessage id="GENERAL.CANCEL" />
              </Button>
              <Button
                id="addApplicationSubmit"
                variant="primary"
                type="submit"
                className={`btn-elevate adduser__btn-primary ${clsx({
                  "spinner spinner--right spinner--md spinner--light": loading,
                })}`}
                onClick={handleSubmit} // eslint-disable-line react/jsx-no-bind
                style={loadingButtonStyle}
              >
                <FormattedMessage id="AUTH.LOGIN.BUTTON" />
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default injectIntl(OTPModal);
