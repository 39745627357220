import { omit, intersection } from "lodash";
import { LICENSE } from "../../../util/auth.constants";

export const GWS = "GWS";
export const OFFICE365 = "Office365";

export const EMPLOYEES_TABLE_KEY = "employees";

export const MODAL_NAMES = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  POPOUT: "POPOUT",
  SECURE: "SECURE",
};

export const hasDiscover = (licenses) =>
  licenses.some(
    (license) =>
      license === "nfr-discover-essentials" ||
      license === "discover-essentials" ||
      license === "discover-premium",
  );

export const hasEngage = (licenses) =>
  licenses.some(
    (license) =>
      license === "nfr-engage-essentials" ||
      license === "engage-essentials" ||
      license === "engage-premium",
  );

export const hasSecureEssentials = (licenses) =>
  licenses.some((license) => license === "secure-essentials");

export const hasSecurePremium = (licenses) =>
  licenses.some(
    (license) =>
      license === "nfr-secure-essentials" || license === "secure-premium",
  );

export const DISCOVER_MODULES = LICENSE.filter(
  (license) => license.licenseType === "discover",
);

export const ENGAGE_MODULES = LICENSE.filter(
  (license) => license.licenseType === "engage",
);

export const SECURE_MODULES = LICENSE.filter(
  (license) => license.licenseType === "secure",
);

export const isFeatureFlagEnabled = (featureFlags, featureName) =>
  (featureFlags || []).some((ff) => ff.feature === featureName && ff.enabled);

export const isSecureFeatureFlagEnabled = (featureFlags) => {
  const secureFFEnabled = isFeatureFlagEnabled(
    featureFlags,
    "secure-licensing",
  );
  const testAccountFFEnabled = isFeatureFlagEnabled(
    featureFlags,
    "test-account",
  );
  return secureFFEnabled || testAccountFFEnabled;
};

const removeCountFromOptions = (options) =>
  options.map((option) => omit(option, ["count"]));

const removeCountFromFilter = (filter) => ({
  ...filter,
  options: filter.options ? removeCountFromOptions(filter.options) : undefined,
});

export const removeCountFromColumns = (columns) =>
  columns.map((column) => ({
    ...column,
    filter: column.filter ? removeCountFromFilter(column.filter) : undefined,
  }));

export const getEmployeesIdsFromIntegrationFilter = (
  values,
  integrationOptions,
) => {
  if (!Array.isArray(values)) {
    return [];
  }

  const employeeIds =
    integrationOptions
      ?.filter((option) => values.includes(option.value))
      ?.map((option) => option.employeeIds)
      ?.flat() ?? [];

  return Array.from(new Set(employeeIds));
};

// transforms redux params before sending them in the request
export const transformParams =
  ({ integrationGroupsOptions, integrationLicensesOptions }) =>
  (params) => {
    const notAssignedIds = params?.filters?.notAssignedIds?.filterVal ?? [];
    const employeesIdsGroups = getEmployeesIdsFromIntegrationFilter(
      params?.filters?.["integration.groups"]?.filterVal,
      integrationGroupsOptions,
    );
    const employeesIdsLicense = getEmployeesIdsFromIntegrationFilter(
      params?.filters?.["integration.licenseType"]?.filterVal,
      integrationLicensesOptions,
    );
    const idsFiltersSelected = [
      ...notAssignedIds,
      ...employeesIdsGroups,
      ...employeesIdsLicense,
    ].length;

    // if there are common ids between the filters will filter by these ids
    // if there is nothing in common, will filter by "[]", which will result in no data for the table
    // if no filter is selected, ids=null, filter will be ignored, which will result in data for the table
    const ids = idsFiltersSelected
      ? intersection(
          ...[notAssignedIds, employeesIdsGroups, employeesIdsLicense].filter(
            (filterValues) => filterValues.length,
          ), // if a filter is not selected ignore it in intersection
        )
      : null;

    return {
      ...params,
      sortField: params.sortField === "name" ? "first_name" : params.sortField, // sorting by merge fields is not supported yet
      filters: {
        ...params.filters,
        "integration.groups": { filterVal: null },
        "integration.licenseType": { filterVal: null },
        notAssignedIds: { filterVal: null },
        id: { filterVal: ids },
      },
    };
  };

// integration
export const mergeIntegrationData = ({
  data = [],
  selectedIntegration,
  selectedIntegrationType,
}) => {
  const groupPath =
    selectedIntegrationType === OFFICE365 ? "m365Groups" : "groups";

  return data.map((item) => ({
    ...item,
    integration: {
      groups:
        // eslint-disable-next-line no-shadow
        selectedIntegration?.[item.id]?.[groupPath]?.map((item) => item.name) ??
        [],
      licenseType:
        selectedIntegration?.[item.id]?.licenseType?.map(
          // eslint-disable-next-line no-shadow
          (item) => item.displayName,
        ) ?? [],
    },
  }));
};

export const getIntegrationOptions = ({
  selectedIntegration = {},
  path,
  labelPath,
}) => {
  const integrationOptions = {
    __noOption: {
      value: "",
      count: 0,
      employeeIds: [],
    },
  };

  Object.values(selectedIntegration).forEach((integration) => {
    const data = integration?.[path] ?? [];

    data.forEach((item) => {
      if (integrationOptions[item.id]) {
        integrationOptions[item.id].count += 1;
        integrationOptions[item.id].employeeIds.push(integration.employeeId);
      } else {
        integrationOptions[item.id] = {
          value: item.id,
          label: item[labelPath],
          employeeIds: [integration.employeeId],
          count: 1,
        };
      }
    });

    if (!data?.length) {
      integrationOptions.__noOption.count += 1; // eslint-disable-line no-underscore-dangle
      integrationOptions.__noOption.employeeIds.push(integration.employeeId); // eslint-disable-line no-underscore-dangle
    }
  });

  // eslint-disable-next-line no-underscore-dangle
  if (integrationOptions.__noOption.count === 0) {
    delete integrationOptions.__noOption; // eslint-disable-line no-underscore-dangle
  }

  return Object.values(integrationOptions);
};
