import axios from "axios";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;

export const CUSTOMERS_URL = `${ROOT}/customer`;
export const EMPLOYEES_URL = `${ROOT}/employee`;

export function getEmployees(customerId) {
  return axios.get(`${CUSTOMERS_URL}/${customerId}/employee`);
}

export function getEmployee(employeeId) {
  return axios.get(`${EMPLOYEES_URL}/${employeeId}`);
}

export function deleteEmployee(employeeId) {
  return axios.delete(`${EMPLOYEES_URL}/${employeeId}`);
}

export function createSubscribers(subs, app, admins, customerId) {
  let employees;
  if (subs[0].id) {
    employees = subs.map((employee) => employee.id);
  } else {
    employees = subs;
  }

  return axios.post(`${EMPLOYEES_URL}/bulkApp`, [
    {
      app,
      employees,
      admins,
      customer: customerId,
    },
  ]);
}

export function updateEmployee(employee) {
  return axios.put(`${EMPLOYEES_URL}/${employee?.id}`, {
    first_name: employee.first_name,
    last_name: employee.last_name,
    phone: employee.phone,
    email: employee.email,
    department: employee.department,
    job_title: employee.job_title,
    display_name: employee.display_name,
    ignored: employee.ignored,
  });
}

export function ignoreEmployee(selectedIds, ignored) {
  return axios.put(`${EMPLOYEES_URL}/ignore`, {
    employees: selectedIds,
    ignored,
  });
}

export function addEmployee(customer, employee) {
  return axios.post(`${CUSTOMERS_URL}/${customer.id}/employee`, {
    employees: [
      {
        first_name: employee.first_name,
        last_name: employee.last_name,
        phone: employee.phone,
        email: employee.email,
        department: employee.department,
        job_title: employee.job_title,
        display_name: employee.display_name,
      },
    ],
  });
}

export function uploadEmployees(file, customerId) {
  return axios.post(`${CUSTOMERS_URL}/${customerId}/employee/template`, {
    key: file.key,
    type: file.extension,
  });
}

export function getDepartments(customer) {
  return axios.post(`${EMPLOYEES_URL}/department`, {
    customer: customer || "*",
  });
}

export function getEmployeesDeviceMappings(customerId) {
  return axios.post(`${EMPLOYEES_URL}/devices/customer`, {
    customerId,
  });
}

export function updateDeviceMapping(customerId, employeeId, profileId) {
  return axios.put(`${EMPLOYEES_URL}/devices/update`, {
    customerId,
    employeeId,
    profile: profileId,
  });
}

export function removeDeviceMapping(customerId, employeeId, profileId) {
  return axios.delete(`${EMPLOYEES_URL}/devices/delete`, {
    data: {
      customerId,
      employeeId,
      profile: profileId,
    },
  });
}

export function assignLicenseToEmployees(customer, employees, modules) {
  return axios.post(`${CUSTOMERS_URL}/${customer.id}/employee/licences`, {
    employeeIds: employees,
    modules,
  });
}

export function getLicenseCount(customer, allCustomers = false) {
  return axios.get(`${CUSTOMERS_URL}/${customer.id}/employee/licences`, {
    params: {
      allCustomers,
    },
  });
}

export function unassignLicenseFromEmployees(customer, employees, module) {
  return axios.delete(`${CUSTOMERS_URL}/${customer.id}/employee/licences`, {
    data: {
      employeeIds: employees,
      modules: module,
    },
  });
}

export function getUnmappedProfiles(customerId) {
  return axios.get(
    `${CUSTOMERS_URL}/${customerId}/employee/profiles/unmapped`,
    {
      customerId,
    },
  );
}

export function getMappedUnmappedProfiles(customerId, employees) {
  return axios.get(`${CUSTOMERS_URL}/${customerId}/profiles`, {
    employeeIds: employees,
  });
}

export function deleteProfile(customerId, profileId) {
  return axios.delete(`${CUSTOMERS_URL}/${customerId}/profiles`, {
    data: {
      customerId,
      profile: profileId,
    },
  });
}

export function getImportSettings(customerId, integrationType) {
  return axios.get(`${CUSTOMERS_URL}/${customerId}/auto-ignore`, {
    params: {
      integrationType,
    },
  });
}

export function postImportSettings(customerId, integrationType, params) {
  return axios.post(`${CUSTOMERS_URL}/${customerId}/auto-ignore`, {
    ...params,
  });
}

export function putImportSettings(customerId, integrationType, params) {
  return axios.put(`${CUSTOMERS_URL}/${customerId}/auto-ignore`, {
    ...params,
  });
}

export function postImportSettingsEstimate(
  customerId,
  integrationType,
  params,
) {
  return axios.post(`${CUSTOMERS_URL}/${customerId}/auto-ignore/estimate`, {
    ...params,
  });
}

export function deleteImportSettings(customerId, integrationType) {
  return axios.delete(`${CUSTOMERS_URL}/${customerId}/auto-ignore`, {
    params: {
      integrationType,
    },
  });
}
