import axios from "axios";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;

export const ORG_URL = `${ROOT}/organization`;
export const CUSTOMER_URL = `${ROOT}/customer`;
export const USER_CUSTOMERS_URL = `${ROOT}/organization/user/customers`;

export function getOrgs() {
  return axios.get(ORG_URL);
}

export function getOrg(orgId) {
  return axios.get(`${ORG_URL}/${orgId}`);
}

export function getCustomer(customerId, force) {
  let url = `${CUSTOMER_URL}/${customerId}`;
  if (force) {
    // if we need to force and not cache, temporary fix to add a timestamp
    url = `${url}?t=${Date.now()}`;
  }
  return axios.get(url);
}

export function getOrgCustomers(orgId) {
  return axios.get(`${ORG_URL}/${orgId}/customer`);
}

export function getOrgPermissionLevels() {
  return axios.get(`${ORG_URL}/user/permissionLevels`);
}
export function getPermissionEntities() {
  return axios.get(`${ORG_URL}/user/permissionEntities`);
}

export function getUserInfo(orgId, userId) {
  return axios.get(`${ORG_URL}/${orgId}/userPermissions/${userId}`);
}

export function getCustomers(customerId, active) {
  return axios.get(
    `${CUSTOMER_URL}/${customerId || "*"}/customers${
      // eslint-disable-next-line no-nested-ternary
      active && active === true
        ? "?activation=true"
        : active === false
          ? "?activation=false"
          : ""
    }`,
  );
}

export function getUserCustomers(active, appname) {
  const url = new URL(USER_CUSTOMERS_URL);

  if ([true, false].includes(active)) {
    url.searchParams.append("activation", active);
  }

  if (appname) {
    url.searchParams.append("appname", appname);
  }

  const fullUrl = url.toString();
  return axios.get(fullUrl);
}
export function createOrg(organization) {
  return axios.post(ORG_URL, organization);
}

export function updateOrg(organization) {
  return axios.put(`${ORG_URL}/${organization.id}`, organization);
}

export function createCustomer(customer) {
  return axios.post(`${ORG_URL}/${customer.organization}/customer`, customer);
}

export function updateCustomer(customer) {
  return axios.put(`${CUSTOMER_URL}/${customer.id}`, customer);
}

export function deleteCustomer(customerId) {
  return axios.delete(`${CUSTOMER_URL}/${customerId}`);
}

export function deactivateCustomer(customerId) {
  return axios.put(`${CUSTOMER_URL}/${customerId}/activation`, {
    activation: false,
  });
}

export function enable2fa(customerId) {
  return axios.put(`${CUSTOMER_URL}/${customerId}`, {
    MFA: true,
  });
}

export function disable2fa(customerId) {
  return axios.put(`${CUSTOMER_URL}/${customerId}`, {
    MFA: false,
  });
}

export function reactivateCustomer(customerId) {
  return axios.put(`${CUSTOMER_URL}/${customerId}/activation`, {
    activation: true,
  });
}

export function updateAutoAssign(customerId, updates) {
  return axios.put(`${CUSTOMER_URL}/license/autoassign`, {
    customerId,
    update: updates,
  });
}

export function getAutoAssign(customerId) {
  return axios.get(`${CUSTOMER_URL}/license/autoassign`, {
    params: {
      customerId,
    },
  });
}

export function getOnboardingChecklist({ organizationId }) {
  return axios.get(`${ORG_URL}/${organizationId}/onboarding-checklist`);
}

export function putOnboardingChecklist({
  organizationId,
  checkName,
  dismissed,
}) {
  return axios.put(`${ORG_URL}/${organizationId}/onboarding-checklist`, {
    checkName,
    dismissed,
  });
}

// Check if secure nfr is enabled, and the opposite (premium or essentials) is not set
const hasNFRCheck = (autoAssignData, opposite) => {
  let hasModuleEnabled = false;

  // Find opposite
  if (opposite) {
    for (let i = 0; i < autoAssignData.modules.length; i += 1) {
      const data = autoAssignData.modules[i];
      if (data.module === opposite && data.isAutoAssigned === true) {
        return hasModuleEnabled; // return false
      }
    }
  }

  // Find nfr
  for (let i = 0; i < autoAssignData.modules.length; i += 1) {
    const data = autoAssignData.modules[i];
    if (data.module === 11 && data.isAutoAssigned === true) {
      hasModuleEnabled = true;
      break;
    }
  }
  return hasModuleEnabled;
};

// Retrieve the auto assign modules - Actioning on secure
export async function isSecureEnabledForTenant(customerId, includeNFR) {
  // Check if has nfr and opposite isn't turned on

  let isAutoAssigned = false;

  // Grab the auto assigned modules

  if (customerId !== undefined) {
    const autoAssign = await getAutoAssign(customerId);

    // Make sure we have some and the specific customer's auto assign data
    if (
      autoAssign.data.length === 1 &&
      autoAssign.data[0].customerId === customerId
    ) {
      const hasNfr = hasNFRCheck(autoAssign.data[0], 12);
      // For each module
      // eslint-disable-next-line array-callback-return
      autoAssign.data[0].modules.map((mod) => {
        // Check if the module is secure
        if (mod.module === 10) {
          if (mod.isAutoAssigned === true || (includeNFR && hasNfr)) {
            // If is auto assigned, mark secure as enabled
            isAutoAssigned = true;
          }
        }
      });
    }
  }

  return isAutoAssigned;
}

// TODO Modularize

// Retrieve the auto assign modules - Actioning on secure
export async function isSecurePremiumEnabledForTenant(customerId, includeNFR) {
  let isAutoAssigned = false;
  // Grab the auto assigned modules

  if (customerId !== undefined) {
    const autoAssign = await getAutoAssign(customerId);

    // Make sure we have some and the specific customer's auto assign data
    if (
      autoAssign.data.length === 1 &&
      autoAssign.data[0].customerId === customerId
    ) {
      // Check if has nfr and opposite isn't turned on
      const hasNfr = hasNFRCheck(autoAssign.data[0]);

      if (includeNFR && hasNfr) {
        return true;
      }

      // For each module
      // eslint-disable-next-line array-callback-return
      autoAssign.data[0].modules.map((mod) => {
        // Check if the module is Secure Premium or NFR secure-essentials which is also premium
        if (mod.module === 12) {
          if (mod.isAutoAssigned === true) {
            // If is auto assigned, mark Secure Premium as enabled
            isAutoAssigned = true;
          }
        }
      });
    }
  }
  return isAutoAssigned;
}
