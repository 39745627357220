import { useState, useCallback } from "react";
import {
  getOnboardingChecklist,
  putOnboardingChecklist,
} from "../crud/orgs.crud";

const requestOnboardingChecklist = async ({
  setError,
  setLoading,
  setData,
  setMeta,
  params,
  options = {},
}) => {
  const { silent } = options;
  setLoading(!silent);
  setError(null);

  try {
    const response = await getOnboardingChecklist(params);
    setData(response?.data?.checklist ?? []);
    setMeta(response?.data?.meta);
  } catch (error) {
    console.error(error);
    setError(true);
  }

  setLoading(false);
};

const updateChecklistItem = async ({
  setError,
  setItemLoading,
  setLoading,
  setData,
  setMeta,
  params,
}) => {
  const { checkName } = params;
  setItemLoading(checkName);
  setError(null);

  try {
    await putOnboardingChecklist(params);
    await requestOnboardingChecklist({
      setError,
      setLoading,
      setData,
      setMeta,
      params,
      options: { silent: true },
    });
  } catch (error) {
    console.error(error);
    setError(true);
  }

  setItemLoading(null);
};

const useOnboardingChecklist = () => {
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(null);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [meta, setMeta] = useState(null);

  const requestOnboardingChecklistCb = useCallback(
    (params) =>
      requestOnboardingChecklist({
        setError,
        setLoading,
        setData,
        setMeta,
        params,
      }),
    [],
  );

  const updateChecklistItemCb = useCallback(
    (params) =>
      updateChecklistItem({
        setError,
        setLoading,
        setItemLoading,
        setData,
        setMeta,
        params,
      }),
    [],
  );

  return {
    loading,
    itemLoading,
    error,
    data,
    meta,
    requestOnboardingChecklist: requestOnboardingChecklistCb,
    updateChecklistItem: updateChecklistItemCb,
  };
};

export default useOnboardingChecklist;
