import axios from "axios";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;

export const PORTAL_URL = `${ROOT}/tools/chargebee/portal`;
export const PAYMENT_URL = `${ROOT}/payment/webhook`;

export function makePayment() {
  return axios.post(PAYMENT_URL, {
    id: 0,
    occured_at: new Date().toISOString,
    source: "portal",
    content: {},
  });
}

export function setPortal(chargebeeId) {
  return axios.post(PORTAL_URL, { chargebee_id: chargebeeId });
}
