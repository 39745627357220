const actionTypes = {
  SetShowSecurityChecksPage: "secure/SET_SHOW_SECURITY_CHECKS_PAGE",
  SetReloadSecurityPostureData: "secure/SET_RELOAD_SECURITY_POSTURE_DATA",
  SetSecurityCheck: "secure/SET_SECURITY_CHECK",
};

const initialState = {
  showSecurityChecksPage: undefined,
  reloadSecurityPostureData: false,
  securityCheck: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetShowSecurityChecksPage:
      return {
        ...state,
        showSecurityChecksPage: action.payload.showSecurityChecksPage,
      };
    case actionTypes.SetReloadSecurityPostureData:
      return {
        ...state,
        reloadSecurityPostureData: action.payload.reloadSecurityPostureData,
      };
    case actionTypes.SetSecurityCheck:
      return {
        ...state,
        securityCheck: action.payload.securityCheck,
      };
    default:
      return state;
  }
};

export const actions = {
  setShowSecurityChecksPage: (showSecurityChecksPage) => ({
    type: actionTypes.SetShowSecurityChecksPage,
    payload: { showSecurityChecksPage },
  }),
  setReloadSecurityPostureData: (reloadSecurityPostureData) => ({
    type: actionTypes.SetReloadSecurityPostureData,
    payload: { reloadSecurityPostureData },
  }),
  setSecurityCheck: (securityCheck) => ({
    type: actionTypes.SetSecurityCheck,
    payload: { securityCheck },
  }),
};
