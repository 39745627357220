const actionTypes = {
  SetUser: "user/SET_USER",
};

const initialState = {
  data: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetUser:
      return { ...state, data: action.payload.user };

    default:
      return state;
  }
};

export const actions = {
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};
