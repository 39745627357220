/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Modal, Button, ProgressBar } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import Select from "react-select";
import axios from "axios";
import clsx from "clsx";
import { Formik } from "formik";
import StyledDropzone from "../../../partials/content/StyledDropzone";
import { getS3File, getS3FileUploadURL } from "../../../crud/files.crud";
import { uploadEmployees } from "../../../crud/employees.crud";
import ErrorModal from "./ErrorModal";
import { selectStyle } from "../../../../_augmentt/utils/styles";
import * as employees from "../../../../_augmentt/ducks/employees";

const { Entropy } = require("entropy-string");

function ImportEmployeesModal(props) {
  const {
    show,
    onHide,
    onUpdate,
    intl,
    customers,
    file,
    fileData,
    user,
    history,
  } = props;

  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
  });
  const [status, setStatus] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [customerOptions, setCustomers] = useState([]);
  const [currentFile, setFile] = useState(file || undefined);
  const [currentFileData, setFileData] = useState(fileData || undefined);
  const [progressModalShow, setProgressShow] = useState(false);
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [uploadProgress, setProgress] = useState();

  const entropy = new Entropy();

  const initialValues = {
    fileName: file ? file.name : "",
    fileType: file ? file.type : "",
    company: user ? user.Customer.id : undefined,
  };

  // Populate customer select control
  useEffect(() => {
    const allCustomers = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const i in customers) {
      allCustomers.push({
        value: customers[i].id,
        label: customers[i].customer_name,
      });
    }
    setCustomers(allCustomers);
  }, [customers]);

  /**
   * Handle add file
   * @param {*} file
   */
  // eslint-disable-next-line no-shadow
  const handleAddFile = (file) => {
    setStatus();
    // eslint-disable-next-line no-use-before-define
    enableLoading();

    const reader = new FileReader();
    // eslint-disable-next-line func-names
    reader.onload = function () {
      const data = reader.result;
      // eslint-disable-next-line no-use-before-define
      disableLoading();
      setFileData(data);
      setFile(file[0]);
    };

    reader.readAsText(file[0]);
  };

  /**
   * Handle rejected drop
   * @param {*} e
   */
  const handleDropRejected = (e) => {
    if (e[0].errors[0].code === "file-invalid-type") {
      setStatus(<FormattedMessage id="SETUP.INVALID_FILE_TYPE_XLS" />);
    } else {
      setStatus(<FormattedMessage id="SETUP.FILE_TOO_BIG" />);
    }
  };

  /**
   * Handle the employee template file download
   * @param {*} url
   */
  const onDownloadFileClick = async (url) => {
    try {
      // eslint-disable-next-line no-shadow
      const file = await getS3File(url, "read");
      const link = document.createElement("a");
      link.href = file.data;
      link.download = url;
      link.click();
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Handle submit
   * @param {*} values
   */
  const handleSubmit = async (values) => {
    let selectedCustomer = customers.filter(
      (obj) => obj.id === values.company.id,
    );

    if (!selectedCustomer.length) {
      selectedCustomer = customers.filter(
        (customer) => customer.customer_name === values.company.label,
      );
    }

    const customer = {
      id: selectedCustomer[0].id,
      customer_name: selectedCustomer[0].customer_name,
      organization: {
        id: selectedCustomer[0].organization,
      },
      parent_id: selectedCustomer[0].parent_id,
    };

    try {
      setStatus();
      // eslint-disable-next-line no-use-before-define
      enableLoading();

      const randomKey = entropy.string();
      const token = await getS3FileUploadURL(
        `${user.amazon_id}/${randomKey}`,
        false,
      );

      const uploadURL = token.data;

      // SHOW PROGRESS MODAL
      setProgressShow(true);

      // UPLOAD TO S3 STORAGE BUCKET
      axios
        .put(uploadURL, currentFile, {
          headers: { "Content-Type": currentFile.type },
          onUploadProgress: (progress) => {
            const percentDone = (
              (progress.loaded / progress.total) *
              100
            ).toFixed();
            setProgress(percentDone);
          },
        })
        .then(() => {
          setProgressShow(false);
          // eslint-disable-next-line no-use-before-define
          finalizeUpload(randomKey, customer);
        })
        .catch((err) => {
          console.error(err);
          // eslint-disable-next-line no-use-before-define
          disableLoading();
          setProgressShow(false);
          setErrorModalShow(true);
        });
    } catch (e) {
      console.error(e);
      setStatus(
        <>
          <FormattedMessage id="GENERAL.ERROR" />
          <a
            href="mailto:support@augmentt.com?subject=Issue with importing employees in application"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="GENERAL.CONTACT_SUPPORT" />
          </a>
        </>,
      );
    }
  };

  async function finalizeUpload(key, customer) {
    const stagedFile = {
      file_name: file
        ? file.name.substring(0, file.name.lastIndexOf("."))
        : currentFile.name.substring(0, currentFile.name.lastIndexOf(".")),
      extension: file
        ? file.name.substring(file.name.lastIndexOf(".") + 1)
        : currentFile.name.substring(currentFile.name.lastIndexOf(".") + 1),
      size: file ? file.size : currentFile.size,
      key: `${user.amazon_id}/${key}`,
    };

    try {
      await uploadEmployees(stagedFile, customer.id);

      // Trigger employee update
      dispatch(employees.actions.employeeRefresh(true));
      dispatch(employees.actions.employeeRefresh(false));

      // eslint-disable-next-line no-use-before-define
      update();
      // eslint-disable-next-line no-use-before-define
      hide();
    } catch (e) {
      console.error(e);
      // eslint-disable-next-line no-use-before-define
      disableLoading();
      if (e.response.data.message === "Employee Template Parse Failed") {
        setStatus(
          intl.formatMessage({ id: "ERROR.PROBLEM_PARSING_EMPLOYEES" }),
        );
      } else {
        setStatus(
          <>
            <FormattedMessage id="GENERAL.ERROR" />
            <a
              href="mailto:support@augmentt.com?subject=Issue with file upload in application"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="GENERAL.CONTACT_SUPPORT" />
            </a>
          </>,
        );
      }
    }
  }

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem", paddingLeft: "2.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem", paddingLeft: "2.5rem" });
  };

  const errorModalClose = () => {
    setErrorModalShow(false);
  };

  const hide = () => {
    onHide();
    disableLoading();
    setProgress(0);
    setFile();
    setFileData();
    setStatus();
  };

  // eslint-disable-next-line no-shadow
  const update = (file, customer) => {
    onUpdate(file, customer);
  };

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="importEmployeesModal"
        show={show}
        onHide={hide}
        onSubmit={update}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <FormattedMessage id="ADMINISTRATION.IMPORT_EMPLOYEE_LIST" />
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            // eslint-disable-next-line no-shadow
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
            errors,
          }) => (
            <Form>
              <Modal.Body>
                {status && (
                  <div
                    role="alert"
                    className={`alert alert-${errors ? "danger" : "success"}`}
                  >
                    <div className="alert-text">{status}</div>
                  </div>
                )}
                {!currentFile && !currentFileData && !file && !fileData && (
                  <>
                    <p>
                      <FormattedMessage id="ADMINISTRATION.IMPORT_EMPLOYEES_DESC" />
                      &nbsp;
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <span
                        onClick={() =>
                          onDownloadFileClick("Employee_Template.xlsx")
                        }
                        className="link"
                      >
                        <FormattedMessage id="ADMINISTRATION.IMPORT_EMPLOYEES_DESC2" />
                      </span>
                      &nbsp;
                      <FormattedMessage id="ADMINISTRATION.IMPORT_EMPLOYEES_DESC3" />
                    </p>
                    <br />
                    <StyledDropzone
                      // eslint-disable-next-line no-shadow
                      onDropAccepted={(file) => handleAddFile(file)}
                      onDropRejected={handleDropRejected}
                      accept=".xlsx, application/vnd.ms-excel, application/xls"
                    />
                    <br />
                    <p>
                      <FormattedMessage id="ADMINISTRATION.IMPORT_EMPLOYEES_DESC4" />
                    </p>
                  </>
                )}
                {((currentFile && currentFileData) || (file && fileData)) && (
                  <>
                    <Form.Group>
                      <Form.Label>
                        <FormattedMessage id="SETUP.DATA_UPLOAD.DATA_IS_FOR" />
                      </Form.Label>
                    </Form.Group>
                    <Form.Group controlId="company">
                      <Form.Label className="inline">Company:</Form.Label>
                      <Select
                        name="company"
                        className="inline"
                        styles={selectStyle}
                        options={customerOptions}
                        value={values.company}
                        onChange={(value) => {
                          setFieldValue("company", value);
                          setSelectedCompany(value);
                        }}
                        onBlur={() => setFieldTouched("company", true)}
                        placeholder={intl.formatMessage({
                          id: "PLACEHOLDER.SELECT_COMPANY",
                        })}
                      />
                    </Form.Group>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  id="uploadBtn"
                  onClick={handleSubmit}
                  disabled={!currentFile || !selectedCompany || isLoading}
                  className={`btn-elevate signup__btn-primary ${clsx({
                    "spinner spinner--right spinner--md spinner--light":
                      isLoading,
                  })}`}
                  style={loadingButtonStyle}
                >
                  <FormattedMessage id="GENERAL.SUBMIT" />
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        size="sm"
        show={progressModalShow}
        onHide={() => setProgressShow(false)}
        autoFocus
        enforceFocus
        keyboard={false}
        centered
        className="progress_modal"
      >
        <Modal.Header>
          <Modal.Title id="progressModal">
            {intl.formatMessage({
              id: "SETUP.UPLOADING",
            })}
            ...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar
            animated
            variant="primary"
            now={uploadProgress || 0}
            max={100}
            label={`${uploadProgress}%`}
          />
        </Modal.Body>
      </Modal>

      <ErrorModal
        show={errorModalShow}
        onHide={errorModalClose}
        history={history}
      />
    </>
  );
}

export default injectIntl(ImportEmployeesModal);
