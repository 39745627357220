import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { signOut } from "../../util/auth.util";

export default function Logout() {
  const dispatch = useDispatch();

  // Basically on load of the this component, log us out
  useEffect(() => {
    const foo = async () => {
      await signOut(dispatch);
    };
    foo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
