/* eslint-disable react/prop-types, react/destructuring-assignment */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import KTToggle from "../../_assets/js/toggle";
import { getLandingPagePath } from "../../utils/utils";

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();

  componentDidMount() {
    // eslint-disable-next-line no-new
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
  }

  render() {
    const {
      headerStickyLogo,
      asideDisplay,
      headerMobileCssClasses,
      headerMobileAttributes,
      permissions,
    } = this.props;

    const landingPagePath = getLandingPagePath(permissions);

    return (
      <div
        id="header_mobile"
        className={`header-mobile ${headerMobileCssClasses}`}
        {...headerMobileAttributes}
      >
        <div className="header-mobile__logo">
          <Link to={landingPagePath}>
            <img alt="logo" src={headerStickyLogo()} />
          </Link>
        </div>

        <div className="header-mobile__toolbar">
          {asideDisplay && (
            <button
              type="button"
              className="header-mobile__toggler header-mobile__toggler--left"
              id="aside_mobile_toggler"
            >
              <span />
            </button>
          )}

          <button
            type="button"
            ref={this.toggleButtonRef}
            className="header-mobile__topbar-toggler"
            id="header_mobile_topbar_toggler"
          >
            <i className="flaticon-more" />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  headerStickyLogo: () =>
    builder.selectors.getStickyLogo(store, builder.selectors.getLogo(store)),
  asideDisplay: objectPath.get(
    store.builder.layoutConfig,
    "aside.self.display",
  ),
  headerMenuSelfDisplay:
    objectPath.get(store.builder.layoutConfig, "header.menu.self.display") ===
    true,
  toggleOptions: {
    target: "body",
    targetState: "header__topbar--mobile-on",
    togglerState: "header-mobile__toolbar-topbar-toggler--active",
  },
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: "header_mobile",
    toString: true,
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: "aside_menu",
  }),
  permissions: store.userPermissions.data.permissions,
});

export default connect(mapStateToProps)(HeaderMobile);
