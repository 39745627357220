/* eslint-disable react/prop-types, react/destructuring-assignment */
import React from "react";
import { isString } from "lodash";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { ReactComponent as BetaMarker } from "../assets/layout-svg-icons/Beta-marker.svg";

function MenuItemText(props) {
  const { item, parentItem } = props;

  return (
    <>
      {isString(item.icon) ? (
        <i className={`menu__link-icon ${item.icon}`} />
      ) : (
        <i className="menu__link-icon submenu">{item.icon}</i>
      )}

      {parentItem && parentItem.bullet === "dot" && (
        <i className="menu__link-bullet menu__link-bullet--dot">
          <span />
        </i>
      )}

      {parentItem && parentItem.bullet === "line" && (
        <i className="menu__link-bullet menu__link-bullet--line">
          <span />
        </i>
      )}

      <span
        className="menu__link-text"
        style={item.badge ? { flexGrow: 0 } : { whiteSpace: "nowrap" }}
      >
        {item.translate ? (
          <FormattedMessage
            id={item.translate}
            defaultMessage={item.translate}
          />
        ) : (
          item.title
        )}
      </span>

      {item.badge && (
        <span className="menu__link-badge pl-4">
          {item.badge === "beta" && (
            <span className="badge-lg">
              <BetaMarker />
            </span>
          )}
        </span>
      )}

      {item.submenu && <i className="menu__ver-arrow flaticon2-right-arrow" />}
    </>
  );
}

export default withRouter(MenuItemText);
