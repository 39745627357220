const actionTypes = {
  SetFeatureFlags: "featureflags/SET_FEATURE_FLAGS",
};

const initialState = {
  data: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetFeatureFlags:
      return { ...state, data: action.payload.featureFlags };
    default:
      return state;
  }
};

export const actions = {
  setFeatureFlags: (featureFlags) => ({
    type: actionTypes.SetFeatureFlags,
    payload: { featureFlags },
  }),
};
