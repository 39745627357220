/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Col, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import clsx from "clsx";
import ScrollMenu from "../../../partials/content/ScrollMenu";
import { addEmployee } from "../../../crud/employees.crud";
import { selectStyle } from "../../../../_augmentt/utils/styles";
import * as employees from "../../../../_augmentt/ducks/employees";

function AddEmployeeModal(props) {
  // Props
  const { show, onHide, onUpdate, intl, customer, customers } = props;
  const dispatch = useDispatch();

  // Local states
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
  });
  const [status, setStatus] = useState();
  const [defaultCustomer, setDefaultCustomer] = useState();
  const [customerOptions, setCustomers] = useState();
  const [phone, setPhone] = useState();

  // Set customer options for customer select from state
  useEffect(() => {
    if (customers) {
      // eslint-disable-next-line no-shadow
      const customerOptions = [];
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const i in customers) {
        if (customers[i]?.id) {
          customerOptions.push({
            value: customers[i].id,
            label: customers[i].customer_name,
          });
        }
        if (customers[i]?.id === customer.id) {
          setDefaultCustomer(customerOptions[i]);
        }
      }
      setCustomers(customerOptions);
    }
  }, [customers, customer]); // eslint-disable-line react-hooks/exhaustive-deps

  const schema = Yup.object({
    firstName: Yup.string()
      .min(
        2,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_SHORT",
        }),
      )
      .trim(
        intl.formatMessage({
          id: "AUTH.VALIDATION.TRIM",
        }),
      )
      .max(
        50,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_LONG",
        }),
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
    lastName: Yup.string()
      .min(
        2,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_SHORT",
        }),
      )
      .trim(
        intl.formatMessage({
          id: "AUTH.VALIDATION.TRIM",
        }),
      )
      .max(
        50,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_LONG",
        }),
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
    email: Yup.string()
      .email()
      .min(
        5,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_SHORT",
        }),
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
    department: Yup.string()
      .min(
        2,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_SHORT",
        }),
      )
      .trim(
        intl.formatMessage({
          id: "AUTH.VALIDATION.TRIM",
        }),
      )
      .max(
        50,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_LONG",
        }),
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
    jobTitle: Yup.string()
      .min(
        2,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_SHORT",
        }),
      )
      .max(
        50,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_LONG",
        }),
      ),
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    department: "",
    customer: defaultCustomer,
  };

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem", paddingLeft: "2.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem", paddingLeft: "2.5rem" });
  };

  async function handleSubmit(values) {
    enableLoading();
    const employee = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone,
      department: values.department,
      job_title: values.jobTitle,
    };

    for (let i = 0; i < customers.length; i += 1) {
      if (customers[i].id === values.customer.value) {
        // eslint-disable-next-line no-use-before-define
        addEmployeeToCustomer(employee, customers[i]);
      }
    }
  }

  // eslint-disable-next-line no-shadow
  async function addEmployeeToCustomer(employee, customer) {
    try {
      await addEmployee(customer, employee);

      // Trigger employee update
      dispatch(employees.actions.employeeRefresh(true));

      // eslint-disable-next-line no-use-before-define
      hide();
      // eslint-disable-next-line no-use-before-define
      update();
    } catch (e) {
      console.error(e.message);
      if (e.response.data === "No Licence!") {
        setStatus(
          <FormattedMessage
            id="ADMINISTRATION.COMPANY_NOT_SUBSCRIBED"
            values={{
              module: "Optimize",
            }}
          />,
        );
      } else {
        setStatus(
          <>
            <FormattedMessage id="GENERAL.ERROR" />
            <a
              href="https://support.augmentt.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="GENERAL.CONTACT_SUPPORT" />
            </a>
          </>,
        );
      }
    }
    disableLoading();
  }

  const hide = () => {
    onHide();
    setPhone();
    setStatus();
  };

  const update = () => {
    onUpdate();
  };

  return (
    <Modal
      id="addEmployeeModal"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={hide}
      onSubmit={update}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <FormattedMessage id="ADMINISTRATION.ADD_EMPLOYEE" />
        </Modal.Title>
      </Modal.Header>

      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        initialErrors={schema.isValidSync(initialValues)}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          // eslint-disable-next-line no-shadow
          handleSubmit,
          handleChange,
          setFieldValue,
          setFieldTouched,
          values,
          errors,
          touched,
        }) => (
          <Form>
            <Modal.Body>
              <div className="row justify-content-center align-items-center">
                <Col md="12">
                  {status && (
                    <div
                      role="alert"
                      className={`alert alert-${errors ? "danger" : "success"}`}
                    >
                      <div className="alert-text">{status}</div>
                    </div>
                  )}
                </Col>
                <Form.Group as={Col} md="12" controlId="customer">
                  <Form.Label className="inline">
                    <FormattedMessage id="ADMINISTRATION.COMPANY" />
                  </Form.Label>
                  <Select
                    name="customer"
                    className="inline"
                    styles={selectStyle}
                    options={customerOptions}
                    value={values.customer}
                    onChange={(value) => setFieldValue("customer", value)}
                    onBlur={() => setFieldTouched("customer", true)}
                    components={{ MenuList: ScrollMenu }}
                  />
                </Form.Group>
                <br />
                <Form.Group as={Col} md="12" controlId="employeeFirstName">
                  <Form.Label className="inline">
                    <FormattedMessage id="FORM.FIRST_NAME" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    className="inline"
                    placeholder={intl.formatMessage({
                      id: "PLACEHOLDER.ENTER_FIRST_NAME",
                    })}
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={(e) => {
                      const val = (e.target.value || "").replace(/\s+/gi, " ");
                      setFieldValue("firstName", val.trim());
                      setFieldTouched("firstName", true, false);
                    }}
                    onKeyPress={(event) => {
                      if (event.key === " " && !event.target.value.length) {
                        event.preventDefault();
                      }
                    }}
                    isInvalid={touched.firstName && !!errors.firstName}
                    isValid={touched.firstName && !errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="employeeLastName">
                  <Form.Label className="inline">
                    <FormattedMessage id="FORM.LAST_NAME" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    className="inline"
                    placeholder={intl.formatMessage({
                      id: "PLACEHOLDER.ENTER_LAST_NAME",
                    })}
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={(e) => {
                      const val = (e.target.value || "").replace(/\s+/gi, " ");
                      setFieldValue("lastName", val.trim());
                      setFieldTouched("lastName", true, false);
                    }}
                    onKeyPress={(event) => {
                      if (event.key === " " && !event.target.value.length) {
                        event.preventDefault();
                      }
                    }}
                    isInvalid={touched.lastName && !!errors.lastName}
                    isValid={touched.lastName && !errors.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="employeeEmail">
                  <Form.Label className="inline">
                    <FormattedMessage
                      id="FORM.EMAIL"
                      defaultMessage="FORM.EMAIL"
                    />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    className="inline"
                    placeholder={intl.formatMessage({
                      id: "PLACEHOLDER.ENTER_EMAIL",
                    })}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={(e) => {
                      const val = (e.target.value || "").replace(/\s+/gi, " ");
                      setFieldValue("email", val.trim());
                      setFieldTouched("email", true, false);
                    }}
                    onKeyPress={(event) => {
                      if (event.key === " " && !event.target.value.length) {
                        event.preventDefault();
                      }
                    }}
                    isInvalid={touched.email && !!errors.email}
                    isValid={touched.email && !errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="phone">
                  <Form.Label className="inline">
                    <FormattedMessage id="ADMINISTRATION.PHONE" />
                  </Form.Label>
                  <PhoneInput
                    name="phone"
                    preferredCountries={["ca", "us", "gb"]}
                    country="ca"
                    value={values.phone}
                    // eslint-disable-next-line no-shadow
                    onChange={(phone) => setPhone(phone)}
                    placeholder={intl.formatMessage({
                      id: "PLACEHOLDER.ENTER_PHONE",
                    })}
                    disableCountryCode={!phone}
                  />
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="employeeDepartment">
                  <Form.Label className="inline">
                    <FormattedMessage id="FORM.DEPARTMENT" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="department"
                    className="inline"
                    placeholder={intl.formatMessage({
                      id: "PLACEHOLDER.ENTER_DEPARTMENT",
                    })}
                    value={values.department}
                    onChange={handleChange}
                    onBlur={(e) => {
                      const val = (e.target.value || "").replace(/\s+/gi, " ");
                      setFieldValue("department", val.trim());
                      setFieldTouched("department", true, false);
                    }}
                    onKeyPress={(event) => {
                      if (event.key === " " && !event.target.value.length) {
                        event.preventDefault();
                      }
                    }}
                    isInvalid={touched.department && !!errors.department}
                    isValid={touched.department && !errors.department}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.department}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="employeeJobTitle">
                  <Form.Label className="inline">
                    <FormattedMessage id="FORM.JOB_TITLE" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="jobTitle"
                    className="inline"
                    placeholder={intl.formatMessage({
                      id: "PLACEHOLDER.ENTER_JOB_TITLE",
                    })}
                    value={values.jobTitle}
                    onChange={handleChange}
                    onBlur={(e) => {
                      const val = (e.target.value || "").replace(/\s+/gi, " ");
                      setFieldValue("jobTitle", val.trim());
                      setFieldTouched("jobTitle", true, false);
                    }}
                    onKeyPress={(event) => {
                      if (event.key === " " && !event.target.value.length) {
                        event.preventDefault();
                      }
                    }}
                    isInvalid={touched.jobTitle && !!errors.jobTitle}
                    isValid={touched.jobTitle && !errors.jobTitle}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.jobTitle}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={hide} variant="light">
                <FormattedMessage id="GENERAL.CANCEL" />
              </Button>
              <Button
                // eslint-disable-next-line react/jsx-no-bind
                onClick={handleSubmit}
                disabled={loading}
                id="addEmployeeSubmit"
                variant="primary"
                className={`btn-elevate adduser__btn-primary ${clsx({
                  "spinner spinner--right spinner--md spinner--light": loading,
                })}`}
                style={loadingButtonStyle}
              >
                <FormattedMessage id="ADMINISTRATION.ADD_EMPLOYEE" />
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default injectIntl(AddEmployeeModal);
