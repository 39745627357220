/* eslint-disable react/prop-types, react/destructuring-assignment */
import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { FormattedMessage } from "react-intl";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#CCCCCC",
  borderStyle: "dashed",
  backgroundColor: "#FFFFFF",
  color: "#333536",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#66CA86",
};

const rejectStyle = {
  borderColor: "#FF7470",
};

function StyledDropzone(props) {
  const { accept } = props;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: props.accept,
    maxSize: 2147482548,
    onDropRejected: props.onDropRejected,
    onDropAccepted: props.onDropAccepted,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject],
  );

  return (
    <div className="container">
      <div className="dropzone" {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <span className="flaticon-upload icon image" />
        <p>
          <FormattedMessage id="SETUP.DROP_FILES_HERE" />
          &nbsp;
          <span className="font-bolder">
            <FormattedMessage id="SETUP.BROWSE_FILES" />
          </span>
          &nbsp;
          <FormattedMessage id="SETUP.TO_UPLOAD" />
        </p>
        <span className="small">
          {accept === ".xlsx, application/vnd.ms-excel, application/xls" ? (
            <FormattedMessage id="SETUP.ACCEPTED_FILES2" />
          ) : (
            <FormattedMessage id="SETUP.ACCEPTED_FILES" />
          )}
        </span>
      </div>
    </div>
  );
}

export default StyledDropzone;
