const actionTypes = {
  SetHasValidSubscription: "meta/SET_HAS_VALID_SUBSCRIPTION",
  SetChargebeeId: "meta/SET_CHARGEBEE_ID",
  SetHasValidM365LicensingReportSubscription:
    "meta/SET_HAS_VALID_M365_LICENSING_REPORT_SUBSCRIPTION",
  SetHasValidPostureBaselineSubscription:
    "meta/SET_HAS_VALID_POSTURE_BASELINE_SUBSCRIPTION",
  SetHasMultiCspSubscription: "meta/SET_HAS_MULTI_CSP_SUBSCRIPTION",
};

const initialState = {
  hasValidSubscription: null,
  chargebeeId: null,
  hasValidM365LicensingReportSubscription: null,
  hasValidPostureBaselineSubscription: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetHasValidSubscription:
      return { ...state, hasValidSubscription: action.payload.meta };
    case actionTypes.SetChargebeeId:
      return { ...state, chargebeeId: action.chargebeeId };
    case actionTypes.SetHasValidM365LicensingReportSubscription:
      return {
        ...state,
        hasValidM365LicensingReportSubscription:
          action.hasValidM365LicensingReportSubscription,
      };
    case actionTypes.SetHasValidPostureBaselineSubscription:
      return {
        ...state,
        hasValidPostureBaselineSubscription:
          action.hasValidPostureBaselineSubscription,
      };
    case actionTypes.SetHasMultiCspSubscription:
      return {
        ...state,
        hasMultiCspSubscription: action.hasMultiCspSubscription,
      };
    default:
      return state;
  }
};

export const actions = {
  setHasValidSubscription: (meta) => ({
    type: actionTypes.SetHasValidSubscription,
    payload: { meta },
  }),
  setChargebeeId: (val) => ({
    type: actionTypes.SetChargebeeId,
    chargebeeId: val,
  }),
  setHasValidM365LicensingReportSubscription: (val) => ({
    type: actionTypes.SetHasValidM365LicensingReportSubscription,
    hasValidM365LicensingReportSubscription: val,
  }),
  setHasValidPostureBaselineSubscription: (val) => ({
    type: actionTypes.SetHasValidPostureBaselineSubscription,
    hasValidPostureBaselineSubscription: val,
  }),
  setHasMultiCspSubscription: (val) => ({
    type: actionTypes.SetHasMultiCspSubscription,
    hasMultiCspSubscription: val,
  }),
};
