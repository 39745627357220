/* eslint-disable react/prop-types */
import { RadioSelect, SelectDropDown } from "@augmentt-dev/ui-components";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import * as secure from "../../../../_augmentt/ducks/secure";
import { getUserCustomers } from "../../../crud/orgs.crud";
import {
  fetchSecurityPostureData,
  getSecureCheckSettings,
  updateSecureCheckSettings,
} from "../../../crud/secure.crud";
import Spinner from "../../../partials/content/Spinner";
import { INTEGRATION_APPS } from "../utils/integrations";

const settingsandChecksMap = {
  adminmfa: "Admin MFA",
  usermfa: "User MFA",
  connectedappsadminconsent: "Connected Apps & User Consent",
  loginportalbranding: "Login Portal Branding",
  selfservepasswordreset: "Self Service Password Reset",
  blocklegacyauthentication: "Block Legacy Authentication",
  inactiveaccounts: "Inactive Accounts",
  teamsdefaultexternaloptions: "Teams Default External/Guest Options",
  sharepointexternalguestsharing: "SharePoint External/Guest Sharing",
  riskyips: "Risky IP Address Policy",
  riskycountries: "Risky Country Policy",
  unifiedauditlog: "Unified Audit Log",
  externalmailforwarding:
    "External Mail Forwarding (Outbound Anti-Spam Policy)",
  safeattachment: "Safe Attachments (SharePoint, OneDrive, Teams)",
  blocksigninsharedmailboxes: "Block Sign-in on Shared Mailboxes",
  mailboxaudit: "Mailbox Auditing Default (org level)",
  powershellaccessfornonadmins: "Powershell Access For Non-admins",
  externalMailTag: "Exchange External Sender Tag",
  dkim: "DKIM",
  contactsettings: "Contact Settings",
  resharingbyexternalusers: "Resharing By External Users",
  sitecreationforstandardusers: "Sharepoint site creation by standard users",
  deleteduseronedriveretention: "Deleted User OneDrive Retention",
  mfanumbermatching: "MFA Number Matching",
  enableexchangemodernauth: "Modern Authentication for Exchange/Outlook",
  entraIdGuestUserAccessPermissions: "Entra ID Guest User Access Permissions",
  temporaryaccesspass: "Temporary Access Pass",
  mfacampaign: "Microsoft MFA Campaign",
  activitybasedtimeoutpolicy: "Idle Session Timeout",
};

function CustomizeAuditModal({ show, onHide, intl }) {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [settingLevel, setSettingLevel] = useState("global");
  const [hasGlobalEditAccess, setHasGlobalEditAccess] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [originalSettings, setOriginalSettings] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [toBeUpdatedSettings, setToBeUpdatedSettings] = useState([]);
  const [settingsLevelOptions, setSettingsLevelOptions] = useState([]);

  const featureFlags = useSelector(({ featureFlags }) => featureFlags.data); // eslint-disable-line no-shadow
  const sharedMailBoxesFF =
    featureFlags.find(
      (flag) => flag.feature === "block-signin-shared-mailbox",
    ) || [];
  const isSharedMailBoxesFFEnabled = !!sharedMailBoxesFF?.enabled;

  const activityBasedTimeOutPolicyFF =
    featureFlags?.find(
      (flag) => flag.feature === "activity-based-timeout-policy",
    ) || [];

  const isActivityBasedTimeOutPolicyFFEnabled =
    !!activityBasedTimeOutPolicyFF?.enabled;

  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedCustomer = {}, data: allCustomers = [] } = useSelector(
    ({ customers }) => customers,
  );
  const { customers: allowedCustomerIds = [] } = useSelector(
    ({ userPermissions }) => userPermissions.data,
  );

  // Retrieves the data for security posture
  const getSecurityPostureData = async (customerId) => {
    try {
      if (customerId !== "*") {
        const securityPostureData = await fetchSecurityPostureData(customerId);

        return securityPostureData;
      }
      return [];
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    getCompanyList().then((data) => {
      const {
        // eslint-disable-next-line no-shadow
        hasGlobalEditAccess,
        toBeUpdatedSettingLevel,
        toBeFetchedCustomerId,
        toBeSelectedCompany,
        // eslint-disable-next-line no-use-before-define
      } = getInitialConfigurations(data);
      setHasGlobalEditAccess(hasGlobalEditAccess);

      setSettingLevel(toBeUpdatedSettingLevel);
      setSelectedCompany(toBeSelectedCompany);
      // eslint-disable-next-line no-use-before-define
      getSettingsData(toBeFetchedCustomerId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const settingsLevels = [
      {
        label: intl.formatMessage({
          id: "SECURE.CUSTOMIZE_AUDIT.GLOBAL",
        }),
        value: "global",
        checked: settingLevel === "global",
      },
      {
        label: intl.formatMessage({
          id: "SECURE.CUSTOMIZE_AUDIT.COMPANY",
        }),
        value: "company",
        checked: settingLevel === "company",
      },
    ];

    if (!hasGlobalEditAccess) {
      settingsLevels.shift();
    }

    setSettingsLevelOptions(settingsLevels);
    if (settingLevel === "global" && selectedCompany) {
      setSelectedCompany(null);
      // eslint-disable-next-line no-use-before-define
      getSettingsData("*");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingLevel, hasGlobalEditAccess]);

  const hide = () => {
    onHide();
  };

  const getInitialConfigurations = (secureCompanyList) => {
    const hasAccessToAllCustomers =
      allowedCustomerIds.length === 1 && allowedCustomerIds[0] === 0;
    const hasAccessToParentCompany =
      allCustomers?.findIndex(
        (customer) =>
          !customer.parent_id && allowedCustomerIds.includes(customer.id),
      ) >= 0;
    // eslint-disable-next-line no-shadow
    const hasGlobalEditAccess =
      hasAccessToAllCustomers || hasAccessToParentCompany;
    const isPreSelectedCustomerIsAllowed =
      secureCompanyList.findIndex(
        (company) => company.value === selectedCustomer?.id,
      ) >= 0;

    let toBeUpdatedSettingLevel;
    let toBeFetchedCustomerId;
    let toBeSelectedCompany;

    if (selectedCustomer?.id && isPreSelectedCustomerIsAllowed) {
      toBeUpdatedSettingLevel = "company";
      toBeFetchedCustomerId = selectedCustomer?.id;
      toBeSelectedCompany = {
        value: selectedCustomer?.id,
        label: selectedCustomer?.customer_name,
      };
    } else {
      // global
      // eslint-disable-next-line no-lonely-if
      if (hasGlobalEditAccess) {
        toBeUpdatedSettingLevel = "global";
        toBeFetchedCustomerId = "*";
        toBeSelectedCompany = null;
      } else {
        // global but don't have global access then will force to company level
        toBeUpdatedSettingLevel = "company";
        let firstAllowedCompany = {};
        if (hasAccessToAllCustomers) {
          firstAllowedCompany = { ...secureCompanyList[0] };
        } else {
          const firstAllowedCompanyIndex = secureCompanyList.findIndex(
            (company) => allowedCustomerIds.includes(company.value),
          );
          if (firstAllowedCompanyIndex >= 0) {
            firstAllowedCompany = secureCompanyList[firstAllowedCompanyIndex];
          }
        }
        toBeFetchedCustomerId = firstAllowedCompany.value;
        toBeSelectedCompany = firstAllowedCompany;
      }
    }

    return {
      hasAccessToAllCustomers,
      hasAccessToParentCompany,
      hasGlobalEditAccess,
      toBeUpdatedSettingLevel,
      toBeFetchedCustomerId,
      toBeSelectedCompany,
    };
  };

  // eslint-disable-next-line consistent-return
  const getCompanyList = async () => {
    setDataLoading(true);
    try {
      let companies = (await getUserCustomers(true))?.data || [];

      companies = companies
        .filter((company) =>
          company?.integratedApplications?.includes(
            INTEGRATION_APPS.OFFICE_365,
          ),
        )
        .map(({ id, customer_name: customerName }) => ({
          value: id,
          label: customerName,
        }));

      setCompanyList(companies);
      return companies;
    } catch (error) {
      setDataLoading(false);
      setErrorStatus(
        intl.formatMessage({
          id: "SECURE.CUSTOMIZE_AUDIT.FETCH_ERROR",
        }),
      );
    }
  };
  const getSettingsData = async (customerId) => {
    setDataLoading(true);
    try {
      const settings = await getSecureCheckSettings(customerId);

      const postureSecureChecks = await getSecurityPostureData(customerId);

      let settingsData = settings?.data;

      if (postureSecureChecks?.data?.securityChecks) {
        const securityCheckNames =
          postureSecureChecks?.data?.securityChecks?.allMonitored?.map(
            (item) => item.securityCheck,
          );
        const settingsNames = Object.keys(settingsandChecksMap).map((key) => ({
          name: key,
          index: securityCheckNames.indexOf(settingsandChecksMap[key]),
        }));

        // Sort settings based on the index of their corresponding security check
        settingsData = settingsNames
          .sort((a, b) => a.index - b.index)
          .map(({ name }) =>
            settings?.data?.find((setting) => setting.name === name),
          );
      }

      // condition will check whether the block sing-in shared mail box is available and filter it out if feature flag disabled
      if (!isSharedMailBoxesFFEnabled) {
        settingsData = settingsData.filter(
          (check) => check.name !== "blocksigninsharedmailboxes",
        );
      }
      // condition will check whether the idle session timeout is available and filter it out if feature flag disabled
      if (!isActivityBasedTimeOutPolicyFFEnabled) {
        settingsData = settingsData?.filter(
          (check) => check.name !== "activitybasedtimeoutpolicy",
        );
      }
      setOriginalSettings(settingsData || []);
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
      setErrorStatus(
        intl.formatMessage({
          id: "SECURE.CUSTOMIZE_AUDIT.FETCH_ERROR",
        }),
      );
    }
  };
  const enableLoading = () => {
    setSubmitLoading(true);
  };

  const disableLoading = () => {
    setSubmitLoading(false);
  };

  const handleChangeSelectedCompany = async (newCompany) => {
    if (newCompany && newCompany.value !== selectedCompany?.value) {
      await getSettingsData(newCompany.value);
    } else if (hasGlobalEditAccess && !newCompany) {
      await getSettingsData("*");
    }
    setSelectedCompany(newCompany);
  };

  const handleToggleSetting = (updatedSetting) => {
    const newSettings = [...originalSettings];
    const settingIndex = newSettings.findIndex(
      (setting) => setting.id === updatedSetting.id,
    );
    newSettings[settingIndex] = updatedSetting;
    setOriginalSettings(newSettings);

    const newToBeUpdatedSettings = [...toBeUpdatedSettings];
    const foundInToBeUpdatedSettings = toBeUpdatedSettings.findIndex(
      (setting) => setting.id === updatedSetting.id,
    );
    if (foundInToBeUpdatedSettings !== -1) {
      newToBeUpdatedSettings.splice(foundInToBeUpdatedSettings, 1);
    } else {
      newToBeUpdatedSettings.push(updatedSetting);
    }

    setToBeUpdatedSettings(newToBeUpdatedSettings);
  };
  const handleSubmit = async () => {
    try {
      enableLoading();
      await updateSecureCheckSettings("*", {
        settings:
          settingLevel === "global" ? originalSettings : toBeUpdatedSettings,
      });

      disableLoading();
      hide();
      history.push("/secure/security-posture");
      dispatch(secure.actions.setShowSecurityChecksPage(undefined));
      dispatch(secure.actions.setReloadSecurityPostureData(true));
    } catch (error) {
      console.error(error);
      disableLoading();
      setErrorStatus(
        intl.formatMessage({
          id: "SECURE.CUSTOMIZE_AUDIT.UPDATE_ERROR",
        }),
      );
    }
  };

  const isUpdateDisabled = () => {
    if (settingLevel === "global" && !submitLoading && !dataLoading) {
      return false;
    }
    return (
      submitLoading ||
      dataLoading ||
      toBeUpdatedSettings.length === 0 ||
      (settingLevel === "company" && !selectedCompany)
    );
  };
  return (
    <Modal
      id="CustomizeAuditModal"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={hide}
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <FormattedMessage id="SECURE.CUSTOMIZE_AUDIT" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="12">
            <p>
              <FormattedMessage id="SECURE.CUSTOMIZE_AUDIT_DESCRIPTION" />
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            {dataLoading ? (
              <Row>
                <Col md="12" className="d-flex justify-content-center">
                  <Spinner />
                </Col>
              </Row>
            ) : (
              <>
                {errorStatus && (
                  <Alert variant="danger">
                    <div className="alert-text">{errorStatus}</div>
                  </Alert>
                )}
                <Row>
                  <Col md="12">
                    <p>
                      <RadioSelect
                        name="settingLevelsGroup"
                        onSelect={(value) => {
                          setSettingLevel(value);
                        }}
                        options={settingsLevelOptions}
                        value={settingLevel}
                      />
                    </p>
                    {settingLevel === "company" && (
                      <div className="mx-3">
                        <SelectDropDown
                          defaultLabel={intl.formatMessage({
                            id: "PLACEHOLDER.SELECT_A_COMPANY",
                          })}
                          onChange={(value) =>
                            handleChangeSelectedCompany(value)
                          }
                          defaultValue={selectedCompany}
                          options={companyList}
                        />
                      </div>
                    )}
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {originalSettings.length > 0 && (
                      <Row>
                        <Col md="12" className="d-flex justify-content-between">
                          <p className="font-bolder text-muted">
                            <FormattedMessage id="SECURE.CUSTOMIZE_AUDIT.SECURITY_CHECK" />
                          </p>
                          <p className="font-bolder text-muted">
                            <FormattedMessage id="SECURE.CUSTOMIZE_AUDIT.INCLUDE_IN_AUDIT" />
                          </p>
                        </Col>
                      </Row>
                    )}
                    {originalSettings.map((setting) => (
                      <Row key={setting.id}>
                        <Col
                          md="11"
                          className="d-flex justify-content-between py-1"
                        >
                          <p className="">
                            <FormattedMessage
                              id={`SECURE.CUSTOMIZE_AUDIT.${setting.name}`}
                            />
                          </p>
                          <Switch
                            onChange={(value) =>
                              handleToggleSetting({
                                ...setting,
                                enabled: value,
                              })
                            }
                            checked={setting.enabled}
                            height={15}
                            width={35}
                            handleDiameter={18}
                            onColor="#BDDCFC"
                            offColor="#E5EAEE"
                            onHandleColor="#3399FF"
                            boxShadow="0px 1px 3px rgba(0, 0, 0, 0.3)"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            className="switch"
                            aria-label="discover-toggle"
                          />
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hide} variant="light">
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isUpdateDisabled()}
          id="addUserSubmit"
          variant="primary"
          className={`btn-elevate adduser__btn-primary ${clsx({
            "spinner spinner--right spinner--md spinner--light": submitLoading,
          })}`}
        >
          <FormattedMessage id="GENERAL.UPDATE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl(CustomizeAuditModal);
