/* eslint-disable react/prop-types */
// Create a export as CSV/XLSX react component for reports, let it handle the buttons, logic for exporting etc
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import moment from "moment";
import {
  fetchReportData,
  createReport,
  onDownloadFileClick,
} from "../crud/report.crud";
import discoverPDF from "../pages/home/Discover/discoverPDF";
import employeesPDF from "../pages/home/Employees/employeesPDF";
import * as reports from "../../_augmentt/ducks/reports";
import * as dates from "../../_augmentt/ducks/dates";
import {
  getCurrentDateLongFormat,
  getNormalizedCustomerName,
} from "../util/string.utils";

const AUGMENTT_LOGO = "/media/logos/logo-dark.png";

// Allow for export via XLSX, CSV
function ExportWidget(props) {
  const { intl } = props;

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCustomer = useSelector(
    ({ customers }) => customers.selectedCustomer,
  );
  const exportData = useSelector(({ exporter }) => exporter);
  const brandingData = useSelector(({ branding }) => branding.data);
  // eslint-disable-next-line no-shadow
  const dateRange = useSelector(({ dates }) => dates.data);
  const featureFlags =
    // eslint-disable-next-line no-shadow
    useSelector(({ featureFlags }) => featureFlags.data) || [];
  const scheduledReportsFlags = featureFlags.find(
    (flag) => flag.feature === "scheduled-reports",
  );

  const [from, setFrom] = useState(null);
  const [onPdf, setOnPdf] = useState(null);
  const [outputs, setOutputs] = useState([]);
  const [isPdfLoading, setPdfLoading] = useState(false);
  const [isPdfPolling, setPdfPolling] = useState(false);
  const [pdfResponse, setPdfResponse] = useState(null);
  const [report, setReport] = useState(null);

  const isFromPage = (path) => {
    switch (path) {
      case "mfa":
        return location.pathname.indexOf("mfa") > -1 && from === "MFA_REPORT";
      case "summary":
        return (
          location.pathname.indexOf("summary") > -1 && from === "SUMMARY_REPORT"
        );
      case "threats":
        return (
          location.pathname.indexOf("threats") > -1 && from === "THREAT_REPORT"
        );
      case "security-posture":
        return (
          location.pathname.indexOf("security-posture") > -1 &&
          from === "POSTURE_REPORT"
        );
      case "discovery":
        return (
          location.pathname.indexOf("discovery") > -1 && from !== "DISCOVERY"
        );
      case "employees":
        return (
          location.pathname.indexOf("employees") > -1 && from !== "EMPLOYEES"
        );
      case "reporting":
        return (
          location.pathname.indexOf("reporting") > -1 && from !== "REPORTING"
        );
      default:
        throw new Error("Unsupported path");
    }
  };

  const isWrongPage =
    isFromPage("discovery") ||
    isFromPage("reporting") ||
    isFromPage("employees");

  // Report pages with scheduled reports available
  const scheduledReportAvailable =
    isFromPage("mfa") ||
    isFromPage("summary") ||
    isFromPage("threats") ||
    isFromPage("security-posture");

  const reset = () => {
    setOnPdf(null);
    setOutputs([]);
  };

  const resetReport = () => {
    setReport(null);
    setPdfResponse(null);
    setPdfLoading(false);
    setPdfPolling(false);
    dispatch(
      reports.actions.setReportError({ status: false, type: null, data: null }),
    );
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isPdfLoading === true) {
      // Create interval to poll for the Report every 3 seconds
      const interval = setInterval(() => {
        // If no PDF response is set in the state or it is set to incomplete, continue polling
        if (!pdfResponse || (!pdfResponse.complete && report)) {
          fetchReportData(report.id)
            .then((res) => {
              // if the PDF generation is complete set the response in the state
              if (res.data.complete === true) {
                setPdfResponse(res.data);
                setPdfPolling(false);
              }
            })
            .catch(() => {
              // Show generic error
              dispatch(
                reports.actions.setReportError({
                  status: true,
                  type: "generic",
                }),
              );
              setPdfLoading(false);
              setPdfPolling(false);
            });
        }
      }, 3000);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [isPdfPolling]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pdfResponse) {
      // PDF completed without errors
      if (pdfResponse.error === false && pdfResponse.url !== undefined) {
        try {
          // eslint-disable-next-line no-use-before-define
          onDownloadFileClick(
            pdfResponse.url,
            report.report_name,
            "pdf",
            () => {
              setPdfLoading(false);
            },
          );
          setReport(null);
          setPdfResponse(null);
        } catch (e) {
          dispatch(
            reports.actions.setReportError({ status: true, type: "generic" }),
          );
        }
        // PDF completed with errors
      } else {
        dispatch(
          reports.actions.setReportError({ status: true, type: "generic" }),
        );
      }
    }
  }, [pdfResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  // Change the pdf functionality based on export data
  useEffect(() => {
    if (exportData && exportData.outputs) {
      setFrom(exportData.from);
      setOutputs(exportData.outputs);
    }
  }, [exportData]); // eslint-disable-line react-hooks/exhaustive-deps

  // Reset PDF on Company change
  useEffect(() => {
    resetReport();
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  // Ensure we are only allowing export if on correct page
  useEffect(() => {
    resetReport();
    if (isWrongPage) {
      reset();
    }
    if (location.pathname.indexOf("discovery") > -1 && from === "DISCOVERY") {
      setOnPdf(discoverPDF);
    }
    if (location.pathname.indexOf("employees") > -1 && from === "EMPLOYEES") {
      setOnPdf(employeesPDF);
    }
    if (isFromPage("threats")) {
      // Create object with parameters for external TaskMaster PDF generation
      setOnPdf({
        exportType: "external",
        reportType: "threat",
        reportName: "Threat Report",
      });
    }
    if (isFromPage("summary")) {
      // Create object with parameters for external TaskMaster PDF generation
      setOnPdf({
        exportType: "external",
        reportType: "summary",
        reportName: "Summary Report",
      });
    }
    if (isFromPage("security-posture")) {
      // Create object with parameters for external TaskMaster PDF generation
      setOnPdf({
        exportType: "external",
        reportType: "posture",
        reportName: "Posture Report",
      });
      dispatch(
        dates.actions.setDateRange({
          startDate: `${moment().format("YYYY-MM-DDT00:00:00.000")}Z`,
          endDate: `${moment().format("YYYY-MM-DDT00:00:00.000")}Z`,
        }),
      );
    }
    if (isFromPage("mfa")) {
      // Create object with parameters for external TaskMaster PDF generation
      setOnPdf({
        exportType: "external",
        reportType: "mfa",
        reportName: "MFA Report",
      });
      dispatch(
        dates.actions.setDateRange({
          startDate: `${moment().format("YYYY-MM-DDT00:00:00.000")}Z`,
          endDate: `${moment().format("YYYY-MM-DDT00:00:00.000")}Z`,
        }),
      );
    }
  }, [location.pathname, from]); // eslint-disable-line react-hooks/exhaustive-deps

  const isUpperCase = (string) => /^[A-Z]*$/.test(string.split(" ").join(""));
  const formatData = (data) => {
    data.forEach((row) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(row)) {
        const updatedValue =
          value && value.join != null ? value.join(",") : value;
        if (!isUpperCase(key)) {
          // eslint-disable-next-line no-param-reassign
          row[
            key
              .match(/([A-Z]?[^A-Z]*)/g)
              .slice(0, -1)
              .join(" ")
              .toUpperCase()
          ] = updatedValue;
          // eslint-disable-next-line no-param-reassign
          delete row[key]; // delete the old key
        } else {
          // eslint-disable-next-line no-param-reassign
          row[key] = updatedValue;
        }
      }
    });
    return data;
  };
  // Builds an excel sheet buffer
  const buildSheet = (data, format = false) => {
    if (format) {
      // eslint-disable-next-line no-param-reassign
      data = formatData(data);
    }
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const letters = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
    ]; // if more are needed add more

    // Assigned headers names in order (A1, B1, C1, ...)
    if (data?.length) {
      const headerKeys = Object.keys(data[0]);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < headerKeys.length; i++) {
        ws[`${letters[i]}1`].v = headerKeys[i]; // value of header assigned
      }
    }

    return { ws, wb };
  };

  // Run the passed in prop function of onPdf if applicable
  const handlePdfExport = () => {
    if (onPdf) {
      // Is this PDF meant to be generated externally from TaskMaster
      if (onPdf.exportType && onPdf.exportType === "external") {
        resetReport();
        setPdfLoading(true);
        createReport(
          selectedCustomer.id,
          onPdf.reportType,
          onPdf.reportName,
          dateRange,
        )
          .then((res) => {
            setReport(res.data);
            setPdfPolling(true);
          })
          .catch((err) => {
            if (err.response.data.error === "invalid_licensing") {
              console.error(err.response.data);
              // Show some error messaging that the user cannot export more than one PDF per quarter without secure licensing
              dispatch(
                reports.actions.setReportError({
                  status: true,
                  type: "licensing",
                  data: err.response.data.message,
                }),
              );
            } else {
              // Show generic error
              dispatch(
                reports.actions.setReportError({
                  status: true,
                  type: "generic",
                }),
              );
            }
            setPdfLoading(false);
          });
      } else {
        // pass in the branding logo, if set, otherwise use the augmentt image.
        const imageForBranding =
          brandingData?.logo_light && brandingData?.publish_logo
            ? brandingData.logo_light
            : AUGMENTT_LOGO;
        onPdf({
          intl,
          data: outputs[0].data,
          branding: imageForBranding,
        }); // pass intl and first output data
      }
    }
  };

  // Create CSV export from excel sheet and save
  const handleCSVExport = (format = false) => {
    // For all outputs build an output and save
    for (let i = 0; i < outputs.length; i += 1) {
      const { ws } = buildSheet(outputs[i].data, format);
      const csvBuffer = XLSX.utils.sheet_to_csv(ws);
      const data = new Blob([csvBuffer], { type: "text/csv" });
      FileSaver.saveAs(
        data,
        from === "EMPLOYEES"
          ? `${getNormalizedCustomerName(
              selectedCustomer?.customer_name,
            )}_${intl.formatMessage({
              id: "ADMINISTRATION.EMPLOYEES",
            })}_${getCurrentDateLongFormat()}.csv`
          : `${outputs[i].fileName}.csv`,
      );
    }
  };

  // Create Excel sheet and save
  const handleXLSXExport = () => {
    for (let i = 0; i < outputs.length; i += 1) {
      const { wb } = buildSheet(outputs[i].data);
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(
        data,
        from === "EMPLOYEES"
          ? `${getNormalizedCustomerName(
              selectedCustomer?.customer_name,
            )}_${intl.formatMessage({
              id: "ADMINISTRATION.EMPLOYEES",
            })}_${getCurrentDateLongFormat()}.xlsx`
          : `${outputs[i].fileName}.xlsx`,
      );
    }
  };

  if (isWrongPage || (!outputs.length && !onPdf)) {
    return (
      <Button
        variant="light"
        className="spinner spinner--center spinner--md spinner--primary"
      />
    ); // no exports available
  }

  if (isPdfLoading) {
    return (
      <Button
        variant="light"
        className="spinner spinner--center spinner--md spinner--primary"
      />
    ); // external export in the works
  }

  // Render button
  return (
    <Dropdown drop="down" className="btn" style={{ padding: 0 }}>
      <Dropdown.Toggle
        variant="btn"
        className="btn-light"
        id="dropdownToggleExport"
        style={{ marginLeft: 0 }}
      >
        <FormattedMessage id="GENERAL.EXPORT" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim">
        <ul className="nav margin-t-10 margin-b-10">
          {onPdf && (
            <li className={clsx("nav__item")} data-toggle="collapse">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <span onClick={handlePdfExport} className={clsx("nav__link")}>
                <span className="nav__link nav__link-text">
                  <FormattedMessage id="GENERAL.PDF" />
                </span>
              </span>
            </li>
          )}
          {isFromPage("mfa") && (
            <li className={clsx("nav__item")} data-toggle="collapse">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <span
                onClick={() => handleCSVExport(true)}
                className={clsx("nav__link")}
              >
                <span className="nav__link nav__link-text">
                  <FormattedMessage id="GENERAL.CSV" />
                </span>
              </span>
            </li>
          )}

          {!isFromPage("mfa") && outputs?.length ? (
            <>
              <li className={clsx("nav__item")} data-toggle="collapse">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <span onClick={handleCSVExport} className={clsx("nav__link")}>
                  <span className="nav__link nav__link-text">
                    <FormattedMessage id="GENERAL.CSV" />
                  </span>
                </span>
              </li>
              <li className={clsx("nav__item")} data-toggle="collapse">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <span onClick={handleXLSXExport} className={clsx("nav__link")}>
                  <span className="nav__link nav__link-text">
                    <FormattedMessage id="GENERAL.XLSX" />
                  </span>
                </span>
              </li>
            </>
          ) : null}

          {scheduledReportsFlags?.enabled && scheduledReportAvailable && (
            <li className={clsx("nav__item")} data-toggle="collapse">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <span
                onClick={() => history.push("/scheduled-reports")}
                className={clsx("nav__link")}
              >
                <span className="nav__link nav__link-text">
                  <FormattedMessage id="MENU.SCHEDULED_REPORTS" />
                </span>
              </span>
            </li>
          )}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default injectIntl(ExportWidget);
