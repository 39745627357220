import secureEssentialsIcon from "../_assets/icons/secure-essentials.svg";
import securePremiumIcon from "../_assets/icons/secure.svg";

/*  selectStyle: Applies a custom style for a custom react-select control
 *  returns:
 *    <object> : Custom style object
 */
export const selectStyle = {
  option: (base, state) => ({
    ...base,
    color: state.value === "-" ? "#3399FF" : "#333536",
    backgroundColor: "#FFFFFF",
    ":active": {
      color: "#333536",
      backgroundColor: "#F6F7FD",
    },
    ":hover": {
      color: "#3399FF",
      backgroundColor: "#F6F7FD",
    },
    padding: "0.5rem 1.5rem",
    fontSize: "1rem",
    fontWeight: state.isSelected ? 600 : 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "#EFF0F6" },
    boxShadow: "none",
    borderColor: "#EFF0F6",
    fontWeight: 600,
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: "1rem",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "transparent",
  }),
  clearIndicator: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#3399FF",
    fontWeight: 400,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    width: 30,
    color: state.isFocused ? "#333536" : "#CCCCCC",
  }),
  menu: (base) => ({
    ...base,
    boxShadow: "0 4px 8px 0 rgba(52, 58, 64, 0.15)",
    border: "solid 1px #eff0f6",
    minWidth: 165,
    maxHeight: 240,
    width: "100%",
    zIndex: 1000,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#EFF0F6",
    borderRadius: 10,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "#3399FF",
    fontSize: "1rem",
    fontWeight: 500,
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: "#3399FF",
    fontSize: "1rem",
    fontWeight: 500,
    ":hover": {
      color: "#3399FF",
      backgroundColor: "#EFF0F6",
      borderRadius: 10,
    },
  }),
};

/*  multiSelectStyle: Applies a custom style for a multi-select react-select control
 *  returns:
 *    <object> : Custom style object
 */
export const multiSelectStyle = {
  option: (base, state) => ({
    ...base,
    color: state.value === "-" ? "#3399FF" : "#333536",
    backgroundColor: "#FFFFFF",
    ":active": {
      color: "#333536",
      backgroundColor: "#F6F7FD",
    },
    ":hover": {
      color: "#3399FF",
      backgroundColor: "#F6F7FD",
    },
    padding: "0.5rem 1rem",
    fontWeight: 500,
    textOverflow: "ellipsis",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "#EFF0F6" },
    boxShadow: "none",
    borderColor: "#EFF0F6",
    fontWeight: 600,
  }),
  singleValue: (base) => ({
    ...base,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "transparent",
  }),
  clearIndicator: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#3399FF",
    fontWeight: 400,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    width: 30,
    color: state.isFocused ? "#333536" : "#CCCCCC",
  }),
  menu: (base) => ({
    ...base,
    boxShadow: "0 4px 8px 0 rgba(52, 58, 64, 0.15)",
    border: "solid 1px #EFF0F6",
    minWidth: 165,
    maxHeight: 240,
    width: "auto",
    zIndex: 100,
  }),
  menuList: (base) => ({
    ...base,
    padding: "0px",
    minWidth: 235,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#EFF0F6",
    borderRadius: 10,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "#3399FF",
    fontSize: "1rem",
    fontWeight: 500,
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: "#3399FF",
    fontSize: "1rem",
    fontWeight: 500,
    ":hover": {
      color: "#3399FF",
      backgroundColor: "#EFF0F6",
      borderRadius: 10,
    },
  }),
  input: () => ({
    position: "absolute",
    color: "#333536",
  }),
  placeholder: (base) => ({
    ...base,
    position: "relative",
    lineHeight: "2.5rem",
  }),
  valueContainer: (base, state) => ({
    ...base,
    color: "#3399FF",
    width: state.isSelected ? "35%" : "70%",
    padding: "0 8px",
    flexWrap: "nowrap",
    height: "30px",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    justifyContent: "space-between",
  }),
};

/*  selectStyleDot: Applies a custom style for a custom react-select control with a colored dot (representing status)
 *  returns:
 *    <object> : Custom style object
 */
export const selectStyleDot = {
  option: (base) => ({
    ...base,
    color: "#333536",
    backgroundColor: "#FFFFFF",
    ":active": {
      color: "#333536",
      backgroundColor: "#F6F7FD",
    },
    ":hover": {
      color: "#3399FF",
      backgroundColor: "#F6F7FD",
    },
    padding: "0.5rem 1rem",
    fontWeight: 500,
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "#EFF0F6" },
    boxShadow: "none",
    borderColor: "#EFF0F6",
    fontWeight: 600,
  }),
  // eslint-disable-next-line no-use-before-define
  input: (base) => ({ ...base, ...dot() }),
  // eslint-disable-next-line no-use-before-define
  placeholder: (base) => ({ ...base, ...dot() }),
  singleValue: (base, { data }) => ({
    ...base,
    /* eslint-disable no-nested-ternary */
    // eslint-disable-next-line no-use-before-define
    ...dot(
      data.value === 0
        ? "#EBEDF2"
        : (data.value === 1 &&
              (data.type === "status" || data.type === "productivity")) ||
            (data.value === 3 && data.type === "risk")
          ? "#FF7470"
          : data.value === 2
            ? "#FFC000"
            : (data.value === 3 &&
                  (data.type === "status" || data.type === "productivity")) ||
                (data.value === 1 && data.type === "risk")
              ? "#66CA86"
              : undefined,
    ),
    /* eslint-enable no-nested-ternary */
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "transparent",
  }),
  menu: (base) => ({
    ...base,
    boxShadow: "0 4px 8px 0 rgba(52, 58, 64, 0.15)",
    border: "solid 1px #EFF0F6",
    minWidth: 165,
    maxHeight: 240,
    width: "100%",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

/** selectStyleLarge: Applies a custom style for a custom react-select control. (Used in main company/employee select controls).
 *  returns:
 *    <object> : Custom style object
 */
export const selectStyleLarge = {
  option: (base, state) => ({
    ...base,
    // eslint-disable-next-line no-nested-ternary
    color: state.isSelected
      ? "#FFFFFF"
      : state.isFocused
        ? "#3399FF"
        : "#333536",
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: state.isSelected
      ? "#3399FF"
      : state.isFocused
        ? "#F6F7FD"
        : null,
    ":active": {
      color: "#333536",
      backgroundColor: "#F6F7FD",
    },
    padding: "0.5rem 1.5rem",
    cursor: "pointer",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "transparent", cursor: "pointer" },
    border: 0,
    boxShadow: "none",
    backgroundColor: "transparent",
    fontWeight: 600,
    fontSize: "1.4rem",
  }),
  valueContainer: (base, state) => ({
    ...base,
    width: state.hasValue ? "fit-content" : 150,
    maxWidth: 250,
    padding: "0",
  }),
  singleValue: (base) => ({
    ...base,
    display: "contents",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "transparent",
  }),
  menu: (base) => ({
    ...base,
    boxShadow: "0px 0px 15px -5px rgba(82, 63, 105, 0.15)",
    width: 165,
    visibility: "visible",
    zIndex: 3,
  }),
  menuList: (base) => ({ ...base, overflow: "hidden" }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

/*  datePickerStyle: Applies a custom style for the datepicker component which uses react-select
 *  returns:
 *    <object> : Custom style object
 */
export const datePickerStyle = {
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? "#FFFFFF" : "#333536",
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: state.isSelected
      ? "#3399FF"
      : state.isFocused
        ? "#F6F7FD"
        : null,
    ":active": {
      color: "#333536",
      backgroundColor: "#F6F7FD",
    },
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "#EFF0F6" },
    width: "325px",
    backgroundColor: "white",
    borderColor: "#EFF0F6",
  }),
  singleValue: (base) => ({
    ...base,
    // eslint-disable-next-line no-use-before-define
    ...dateIcon(),
    fontWeight: 600,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "transparent",
  }),
  // eslint-disable-next-line no-use-before-define
  input: (styles) => ({ ...styles, ...dateIcon() }),
  // eslint-disable-next-line no-use-before-define
  placeholder: (styles) => ({ ...styles, ...dateIcon() }),
};

export const datePickerStyleReporting = {
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? "#FFFFFF" : "#333536",
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: state.isSelected
      ? "#3399FF"
      : state.isFocused
        ? "#F6F7FD"
        : null,
    ":active": {
      color: "#333536",
      backgroundColor: "#F6F7FD",
    },
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "#EFF0F6" },
    width: "auto",
    backgroundColor: "white",
    borderColor: "#EFF0F6",
  }),
  singleValue: (base) => ({
    ...base,
    // eslint-disable-next-line no-use-before-define
    ...dateIcon(),
    fontWeight: 600,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "#EFF0F6",
  }),
  // eslint-disable-next-line no-use-before-define
  input: (styles) => ({ ...styles, ...dateIcon() }),
  // eslint-disable-next-line no-use-before-define
  placeholder: (styles) => ({ ...styles, ...dateIcon() }),
};

/*  selectLicenseStyle: Applies a custom style for a custom react-select control
 *  returns:
 *    <object> : Custom style object
 */
export const selectLicenseStyle = {
  option: (base, state) => ({
    ...base,
    // eslint-disable-next-line no-use-before-define
    ...featureIcon(state.value),
    color: state.value === "-" ? "#3399FF" : "#333536",
    backgroundColor: "#FFFFFF",
    ":active": {
      color: "#333536",
      backgroundColor: "#F6F7FD",
    },
    ":hover": {
      color: "#3399FF",
      backgroundColor: "#F6F7FD",
    },
    padding: "0.5rem",
    fontSize: "1rem",
    fontWeight: state.isSelected ? 600 : 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  control: (base) => ({
    ...base,
    "&:hover": { borderColor: "#EFF0F6" },
    boxShadow: "none",
    borderColor: "#EFF0F6",
    fontWeight: 600,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "transparent",
  }),
  clearIndicator: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#3399FF",
    fontWeight: 400,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    width: 30,
    color: state.isFocused ? "#333536" : "#CCCCCC",
  }),
  menu: (base) => ({
    ...base,
    boxShadow: "0 4px 8px 0 rgba(52, 58, 64, 0.15)",
    border: "solid 1px #eff0f6",
    minWidth: 165,
    maxHeight: 240,
    width: "100%",
    zIndex: 1000,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#EFF0F6",
    borderRadius: 10,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "#3399FF",
    fontSize: "1rem",
    fontWeight: 500,
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: "#3399FF",
    fontSize: "1rem",
    fontWeight: 500,
    ":hover": {
      color: "#3399FF",
      backgroundColor: "#EFF0F6",
      borderRadius: 10,
    },
  }),
  // eslint-disable-next-line no-use-before-define
  input: (base) => ({ ...base, ...featureIcon() }),
  singleValue: (base, { data }) => ({
    ...base,
    // eslint-disable-next-line no-use-before-define
    ...featureIcon(data.value),
  }),
};

/*  dot: Returns the style info for a colored dot
 *  returns:
 *    <object> : Custom style object
 */
const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",
  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

/*  dateIcon: Returns the style info for a date/calendar icon
 *  returns:
 *    <object> : Custom style object
 */
const dateIcon = () => ({
  alignItems: "center",
  display: "flex",
  ":before": {
    fontFamily: "'Font Awesome 5 Free'",
    fontWeight: 400,
    fontVariant: "normal",
    content: '"073"',
    color: "#305FC6",
    display: "block",
    height: 24,
    width: 24,
    fontSize: "1.2rem",
    paddingRight: "1rem",
  },
});

const featureIcon = (value = undefined) => ({
  alignItems: "center",
  display: "flex",
  ":before": {
    content: '""',
    backgroundImage: `url(${
      // eslint-disable-next-line no-nested-ternary
      value === 0
        ? secureEssentialsIcon
        : value === 1
          ? securePremiumIcon
          : undefined
    })`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "65%",
    display: "block",
    height: 24,
    width: 24,
    marginRight: "0.25rem",
  },
});
