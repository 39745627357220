import React from "react";

// eslint-disable-next-line react/prefer-stateless-function
export default class NotificationsPanel extends React.Component {
  render() {
    return (
      <div className="header__topbar-item">
        <div className="header__topbar-wrapper">
          <span className="header__topbar-icon">
            <span
              className="icon-xl flaticon2-notification"
              id="notifications"
            />
          </span>
        </div>
      </div>
    );
  }
}
