export const CHECKS_NAMES = {
  CHECKLIST_VIEWED_META: "CHECKLIST_VIEWED_META",
  INTEGRATE_TO_MICROSOFT: "INTEGRATE_TO_MICROSOFT",
  MAP_CSP_TENANTS: "MAP_CSP_TENANTS",
  INTEGRATE_TO_PSA: "INTEGRATE_TO_PSA",
  ADD_SYSTEM_USER: "ADD_SYSTEM_USER",
  ADD_OPERATING_COUNTRIES: "ADD_OPERATING_COUNTRIES",
  SCHEDULE_REPORT: "SCHEDULE_REPORT",
  ADD_CUSTOM_BRANDING: "ADD_CUSTOM_BRANDING",
};

export const COMPLETION_STATUS = {
  COMPLETE: "COMPLETE",
  PARTIALLY_COMPLETE: "PARTIALLY_COMPLETE",
  INCOMPLETE: "INCOMPLETE",
};
