/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LayoutSplashScreen } from "./LayoutContext";
import * as builder from "../ducks/builder";
import { displayPage, resetDefaultPermission } from "../utils/utils";

/**
 * Used to synchronize current layout `menuConfig`, `layoutConfig` and
 * `htmlClassService` with redux store.
 */
export default function LayoutInitializer({
  children,
  menuConfig,
  layoutConfig,
  htmlClassService,
}) {
  const dispatch = useDispatch();
  const builderState = useSelector(({ builder }) => builder); // eslint-disable-line no-shadow
  const featureFlags = useSelector(({ featureFlags }) => featureFlags.data); // eslint-disable-line no-shadow
  const permissions = useSelector(
    ({ userPermissions }) => userPermissions.data.permissions,
  );

  const hasValidM365LicensingReportSubscription = useSelector(
    ({ meta }) => meta.hasValidM365LicensingReportSubscription,
  );

  const hasValidPostureBaselineSubscription = useSelector(
    ({ meta }) => meta.hasValidPostureBaselineSubscription,
  );

  useEffect(() => {
    if (featureFlags && menuConfig) {
      // reset default permission
      resetDefaultPermission(menuConfig);
      // Retrieve all feature flag menu items from redux
      const menuFeatureFlags = featureFlags.filter((featureFlagObject) =>
        featureFlagObject.feature.includes("menu"),
      );

      const isPostureProtectM3Enabled = featureFlags?.find(
        (flag) => flag.feature === "posture-protect-m3",
      )?.enabled;

      const isdiscoverComplianceEnabled = featureFlags?.find(
        (flag) => flag.feature === "discover-security",
      )?.enabled;

      const isPolicyManagementEnabled = featureFlags?.find(
        (flag) => flag.feature === "policy-management",
      )?.enabled;

      // Check feature flags, modify the menu config here based on them
      // eslint-disable-next-line no-restricted-syntax
      for (const menuFeatureFlag of menuFeatureFlags) {
        let menuEnabled = false;

        if (menuFeatureFlag?.enabled) {
          menuEnabled = true;
        }

        try {
          // Secure
          if (menuFeatureFlag.feature.includes("secure")) {
            const secureMenuItem = menuConfig.aside.items.find(
              (item) => item.page === "secure",
            ); // Secure Menu
            secureMenuItem.visible = menuEnabled; // Main item
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < secureMenuItem.submenu.length; i++) {
              // Secure Submenus
              secureMenuItem.submenu[i].visible = menuEnabled;

              // disable posture-templates based on ff
              if (
                !isPostureProtectM3Enabled &&
                secureMenuItem.submenu[i].page === "secure/posture-templates"
              ) {
                secureMenuItem.submenu[i].visible = false;
              }

              // If posture baseline subscription not set, set as preview only
              if (
                !hasValidPostureBaselineSubscription &&
                secureMenuItem.submenu[i].page === "secure/posture-templates"
              ) {
                secureMenuItem.submenu[i].preview = true;
              }

              if (
                secureMenuItem.submenu[i].page ===
                "secure/posture-templates/:templateId"
              ) {
                secureMenuItem.submenu[i].visible = false; // always false, used just for the title of the subpage
              }

              if (
                !isPolicyManagementEnabled &&
                secureMenuItem.submenu[i].page === "secure/policy-templates"
              ) {
                secureMenuItem.submenu[i].visible = false; // always false, used just for the title of the subpage
              }
            }
          } else if (menuFeatureFlag.feature.includes("alerts")) {
            // Alerts
            const configurationMenu = menuConfig?.aside?.items.find(
              (obj) => obj.page === "configuration",
            );

            const alertsMenuItem = configurationMenu?.submenu.find(
              (obj) => obj.page === "alerts",
            );

            if (alertsMenuItem) {
              alertsMenuItem.visible = menuEnabled;
            }
          }
        } catch (e) {
          console.error("ERROR, secure menu error", e);
        }

        try {
          // License report - menu item visible or not
          const licenseReportMenuItem = menuConfig.aside.items.find(
            (item) => item.page === "licensing-report",
          ); // License Report menu

          // Check if the feature flag is enabled, also check if the organization has the relevant subscription
          if (
            licenseReportMenuItem &&
            menuFeatureFlag.feature.includes("license") &&
            hasValidM365LicensingReportSubscription != null
          ) {
            licenseReportMenuItem.visible = menuEnabled;
            // Check if the org has subscription, that overrides the FF with its state
            if (
              licenseReportMenuItem.visible &&
              !hasValidM365LicensingReportSubscription
            ) {
              // License Report Preview
              const licenseReportPreviewMenuItem = menuConfig.aside.items.find(
                (item) => item.page === "licensing-report-preview",
              );
              licenseReportMenuItem.visible = false;
              licenseReportPreviewMenuItem.visible = true;
            }
          }
        } catch (e) {
          console.error("ERROR, licensing-report menu error", e);
        }

        try {
          // Discover report - menu item visible or not
          const discoverMenuItem = menuConfig.aside.items.find(
            (item) => item.page === "discovery",
          ); // Discover Report menu

          const discoverComplianceMenuItem = discoverMenuItem?.submenu.find(
            (subItem) => subItem.page === "discover/risk",
          );
          if (discoverComplianceMenuItem && !isdiscoverComplianceEnabled) {
            discoverComplianceMenuItem.visible = false;
          }
        } catch (e) {
          console.error("ERROR, Discover-report menu error", e);
        }
      }

      // Update menu config
      dispatch(builder.actions.setMenuConfig(menuConfig));
    }

    if (permissions && menuConfig) {
      menuConfig.aside.items.forEach((menuItem) => {
        if (!displayPage(`/${menuItem.page}`, permissions)) {
          menuItem.visible = false; // eslint-disable-line no-param-reassign
        } else if (menuItem.submenu) {
          menuItem.submenu.forEach((submenuItem) => {
            if (!displayPage(`/${submenuItem.page}`, permissions)) {
              submenuItem.visible = false; // eslint-disable-line no-param-reassign
            }
          });
        }
      });

      // hide configuration parent menu if no child found
      const configMenu = menuConfig.aside.items.find(
        (x) => x.page === "configuration",
      );
      if (configMenu) {
        let configMenuChildrenVisible = false;
        // eslint-disable-next-line array-callback-return
        configMenu.submenu.map((sub) => {
          if (sub.visible) {
            configMenuChildrenVisible = true;
          }
        });
        configMenu.visible = configMenuChildrenVisible;
      }
    }
  }, [
    menuConfig,
    featureFlags,
    permissions,
    dispatch,
    hasValidM365LicensingReportSubscription,
    hasValidPostureBaselineSubscription,
  ]);

  useEffect(() => {
    if (layoutConfig.demo !== builderState.layoutConfig.demo) {
      dispatch(builder.actions.setLayoutConfigs(layoutConfig));
    }
  }, [dispatch, builderState, layoutConfig]);

  useEffect(() => {
    dispatch(builder.actions.setHtmlClassService(htmlClassService));
  }, [dispatch, htmlClassService]);

  return htmlClassService === builderState.htmlClassServiceObjects ? (
    // Render content when `htmlClassService` synchronized with redux store.
    children
  ) : (
    // Otherwise sow loading splash screen.
    <LayoutSplashScreen />
  );
}
