const actionTypes = {
  SetLicenses: "licenses/SET_LICENSES",
};

const initialState = {
  data: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetLicenses:
      return { ...state, data: action.payload.licenses };
    default:
      return state;
  }
};

export const actions = {
  setLicenses: (licenses) => ({
    type: actionTypes.SetLicenses,
    payload: { licenses },
  }),
};
