import React from "react";
import { useSelector } from "react-redux";
import { useHistory, generatePath } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Button } from "@augmentt-dev/ui-components";
import { POSTURE_TEMPLATE_PATH } from "../../../../../router/constants";

function CreatePostureTemplateButton() {
  const history = useHistory();

  const hasValidPostureBaselineSubscription = useSelector(
    ({ meta }) => meta.hasValidPostureBaselineSubscription,
  );

  const newTemplatePath = generatePath(POSTURE_TEMPLATE_PATH, {
    templateId: "new",
  });

  return (
    <Button
      onClick={() => history.push(newTemplatePath)}
      disabled={!hasValidPostureBaselineSubscription}
    >
      <FormattedMessage id="POSTURE_TEMPLATES.CREATE_NEW" />
    </Button>
  );
}

export default CreatePostureTemplateButton;
