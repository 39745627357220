import { rest } from "msw";
import { orderBy, isEmpty } from "lodash";
import employees from "./data/table.json";

/**
 * Mocks the response of a table of employees
 */
const getTableData = rest.get("/employees-table", (req, res, ctx) => {
  const { searchParams } = req.url;

  const nameFilter = searchParams.get("name");
  const departmentFilter = searchParams.getAll("department");
  const filtered = employees.filter((item) => {
    let exists = true;
    if (nameFilter) {
      exists =
        exists && item.name.toLowerCase().includes(nameFilter.toLowerCase());
    }
    if (!isEmpty(departmentFilter)) {
      exists = exists && departmentFilter.includes(item.department);
    }
    return exists;
  });

  const sortField = searchParams.get("sort_field");
  const sortOrder = searchParams.get("sort_direction");
  let ordered = filtered;
  if (sortField && sortOrder) {
    ordered = orderBy(filtered, [sortField], [sortOrder.toLowerCase()]);
  }

  const page = Number(searchParams.get("page"));
  const pageSize = Number(searchParams.get("page_size"));
  const firstItemIdx = (page - 1) * pageSize;
  const lastItemIdx = firstItemIdx + pageSize;
  const data = ordered.slice(firstItemIdx, lastItemIdx);

  const result = {
    meta: {
      count: filtered.length,
      sortable: ["id", "name", "department"],
      filterable: [
        {
          field: "name",
          type: "TEXT",
        },
        {
          field: "department",
          type: "MULTISELECT",
          options: [
            {
              value: "Business Development",
              count: 19,
            },
            {
              value: "Sales",
              count: 14,
            },
            {
              value: "Legal",
              count: 15,
            },
            {
              value: "Marketing",
              count: 18,
            },
            {
              value: "Services",
              count: 21,
            },
            {
              value: "Support",
              count: 13,
            },
            {
              value: "Product Management",
              count: 19,
            },
            {
              value: "Training",
              count: 19,
            },
            {
              value: "Engineering",
              count: 16,
            },
            {
              value: "Human Resources",
              count: 16,
            },
            {
              value: "Research and Development",
              count: 18,
            },
            {
              value: "Accounting",
              count: 12,
            },
          ],
        },
      ],
      searchable: [],
    },
    data,
  };

  return res(ctx.json(result), ctx.delay());
});

const apis = [getTableData];

export default apis;
