/* Reducer to control actions and values set by the SubHeader component */
const actionTypes = {
  SetGlobalValue: "subheader/SET_GLOBAL_VALUE",
};

const initialState = {
  showEventSettingsModal: false,
  dimissedEventSettingsBanners: [],
  showGdapMagicLinkModal: false,
  redirectNav: null,
  redirectedFromLogin: false,
  createNewPolicy: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetGlobalValue:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const actions = {
  setGlobalValue: (payload = {}) => ({
    type: actionTypes.SetGlobalValue,
    payload,
  }),
};
