export const POSTURE_TEMPLATES_LIST_PATH = "/secure/posture-templates";
export const POSTURE_TEMPLATE_PATH = "/secure/posture-templates/:templateId";

export const SECURE_POSTURE_TAB_KEYS = {
  AUDIT: "audit",
  PROTECT: "protect",
};
export const SECURE_POSTURE_PATH =
  "/secure/security-posture/:companySelectorId?";
export const SECURE_POSTURE_COMPANY_PATH =
  "/secure/security-posture/:companySelectorId?/:tabKey";
export const SECURE_POSTURE_CHECK_PATH = `/secure/security-posture/:companySelectorId/${SECURE_POSTURE_TAB_KEYS.AUDIT}/:securityCheckType`;

export const SECURE_ALERTS_PATH = "/secure/alerts/:companySelectorId?";

export const SECURE_POLICY_TEMPLATES_PATH =
  "/secure/policy-templates/:companySelectorId?";

export const INTEGRATIONS_PATH = "/integrations/:companySelectorId?";

export const POLICY_TEMPLATES_LIST_PATH = "/secure/policy-templates";
