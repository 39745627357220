export function isTestingServer() {
  const testServers = [
    "localhost",
    "develop.augmentt.com",
    "staging.augmentt.com",
    "beta.augmentt.com",
    "test.augmentt.com",
    "develop-testing.augmentt.com",
  ];
  const currentOrigin = window.location.origin;
  const tesetServer = testServers.find((server) =>
    currentOrigin.includes(server),
  );
  return !!tesetServer;
}

export function isAutomationEmail(email) {
  return [
    process.env.REACT_APP_AUTOMATION_EMAIL,
    process.env.REACT_APP_AUTOMATION_EMAIL_CSP,
    process.env.REACT_APP_PRODUCTION_EMAIL,
  ]
    .filter(Boolean) // remove undefined env emails
    .includes(email);
}
