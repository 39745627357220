import axios from "axios";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;

export function getEngageInfo(customer, employee, department) {
  return axios.post(`${ROOT}/app/manage`, {
    customer: customer ? customer.id : "*",
    employee: employee || "",
    department: department || "",
  });
}

export function deleteSubscription(subscription, customer) {
  return axios.delete(`${ROOT}/app/${subscription}`, {
    customer: customer ? customer.id : "*",
  });
}

export function getAppConfigs() {
  return axios.get(`${ROOT}/support/config`, {});
}

export function createTicket(customer, employee, application, action) {
  if (employee.length === 1 && !employee[0]) {
    // eslint-disable-next-line no-param-reassign
    employee = []; // make empty
  }

  return axios.post(`${ROOT}/app/action`, {
    customer,
    employee,
    app: application,
    action,
  });
}

export function getCategories(customer) {
  return new Promise((resolve) => {
    // note: this is a hard coded list in S3, will be future updated by customers
    if (!customer) {
      // eslint-disable-next-line no-param-reassign
      customer = "*";
    }
    axios
      .get(`${ROOT}/app/category`, {
        customer: typeof customer === "object" ? customer.id : customer,
      })
      .then((response) => {
        let categories = [];
        if (typeof response.data === "object" && response.data.length) {
          // data is an array of strings, format it into frontend usable
          categories = response.data.map((category) => ({ label: category }));
        }

        resolve(categories);
      });
  });
}

export function getExternalLinks() {
  return axios.get(`${ROOT}/app/external-links`);
}
