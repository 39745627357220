const actionTypes = {
  OpenSessionModal: "sessionModal/OPEN_SESSION_MODAL",
  CloseSessionModal: "sessionModal/CLOSE_SESSION_MODAL",
};

const initialState = {
  show: false,
  description: "",
  refresh: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OpenSessionModal:
      return {
        ...state,
        show: true,
        description: action.payload.description,
        refresh: action.payload.refresh,
        idleTimer: action.payload.idleTimer,
      };
    case actionTypes.CloseSessionModal:
      return { ...initialState };
    default:
      return state;
  }
};

export const actions = {
  openSessionModal: (payload) => ({
    type: actionTypes.OpenSessionModal,
    payload,
  }),
  closeSessionModal: () => ({ type: actionTypes.CloseSessionModal }),
};
