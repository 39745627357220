import axios from "axios";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;

export const USERS_URL = `${ROOT}/user`;
export const ORGANIZATION_URL = `${ROOT}/organization`;
export const INVITE_URL = `${ROOT}/tools/cognito/invite`;
export const TOGGLE_URL = `${ROOT}/tools/cognito/mfa`;
export const USER_STATUS_URL = `${ROOT}/tools/cognito/user-status`;
export const COGNITO_RESEND_INVITE = `${ROOT}/tools/cognito/invite-resend`;

export function signUp(values) {
  return axios.post(`${ROOT}/signup`, {
    organization: {
      name: values.companyName,
    },
    customer: {
      name: values.companyName,
    },
    user: {
      amazon_id: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
      phone: values.phone,
      role: values.role,
    },
  });
}

/* eslint-disable camelcase */
export function getUserById(amazon_id) {
  return axios.post(`${ORGANIZATION_URL}/user/amazon`, { amazon_id });
}
/* eslint-enable camelcase */

export function inviteUser(user) {
  return axios.post(INVITE_URL, {
    amazon_id: user.email,
    username: user.email,
    email: user.email,
    role: user.role,
    first_name: user.first_name,
    last_name: user.last_name,
    organization_string: user.Customer.organization.toString(),
    customer_string: user.Customer.id.toString(),
    login_options: user.login_options,
    message_action: user.message_action,
  });
}

export function getCognitoUserStatus(email) {
  return axios.post(USER_STATUS_URL, {
    email,
  });
}

export function resendSignupEmail(email) {
  return axios.post(COGNITO_RESEND_INVITE, {
    email,
  });
}

export function toggleMFA(user) {
  return axios.post(TOGGLE_URL, {
    customer: user.Customer.id,
    email: [user.email],
  });
}

export function getSystemUsers(orgId) {
  return axios.get(`${ORGANIZATION_URL}/${orgId}/user`);
}

export function addSystemUser(organizationId, data) {
  return axios.post(`${ORGANIZATION_URL}/${organizationId}/user`, data);
}

export function updateSystemUser(organizationId, userId, data) {
  return axios.put(
    `${ORGANIZATION_URL}/${organizationId}/user/${userId}`,
    data,
  );
}

export function deleteSystemUser(organizationId, userId) {
  return axios.delete(`${ORGANIZATION_URL}/${organizationId}/user/${userId}`);
}

export function updateUserProfile(data) {
  return axios.put(`${USERS_URL}/profile`, data);
}

export function checkAgreement(user) {
  return axios.put(`${USERS_URL}/${user.id}/agreement`, {
    type: "EULA",
  });
}

export function signAgreement(user) {
  return axios.post(`${USERS_URL}/${user.id}/agreement`, {
    version: user.eula.latestVersion,
    type: "EULA",
  });
}
