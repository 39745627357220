/* eslint-disable react/prop-types, new-cap */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Amplify, { Auth } from "aws-amplify";
import * as Yup from "yup";
import awsConfig from "../pages/auth/aws-exports";

import * as user from "../../_augmentt/ducks/user";
import * as customers from "../../_augmentt/ducks/customers";
import * as uploads from "../../_augmentt/ducks/uploads";
import * as licenses from "../../_augmentt/ducks/licenses";
import * as meta from "../../_augmentt/ducks/meta";
import * as sessionModal from "../../_augmentt/ducks/sessionModal";

export const NO_SPACE_REGEX_EXP = /^\S+$/;
export const PASSWORD_REGEX_EXP =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/;
export const PHONE_REGEX_EXP =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const allowPage = (path, allowPermissions) => {
  if (path === "users") {
    // eslint-disable-next-line no-param-reassign
    path = "systemUsers";
  } else if (path.includes("secure")) {
    // eslint-disable-next-line no-param-reassign
    path = "secure";
  } else if (path.includes("licenses")) {
    // eslint-disable-next-line no-param-reassign
    path = "licenseCenter";
  } else if (path.includes("look-and-feel")) {
    // eslint-disable-next-line no-param-reassign
    path = "lookAndFeel";
  } else if (path.includes("discovery") || path.includes("discover")) {
    // eslint-disable-next-line no-param-reassign
    path = "discover";
  }

  return !(allowPermissions[path] && allowPermissions[path] === "disabled");
};

export const getConfirmPasswordSchema = (intl, oneOfRef) =>
  new Yup.string()
    .min(
      8,
      intl.formatMessage({
        id: "AUTH.VALIDATION.TOO_SHORT",
      }),
    )
    .oneOf(
      [Yup.ref(oneOfRef), null],
      intl.formatMessage({
        id: "AUTH.VALIDATION.PASSWORD_MISMATCH",
      }),
    )
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      }),
    );

export const getPasswordSchema = (intl) =>
  new Yup.string()
    .min(
      8,
      intl.formatMessage({
        id: "AUTH.VALIDATION.TOO_SHORT",
      }),
    )
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      }),
    );

export const getNewPasswordSchema = (intl) =>
  // START_WITH_SPACE
  new Yup.string()
    .matches(
      NO_SPACE_REGEX_EXP,
      intl.formatMessage({
        id: "AUTH.VALIDATION.NO_SPACE",
      }),
    )
    .min(
      8,
      intl.formatMessage({
        id: "AUTH.VALIDATION.TOO_SHORT",
      }),
    )
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      }),
    )
    .matches(
      PASSWORD_REGEX_EXP,
      intl.formatMessage({
        id: "AUTH.VALIDATION.INVALID_PASSWORD",
      }),
    );

export const getEmailSchema = (intl) =>
  new Yup.string()
    .email(
      intl.formatMessage({
        id: "AUTH.VALIDATION.INVALID_EMAIL",
      }),
    )
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      }),
    );

export const getNonRequiredEmailSchema = (intl) =>
  new Yup.string().email(
    intl.formatMessage({
      id: "AUTH.VALIDATION.INVALID_EMAIL",
    }),
  );

export const getFirstNameSchema = (intl) =>
  new Yup.string()
    .min(
      2,
      intl.formatMessage({
        id: "AUTH.VALIDATION.TOO_SHORT",
      }),
    )
    .max(
      50,
      intl.formatMessage({
        id: "AUTH.VALIDATION.TOO_LONG",
      }),
    )
    .matches(
      /^[a-zA-Z -]*$/,
      intl.formatMessage({
        id: "AUTH.VALIDATION.NO_SPECIAL_CHARACTERS",
      }),
    )
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      }),
    );

export const getLastNameSchema = (intl) =>
  new Yup.string()
    .min(
      2,
      intl.formatMessage({
        id: "AUTH.VALIDATION.TOO_SHORT",
      }),
    )
    .max(
      50,
      intl.formatMessage({
        id: "AUTH.VALIDATION.TOO_LONG",
      }),
    )
    .matches(
      /^[a-zA-Z -]*$/,
      intl.formatMessage({
        id: "AUTH.VALIDATION.NO_SPECIAL_CHARACTERS",
      }),
    )
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      }),
    );

export const getCompanyNameSchema = (intl) =>
  new Yup.string()
    .min(
      2,
      intl.formatMessage({
        id: "AUTH.VALIDATION.TOO_SHORT",
      }),
    )
    .max(
      50,
      intl.formatMessage({
        id: "AUTH.VALIDATION.TOO_LONG",
      }),
    )
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      }),
    );

export const getPhoneSchema = (intl) =>
  new Yup.string()
    .matches(
      PHONE_REGEX_EXP,
      intl.formatMessage({
        id: "AUTH.VALIDATION.INVALID_PHONE_NUMBER",
      }),
    )
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      }),
    );

export async function signOut(dispatch) {
  Amplify.configure(awsConfig);
  try {
    // Reset info in redux store
    dispatch(user.actions.setUser());
    dispatch(customers.actions.setCustomers([]));
    dispatch(uploads.actions.setFiles([]));
    dispatch(licenses.actions.setLicenses());
    dispatch(meta.actions.setHasValidSubscription(null));
    dispatch(meta.actions.setChargebeeId(null));
    dispatch(meta.actions.setHasValidM365LicensingReportSubscription(null));
    dispatch(meta.actions.setHasValidPostureBaselineSubscription(null));
    dispatch(customers.actions.setSelectedCustomer([]));
    dispatch(sessionModal.actions.closeSessionModal());

    await Auth.signOut();

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

const openSessionModal = (dispatch, intl) => {
  dispatch(
    sessionModal.actions.openSessionModal({
      description: intl.formatMessage({
        id: "AUTH.SESSIONTIMEOUT.PASSWORD_CHANGED",
      }),
    }),
  );
};

export const verifySession = async (dispatch, intl) => {
  Amplify.configure(awsConfig);

  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const response = await cognitoUser.refreshSessionIfPossible();
    if (response?.code === "NotAuthorizedException") {
      openSessionModal(dispatch, intl);
    }
  } catch (e) {
    openSessionModal(dispatch, intl);
  }
};

// Display a lock or not
export function Lock(props) {
  const { intl, display, messageId } = props;

  let message = "Locked";
  if (messageId)
    message = intl
      .formatMessage({ id: "UPGRADE.TO" })
      .replace("{plan}", intl.formatMessage({ id: messageId }));

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {display === true && (
        <span className="menu__link-badge" style={{ paddingLeft: "1rem" }}>
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip id="lock-tooltip">{message}</Tooltip>}
          >
            <span className="badge-lg fa fa-lock" />
          </OverlayTrigger>
        </span>
      )}
    </>
  );
}
