const actionTypes = {
  SetCustomers: "customers/SET_CUSTOMERS",
  SetSelectedCustomer: "customers/SET_SELECTED_CUSTOMER",
  SetNewSyncingCustomer: "customers/SET_NEW_SYNCING_CUSTOMER",
  RemoveSyncingCustomer: "customers/REMOVE_SYNCING_CUSTOMER",
};

const initialState = {
  data: [],
  syncing: [],
  selectedCustomer: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetCustomers:
      return { ...state, data: action.payload.customers };
    case actionTypes.SetSelectedCustomer:
      return { ...state, selectedCustomer: action.payload.selectedCustomer };
    case actionTypes.SetNewSyncingCustomer:
      return {
        ...state,
        syncing: [...state.syncing, action.payload.customerId],
      };
    case actionTypes.RemoveSyncingCustomer:
      return {
        ...state,
        syncing: state.syncing.filter((id) => id !== action.payload.customerId),
      };
    default:
      return state;
  }
};

export const actions = {
  setCustomers: (customers) => ({
    type: actionTypes.SetCustomers,
    payload: { customers },
  }),
  setSelectedCustomer: (selectedCustomer) => ({
    type: actionTypes.SetSelectedCustomer,
    payload: { selectedCustomer },
  }),
  setNewSyncingCustomer: (customerId) => ({
    type: actionTypes.SetNewSyncingCustomer,
    payload: { customerId },
  }),
  removeSyncingCustomer: (customerId) => ({
    type: actionTypes.RemoveSyncingCustomer,
    payload: { customerId },
  }),
};
