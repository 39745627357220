/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { MultiSelectSearch, SelectDropDown } from "@augmentt-dev/ui-components";
import { useSelector } from "react-redux";
import useImportSettings from "../../Employees/useImportSettings";
import styles from "./ImportSettingsModal.module.scss";
import { getIntegratedEmployeesMetaData } from "../../../../crud/integration.crud";

function ImportSettingsModal(props) {
  // Props
  const {
    show,
    onHide,
    groupOptions,
    licenseOptions,
    rolesOptions,
    domainOptions,
    usersOption,
    intl,
    selectedIntegrationType,
    customer,
  } = props;

  const [selectedCompany, setSelectedCompany] = useState(null);
  const {
    estimate,
    getEstimate,
    getImportSettings,
    importSettings,
    importSettingsLoading,
    saveImportSettings,
    updateImportSettings,
    deleteCustomerImportSettings,
    defaultRuleSettings,
  } = useImportSettings(
    selectedCompany ? selectedCompany.value : customer?.id,
    selectedIntegrationType,
  );

  const IMPORT_ALL = "All";
  const IMPORT_OTHERS_TEXT = "Selected accounts";
  const EXCLUDE_NONE_TEXT = "None";
  const EXCLUDE_QUICK_OPTION = "Quick option";
  const EXCLUDE_SELECT_ACCOUNT = "Selected";

  // Local states
  const [includeAccordion, setIncludeAccordion] = useState(false);
  const [excludeAccordion, setexcludeAccordion] = useState(false);
  const [accountSelection, setAccountSelection] = useState(IMPORT_ALL);
  const [excludeSelection, setExcludeSelection] = useState(EXCLUDE_NONE_TEXT);
  const [excludeSelectionText, setExcludeSelectionText] =
    useState(EXCLUDE_NONE_TEXT);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedLicenseType, setSelectedLicenseType] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [includeAllCompany, setIncludeAllCompany] = useState(true);

  const [selectedExcludeLicenseType, setSelectedExcludeLicenseType] = useState(
    [],
  );
  const [selectedExcludeGroups, setSelectedExcludeGroups] = useState([]);
  const [selectedExcludeUsers, setSelectedExcludeUsers] = useState([]);
  const [selectedExcludeRoles, setSelectedExcludeRoles] = useState([]);
  const [accountWithoutLicense, setAccountWithoutLicense] = useState(false);
  const [accountWithNoGroups, setAccountWithNoGroups] = useState(false);
  const [isDefaultRuleChanged, setIsDefaultRuleChanged] = useState(false);
  const [importSettingsLoaded, setImportSettingsLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [changedGroupOptions, setChangedGroupOptions] = useState([]);
  const [changedLicensesOptions, setChangedLicensesOptions] = useState([]);
  const [changedRolesOptions, setChangedRolesOptions] = useState([]);
  const [changedDomainOptions, setChangedDomainOptions] = useState([]);
  const [changedUsersOptions, setChangedUsersOptions] = useState([]);
  const [isNewRuleSaved, setIsNewRuleSaved] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    getDetails();
    setIsDefaultRuleChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importSettings]);

  const handleDefaultValueChange = () => {
    setIsDefaultRuleChanged(true); // Set state to true when any value changes
  };

  const getFilteredParam = () => {
    const payload = {
      payload: {
        domains:
          // eslint-disable-next-line no-nested-ternary
          domainOptions?.length === 1
            ? [domainOptions[0]?.value]
            : selectedDomain.length && selectedDomain[0] === "all"
              ? "all"
              : selectedDomain || [],
        include:
          accountSelection === IMPORT_ALL
            ? "all"
            : {
                groups: selectedGroups,
                users: selectedUsers,
                licences: selectedLicenseType,
                roles: selectedRoles,
              },
        exclude:
          excludeSelection === EXCLUDE_NONE_TEXT
            ? "none"
            : {
                groups:
                  excludeSelection === EXCLUDE_SELECT_ACCOUNT
                    ? selectedExcludeGroups
                    : undefined,
                users:
                  excludeSelection === EXCLUDE_SELECT_ACCOUNT
                    ? selectedExcludeUsers
                    : undefined,
                licences:
                  excludeSelection === EXCLUDE_SELECT_ACCOUNT
                    ? selectedExcludeLicenseType
                    : undefined,
                roles:
                  excludeSelection === EXCLUDE_SELECT_ACCOUNT
                    ? selectedExcludeRoles
                    : undefined,
                noLicences:
                  excludeSelection === EXCLUDE_QUICK_OPTION
                    ? accountWithoutLicense
                    : undefined,
                noGroups:
                  excludeSelection === EXCLUDE_QUICK_OPTION
                    ? accountWithNoGroups
                    : undefined,
              },
      },
      integrationType: selectedIntegrationType,
    };
    Object.keys(payload?.payload?.exclude).forEach((key) => {
      if (payload?.payload?.exclude[key] === undefined) {
        delete payload?.payload?.exclude[key];
      }
    });
    return payload;
  };

  const customers = useSelector((state) => state.customers);

  const getEmployeesMetaData = async (selectedCustomer) => {
    try {
      const employeesMeta = await getIntegratedEmployeesMetaData(
        selectedCustomer.value,
        selectedIntegrationType,
      );
      // Assuming employeesMeta contains the new values for the options

      if (employeesMeta) {
        setChangedGroupOptions(employeesMeta?.data?.groups || []);
        setChangedLicensesOptions(employeesMeta?.data?.licenseType || []);
        setChangedRolesOptions(employeesMeta?.data?.roles || []);
        setChangedDomainOptions(employeesMeta?.data?.domains || []);
        setChangedUsersOptions(employeesMeta?.data?.users || []);
      }
    } catch (err) {
      console.error(`getIntegratedEmployeesMetaData error`, err);
    }
  };

  // get popup details
  const getDetails = () => {
    // include work
    if (importSettings && importSettings?.domains) {
      /* eslint-disable no-nested-ternary */
      const resDomains =
        !domainOptions || domainOptions.length === 0
          ? "all"
          : domainOptions?.length === 1
            ? [domainOptions[0]?.value]
            : importSettings?.domains === "all"
              ? ["all"]
              : importSettings?.domains || [];
      /* eslint-enable no-nested-ternary */
      setSelectedDomain(resDomains);
    }

    if (importSettings && importSettings?.include?.groups) {
      setSelectedGroups(importSettings?.include?.groups || []);
      if (accountSelection !== IMPORT_OTHERS_TEXT)
        setAccountSelection(IMPORT_OTHERS_TEXT);
    }

    if (importSettings && importSettings?.include?.users) {
      setSelectedUsers(importSettings?.include?.users || []);
      if (accountSelection !== IMPORT_OTHERS_TEXT)
        setAccountSelection(IMPORT_OTHERS_TEXT);
    }

    if (importSettings && importSettings?.include?.roles) {
      setSelectedRoles(importSettings?.include?.roles || []);
      if (accountSelection !== IMPORT_OTHERS_TEXT)
        setAccountSelection(IMPORT_OTHERS_TEXT);
    }

    if (importSettings && importSettings?.include?.licences) {
      setSelectedLicenseType(importSettings?.include?.licences || []);
      if (accountSelection !== IMPORT_OTHERS_TEXT)
        setAccountSelection(IMPORT_OTHERS_TEXT);
    }

    // exclude work
    if (importSettings && importSettings?.exclude === "none") {
      setExcludeSelection(EXCLUDE_NONE_TEXT);
      setExcludeSelectionText(EXCLUDE_NONE_TEXT);
    } else {
      if (importSettings && importSettings?.exclude?.users) {
        setSelectedExcludeUsers(importSettings?.exclude?.users || []);
        if (excludeSelectionText !== EXCLUDE_SELECT_ACCOUNT) {
          setExcludeSelectionText(EXCLUDE_SELECT_ACCOUNT);
          setExcludeSelection(EXCLUDE_SELECT_ACCOUNT);
        }
      }

      if (importSettings && importSettings?.exclude?.groups) {
        setSelectedExcludeGroups(importSettings?.exclude?.groups || []);
        if (excludeSelectionText !== EXCLUDE_SELECT_ACCOUNT) {
          setExcludeSelectionText(EXCLUDE_SELECT_ACCOUNT);
          setExcludeSelection(EXCLUDE_SELECT_ACCOUNT);
        }
      }

      if (importSettings && importSettings?.exclude?.licences) {
        setSelectedExcludeLicenseType(importSettings?.exclude?.licences || []);
        if (excludeSelectionText !== EXCLUDE_SELECT_ACCOUNT) {
          setExcludeSelectionText(EXCLUDE_SELECT_ACCOUNT);
          setExcludeSelection(EXCLUDE_SELECT_ACCOUNT);
        }
      }

      if (importSettings && importSettings?.exclude?.roles) {
        setSelectedExcludeRoles(importSettings?.exclude?.roles || []);
        if (excludeSelectionText !== EXCLUDE_SELECT_ACCOUNT) {
          setExcludeSelectionText(EXCLUDE_SELECT_ACCOUNT);
          setExcludeSelection(EXCLUDE_SELECT_ACCOUNT);
        }
      }

      if (
        importSettings &&
        importSettings?.exclude?.hasOwnProperty("noLicences") // eslint-disable-line no-prototype-builtins
      ) {
        setAccountWithoutLicense(importSettings?.exclude?.noLicences);
        setExcludeSelection(EXCLUDE_QUICK_OPTION);
      }

      if (
        importSettings &&
        importSettings?.exclude?.hasOwnProperty("noGroups") // eslint-disable-line no-prototype-builtins
      ) {
        setAccountWithNoGroups(importSettings?.exclude?.noGroups);
        setExcludeSelection(EXCLUDE_QUICK_OPTION);
      }
    }
  };

  // Handles initial render of component
  useEffect(() => {
    getEstimate(getFilteredParam());
    getImportSettings();
    setIsDefaultRuleChanged(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    excludeTextSelector(excludeSelection);
    getEstimate(getFilteredParam());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedDomain,
    selectedGroups,
    selectedRoles,
    selectedLicenseType,
    selectedUsers,
    selectedExcludeGroups,
    selectedExcludeLicenseType,
    selectedExcludeUsers,
    selectedExcludeRoles,
    accountWithoutLicense,
    accountWithNoGroups,
    excludeSelection,
    accountSelection,
  ]);

  useEffect(() => {
    // Load import settings once when the component mounts
    if (!importSettingsLoaded) {
      getImportSettings();
      setImportSettingsLoaded(true);
    }
  }, [getImportSettings, importSettingsLoaded]);

  useEffect(() => {
    setIncludeAllCompany(true);
  }, []);

  const excludeTextSelector = (excludeSelectedData) => {
    const excludeText = [];
    if (excludeSelectedData === EXCLUDE_QUICK_OPTION) {
      if (accountWithoutLicense || accountWithNoGroups) {
        if (accountWithoutLicense) excludeText.push("No licenses");
        if (accountWithNoGroups) excludeText.push("No groups");
      }
      setExcludeSelectionText(excludeText.join(", "));
    }
  };

  const hide = () => {
    onHide();
  };

  const withAllOptions = (options, label, value) => [
    {
      label,
      value,
    },
    ...options,
  ];

  const onDomainChange = (domain) => {
    handleDefaultValueChange();
    const hasAllPreviouslySelected =
      selectedDomain?.filter((item) => item === "all") || [];
    const hasAllCurrentlySelected =
      domain?.filter((item) => item === "all") || [];
    if (hasAllPreviouslySelected?.length) {
      if (hasAllCurrentlySelected?.length) {
        setSelectedDomain(domain?.filter((item) => item !== "all"));
      } else {
        setSelectedDomain(domain);
      }
    } else if (hasAllCurrentlySelected?.length) {
      setSelectedDomain(domain?.filter((item) => item === "all"));
    } else {
      setSelectedDomain(domain?.filter((item) => item !== "all"));
    }
  };

  // Import account submit
  const handleSubmit = async () => {
    if (!(importSettings && Object.keys(importSettings).length > 0)) {
      saveImportSettings(getFilteredParam());
      setIsNewRuleSaved(true);
    } else {
      updateImportSettings(getFilteredParam());
      setIsNewRuleSaved(true);
    }
  };

  // section for count
  // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
  function CountSection({ estimate }) {
    return (
      <div className={styles.empolyeeCount}>
        <div>
          <h3>{estimate?.accounts ?? 0}</h3>
          <p>
            {intl.formatMessage({
              id: "ADMINISTRATION.TITLE_ACCOUNT_FOUND_ON_MICROSOFT_TENANT",
            })}
          </p>
        </div>
        <div>
          <h3>{estimate?.selectedAccounts ?? 0}</h3>
          <p>
            {intl.formatMessage({
              id: "ADMINISTRATION.TITLE_SELECTED_ACCOUNTS_TO_SYNC_TO_AUGMENTT",
            })}
          </p>
        </div>
      </div>
    );
  }

  const isDefaultImportSettings = () => {
    const defaultRule = {
      domains: [],
      exclude: {
        noGroups: false,
        noLicences: true,
      },
      include: "all",
    };

    if (
      defaultRule.exclude?.noGroups === accountWithNoGroups &&
      defaultRule.exclude?.noLicences === accountWithoutLicense &&
      defaultRule.include === accountSelection.toLocaleLowerCase() &&
      excludeSelection !== EXCLUDE_NONE_TEXT &&
      excludeSelection !== EXCLUDE_SELECT_ACCOUNT &&
      selectedDomain?.length === 0
    ) {
      return true;
    }

    return false;
  };

  // disable import button
  const disabledImports = () => {
    if (
      accountSelection === IMPORT_OTHERS_TEXT &&
      !(
        selectedGroups.length ||
        selectedUsers.length ||
        selectedLicenseType.length ||
        selectedRoles.length
      )
    ) {
      return true;
    }
    if (
      excludeSelection === EXCLUDE_SELECT_ACCOUNT &&
      !(
        selectedExcludeGroups.length ||
        selectedExcludeUsers.length ||
        selectedExcludeRoles.length ||
        selectedExcludeLicenseType.length
      )
    ) {
      return true;
    }

    if (defaultRuleSettings.organization_id === null && !isDefaultRuleChanged) {
      return true;
    }

    if (estimate.selectedAccounts === 0) {
      return true;
    }

    if (isDefaultImportSettings()) {
      return true;
    }

    return false;
  };

  const handleResetClick = () => {
    setShowConfirmation(true);
  };

  const handleResetConfirm = () => {
    deleteCustomerImportSettings().then(() => {
      getImportSettings();
    });
    setShowConfirmation(false);
    setIsNewRuleSaved(false);
  };

  const handleResetCancel = () => {
    setShowConfirmation(false); // Close the confirmation modal
  };

  return (
    <Modal
      id="ImportSettingsModal"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={hide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <FormattedMessage id="ADMINISTRATION.EMPLOYEE_IMPORT_SETTINGS" />
        </Modal.Title>
      </Modal.Header>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <Modal.Body>
          <div className={styles.importSettingModal}>
            <CountSection estimate={estimate} />
            {/* accordion for import/excluded */}

            <div className={styles.accordion} id="accordionSelector">
              <div className={styles.card}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  className={`card-header ${styles.importCompanyDropdown}`}
                  id="headingThree"
                >
                  <p>
                    {intl.formatMessage({
                      id: "ADMINISTRATION.TITLE_COMPANY",
                    })}{" "}
                    <span>
                      (
                      {selectedCompany
                        ? selectedCompany.label
                        : customer?.customer_name}
                      )
                    </span>
                  </p>
                </div>
                <div
                  id="collapseThree"
                  className={`collapse ${includeAllCompany ? "show" : ""}`}
                  aria-labelledby="headingThree"
                  data-parent="includeAllCompany"
                >
                  <div className={`${styles.cardBody} card-body`}>
                    <p>
                      <FormattedMessage id="ADMINISTRATION.IMPORT_SETTINGS_COMPANY_TEXT" />
                    </p>

                    <div className={styles.selectOthersParent}>
                      <div className={styles.importSelection}>
                        <div className={styles.multiSelectOthers}>
                          <SelectDropDown
                            id="selectIncludeAccount"
                            isDisabled={false}
                            value={selectedCompany}
                            defaultLabel={customer?.customer_name}
                            options={(customers.data || []).map((list) => ({
                              label: list.customer_name,
                              value: list.id,
                            }))}
                            onChange={(selectedCustomer) => {
                              if (selectedCustomer) {
                                setSelectedCompany(selectedCustomer);
                                setImportSettingsLoaded(false);
                                getEmployeesMetaData(selectedCustomer);
                              } else {
                                setSelectedCompany([]);
                                setImportSettingsLoaded(false);
                              }
                            }}
                            name="selectIncludeAccount"
                            isClearable={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.card}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  className={`card-header ${styles.importExcludeDropdown}`}
                  id="headingOne"
                  onClick={() => {
                    setIncludeAccordion(!includeAccordion);
                    setexcludeAccordion(false);
                    // setIncludeAllCompany(false);
                  }}
                >
                  <p>
                    {intl.formatMessage({
                      id: "ADMINISTRATION.TITLE_IMPORT",
                    })}{" "}
                    <span
                      className={`${
                        accountSelection === IMPORT_OTHERS_TEXT &&
                        !(
                          selectedGroups.length ||
                          selectedUsers.length ||
                          selectedLicenseType.length ||
                          selectedRoles.length
                        )
                          ? styles.validationErrorColor
                          : ""
                      }`}
                    >
                      ({accountSelection})
                    </span>
                  </p>
                  <span
                    className={`${includeAccordion && styles.activeDropDown}`}
                  >
                    <i className="menu__ver-arrow flaticon2-right-arrow" />
                  </span>
                </div>
                <div
                  id="collapseOne"
                  className={`collapse ${includeAccordion ? "show" : ""}`}
                  aria-labelledby="headingOne"
                  data-parent="includeAccordion"
                >
                  <div className={`${styles.cardBody} card-body`}>
                    <p>
                      <FormattedMessage id="ADMINISTRATION.IMPORT_SETTINGS_IMPORT_TEXT" />
                    </p>
                    <p className={styles.importTitle}>
                      <FormattedMessage id="ADMINISTRATION.IMPORT_SETTINGS_DOMAINS_TITLE" />
                    </p>
                    {domainOptions && domainOptions?.length === 1 && (
                      <>
                        <span>
                          {intl.formatMessage({
                            id: "ADMINISTRATION.TITLE_ONLY_ONE_DOMAIN_FOUND_FOR_THIS_TENANT",
                          })}
                        </span>
                        <span>
                          <b>@{domainOptions[0]?.label}</b>
                        </span>
                      </>
                    )}
                    {!(domainOptions && domainOptions?.length === 1) && (
                      <div className={styles.domainSelector}>
                        <div className={styles.multiSelectOthers}>
                          <MultiSelectSearch
                            id="selectDomain"
                            name="selectDomain"
                            options={withAllOptions(
                              (changedDomainOptions?.length > 0
                                ? changedDomainOptions
                                : domainOptions || []
                              ).map((list) => ({
                                label: list.label,
                                value: list.value,
                              })),
                              "All domains",
                              "all",
                            )}
                            onChange={(domain) => onDomainChange(domain)}
                            remoteValue={selectedDomain}
                            placeholder={
                              selectedUsers?.length
                                ? intl.formatMessage({
                                    id: "PLACEHOLDER.DOMAINS",
                                  })
                                : intl.formatMessage({
                                    id: "PLACEHOLDER.SELECT_DOMAINS",
                                  })
                            }
                          />
                        </div>
                      </div>
                    )}
                    <p className={`${styles.importTitle} ${styles.mt_18}`}>
                      <FormattedMessage id="ADMINISTRATION.IMPORT_SETTINGS_ACCOUNT_TITLE" />
                    </p>
                    <div className={styles.accountCheckBox}>
                      <span className={styles.importselectedCheckBox}>
                        <Form.Check
                          className={styles.allAndOtherCheckBox}
                          type="radio"
                          id="allAccount"
                          label={intl.formatMessage({
                            id: "ADMINISTRATION.ALL_ACCOUNTS",
                          })}
                          name="selectAccount"
                          checked={accountSelection === IMPORT_ALL}
                          onClick={() => {
                            setAccountSelection(IMPORT_ALL);
                            handleDefaultValueChange();
                          }}
                        />
                        &nbsp;
                        {accountSelection === IMPORT_ALL && (
                          <span>
                            (
                            {intl.formatMessage({
                              id: "ADMINISTRATION.MINUS_ANY_EXCLUSTIONS",
                            })}
                            )
                          </span>
                        )}
                      </span>
                      <span className={styles.importselectedCheckBox}>
                        <Form.Check
                          className={styles.allAndOtherCheckBox}
                          type="radio"
                          id="SelectAccount"
                          label={intl.formatMessage({
                            id: "ADMINISTRATION.SELECT_MULTI",
                          })}
                          name="selectAccount"
                          checked={accountSelection === IMPORT_OTHERS_TEXT}
                          onClick={() => {
                            setAccountSelection(IMPORT_OTHERS_TEXT);
                            handleDefaultValueChange();
                          }}
                        />
                        &nbsp;
                        {accountSelection === IMPORT_OTHERS_TEXT && (
                          <span>
                            (
                            {intl.formatMessage({
                              id: "ADMINISTRATION.MINUS_ANY_EXCLUSTIONS",
                            })}
                            )
                          </span>
                        )}
                      </span>
                    </div>
                    {accountSelection === IMPORT_OTHERS_TEXT && (
                      <div className={styles.selectOthersParent}>
                        <div className={styles.importSelection}>
                          <div className={styles.multiSelectOthers}>
                            <MultiSelectSearch
                              id="selectIncludeAccount"
                              name="selectIncludeAccount"
                              options={(changedUsersOptions?.length > 0
                                ? changedUsersOptions
                                : usersOption || []
                              ).map((list) => ({
                                label: list.label,
                                value: list.value,
                              }))}
                              onChange={(users) => {
                                if (users && users.length) {
                                  setSelectedUsers(users);
                                } else {
                                  setSelectedUsers([]);
                                }
                                handleDefaultValueChange();
                              }}
                              remoteValue={selectedUsers}
                              placeholder={
                                selectedUsers?.length
                                  ? intl.formatMessage({
                                      id: "PLACEHOLDER.ACCOUNTS",
                                    })
                                  : intl.formatMessage({
                                      id: "PLACEHOLDER.SELECT_ACCOUNTS",
                                    })
                              }
                              menuPlacement="top"
                            />
                          </div>
                          <div className={styles.multiSelectOthers}>
                            <MultiSelectSearch
                              id="selectIncludeRole"
                              name="selectIncludeRole"
                              options={(changedRolesOptions?.length > 0
                                ? changedRolesOptions
                                : rolesOptions || []
                              ).map((list) => ({
                                label: list.label,
                                value: list.value,
                              }))}
                              onChange={(roles) => {
                                if (roles && roles.length) {
                                  setSelectedRoles(roles);
                                } else {
                                  setSelectedRoles([]);
                                }
                                handleDefaultValueChange();
                              }}
                              remoteValue={selectedRoles}
                              placeholder={
                                selectedRoles?.length
                                  ? intl.formatMessage({
                                      id: "PLACEHOLDER.ROLES",
                                    })
                                  : intl.formatMessage({
                                      id: "PLACEHOLDER.SELECT_ROLES",
                                    })
                              }
                              menuPlacement="top"
                            />
                          </div>
                        </div>

                        <div className={styles.importSelection}>
                          <div className={styles.multiSelectOthers}>
                            <MultiSelectSearch
                              id="selectIncludeGroup"
                              name="selectIncludeGroup"
                              options={(changedGroupOptions?.length > 0
                                ? changedGroupOptions
                                : groupOptions || []
                              ).map((list) => ({
                                label: list.label,
                                value: list.value,
                              }))}
                              onChange={(groups) => {
                                if (groups && groups.length) {
                                  setSelectedGroups(groups);
                                } else {
                                  setSelectedGroups([]);
                                }
                                handleDefaultValueChange();
                              }}
                              remoteValue={selectedGroups}
                              placeholder={
                                selectedGroups?.length
                                  ? intl.formatMessage({
                                      id: "ENGAGE.M365_GROUPS",
                                    })
                                  : intl.formatMessage({
                                      id: "ENGAGE.SELECT_M365_GROUP(S)",
                                    })
                              }
                              menuPlacement="top"
                            />
                          </div>

                          <div className={styles.multiSelectOthers}>
                            <MultiSelectSearch
                              id="selectIncludeLicenseType"
                              name="selectIncludeLicenseType"
                              options={(changedLicensesOptions?.length > 0
                                ? changedLicensesOptions
                                : licenseOptions || []
                              ).map((list) => ({
                                label: list.label,
                                value: list.value,
                              }))}
                              onChange={(value) => {
                                if (value && value.length) {
                                  setSelectedLicenseType(value);
                                } else {
                                  setSelectedLicenseType([]);
                                }
                                handleDefaultValueChange();
                              }}
                              remoteValue={selectedLicenseType}
                              placeholder={
                                selectedLicenseType?.length
                                  ? intl.formatMessage({
                                      id: "LICENSES",
                                    })
                                  : intl.formatMessage({
                                      id: "PLACEHOLDER.SELECT_LICENSE_TYPE",
                                    })
                              }
                              menuPlacement="top"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.card}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  className={`card-header ${styles.importExcludeDropdown}`}
                  id="headingTwo"
                  onClick={() => {
                    setexcludeAccordion(!excludeAccordion);
                    setIncludeAccordion(false);
                  }}
                >
                  <p>
                    {intl.formatMessage({
                      id: "ADMINISTRATION.TITLE_EXCLUDE_FROM_IMPORT",
                    })}{" "}
                    <span
                      className={`${
                        excludeSelectionText === EXCLUDE_NONE_TEXT
                          ? styles.nonActiveSelectionColor
                          : styles.activeSelectionColor
                      } ${
                        excludeSelection === EXCLUDE_SELECT_ACCOUNT &&
                        !(
                          selectedExcludeGroups.length ||
                          selectedExcludeUsers.length ||
                          selectedExcludeRoles.length ||
                          selectedExcludeLicenseType.length
                        )
                          ? styles.validationErrorColor
                          : ""
                      }`}
                    >
                      {excludeSelectionText !== ""
                        ? `(${excludeSelectionText})`
                        : ""}
                    </span>
                  </p>
                  <span
                    className={`${excludeAccordion && styles.activeDropDown}`}
                  >
                    <i className="menu__ver-arrow flaticon2-right-arrow" />
                  </span>
                </div>
                <div
                  id="collapseTwo"
                  className={`collapse ${excludeAccordion ? "show" : ""}`}
                  aria-labelledby="headingTwo"
                  data-parent="exculdeAccordion"
                >
                  <div className={`${styles.cardBody} card-body`}>
                    <p>
                      <FormattedMessage id="ADMINISTRATION.IMPORT_SETTINGS_EXCLUDE_IMPORT_TEXT" />
                    </p>
                    <div className="checkBoxDiv">
                      <div className={styles.accountCheckBox}>
                        <Form.Check
                          className={styles.allAndOtherCheckBox}
                          type="radio"
                          id="excludeNone"
                          label={intl.formatMessage({
                            id: "GENERAL.NONE",
                          })}
                          name="selectExclude"
                          checked={excludeSelection === EXCLUDE_NONE_TEXT}
                          onClick={() => {
                            setExcludeSelection(EXCLUDE_NONE_TEXT);
                            setExcludeSelectionText(EXCLUDE_NONE_TEXT);
                            handleDefaultValueChange();
                          }}
                        />
                        <Form.Check
                          className={styles.allAndOtherCheckBox}
                          type="radio"
                          id="excludeQuickOption"
                          label={intl.formatMessage({
                            id: "GENERAL.QUICK_OPTIONS",
                          })}
                          name="selectExclude"
                          checked={excludeSelection === EXCLUDE_QUICK_OPTION}
                          onClick={() => {
                            setExcludeSelection(EXCLUDE_QUICK_OPTION);
                            excludeTextSelector(EXCLUDE_QUICK_OPTION);
                            handleDefaultValueChange();
                          }}
                        />

                        {excludeSelection === EXCLUDE_QUICK_OPTION && (
                          <div className={styles.excludeQuickOptions}>
                            <Form.Check
                              custom
                              type="checkbox"
                              id="accountWithLicense"
                              checked={accountWithoutLicense}
                              label={intl.formatMessage({
                                id: "ADMINISTRATION.ACCOUNT_WITHOUT_LICENSES",
                              })}
                              onClick={() => {
                                setAccountWithoutLicense(
                                  !accountWithoutLicense,
                                );
                                handleDefaultValueChange();
                              }}
                            />
                            <span>
                              {estimate?.noLicensesAccounts ?? 0}{" "}
                              {intl.formatMessage({
                                id: "ADMINISTRATION.TITLE_ACCOUNTS",
                              })}
                            </span>

                            <Form.Check
                              custom
                              type="checkbox"
                              id="accountWithNoGroups"
                              checked={accountWithNoGroups}
                              label={intl.formatMessage({
                                id: "ADMINISTRATION.ACCOUNT_WITH_NO_GROUPS_ASSIGNED",
                              })}
                              onClick={() => {
                                setAccountWithNoGroups(!accountWithNoGroups);
                                handleDefaultValueChange();
                              }}
                            />
                            <span>
                              {estimate?.noGroupsAccounts ?? 0}{" "}
                              {intl.formatMessage({
                                id: "ADMINISTRATION.TITLE_ACCOUNTS",
                              })}
                            </span>
                          </div>
                        )}

                        <Form.Check
                          className={styles.allAndOtherCheckBox}
                          type="radio"
                          id="excludeSelectMulti"
                          label={intl.formatMessage({
                            id: "ADMINISTRATION.SELECT_MULTI_OPTIONS",
                          })}
                          name="selectExclude"
                          checked={excludeSelection === EXCLUDE_SELECT_ACCOUNT}
                          onClick={() => {
                            setExcludeSelection(EXCLUDE_SELECT_ACCOUNT);
                            setExcludeSelectionText(EXCLUDE_SELECT_ACCOUNT);
                            handleDefaultValueChange();
                          }}
                        />
                        {excludeSelection === EXCLUDE_SELECT_ACCOUNT && (
                          <div className={styles.selectOthersParent}>
                            <div className={styles.importSelection}>
                              <div className={styles.multiSelectOthers}>
                                <MultiSelectSearch
                                  id="selectExcludeUser"
                                  name="selectExcludeUser"
                                  options={(changedUsersOptions?.length > 0
                                    ? changedUsersOptions
                                    : usersOption || []
                                  ).map((list) => ({
                                    label: list.label,
                                    value: list.value,
                                  }))}
                                  onChange={(users) => {
                                    if (users && users.length) {
                                      setSelectedExcludeUsers(users);
                                    } else {
                                      setSelectedExcludeUsers([]);
                                    }
                                    handleDefaultValueChange();
                                  }}
                                  remoteValue={selectedExcludeUsers}
                                  placeholder={
                                    selectedExcludeUsers?.length
                                      ? intl.formatMessage({
                                          id: "PLACEHOLDER.ACCOUNTS",
                                        })
                                      : intl.formatMessage({
                                          id: "PLACEHOLDER.SELECT_ACCOUNTS",
                                        })
                                  }
                                  menuPlacement="top"
                                />
                              </div>
                              <div className={styles.multiSelectOthers}>
                                <MultiSelectSearch
                                  id="selectExcludeRole"
                                  name="selectExcludeRole"
                                  options={(changedRolesOptions?.length > 0
                                    ? changedRolesOptions
                                    : rolesOptions || []
                                  ).map((list) => ({
                                    label: list.label,
                                    value: list.value,
                                  }))}
                                  onChange={(roles) => {
                                    if (roles && roles.length) {
                                      setSelectedExcludeRoles(roles);
                                    } else {
                                      setSelectedExcludeRoles([]);
                                    }
                                    handleDefaultValueChange();
                                  }}
                                  remoteValue={selectedExcludeRoles}
                                  placeholder={
                                    selectedExcludeRoles?.length
                                      ? intl.formatMessage({
                                          id: "PLACEHOLDER.ROLES",
                                        })
                                      : intl.formatMessage({
                                          id: "PLACEHOLDER.SELECT_ROLES",
                                        })
                                  }
                                  menuPlacement="top"
                                />
                              </div>
                            </div>

                            <div className={styles.importSelection}>
                              <div className={styles.multiSelectOthers}>
                                <MultiSelectSearch
                                  id="selectExcludeGroups"
                                  name="selectExcludeGroups"
                                  options={(changedGroupOptions?.length > 0
                                    ? changedGroupOptions
                                    : groupOptions || []
                                  ).map((list) => ({
                                    label: list.label,
                                    value: list.value,
                                  }))}
                                  onChange={(groups) => {
                                    if (groups && groups.length) {
                                      setSelectedExcludeGroups(groups);
                                    } else {
                                      setSelectedExcludeGroups([]);
                                    }
                                    handleDefaultValueChange();
                                  }}
                                  remoteValue={selectedExcludeGroups}
                                  placeholder={
                                    selectedExcludeGroups?.length
                                      ? intl.formatMessage({
                                          id: "ENGAGE.M365_GROUPS",
                                        })
                                      : intl.formatMessage({
                                          id: "ENGAGE.SELECT_M365_GROUP(S)",
                                        })
                                  }
                                  menuPlacement="top"
                                />
                              </div>
                              <div className={styles.multiSelectOthers}>
                                <MultiSelectSearch
                                  id="selectExcludeLicenseType"
                                  name="selectExcludeLicenseType"
                                  options={(changedLicensesOptions?.length > 0
                                    ? changedLicensesOptions
                                    : licenseOptions || []
                                  ).map((list) => ({
                                    label: list.label,
                                    value: list.value,
                                  }))}
                                  onChange={(value) => {
                                    if (value && value.length) {
                                      setSelectedExcludeLicenseType(value);
                                    } else {
                                      setSelectedExcludeLicenseType([]);
                                    }
                                    handleDefaultValueChange();
                                  }}
                                  remoteValue={selectedExcludeLicenseType}
                                  placeholder={
                                    selectedExcludeLicenseType?.length
                                      ? intl.formatMessage({
                                          id: "LICENSES",
                                        })
                                      : intl.formatMessage({
                                          id: "PLACEHOLDER.SELECT_LICENSE_TYPE",
                                        })
                                  }
                                  menuPlacement="top"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.ResetToDefaultSettingsContainer}>
            {(defaultRuleSettings?.organization_id || isNewRuleSaved) && (
              <div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <span
                  onClick={handleResetClick}
                  className={styles.ResetToDefaultSettings}
                >
                  <FormattedMessage id="ADMINISTRATION.RESET_TO_DEFAULT" />
                </span>
              </div>
            )}
          </div>
          <Button onClick={hide} variant="light">
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>
          <Button
            type="submit"
            disabled={importSettingsLoading || disabledImports()}
            id="addEmployeeSubmit"
            variant="primary"
            className="btn-elevate adduser__btn-primary"
          >
            <FormattedMessage id="ADMINISTRATION.IMPORT_ACCOUNTS" />
          </Button>
        </Modal.Footer>
      </Form>
      {/* Confirmation Modal */}
      <Modal
        id="ConfirmationModal"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showConfirmation}
        onHide={handleResetCancel}
        className={styles.custommodal}
        backdrop="static"
      >
        <Modal.Body>
          <p className={styles.customconfirmationmodal}>
            {" "}
            <FormattedMessage id="ADMINISTRATION.RESET_TO_DEFAULT_POPUP_MESSAGE" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleResetCancel} variant="light">
            <FormattedMessage id="GENERAL.NO" />
          </Button>
          <Button onClick={handleResetConfirm}>
            <FormattedMessage id="GENERAL.YES" />
          </Button>
        </Modal.Footer>
      </Modal>
    </Modal>
  );
}

export default injectIntl(ImportSettingsModal);
