import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { generatePath, Link } from "react-router-dom";
import {
  ExternalLink,
  Button,
  Skeleton,
  Spinner,
} from "@augmentt-dev/ui-components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Card from "./Card";
import { COMPLETION_STATUS } from "./constants";
import styles from "./CheckCard.module.scss";

const STATUS_STYLES = {
  [COMPLETION_STATUS.INCOMPLETE]: "text-color-light",
  [COMPLETION_STATUS.PARTIALLY_COMPLETE]: "text-color-warning",
  [COMPLETION_STATUS.COMPLETE]: "text-color-success",
};

const propTypes = {
  /**
   * Title message id
   */
  title: PropTypes.string.isRequired,
  /**
   * Description message id
   */
  description: PropTypes.string.isRequired,
  /**
   * Status of the check (COMPLETE, PARTIALLY_COMPLETE, INCOMPLETE)
   */
  status: PropTypes.string,
  /**
   * If true it means the check was dismissed
   */
  dismissed: PropTypes.bool,
  /**
   * Link to knowledge base
   */
  moreInfoLink: PropTypes.string,
  /**
   * Link configurations to the pages
   */
  pageLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.shape({
        id: PropTypes.string.isRequired, // id of the FormattedMessage
        img: PropTypes.string, // optional image path
        imgAlt: PropTypes.string, // image alt (if image)
      }),
      path: PropTypes.string.isRequired, // link pathname
      search: PropTypes.string, // link search (e.g. ?modal=addUser)
    }),
  ),
  /**
   * Callback to close the modal
   */
  onHide: PropTypes.func.isRequired,
  /**
   * Object containing more information about the check item
   */
  details: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /**
   * Function to render the details of an complete/partially complete check, called with 'details'
   */
  renderDetails: PropTypes.func,
  /**
   * Indicates that information is loading
   */
  loading: PropTypes.bool,
  /**
   * Indicates that the ignore operation is being done
   */
  updateLoading: PropTypes.bool,
  /**
   * Indicates that the update operation is disabled (due to other item is being updated)
   */
  updateDisabled: PropTypes.bool,
  /**
   * Indicates if card should be collapsed when status is partially complete
   */
  collapseWhenPartiallyComplete: PropTypes.bool,
  /**
   * Card unique identifier
   */
  checkName: PropTypes.string.isRequired,
  /**
   * Callback to update the check when the user clicks on ignore/unignore
   */
  onUpdateCheck: PropTypes.func.isRequired,
  /**
   * Permission of the feature
   */
  permission: PropTypes.string,
};

const defaultProps = {
  status: COMPLETION_STATUS.INCOMPLETE,
  dismissed: false,
  moreInfoLink: null,
  pageLinks: [],
  details: {},
  renderDetails: () => null,
  loading: false,
  updateLoading: false,
  updateDisabled: false,
  collapseWhenPartiallyComplete: false,
  permission: null,
};

function CheckCard(props) {
  const {
    title,
    description,
    moreInfoLink,
    status,
    dismissed,
    pageLinks,
    onHide,
    details,
    renderDetails,
    loading,
    updateLoading,
    updateDisabled,
    collapseWhenPartiallyComplete,
    checkName,
    onUpdateCheck,
    permission,
  } = props;

  const permissions = useSelector(
    ({ userPermissions }) => userPermissions.data.permissions,
  );

  const [onHover, setOnHover] = useState(false);

  const showIgnoreBtn = onHover || updateLoading;

  const collapse =
    dismissed ||
    status === COMPLETION_STATUS.COMPLETE ||
    (collapseWhenPartiallyComplete &&
      status === COMPLETION_STATUS.PARTIALLY_COMPLETE);

  let hasPermission = true;
  if (permission) {
    hasPermission = permissions?.[permission] === "manage";
  }

  if (loading) {
    return (
      <Card className="d-flex">
        <div className="mr-3">
          <i
            className={clsx(
              "fas fa-check-circle",
              STATUS_STYLES.INCOMPLETE,
              styles.status,
            )}
          />
        </div>
        <div className="w-100 mr-4">
          <Skeleton count={4} />
        </div>
      </Card>
    );
  }

  return (
    <Card
      className="d-flex"
      data-cy={`onboarding-checklist-card-${checkName}`}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <div className="mr-3">
        <i
          data-cy="card-status"
          className={clsx(
            "fas fa-check-circle",
            (dismissed && STATUS_STYLES.INCOMPLETE) || STATUS_STYLES[status],
            styles.status,
          )}
        />
      </div>
      <div className="w-100 mr-4">
        <div
          className={clsx(
            "d-flex align-items-baseline justify-content-between",
            !collapse && "mb-3",
          )}
        >
          {title && (
            <span
              className={clsx(
                "text-strong",
                dismissed && "text-color-secondary",
              )}
              data-cy="card-title"
            >
              <FormattedMessage id={title} />
            </span>
          )}

          {(status === COMPLETION_STATUS.COMPLETE ||
            status === COMPLETION_STATUS.PARTIALLY_COMPLETE) &&
          !dismissed ? (
            <span data-cy="card-details">{renderDetails(details)}</span>
          ) : null}

          {dismissed && showIgnoreBtn && (
            <UpdateCheckButton
              loading={updateLoading}
              disabled={updateDisabled}
              onClick={() => {
                onUpdateCheck({ checkName, dismissed: false });
                setOnHover(false);
              }}
              message="GENERAL.UNIGNORE"
            />
          )}
        </div>
        {!collapse && (
          <>
            <div className="mb-3" data-cy="card-description">
              {description && <FormattedMessage id={description} />}
            </div>

            {pageLinks?.map((link) => {
              const { label, variant = "light", path = "", search } = link;
              // interpolate variables in the path (e.g. /integration/:id) with their match in details object (e.g. { id: 122 })
              const generatedPath = generatePath(path, details);

              return (
                <OverlayTrigger
                  show={!hasPermission ? undefined : false}
                  overlay={
                    <Tooltip className={styles.tooltip}>
                      <FormattedMessage id="GENERAL.NO_PERMISSION" />
                    </Tooltip>
                  }
                >
                  <div key={label?.id} className="mb-3">
                    <Link
                      data-cy="card-link"
                      to={{ pathname: generatedPath, search }}
                      onClick={onHide}
                      disabled={!hasPermission}
                    >
                      <Button
                        disabled={!hasPermission}
                        variant={variant}
                        className="w-100"
                      >
                        {label?.img && (
                          <img
                            src={label.img}
                            alt={label.imgAlt ?? ""}
                            className="mr-2"
                            width="20px"
                            height="20px"
                          />
                        )}
                        {label?.id && <FormattedMessage id={label.id} />}
                        {label?.subtitle && (
                          <span className="text-color-secondary">
                            {" "}
                            (<FormattedMessage id={label.subtitle} />)
                          </span>
                        )}
                      </Button>
                    </Link>
                  </div>
                </OverlayTrigger>
              );
            })}

            <div className="d-flex justify-content-between align-items-center">
              <div className="text-bold" data-cy="card-more-info">
                {moreInfoLink && (
                  <ExternalLink
                    href={moreInfoLink}
                    label={<FormattedMessage id="GENERAL.MORE_INFO" />}
                  />
                )}
              </div>

              <UpdateCheckButton
                loading={updateLoading}
                disabled={updateDisabled}
                onClick={() => {
                  onUpdateCheck({ checkName, dismissed: true });
                  setOnHover(false);
                }}
                message="GENERAL.IGNORE"
                className={clsx(!showIgnoreBtn && "invisible")}
              />
            </div>
          </>
        )}
      </div>
    </Card>
  );
}

CheckCard.propTypes = propTypes;
CheckCard.defaultProps = defaultProps;

export default CheckCard;

// SUPPORT COMPONENTS
function UpdateCheckButton(props) {
  // eslint-disable-next-line react/prop-types
  const { loading, disabled, onClick, message, className } = props;
  return (
    <div>
      {loading && <Spinner size="extraSmall" className="mr-2" />}
      <Button
        variant="link"
        className={clsx("p-0", className)}
        disabled={disabled || loading}
        onClick={onClick}
      >
        <FormattedMessage id={message} />
      </Button>
    </div>
  );
}
