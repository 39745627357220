import React from "react";
import { toAbsoluteUrl } from "../../../_augmentt";
import "../../../_augmentt/_assets/sass/pages/error/error.scss";

export default function ErrorPage() {
  return (
    <div className="grid grid--ver grid--root">
      <div
        className="grid__item grid__item--fluid grid error-v1"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`,
        }}
      >
        <div className="error-v1__container">
          <h1 className="error-v1__number">404</h1>
          <p className="error-v1__desc">OOPS! Something went wrong here</p>
        </div>
      </div>
    </div>
  );
}
