import React from "react";
import { useLocation, matchPath } from "react-router-dom";
import {
  SECURE_POSTURE_PATH,
  SECURE_POSTURE_COMPANY_PATH,
  SECURE_ALERTS_PATH,
  INTEGRATIONS_PATH,
  SECURE_POLICY_TEMPLATES_PATH,
} from "../../app/router/constants";

export function removeCSSClass(ele, cls) {
  const reg = new RegExp(`(\\s|^)${cls}(\\s|$)`);
  ele.className = ele.className.replace(reg, " "); // eslint-disable-line no-param-reassign
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios) {
  axios.interceptors.request.use(
    (config) =>
      /*
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      */

      config,
    (err) => Promise.reject(err),
  );
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.removeItem(key);
    localStorage.removeItem(`${key}_expiresIn`);
  } catch (e) {
    console.error(
      `removeStorage: Error removing key [${key}] from localStorage: ${JSON.stringify(
        e,
      )}`,
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); // epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(`${key}_expiresIn`);
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  }
  try {
    const value = localStorage.getItem(key);
    return value;
  } catch (e) {
    console.error(
      `getStorage: Error reading key [${key}] from localStorage: ${JSON.stringify(
        e,
      )}`,
    );
    return null;
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    // eslint-disable-next-line no-param-reassign
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); // millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(`${key}_expiresIn`, schedule);
  } catch (e) {
    console.error(
      `setStorage: Error setting key [${key}] in localStorage: ${JSON.stringify(
        e,
      )}`,
    );
    return false;
  }
  return true;
}

export const sortingCarets = (order) => {
  if (!order) {
    return (
      <span className="carets">
        <span className="icon-md fa fa-arrow-down font-color-1" />
        &nbsp;
        <span className="icon-md fa fa-arrow-up font-color-1" />
      </span>
    );
  }
  if (order === "asc") {
    return (
      <span className="carets">
        <span className="icon-md fa fa-arrow-down" />
        &nbsp;
        <span className="icon-md fa fa-arrow-up font-color-primary" />
      </span>
    );
  }
  if (order === "desc") {
    return (
      <span className="carets">
        <span className="icon-md fa fa-arrow-down font-color-primary" />
        &nbsp;
        <span className="icon-md fa fa-arrow-up" />
      </span>
    );
  }
  return null;
};

// Check if string is null or whitespace
export const isNullOrWhiteSpace = (str) =>
  !str || str.length === 0 || /^\s*$/.test(str);

/**
 * waits before performing next action.
 * Has to be called with await, e.g. await sleep(2000); // stops execution for 2 seconds
 * @param {number} ms Number of miliseconds to wait
 */
export const delay = async (ms) =>
  // eslint-disable-next-line no-return-await, no-promise-executor-return
  await new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Map of page path to permission name
 */
export const PAGE_TO_PERMISSION = {
  "/billing": "billing",
  "/companies": "companies",
  "/discovery": "discover",
  "/discover/data": "discover",
  "/discover/risk": "discover",
  "/discover/applications": "discover",
  "/engage": "engage",
  "/integrations": "integrations",
  "/scheduled-reports": "discover",
  "/licenses": "licenseCenter",
  "/look-and-feel": "lookAndFeel",
  "/secure": "secure",
  "/secure/alerts": "secure",
  "/secure/security-posture": "secure",
  "/secure/mfa": "secure",
  // "/license-report": "secure", // NOTE not locked under permissions, sold separately
  "/secure/threats": "secure",
  "/secure/summary": "secure",
  "/users": "systemUsers",
};

export const INITIAL_MENU_PERMISSION = {
  secure: true,
  "secure/alerts": true,
  "secure/security-posture": true,
  "secure/mfa": true,
  "licensing-report": true,
  "secure/threats": true,
  "secure/summary": true,
  discovery: true,
  "scheduled-reports": true,
  "discover/data": true,
  "discover/risk": true,
  "discover/applications": true,
  optimize: false,
  engage: true,
  backup: true,
  configuration: true,
  companies: true,
  security_training: true,
  "open-ticket": true,
  chat: true,
  marketing: true,
  employees: true,
  integrations: true,
  licenses: true,
  users: true,
  billing: true,
  support: true,
  "user-profile": false,
  notifications: false,
  "look-and-feel": true,
};

export const ROUTES_PRIORITY_QUEUE = [
  "/secure/security-posture",
  "/discovery",
  "/engage",
];

export const displayPage = (page, permissions, options = {}) => {
  const { customers } = options;

  // Employees : Set to Manage if technician has manage access to Discover, Engage, Secure or License Center, otherwise Disabled. remove
  if (page === "/employees") {
    return (
      permissions.discover === "manage" ||
      permissions.secure === "manage" ||
      permissions.engage === "manage" ||
      permissions.licenseCenter === "manage"
    );
  }

  // special case // Agent: Set to Manage if technician has >>>Manage access to Discover, otherwise disabled
  if (page === "/discover/data" && permissions.discover === "readOnly") {
    return false;
  }
  // SaaS Apps:  App Catalogue: Disabled if technician has Discover Disabled/Read only, otherwise is set to Manage.
  if (
    page === "/discover/applications" &&
    permissions.discover === "readOnly"
  ) {
    return false;
  }

  // check access to integrations mapping page
  const mappingPages = [
    "/integrations/:integrationId/connectwise",
    "/integrations/:integrationId/autotask",
    "/integrations/:integrationId/microsoft-csp",
  ];
  if (customers?.length && mappingPages.includes(page)) {
    const hasPermission =
      permissions[PAGE_TO_PERMISSION["/integrations"]] !== "disabled";
    const hasParentCompany = customers.some((customer) => !customer.parent_id);
    return hasPermission && hasParentCompany;
  }

  return permissions[PAGE_TO_PERMISSION[page]] !== "disabled";
};

export const getLandingPagePath = (userPermissions = {}) => {
  let landingPagePath = "";
  // eslint-disable-next-line no-restricted-syntax
  for (const route of ROUTES_PRIORITY_QUEUE) {
    if (userPermissions[PAGE_TO_PERMISSION[route]] !== "disabled") {
      landingPagePath = route;
      return landingPagePath;
    }
  }

  return "/welcome";
};

export const resetDefaultPermission = (menuConfig) => {
  menuConfig.aside.items.forEach((menuItem) => {
    menuItem.visible = INITIAL_MENU_PERMISSION[menuItem.page]; // eslint-disable-line no-param-reassign
    if (menuItem.submenu) {
      menuItem.submenu.forEach((submenuItem) => {
        submenuItem.visible = INITIAL_MENU_PERMISSION[submenuItem.page]; // eslint-disable-line no-param-reassign
      });
    }
  });
};

/**
 * Paths used to display <IntegrationRefreshButton /> and <CustomerMappingAlert />
 */
export const syncMappedPaths = [
  /secure/,
  /engage/,
  /employees/,
  /integrations/,
  /licensing-report/,
];
export const isSyncPath = () =>
  syncMappedPaths.some((path) => window.location.pathname.match(path));

// Add const here for path bases to compare later
// customer routes have to include 'companySelectorId' param
export const CUSTOMER_ROUTES = [
  SECURE_POSTURE_PATH,
  SECURE_POSTURE_COMPANY_PATH,
  SECURE_ALERTS_PATH,
  INTEGRATIONS_PATH,
  SECURE_POLICY_TEMPLATES_PATH,
];

export const useCustomerRoutePath = () => {
  const { pathname } = useLocation();

  return CUSTOMER_ROUTES.find((path) =>
    matchPath(pathname, {
      path,
      exact: true,
    }),
  );
};
