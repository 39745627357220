const actionTypes = {
  SetReportError: "reports/SET_REPORT_ERROR",
};

const initialState = {
  error: {
    status: false,
    type: null,
    data: null,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetReportError:
      return {
        ...state,
        error: {
          ...state.error,
          status: action.payload.reports.status,
          type: action.payload.reports.type,
          data: action.payload.reports.data,
        },
      };
    default:
      return state;
  }
};

export const actions = {
  setReportError: (reports) => ({
    type: actionTypes.SetReportError,
    payload: { reports },
  }),
};
