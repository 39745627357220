/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Form, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Amplify, { Auth } from "aws-amplify";
import awsConfig from "./aws-exports";
import {
  getEmailSchema,
  getNewPasswordSchema,
  getConfirmPasswordSchema,
} from "../../util/auth.util";
import { getCognitoUserStatus, resendSignupEmail } from "../../crud/users.crud";

function ForgotPassword(props) {
  const { intl, history, location } = props;

  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [status, setStatus] = useState();
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });

  Amplify.configure(awsConfig);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id");

    /* eslint-disable-next-line  */
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (id && id.match(mailformat)) {
      const values = {
        email: id,
        code: "",
        newPassword: "",
        confirmPassword: "",
      };
      handleSubmit(values); // eslint-disable-line no-use-before-define
    } else {
      history.push("/auth/forgot-password");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const schema1 = Yup.object({
    email: getEmailSchema(intl),
  });

  const schema2 = Yup.object({
    code: Yup.string()
      .min(
        6,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_SHORT",
        }),
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
    newPassword: getNewPasswordSchema(intl),
    confirmPassword: getConfirmPasswordSchema(intl, "newPassword"),
  });

  const initialValues = {
    email: "",
    code: "",
    newPassword: "",
    confirmPassword: "",
  };

  async function handleSubmit(values) {
    enableLoading();
    setEmail(values.email); // Saving email to state to get around form reset
    try {
      // Fetch cognito user and check status
      const cognitoUserStatusResponse = await getCognitoUserStatus(
        values.email,
      );
      // Call Amplify method based on users status.
      if (
        cognitoUserStatusResponse?.data?.userStatus === "FORCE_CHANGE_PASSWORD"
      ) {
        // Resend signup email
        await resendSignupEmail(values.email);
      } else {
        await Auth.forgotPassword(values.email);
        // Show Complete Password Reset page.
        setConfirming(true);
      }

      setStatus(
        intl.formatMessage({
          id: "AUTH.FORGOT.EMAILSENT",
        }),
      );
    } catch (e) {
      console.error(e);
      if (e.code === "LimitExceededException") {
        setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.ATTEMPT_LIMIT_EXCEEDED",
          }),
        );
      } else if (e.code === "UserNotFoundException") {
        setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.USER_NOT_FOUND",
          }),
        );
      } else if (e.code === "NotAuthorizedException") {
        setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.NOT_AUTHORIZED",
          }),
        );
      } else {
        setStatus(
          <>
            <FormattedMessage id="GENERAL.ERROR" />
            <a
              href="https://support.augmentt.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="GENERAL.CONTACT_SUPPORT" />
            </a>
          </>,
        );
      }
    }
    disableLoading();
  }

  async function handleConfirmSubmit(values) {
    enableLoading();
    try {
      await Auth.forgotPasswordSubmit(email, values.code, values.newPassword);
      history.push("/auth/login");
    } catch (e) {
      console.error(e);
      setStatus(
        intl.formatMessage({
          id: "AUTH.VALIDATION.INVALID_LOGIN",
        }),
      );
    }
    disableLoading();
  }

  return (
    <div className="login__body">
      {!confirming ? (
        <div className="login__form">
          <div className="login__title">
            <h3>
              <FormattedMessage id="AUTH.FORGOT.TITLE" />
            </h3>
          </div>

          <Formik
            validationSchema={schema1}
            initialValues={initialValues}
            initialErrors={schema1.isValidSync(initialValues)}
            onSubmit={async (values, { resetForm }) => {
              await handleSubmit(values);
              resetForm();
            }}
          >
            {({
              handleSubmit, // eslint-disable-line no-shadow
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
            }) => (
              <Form>
                <Col md="12">
                  {status ? (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  ) : (
                    <div role="alert" className="alert alert-info">
                      <div className="alert-text">
                        <FormattedMessage id="AUTH.FORGOT.DESC" />
                      </div>
                    </div>
                  )}
                </Col>
                <Form.Group as={Col} md="12" controlId="email">
                  <Form.Label className="inline">
                    <FormattedMessage
                      id="FORM.EMAIL"
                      defaultMessage="FORM.EMAIL"
                    />
                  </Form.Label>
                  <Form.Control
                    className="inline"
                    type="text"
                    placeholder={intl.formatMessage({
                      id: "PLACEHOLDER.ENTER_EMAIL",
                    })}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.email && !!errors.email}
                    isValid={touched.email && !errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="login__actions">
                  <Link to="/auth">
                    <Button
                      id="backBtn"
                      variant="secondary"
                      className="btn-elevate login__btn-secondary"
                    >
                      <FormattedMessage id="GENERAL.BACK" />
                    </Button>
                  </Link>

                  <Button
                    id="submitBtn"
                    onClick={handleSubmit} // eslint-disable-line react/jsx-no-bind
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleSubmit(values);
                      }
                    }}
                    variant="primary"
                    className={`btn-elevate login__btn-primary ${clsx({
                      "spinner spinner--right spinner--md spinner--light":
                        loading,
                    })}`}
                    style={loadingButtonStyle}
                    disabled={errors.email}
                  >
                    <FormattedMessage id="GENERAL.SUBMIT" />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        // Confirm password screen
        <div className="login__form">
          <div className="login__title">
            <h3>
              <FormattedMessage id="AUTH.FORGOT.CONFIRMTITLE" />
            </h3>
          </div>

          <Formik
            validationSchema={schema2}
            initialValues={initialValues}
            initialErrors={schema2.isValidSync(initialValues)}
            onSubmit={(values) => handleConfirmSubmit(values)}
          >
            {({
              handleSubmit, // eslint-disable-line no-shadow
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
            }) => (
              <Form>
                <Col md="12">
                  {status && errors ? (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  ) : (
                    <div role="alert" className="alert alert-success">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}
                </Col>
                <Form.Group as={Col} md="12" controlId="code">
                  <Form.Label className="inline">
                    <FormattedMessage
                      id="AUTH.INPUT.CONFIRM_CODE"
                      defaultMessage="AUTH.INPUT.CONFIRM_CODE"
                    />
                  </Form.Label>
                  <Form.Control
                    className="inline"
                    type="text"
                    placeholder={intl.formatMessage({
                      id: "AUTH.CONFIRMUSER.TITLE",
                    })}
                    maxLength={6}
                    value={values.code || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.code && !!errors.code}
                    isValid={touched.code && !errors.code}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.code}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="newPassword">
                  <Form.Label className="inline">
                    <FormattedMessage id="AUTH.INPUT.NEW_PASSWORD" />
                  </Form.Label>
                  <Form.Control
                    className="inline"
                    type="password"
                    placeholder={intl.formatMessage({
                      id: "PLACEHOLDER.ENTER_NEW_PASSWORD",
                    })}
                    value={values.newPassword || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.newPassword && !!errors.newPassword}
                    isValid={touched.newPassword && !errors.newPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.newPassword || errors.code}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="confirmPassword">
                  <Form.Label className="inline">
                    <FormattedMessage id="AUTH.INPUT.CONFIRM_PASSWORD" />
                  </Form.Label>
                  <Form.Control
                    className="inline"
                    type="password"
                    placeholder={intl.formatMessage({
                      id: "PLACEHOLDER.REENTER_PASSWORD",
                    })}
                    value={values.confirmPassword || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleConfirmSubmit(values);
                      }
                    }}
                    isInvalid={
                      touched.confirmPassword && !!errors.confirmPassword
                    }
                    isValid={touched.confirmPassword && !errors.confirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword || errors.code}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="login__actions">
                  <Link to="/auth">
                    <Button
                      id="backBtn"
                      variant="secondary"
                      className="btn-elevate login__btn-secondary"
                    >
                      <FormattedMessage id="GENERAL.BACK" />
                    </Button>
                  </Link>

                  <Button
                    id="submitBtn"
                    onClick={handleSubmit} // eslint-disable-line react/jsx-no-bind
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleConfirmSubmit(values);
                      }
                    }}
                    variant="primary"
                    className={`btn-elevate login__btn-primary ${clsx({
                      "spinner spinner--right spinner--md spinner--light":
                        loading,
                    })}`}
                    style={loadingButtonStyle}
                    disabled={
                      !!(
                        errors.code ||
                        !!errors.newPassword ||
                        !!errors.confirmPassword ||
                        (touched.newPassword &&
                          values.newPassword !== values.confirmPassword) ||
                        (touched.confirmPassword && !!errors.confirmPassword)
                      ) ||
                      (!touched.code &&
                        !touched.newPassword &&
                        !touched.confirmPassword)
                    }
                  >
                    <FormattedMessage id="GENERAL.SUBMIT" />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default injectIntl(connect()(ForgotPassword));
