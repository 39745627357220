import { useEffect } from "react";
import { AwsRum } from "aws-rum-web";

const HOST_CONFIG = {
  "app.develop.augmentt.com": {
    config: {
      sessionSampleRate: 1,
      guestRoleArn:
        "arn:aws:iam::066955235001:role/RUM-Monitor-us-east-1-066955235001-3127276411861-Unauth",
      identityPoolId: "us-east-1:82f04336-f468-4840-b85b-17f46062b93b",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: false,
    },
    APPLICATION_ID: "cfbca3ac-a95f-4610-a405-7a908486687c",
    APPLICATION_VERSION: "1.0.0",
    APPLICATION_REGION: "us-east-1",
  },
  "app.staging.augmentt.com": {
    config: {
      sessionSampleRate: 1,
      guestRoleArn:
        "arn:aws:iam::066955235001:role/RUM-Monitor-us-east-1-066955235001-2047876411861-Unauth",
      identityPoolId: "us-east-1:97891f83-7b49-466e-b1f2-8d53bbd1c263",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: false,
    },
    APPLICATION_ID: "67313ea0-2bb3-4623-9e2f-ae6d5d055138",
    APPLICATION_VERSION: "1.0.0",
    APPLICATION_REGION: "us-east-1",
  },
  "app.test.augmentt.com": {
    config: {
      sessionSampleRate: 1,
      guestRoleArn:
        "arn:aws:iam::066955235001:role/RUM-Monitor-us-east-1-066955235001-1056286411861-Unauth",
      identityPoolId: "us-east-1:57994573-fb42-4831-afb7-89a93e0ad352",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: false,
    },
    APPLICATION_ID: "0596b1a1-9073-4a0e-9fdf-5371f15b0d11",
    APPLICATION_VERSION: "1.0.0",
    APPLICATION_REGION: "us-east-1",
  },
  "app.augmentt.com": {
    config: {
      sessionSampleRate: 1,
      guestRoleArn:
        "arn:aws:iam::066955235001:role/RUM-Monitor-us-east-1-066955235001-7642666411861-Unauth",
      identityPoolId: "us-east-1:cf73c83a-8439-4add-b84a-9d3b4ca94ec9",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: false,
    },
    APPLICATION_ID: "9039f4b2-7365-4d84-9638-9fb8491254bd",
    APPLICATION_VERSION: "1.0.0",
    APPLICATION_REGION: "us-east-1",
  },
};

const initializeRUM = ({
  APPLICATION_ID,
  APPLICATION_VERSION,
  APPLICATION_REGION,
  config,
}) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
};

const useAwsRum = () => {
  useEffect(() => {
    const hostname = window?.location?.hostname;
    const config = HOST_CONFIG[hostname];
    if (config) {
      initializeRUM(config);
    } else if (hostname !== "localhost") {
      console.error("AWS RUM: no snippet found for the domain", hostname);
    }
  }, []);
};

export default useAwsRum;
