/* eslint-disable react/prefer-stateless-function, react/prop-types */
import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem"; // eslint-disable-line import/no-cycle

export default class MenuSubmenu extends React.Component {
  render() {
    const { item, currentUrl, layoutConfig, currentUser, organization } =
      this.props;

    return (
      <ul className="menu__subnav">
        {item.submenu.map((child, index) => {
          // Check if the menu item is for admins only
          if (child.adminOnly) {
            // Check if the current user is an admin
            const isAdmin = currentUser && currentUser.chargebee_id;
            // Not an admin?
            if (!isAdmin) {
              return <></>; // eslint-disable-line react/jsx-no-useless-fragment
            }
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {child.section && (
                <MenuSection
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              )}

              {child.separator && (
                <MenuItemSeparator
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              )}

              {child.title && (
                <MenuItem
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                  layoutConfig={layoutConfig}
                  currentUser={currentUser}
                  organization={organization}
                />
              )}
            </React.Fragment>
          );
        })}
      </ul>
    );
  }
}
