import axios from "axios";
import moment from "moment";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;

const customerGroupsUrl = (customerId) =>
  `${ROOT}/customer/${customerId}/groups`;
const integrationUrlByCustomerId = (customerId) =>
  `${ROOT}/customer/${customerId}/integration`;
const integrationUrlById = (integrationId) =>
  `${ROOT}/integration/${integrationId}`;
const integrationCustomer = (customerId, appName) =>
  `${ROOT}/integration/${appName}/customer/${customerId}`;
const integrationTaskUrl = (customerId) =>
  `${ROOT}/integration/${customerId}/task`;
const integrationEmployeesUrl = () => `${ROOT}/integration/employees`;
const integratedEmployeesMetaUrl = (customerId, integrationType) =>
  `${ROOT}/integration/${customerId}/integrationEmployees/${integrationType}/metaData`;
const licensesUrl = (appName) => `${ROOT}/integration/app/${appName}/licences`;
const permissionsUrl = (appName) =>
  `${ROOT}/integration/app/${appName}/permissions`;
const removeUserUrl = (appName) =>
  `${ROOT}/integration/app/${appName}/removeuserinfo`;
const toolIntuitUrl = () => `${ROOT}/tools/intuit`;
const toolM365Url = () => `${ROOT}/tools/m365`;
const duoIntegrationUrl = () => `${ROOT}/tools/duo-security`;
const autotaskIntegrationUrl = () => `${ROOT}/tools/autotask`;
const connectWiseIntegrationUrl = () => `${ROOT}/tools/connectwise`;
const syncroIntegrationUrl = () => `${ROOT}/tools/syncro`;
const haloIntegrationUrl = () => `${ROOT}/tools/halo`;
const workerIntegrationUrl = () => `${ROOT}/worker/integration`;
const toolGWSUrl = () => `${ROOT}/tools/google-workspace`;
const autoEnableForAllCompaniesConfig = () =>
  `${ROOT}/aware/autoEnableForAllCompaniesConfig`;
const toolM365CSPUrl = () => `${ROOT}/tools/m365csp`;

export const INTEGRATION_TASK_STATUS = {
  COMPLETED: "Completed",
  FAILED: "Failed",
};

// GET /customer/:customerId/integration
export const getIntegrations = (customerId) =>
  axios.get(
    `${integrationUrlByCustomerId(customerId)}?timestamp=${moment().format(
      "D-M-YYYY",
    )}`,
  ); // timestamp added to make this request different in every call for the browser

// POST /customer/:customerId/integration
export const createIntegration = (
  customerId,
  integrationApplicationId,
  otherProperties,
) => {
  if (!otherProperties) {
    // eslint-disable-next-line no-param-reassign
    otherProperties = {}; // empty
  }

  const payload = {
    ...otherProperties,
    integrationApplicationId,
  };

  return axios.post(integrationUrlByCustomerId(customerId), payload);
};

export const loadCustomerGroups = (customerId, integrationApplicationId) => {
  const payload = {
    integrationApplicationId,
  };
  return axios.post(customerGroupsUrl(customerId), payload);
};

// PUT /integration/integrationId
export const updateIntegration = (
  integrationId,
  integrationAppId,
  customerId,
  data,
  extendedCredentials,
  isActive,
) =>
  axios.put(integrationUrlById(integrationId), {
    customerId,
    integrationApplicationId: integrationAppId,
    metaData: data,
    extendedCredentials,
    isActive,
  });

// GET /integration/integrationId and /integration/integrationId?includeClientId=true
export const getIntegration = (integrationId, params = {}) => {
  const { includeClientId } = params;

  return axios.get(integrationUrlById(integrationId), {
    params: {
      includeClientId,
    },
  });
};

// Automatically enable threat alerts
// POST aware/autoEnableForAllCompaniesConfig
export const enableAwareForAllCompanies = (
  customerId,
  integrationApplicationId,
  otherProperties = {},
) => {
  const payload = {
    ...otherProperties,
    appId: integrationApplicationId,
    customerId,
  };
  return axios.post(autoEnableForAllCompaniesConfig, payload);
};

// Flexible function for passing whatever properties you want.
// otherProperties
/*
  metaData,
  lastTransactionEndDate,
  isActive,
  syncStatus,
  lastSynchUTCDate,
  extendedCredentials,
  extendedStatus,
  otherProperties: { manageMfa, requestedBy }
*/
export const updateIntegrationV2 = (
  integrationId,
  integrationApplicationId,
  customerId,
  otherProperties,
) => {
  const payload = { ...otherProperties, customerId, integrationApplicationId };
  return axios.put(integrationUrlById(integrationId), payload);
};

// DELETE /integration/integrationId
export const deleteIntegration = (integrationId) =>
  axios.delete(integrationUrlById(integrationId));

// GET /integration/:customerId/task
export const getTask = (customerId, params) =>
  axios.get(integrationTaskUrl(customerId), {
    params, // taskId or taskShortName
  });

// GET /integration/app/:appName/licences
export const getLicenses = (appName, customerId, employeeId) =>
  axios.get(licensesUrl(appName), {
    params: {
      customerId,
      employeeId,
    },
  });

// GET /integration/app/:appName/permissions
export const getPermissions = (appName, customerId) =>
  axios.post(permissionsUrl(appName), {
    customerId,
  });

// GET /integration/app/:appName/removeuserinfo
export const getRemoveUserInfo = (appName, customerId, employeeId) =>
  axios.get(removeUserUrl(appName), {
    params: {
      customerId,
      employeeId,
    },
  });

// DELETE /integration/app/:appName/removeuserinfo
export const deleteEmployeeIntegrationInfo = (
  appName,
  customerId,
  employeeId,
) =>
  axios.delete(removeUserUrl(appName), {
    data: {
      customerId,
      employeeId,
    },
  });

// POST /integration/employees
export const getIntegrationEmployees = (
  customerId,
  integrationApplicationId,
  requiredModules,
) =>
  axios.post(integrationEmployeesUrl(customerId), {
    integrationName: integrationApplicationId,
    customerId,
    requiredModules,
  });

export function getIntegratedEmployeesMetaData(customerId, integrationType) {
  return axios.get(integratedEmployeesMetaUrl(customerId, integrationType));
}

export const createMgmTask = (args) => {
  const { customerId, userId, integrationType, mgmTaskId, parameters } = args;

  // eslint-disable-next-line no-use-before-define
  return createTask(customerId, integrationType, mgmTaskId, userId, parameters);
};

// POST /integration/:customerId/task
export const createTask = (
  customerId,
  integrationApplicationId,
  taskId,
  userId,
  parameters,
) =>
  axios.post(integrationTaskUrl(customerId), {
    integrationType: integrationApplicationId,
    mgmtaskid: taskId,
    parameters,
    scheduletype: "now",
    schedule: "",
    requestedby: userId,
  });

// GET /integration/:appName/customer/:customerId
export const getIntegrationCustomerInformation = (customerId, appName) =>
  axios.get(integrationCustomer(customerId, appName));

// POST /tools/intuit { action: 'get-auth-uri' }
export const getIntuitAuthUri = () => {
  const data = { action: "get-auth-uri" };
  return axios.post(toolIntuitUrl(), data);
};

// POST /tools/intuit { action: 'create-token-userinfo' }
export const createIntuitTokenUserInfo = (
  callbackUri,
  customerId,
  integrationApplicationId,
) =>
  axios.post(toolIntuitUrl(), {
    action: "create-token-userinfo",
    uri: callbackUri,
    customerId,
    integrationApplicationId,
  });

// POST /tools/duo-security
export const connectDUO = (
  accountsApi,
  adminApi,
  customerId,
  integrationApplicationId,
  requestedBy,
) => {
  const data = {
    action: "credentials",
    customerId,
    integrationApplicationId,
    requestedBy,
    accountsApi,
    adminApi,
  };

  return axios.post(duoIntegrationUrl(), data);
};

// POST /tools/autotask
export const connectAutotask = ({
  customerId,
  integrationApplicationId,
  organizationId,
  requestedBy,
  credentials,
}) => {
  const data = {
    customerId,
    integrationApplicationId,
    organizationId,
    requestedBy,
    credentials,
  };

  return axios.post(autotaskIntegrationUrl(), data);
};

// POST /tools/connectWise
export const connectConnectWise = ({
  customerId,
  integrationApplicationId,
  organizationId,
  requestedBy,
  credentials,
}) => {
  const data = {
    customerId,
    integrationApplicationId,
    organizationId,
    requestedBy,
    credentials,
  };

  return axios.post(connectWiseIntegrationUrl(), data);
};
// POST /tools/syncro
export const connectSyncro = ({
  customerId,
  integrationApplicationId,
  organizationId,
  requestedBy,
  credentials,
}) => {
  const data = {
    customerId,
    integrationApplicationId,
    organizationId,
    requestedBy,
    credentials,
  };
  return axios.post(syncroIntegrationUrl(), data);
};

// POST /tools/Halo
export const connectHalo = ({
  customerId,
  integrationApplicationId,
  organizationId,
  requestedBy,
  credentials,
}) => {
  const data = {
    customerId,
    integrationApplicationId,
    organizationId,
    requestedBy,
    credentials,
  };
  return axios.post(haloIntegrationUrl(), data);
};

// POST /tools/m365 { action: 'get-auth-code-url' }
export const getM365AuthCodeUrl = (customerId) => {
  const data = { action: "get-auth-code-url", customerId };
  return axios.post(toolM365Url(), data);
};

// POST /tools/m365 { action: 'get-app-tenant-code-url' }
export const getM365AppTenantCodeUrl = (customerId) => {
  const data = {
    action: "get-app-tenant-code-url",
    customerId,
  };
  return axios.post(toolM365Url(), data);
};

// POST /tools/m365 { action: 'get-manage-office-auth-code-url' }
export const getM365ManageOfficeAuthCodeUrl = (customerId) => {
  const data = { action: "get-manage-office-auth-code-url", customerId };
  return axios.post(toolM365Url(), data);
};

// POST /tools/m365 { action: 'acquire-app-tenant-token-by-code' }
export const acquireM365AppTenantTokenByCode = (
  code,
  customerId,
  integrationApplicationId,
  userId,
) => {
  const data = {
    action: "acquire-app-tenant-token-by-code",
    code,
    customerId,
    integrationApplicationId,
    requestedBy: userId,
  };
  return axios.post(toolM365Url(), data);
};

export const triggerDataCollection = (customerId, requestedBy) => {
  const data = {
    action: "trigger-data-collection",
    customerId,
    requestedBy,
  };
  return axios.post(toolM365Url(), data);
};

// POST /tools/intuit { action: 'acquire-token-by-code' }
export const acquireM365TokenByCode = (
  code,
  customerId,
  integrationApplicationId,
  userId,
) => {
  const data = {
    action: "acquire-token-by-code",
    code,
    customerId,
    integrationApplicationId,
    requestedBy: userId,
  };
  return axios.post(toolM365Url(), data);
};

// POST /tools/intuit { action: 'acquire-manage-office-token-by-code' }
export const acquireManageOfficeTokenByCode = (
  code,
  customerId,
  integrationApplicationId,
  userId,
) => {
  const data = {
    action: "acquire-manage-office-token-by-code",
    code,
    customerId,
    integrationApplicationId,
    requestedBy: userId,
  };
  return axios.post(toolM365Url(), data);
};

// POST /tools/m365 { action: 'acquire-exo-token-by-code' }
export const acquireM365ExoTokenByCode = (
  code,
  tenant,
  clientId,
  customerId,
  integrationApplicationId,
  userId,
) => {
  const data = {
    action: "acquire-exo-token-by-code",
    code,
    tenantId: tenant,
    customerId,
    integrationApplicationId,
    requestedBy: userId,
    clientId,
  };
  return axios.post(toolM365Url(), data);
};

// POST /worker/integration { action: 'get-auth-uri' }
export const runIntegrationWorker = (integrationId) => {
  const data = { integration: integrationId };
  return axios.post(workerIntegrationUrl(), data);
};

// POST /tools/google-workspace { action: 'get-auth-code-url' }
export const getGWSAuthCodeUrl = () => {
  const data = { action: "get-auth-code-url" };
  return axios.post(toolGWSUrl(), data);
};

// POST /tools/google-workspace { action: 'acquire-token-by-code' }
export const acquireGWSTokenByCode = (
  code,
  customerId,
  integrationApplicationId,
  userId,
) => {
  const data = {
    action: "acquire-token-by-code",
    code,
    customerId,
    integrationApplicationId,
    requestedBy: userId,
  };
  return axios.post(toolGWSUrl(), data);
};

// Constants for integrations to use (for integrationApplication.application_name)
export const OFFICE365 = "Office365";
export const GWS = "GWS";

// CSP: POST /tools/m365csp { action: 'csp-get-auth-code-url' }
export const getM365CspAuthCodeUrl = (customerId) => {
  const data = { action: "csp-get-auth-code-url", customerId };
  return axios.post(toolM365CSPUrl(), data);
};

// CSP: POST /tools/m365csp { action: 'csp-acquire-token-by-code' }
export const acquireM365CSPTokenByCode = (
  code,
  customerId,
  integrationApplicationId,
  userId,
) => {
  const data = {
    action: "csp-acquire-token-by-code",
    code,
    customerId,
    integrationApplicationId,
    requestedBy: userId,
  };
  return axios.post(toolM365CSPUrl(), data);
};

// CSP: POST /tools/m365csp { action: 'csp-get-admin-consent-url' }
export const getAdminConsentUrlM365CSP = (
  customerId,
  integrationApplicationId,
  userId,
) => {
  const data = {
    action: "csp-get-admin-consent-url",
    customerId,
    integrationApplicationId,
    requestedBy: userId,
  };
  return axios.post(toolM365CSPUrl(), data);
};

// CSP: POST /tools/m365csp { action: 'csp-admin-consent' }
export const adminConsentM365CSP = (
  adminConsent,
  error,
  errorDescription,
  customerId,
  integrationApplicationId,
) => {
  const data = {
    action: "csp-admin-consent",
    adminConsent,
    errorDescription:
      error && errorDescription ? `${error} : ${errorDescription}` : null,
    customerId,
    integrationApplicationId,
  };
  return axios.post(toolM365CSPUrl(), data);
};

// CSP: POST /tools/m365csp { action: 'csp-admin-consent-exo' }
export const adminConsentExOM365CSP = (
  adminConsent,
  error,
  errorDescription,
  customerId,
  integrationApplicationId,
) => {
  const data = {
    action: "csp-exo-admin-consent",
    adminConsent,
    errorDescription:
      error && errorDescription ? `${error} : ${errorDescription}` : null,
    customerId,
    integrationApplicationId,
  };
  return axios.post(toolM365CSPUrl(), data);
};

// CSP: POST /tools/m365csp { action: 'csp-acquire-hybrid-token-by-code' }
export const acquireM365CSPHybridTokenByCode = (
  code,
  customerId,
  integrationApplicationId,
  userId,
) => {
  const data = {
    action: "csp-acquire-hybrid-token-by-code",
    code,
    customerId,
    integrationApplicationId,
    requestedBy: userId,
  };
  return axios.post(toolM365CSPUrl(), data);
};

/**
 * Continuously polls the backend for the current state of an Integration Task
 *
 * @param {String} customerId The id of the customer
 * @param {String} taskId The unique id of the Task
 * @param {object} params Optional values to apply to polling. Defaults empty object if none is provided.
 * @param {?number} params.retries The number of attempts to continue making requests for task status if it isn't completed. Default 10 attempts.
 * @param {?number} params.timeout The amount of time in milliseconds to wait between making subsequent requests. Default 2000 ms
 * @returns {object} An object containing the final values of the Task when the promise is resolved or rejected
 */
export const pollIntegrationTaskStatus = async (
  customerId,
  taskId,
  params = {},
) => {
  const maxRetry = params?.retries || 10;
  const timeout = params?.timeout || 2000;
  let attempts = 0;
  let stop = false;
  let timeoutId;

  const cancel = () => {
    stop = true;
    clearInterval(timeoutId);
  };

  // eslint-disable-next-line consistent-return
  const poll = async (resolve, reject) => {
    let result;
    try {
      result = await getTask(customerId, { taskId });
    } catch (err) {
      return reject(new Error("Failed to retrieve task"));
    }

    attempts += 1;

    if (result?.data?.Status === INTEGRATION_TASK_STATUS.COMPLETED) {
      return resolve(result);
    }
    if (result?.data?.Status === INTEGRATION_TASK_STATUS.FAILED) {
      return reject(new Error(result?.data?.StatusMessage));
    }
    if (attempts === maxRetry) {
      return reject(new Error("Exceeded max attempts"));
    }
    if (stop) {
      return resolve(null);
    }
    timeoutId = setTimeout(poll, timeout, resolve, reject);
  };

  return { promise: new Promise(poll), cancel };
};
