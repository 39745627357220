import React from "react";
import { FormattedMessage } from "react-intl";
import {
  INTEGRATION_APPS,
  INTEGRATION_TYPES,
} from "../../../pages/home/utils/integrations";
import { CHECKS_NAMES } from "./constants";

const configurations = {
  [CHECKS_NAMES.INTEGRATE_TO_MICROSOFT]: {
    title: "ONBOARDING_CHECKLIST.INTEGRATE_TO_MICROSOFT",
    description: "ONBOARDING_CHECKLIST.INTEGRATE_TO_MICROSOFT_DESCRIPTION",
    moreInfoLinkId: "onboardingChecklist_integrateToMicrosoft",
    permission: "integrations",
    pageLinks: [
      {
        label: { id: "ONBOARDING_CHECKLIST.CSP_INTEGRATION" },
        path: "/integrations/:parentCustomerId?", // parentCustomerId has to be provided in the 'details' object to be interpolated with the path
        search: new URLSearchParams({
          applicationNameQ: INTEGRATION_APPS.OFFICE_365,
          integrationTypeQ: INTEGRATION_TYPES.CSP,
        }).toString(),
        variant: "primary",
      },
      {
        label: {
          id: "ONBOARDING_CHECKLIST.DIRECT_INTEGRATION",
          subtitle: "ONBOARDING_CHECKLIST.PER_CUSTOMER",
        },
        path: "/integrations/:parentCustomerId?", // parentCustomerId has to be provided in the 'details' object to be interpolated with the path
        search: new URLSearchParams({
          applicationNameQ: INTEGRATION_APPS.OFFICE_365,
          integrationTypeQ: INTEGRATION_TYPES.ONE_TO_ONE,
        }).toString(),
      },
    ],
    collapseWhenPartiallyComplete: true,
    renderDetails: (details = {}) => {
      const { isCSP, numCustomers, numDirectIntegrations } = details;

      return isCSP ? (
        <FormattedMessage id="INTEGRATION.CSP" />
      ) : (
        <span>
          <FormattedMessage id="ONBOARDING_CHECKLIST.DIRECT" />{" "}
          {numDirectIntegrations}/{numCustomers}
        </span>
      );
    },
  },
  [CHECKS_NAMES.MAP_CSP_TENANTS]: {
    title: "ONBOARDING_CHECKLIST.MAP_CSP_TENANTS",
    description: "ONBOARDING_CHECKLIST.MAP_CSP_TENANTS_DESCRIPTION",
    moreInfoLinkId: "onboardingChecklist_mapCspTenants",
    permission: "integrations",
    pageLinks: [
      {
        label: { id: "ONBOARDING_CHECKLIST.CSP_SETUP_AND_MAPPINGS" },
        path: "/integrations/:cspIntegrationId/microsoft-csp", // cspIntegrationId has to be provided in the 'details' object to be interpolated with the path
      },
    ],
    renderDetails: (details = {}) => {
      const { numExternalCustomers, numMappings } = details;
      return (
        <span>
          {numMappings}/{numExternalCustomers}
        </span>
      );
    },
  },
  [CHECKS_NAMES.INTEGRATE_TO_PSA]: {
    title: "ONBOARDING_CHECKLIST.INTEGRATE_TO_PSA",
    description: "ONBOARDING_CHECKLIST.INTEGRATE_TO_PSA_DESCRIPTION",
    moreInfoLinkId: "onboardingChecklist_integrateToPsa",
    permission: "integrations",
    pageLinks: [
      {
        label: {
          id: "ONBOARDING_CHECKLIST.AUTOTASK_INTEGRATION",
          img: "/media/logos/apps/Autotask.png",
          imgAlt: "Autotask",
        },
        path: "/integrations",
        search: new URLSearchParams({
          applicationNameQ: INTEGRATION_APPS.AUTOTASK,
        }).toString(),
      },
      {
        label: {
          id: "ONBOARDING_CHECKLIST.CONNECTWISE_INTEGRATION",
          img: "/media/logos/apps/ConnectWise.png",
          imgAlt: "ConnectWise",
        },
        path: "/integrations",
        search: new URLSearchParams({
          applicationNameQ: INTEGRATION_APPS.CONNECTWISE,
        }).toString(),
      },
      {
        label: {
          id: "ONBOARDING_CHECKLIST.HALO_INTEGRATION",
          img: "/media/logos/apps/Halo.png",
          imgAlt: "Halo",
        },
        path: "/integrations",
        search: new URLSearchParams({
          applicationNameQ: INTEGRATION_APPS.HALO,
        }).toString(),
      },
      {
        label: {
          id: "ONBOARDING_CHECKLIST.SYNCRO_INTEGRATION",
          img: "/media/logos/apps/Syncro.png",
          imgAlt: "Syncro",
        },
        path: "/integrations",
        search: new URLSearchParams({
          applicationNameQ: INTEGRATION_APPS.SYNCRO,
        }).toString(),
      },
    ],
    renderDetails: (details) => details?.displayName,
  },
  [CHECKS_NAMES.ADD_SYSTEM_USER]: {
    title: "ONBOARDING_CHECKLIST.ADD_A_SYSTEM_USER",
    description: "ONBOARDING_CHECKLIST.ADD_SYSTEM_USER_DESCRIPTION",
    moreInfoLinkId: "onboardingChecklist_addSystemUser",
    permission: "systemUsers",
    pageLinks: [
      {
        label: {
          id: "ONBOARDING_CHECKLIST.ADD_SYSTEM_USER",
        },
        path: "/users",
        search: new URLSearchParams({
          modal: "addUser",
        }).toString(),
      },
    ],
    renderDetails: (details) => (
      <FormattedMessage
        id="ONBOARDING_CHECKLIST.NUM_ADDED"
        values={{ num: details?.numSystemUsers }}
      />
    ),
  },
  [CHECKS_NAMES.ADD_OPERATING_COUNTRIES]: {
    title: "ONBOARDING_CHECKLIST.MANAGE_EVENT_SETTINGS_TITLE",
    description: "ONBOARDING_CHECKLIST.MANAGE_EVENT_SETTINGS_DESCRIPTION",
    moreInfoLinkId: null, // not present in KB
    permission: "secure",
    pageLinks: [
      {
        label: {
          id: "ONBOARDING_CHECKLIST.MANAGE_EVENT_SETTINGS",
        },
        path: "/secure/alerts",
        search: new URLSearchParams({
          modal: "eventSettings",
        }).toString(),
      },
    ],
    renderDetails: (details = {}) => {
      const {
        numCustomersWithSettings,
        numCustomersWithAware,
        hasGlobalDefaults,
      } = details;
      return hasGlobalDefaults ? (
        <FormattedMessage id="THREAT_ALERTS.GLOBAL_DEFAULTS" />
      ) : (
        <span>
          {numCustomersWithSettings}/{numCustomersWithAware}
        </span>
      );
    },
  },
  [CHECKS_NAMES.SCHEDULE_REPORT]: {
    title: "ONBOARDING_CHECKLIST.SCHEDULE_REPORT",
    description: "ONBOARDING_CHECKLIST.SCHEDULE_REPORT_DESCRIPTION",
    moreInfoLinkId: "onboardingChecklist_scheduleReport",
    pageLinks: [
      {
        label: {
          id: "ONBOARDING_CHECKLIST.SCHEDULE_REPORT",
        },
        path: "/scheduled-reports",
      },
    ],
    renderDetails: (details) => (
      <FormattedMessage
        id="ONBOARDING_CHECKLIST.NUM_SCHEDULED"
        values={{ num: details?.numScheduledReports }}
      />
    ),
  },
  [CHECKS_NAMES.ADD_CUSTOM_BRANDING]: {
    title: "ONBOARDING_CHECKLIST.ADD_CUSTOM_BRANDING",
    description: "ONBOARDING_CHECKLIST.ADD_CUSTOM_BRANDING_DESCRIPTION",
    moreInfoLinkId: "onboardingChecklist_addCustomBranding",
    permission: "lookAndFeel",
    pageLinks: [
      {
        label: {
          id: "ONBOARDING_CHECKLIST.EDIT_BRANDING",
        },
        path: "/look-and-feel",
      },
    ],
  },
};

const useOnboardingCardConfigs = () => configurations;

export default useOnboardingCardConfigs;
