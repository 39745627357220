/**
 * Capitalize first character for the input string
 * @param {string} value String value
 */
export const capitalizeFirstLetter = (value) =>
  value ? value.charAt(0).toUpperCase() + value.slice(1) : "";

// returns current date as YYYY_MM_DD-hhmmss
export const getCurrentDateLongFormat = () => {
  const today = new Date();
  return `${String(today.getFullYear()).padStart(4, "0")}_${String(
    today.getMonth() + 1,
  ).padStart(2, "0")}_${String(today.getDate()).padStart(2, "0")}-${String(
    today.getHours(),
  ).padStart(2, "0")}${String(today.getMinutes()).padStart(2, "0")}${String(
    today.getSeconds(),
  ).padStart(2, "0")}`;
};

// to remove any chars other than alphabet/number
export const getNormalizedCustomerName = (customerName = "") => {
  if (!customerName) {
    return customerName;
  }
  return customerName.trim().replace(/[^\w ]+/g, "");
};

/**
 * Regex for validating email
 * @param {string} email String value
 */
export const validateEmail = (email) =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(email);
