import { getIntegrations } from "../../../crud/integration.crud";

export const INTEGRATION_APPS = {
  OFFICE_365: "Office365",
  DUO_SECURITY: "DUOSecurity",
  AUTOTASK: "Autotask",
  SYNCRO: "Syncro",
  CONNECTWISE: "ConnectWise",
  HALO: "Halo",
};

export const INTEGRATION_TYPES = {
  LEGACY: 0,
  TOKEN: 1,
  ONE_TO_ONE: 2,
  CSP: 3,
};

export const getM365CspIntegration = async ({ parentId }) => {
  let cspIntegration;
  try {
    const parentIntegrations = await getIntegrations(parentId);
    cspIntegration = parentIntegrations?.data?.find(
      (integration) =>
        integration?.IntegrationApplication?.application_name === "Office365" &&
        integration?.integration_type === INTEGRATION_TYPES.CSP,
    );
  } catch (error) {
    console.error("Failed to retrieve parent company integrations", error);
  }

  return cspIntegration ?? null;
};

export const PSA_INTEGRATIONS = ["Autotask", "Halo", "Syncro", "ConnectWise"];
