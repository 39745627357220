import { combineReducers } from "redux";

import { augmentt } from "../../_augmentt";

export const rootReducer = combineReducers({
  i18n: augmentt.i18n.reducer,
  customers: augmentt.customers.reducer,
  secure: augmentt.secure.reducer,
  licenses: augmentt.licenses.reducer,
  user: augmentt.user.reducer,
  dates: augmentt.dates.reducer,
  employees: augmentt.employees.reducer,
  branding: augmentt.branding.reducer,
  builder: augmentt.builder.reducer,
  uploads: augmentt.uploads.reducer,
  flags: augmentt.flags.reducer,
  featureFlags: augmentt.featureFlags.reducer,
  externalLinks: augmentt.externalLinks.reducer,
  integrations: augmentt.integrations.reducer,
  exporter: augmentt.exporter.reducer,
  meta: augmentt.meta.reducer,
  reports: augmentt.reports.reducer,
  sessionModal: augmentt.sessionModal.reducer,
  userPermissions: augmentt.userPermissions.reducer,
  table: augmentt.table.reducer,
  subheader: augmentt.subheader.reducer,
});

// eslint-disable-next-line no-empty-function
export function* rootSaga() {}
