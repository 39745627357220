/* eslint-disable react/destructuring-assignment, jsx-a11y/label-has-associated-control */
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, Tab } from "react-bootstrap";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export default class QuickPanel extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = { selectedTab: "Notifications" };

  setTab = (_tabName) => {
    this.setState({ selectedTab: _tabName });
  };

  render() {
    return (
      <div id="kt_quick_panel" className="quick-panel">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="quick-panel__close"
          id="kt_quick_panel_close_btn"
        >
          <i className="flaticon2-delete" />
        </a>
        <Tab.Container defaultActiveKey={this.state.selectedTab}>
          <div className="quick-panel__nav">
            <Nav
              onSelect={this.setTab}
              className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand notification-item-padding-x"
            >
              <Nav.Item className="nav-item">
                <Nav.Link eventKey="Notifications" className="nav-link show">
                  Notifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="AuditLogs" className="nav-link show">
                  Audit Logs
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Settings" className="nav-link show">
                  Settings
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="quick-panel__content">
            <Tab.Content>
              <Tab.Pane eventKey="Notifications">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    position: "relative",
                  }}
                >
                  <div className="notification" />
                </PerfectScrollbar>
              </Tab.Pane>
              <Tab.Pane eventKey="AuditLogs">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    position: "relative",
                  }}
                >
                  <div className="notification-v2" />
                </PerfectScrollbar>
              </Tab.Pane>
              <Tab.Pane eventKey="Settings">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    position: "relative",
                  }}
                >
                  <div className="quick-panel__content-padding-x">
                    <form className="form">
                      <div className="heading heading--sm heading--space-sm">
                        Customer Care
                      </div>
                      <div className="form-group form-group-xs row">
                        <label className="col-8 col-form-label">
                          Enable Notifications:
                        </label>
                        <div className="col-4 align-right">
                          <span className="switch switch--success switch--sm">
                            <label>
                              <input
                                type="checkbox"
                                name="quick_panel_notifications_1"
                              />
                              <span />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="form-group form-group-xs row">
                        <label className="col-8 col-form-label">
                          Enable Case Tracking:
                        </label>
                        <div className="col-4 align-right">
                          <span className="switch switch--success switch--sm">
                            <label>
                              <input
                                type="checkbox"
                                name="quick_panel_notifications_2"
                              />
                              <span />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="form-group form-group-last form-group-xs row">
                        <label className="col-8 col-form-label">
                          Support Portal:
                        </label>
                        <div className="col-4 align-right">
                          <span className="switch switch--success switch--sm">
                            <label>
                              <input
                                type="checkbox"
                                name="quick_panel_notifications_2"
                              />
                              <span />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="separator separator--space-md separator--border-dashed" />
                      <div className="heading heading--sm heading--space-sm">
                        Reports
                      </div>
                      <div className="form-group form-group-xs row">
                        <label className="col-8 col-form-label">
                          Generate Reports:
                        </label>
                        <div className="col-4 align-right">
                          <span className="switch switch--sm switch--danger">
                            <label>
                              <input
                                type="checkbox"
                                name="quick_panel_notifications_3"
                              />
                              <span />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="form-group form-group-xs row">
                        <label className="col-8 col-form-label">
                          Enable Report Export:
                        </label>
                        <div className="col-4 align-right">
                          <span className="switch switch--sm switch--danger">
                            <label>
                              <input
                                type="checkbox"
                                name="quick_panel_notifications_3"
                              />
                              <span />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="form-group form-group-last form-group-xs row">
                        <label className="col-8 col-form-label">
                          Allow Data Collection:
                        </label>
                        <div className="col-4 align-right">
                          <span className="switch switch--sm switch--danger">
                            <label>
                              <input
                                type="checkbox"
                                name="quick_panel_notifications_4"
                              />
                              <span />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="separator separator--space-md separator--border-dashed" />
                      <div className="heading heading--sm heading--space-sm">
                        Members
                      </div>
                      <div className="form-group form-group-xs row">
                        <label className="col-8 col-form-label">
                          Enable Member singup:
                        </label>
                        <div className="col-4 align-right">
                          <span className="switch switch--sm switch--brand">
                            <label>
                              <input
                                type="checkbox"
                                name="quick_panel_notifications_5"
                              />
                              <span />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="form-group form-group-xs row">
                        <label className="col-8 col-form-label">
                          Allow User Feedbacks:
                        </label>
                        <div className="col-4 align-right">
                          <span className="switch switch--sm switch--brand">
                            <label>
                              <input
                                type="checkbox"
                                name="quick_panel_notifications_5"
                              />
                              <span />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="form-group form-group-last form-group-xs row">
                        <label className="col-8 col-form-label">
                          Enable Customer Portal:
                        </label>
                        <div className="col-4 align-right">
                          <span className="switch switch--sm switch--brand">
                            <label>
                              <input
                                type="checkbox"
                                name="quick_panel_notifications_6"
                              />
                              <span />
                            </label>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </PerfectScrollbar>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    );
  }
}
