/* eslint-disable react/prop-types */
/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Amplify, { Auth, Cache } from "aws-amplify";
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_augmentt";
import store from "./app/store/store";
import awsConfig from "./app/pages/auth/aws-exports";
import { Routes } from "./app/router/Routes";
import GoogleTagManagerVars from "./app/partials/content/GoogleTagManagerVars";
import { getUserById } from "./app/crud/users.crud";
import { getBranding } from "./app/crud/branding.crud";
import { getExternalLinks } from "./app/crud/app.crud";
import { getFeatureFlags } from "./app/crud/featureFlags.crud";
import { getOrg } from "./app/crud/orgs.crud";
import useAwsRum from "./app/hooks/useAwsRum";
import * as user from "./_augmentt/ducks/user";
import * as userPermissions from "./_augmentt/ducks/userPermissions";
import * as branding from "./_augmentt/ducks/branding";
import * as licenses from "./_augmentt/ducks/licenses";
import * as meta from "./_augmentt/ducks/meta";
import * as featureFlags from "./_augmentt/ducks/featureFlags";
import * as externalLinks from "./_augmentt/ducks/externalLinks";
import * as builder from "./_augmentt/ducks/builder";

Amplify.configure(awsConfig);

export default function App({ persistor, basename }) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [_, setRetrievedFeatureFlags] = useState(false); // eslint-disable-line no-unused-vars

  const dispatch = useDispatch();

  const currentUser = useSelector(({ user }) => user.data); // eslint-disable-line no-shadow
  const license = useSelector(({ licenses }) => licenses.data); // eslint-disable-line no-shadow

  async function signOut() {
    await Auth.signOut();
    setIsAuthenticating(false);
    setRetrievedFeatureFlags(false);
  }

  // When at 'setup-Mfa' page the user hits on Browser's back button then we need to signOut User.
  // Below fumction not actually captures the Browser's back button event but rely more on onload event.
  // Whenever there is an onload on the window and something is pushed to history we are capturing that.
  // In this particular case if 'auth/login' is pushed in history from any other page(for now only 'setup-Mfa')
  // then we clear redux store and signOut user.
  // eslint-disable-next-line func-names
  window.onload = function () {
    if (typeof window.history.pushState === "function") {
      window.history.pushState("NothingPressed", null, null);
      // eslint-disable-next-line func-names
      window.onpopstate = function () {
        if (window.location.pathname === "/auth/login") {
          window.history.pushState("BackButtonPressed", null, null);
          signOut();
        }
      };
    }
  };

  useEffect(() => {
    updateSplashScreen(); // eslint-disable-line no-use-before-define
    getUser(); // eslint-disable-line no-use-before-define
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window.location.pathname !== "/error") {
      // Get license information & permissions if we don't have them
      if (currentUser && !license) {
        // eslint-disable-next-line no-use-before-define
        getOrganization().then(() => {
          setIsAuthenticating(false);
        });
      }
    }
  }, [currentUser, license]); // eslint-disable-line react-hooks/exhaustive-deps

  // Trigger feature flag pull
  useEffect(() => {
    dispatch(
      builder.actions.setSubHeaderDisplay({
        display: window.location.pathname !== "/welcome" ?? false,
      }),
    );
    loadFeatureFlags(); // eslint-disable-line no-use-before-define
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  // Installs AWS CloudWatch RUM snippet
  useAwsRum();

  async function loadFeatureFlags() {
    if (!currentUser) {
      return; // we need a user, and we only retrieve the data once from the backend
    }

    try {
      const featureFlagsData = await getFeatureFlags();
      const externalLinksData = await getExternalLinks();

      dispatch(featureFlags.actions.setFeatureFlags(featureFlagsData?.data));
      dispatch(externalLinks.actions.setLinks(externalLinksData.data));
      setRetrievedFeatureFlags(true); // do this once.
    } catch (e) {
      console.error("Feature flags failed to load. Error:", e);
    }
  }

  async function getUser() {
    const session = Cache.getItem("Session");
    if (!session) {
      // Sign out if session is expired/non-existent
      signOut();
    } else {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const userInfo = await getUserById(cognitoUser.attributes.email);
        const mspBranding = await getBranding(
          userInfo.data.userData.Customer.Organization.id,
        );

        dispatch(user.actions.setUser(userInfo.data.userData));
        dispatch(
          userPermissions.actions.setUserPermissions({
            customers: userInfo.data.customers,
            permissions: userInfo.data.permissions,
          }),
        );
        dispatch(
          userPermissions.actions.setOwnerChargeBeeID(
            userInfo.data.ownerChargebeeid,
          ),
        );
        dispatch(
          branding.actions.setBranding(
            mspBranding.data || {
              logo_light: null,
              publish_logo: false,
              favicon: null,
              publish_favicon: false,
            },
          ),
        );
      } catch (e) {
        console.error(e);
        setIsAuthenticating(false);
      }
    }
  }

  // eslint-disable-next-line consistent-return
  async function getOrganization() {
    // subscription, licenses, modules
    if (currentUser.Customer) {
      const response = await getOrg(currentUser.Customer.Organization.id);
      try {
        dispatch(
          meta.actions.setHasValidSubscription(
            response?.data.hasValidSubscription,
          ),
        );
        dispatch(meta.actions.setChargebeeId(response?.data.chargebeeId));
        dispatch(
          meta.actions.setHasValidM365LicensingReportSubscription(
            response?.data.hasValidM365LicensingReportSubscription,
          ),
        );
        // Has valid posture baseline subscription. `hasPbSub` is short form
        dispatch(
          meta.actions.setHasValidPostureBaselineSubscription(
            response?.data.hasPbSub,
          ),
        );
        dispatch(licenses.actions.setLicenses(response?.data.licenses));
        dispatch(
          meta.actions.setHasMultiCspSubscription(
            response?.data?.hasMultiCspSubscription || false,
          ),
        );
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  }

  const updateSplashScreen = () => {
    const trialLogin = document.getElementById("trial-login-msg");
    const spinner = document.getElementById("spinner");

    if (
      window.location.hostname === "app.test.augmentt.com" &&
      (window.location.pathname === "/auth/login" ||
        window.location.pathname === "/")
    ) {
      trialLogin.classList.remove("hidden");
      spinner.classList.add("hidden");
    } else {
      trialLogin.classList.add("hidden");
      spinner.classList.remove("hidden");
    }
  };

  return (
    !isAuthenticating && (
      /* Provide Redux store */
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/* Provide Metronic theme overrides. */}
              <ThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Provides ChurnZero access to user variables */}
                  <GoogleTagManagerVars />
                  {/* Render routes with provided `Layout`. */}
                  <Routes />
                </I18nProvider>
              </ThemeProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    )
  );
}
