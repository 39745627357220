import * as i18n from "./ducks/i18n";
import * as customers from "./ducks/customers";
import * as secure from "./ducks/secure";
import * as licenses from "./ducks/licenses";
import * as user from "./ducks/user";
import * as dates from "./ducks/dates";
import * as employees from "./ducks/employees";
import * as branding from "./ducks/branding";
import * as builder from "./ducks/builder";
import * as uploads from "./ducks/uploads";
import * as flags from "./ducks/flags";
import * as integrations from "./ducks/integrations";
import * as exporter from "./ducks/exporter";
import * as meta from "./ducks/meta";
import * as featureFlags from "./ducks/featureFlags";
import * as externalLinks from "./ducks/externalLinks";
import * as reports from "./ducks/reports";
import * as sessionModal from "./ducks/sessionModal";
import * as userPermissions from "./ducks/userPermissions";
import * as table from "./ducks/table";
import * as subheader from "./ducks/subheader";

/**
 * Reexports
 */
export * from "./utils/utils";
export * from "./layout/LayoutContext";
export * from "./layout/LayoutConfig";
export { default as LayoutConfig } from "./layout/LayoutConfig";
export { default as LayoutInitializer } from "./layout/LayoutInitializer";
export { default as I18nProvider } from "./i18n/I18nProvider";
export { default as ThemeProvider } from "./materialUIThemeProvider/ThemeProvider";

/**
 * Ducks
 */
export const augmentt = {
  i18n,
  customers,
  secure,
  licenses,
  user,
  dates,
  employees,
  branding,
  builder,
  uploads,
  flags,
  integrations,
  exporter,
  meta,
  featureFlags,
  externalLinks,
  reports,
  sessionModal,
  userPermissions,
  table,
  subheader,
};
