/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { injectIntl } from "react-intl";
import { Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from "react-select";
import moment from "moment";
import { datePickerStyle } from "../../../_augmentt/utils/styles";
import * as dates from "../../../_augmentt/ducks/dates";

function DatePicker(props) {
  const { dateRanges, showCustomRangeLabel = true } = props;

  const dispatch = useDispatch();

  const startOfDay = { hour: 0, minute: 0, second: 0, millisecond: 0 };
  const endOfDay = { hour: 23, minute: 59, second: 59, millisecond: 999 };

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").set(startOfDay),
  );
  const [endDate, setEndDate] = useState(moment().set(endOfDay));

  const [selectedDates, setSelectedDates] = useState([
    {
      label: `${startDate.format("MMMM DD, YYYY")} - ${endDate.format(
        "MMMM DD, YYYY",
      )}`.toString(),
      value: { start: startDate, end: endDate },
    },
  ]);

  const [initialized, setInitialized] = useState(false);

  // Handles date change
  const handleDateChange = (start, end) => {
    setStartDate(moment(start).set(startOfDay));
    setEndDate(moment(end).set(endOfDay));
    setSelectedDates([
      {
        label: `${moment(start).format("MMMM DD, YYYY")} - ${moment(end).format(
          "MMMM DD, YYYY",
        )}`.toString(),
        value: { start: startDate, end: endDate },
      },
    ]);

    // Update redux store
    dispatch(
      dates.actions.setDateRange({
        startDate: `${moment(start)
          .set(startOfDay)
          .format("YYYY-MM-DDT00:00:00.000")}Z`,
        endDate: `${moment(end)
          .set(endOfDay)
          .format("YYYY-MM-DDT23:59:59.999")}Z`,
      }),
    );
  };

  // Determine date ranges to display in the left hand side of the date range picker
  let dateRangesForDatePicker = {
    Today: [moment(), moment()],
    Yesterday: [
      moment().subtract(1, "days").set(startOfDay),
      moment().subtract(1, "days").set(endOfDay),
    ],
    "Last 7 Days": [
      moment().subtract(6, "days").set(startOfDay),
      moment().set(endOfDay),
    ],
    "Last 30 Days": [
      moment().subtract(29, "days").set(startOfDay),
      moment().set(endOfDay),
    ],
    "This Month": [
      moment().startOf("month").set(startOfDay),
      moment().endOf("month").set(endOfDay),
    ],
    "Last Month": [
      moment()
        .subtract(1, "month")
        .set(startOfDay)
        .startOf("month")
        .set(endOfDay),
      moment()
        .subtract(1, "month")
        .set(startOfDay)
        .endOf("month")
        .set(endOfDay),
    ],
  };

  if (dateRanges) {
    dateRangesForDatePicker = dateRanges;

    if (!initialized) {
      setInitialized(true);
      const values = Object.values(dateRangesForDatePicker);
      handleDateChange(values[0][0], values[0][1]);
    }
  }

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(
        dates.actions.setDateRange({
          startDate: `${moment(startDate)
            .set(startOfDay)
            .format("YYYY-MM-DDT00:00:00.000")}Z`,
          endDate: `${moment(endDate)
            .set(endOfDay)
            .format("YYYY-MM-DDT23:59:59.999")}Z`,
        }),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DateRangePicker
      initialSettings={{
        showCustomRangeLabel,
        opens: "left",
        drops: "down",
        startDate,
        endDate,
        minDate: moment().subtract(4, "month").set(startOfDay),
        maxDate: moment().set(endOfDay),
        ranges: dateRangesForDatePicker,
      }}
      onCallback={handleDateChange}
    >
      <Button variant="link" id="daterange" style={{ padding: 0 }}>
        <Select
          name="daterange"
          menuIsOpen={false}
          styles={datePickerStyle}
          defaultValue={selectedDates[0]}
          value={selectedDates[0]}
          isSearchable={false}
          openMenuOnClick={false}
        />
      </Button>
    </DateRangePicker>
  );
}

export default injectIntl(DatePicker);
