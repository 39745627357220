/* eslint-disable react/prefer-stateless-function, react/prop-types, react/destructuring-assignment */
import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";

class Footer extends React.Component {
  render() {
    const today = new Date().getFullYear();
    return (
      <div
        className={`footer ${this.props.footerClasses} grid__item grid grid--desktop grid--ver-desktop`}
        id="footer"
      >
        <div className={`container ${this.props.footerContainerClasses}`}>
          <div className="footer__copyright">
            {today.toString()}&nbsp;&copy;&nbsp;
            <a
              href="http://www.augmentt.com"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              AUGMENTT TECHNOLOGY INC.
            </a>
          </div>
          <a href="https://clearbit.com">Logos provided by Clearbit</a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  fluid:
    objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true,
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true,
  }),
});

export default connect(mapStateToProps)(Footer);
