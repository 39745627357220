import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { Modal, Alert } from "react-bootstrap";
import ProgressCard from "./ProgressCard";
import CheckCard from "./CheckCard";
import useOnboardingCardConfigs from "./useOnboardingCardConfigs";
import styles from "./OnboardingChecklistModal.module.scss";

const propTypes = {
  /**
   * If true, shows the modal
   */
  show: PropTypes.bool,
  /**
   * Callback called when modal is closing
   */
  onHide: PropTypes.func.isRequired,
  /**
   * Number of completed items of the checklist
   */
  numComplete: PropTypes.number,
  /**
   * Number of items in the checklist
   */
  numTotal: PropTypes.number,
  /**
   * Check list with onboarding items (not dismissed)
   */
  onboardingChecklist: PropTypes.arrayOf(PropTypes.shape()),
  /**
   * Check list with the dismissed onboarding items
   */
  onboardingChecklistDismissed: PropTypes.arrayOf(PropTypes.shape()),
  /**
   * Callback to request the onboarding checklist
   */
  requestOnboardingChecklist: PropTypes.func.isRequired,
  /**
   * Callback to update the onboarding checklist
   */
  updateChecklistItem: PropTypes.func.isRequired,
  /**
   * Indicates if onboarding checklist request is loading
   */
  loading: PropTypes.bool,
  /**
   * Indicates if an individual item is loading (ignore/unignore case)
   * itemLoading should match the checkName
   */
  itemLoading: PropTypes.string,
  /**
   * Indicates if request failed
   */
  error: PropTypes.bool,
};

const defaultProps = {
  show: false,
  numComplete: 0,
  numTotal: 0,
  onboardingChecklist: [],
  onboardingChecklistDismissed: [],
  loading: false,
  itemLoading: null,
  error: false,
};

function OnboardingChecklistModal(props) {
  const {
    show,
    onHide,
    numTotal,
    numComplete,
    onboardingChecklist,
    onboardingChecklistDismissed,
    requestOnboardingChecklist,
    updateChecklistItem,
    loading,
    itemLoading,
    error,
  } = props;

  const externalLinksList = useSelector(
    ({ externalLinks }) => externalLinks.data,
  );

  const organizationId = useSelector(({ user }) => user?.data?.organization);

  const configurations = useOnboardingCardConfigs();

  useEffect(() => {
    if (show && organizationId) {
      requestOnboardingChecklist({ organizationId });
    }
  }, [show, organizationId, requestOnboardingChecklist]);

  const onUpdateCheck = useCallback(
    ({ checkName, dismissed }) => {
      updateChecklistItem({ organizationId, checkName, dismissed });
    },
    [updateChecklistItem, organizationId],
  );

  return (
    <Modal
      size="md"
      backdrop
      className={clsx("modal-topbar right", { in: show })}
      show={show}
      onHide={onHide}
    >
      <Modal.Header
        data-cy="onboarding-checklist-modal-header"
        className="d-flex align-items-center"
        closeButton
      >
        <Modal.Title>
          <FormattedMessage id="ONBOARDING_CHECKLIST.TITLE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={clsx("modal-body-sidebar", styles.modalBody)}>
        {error && (
          <Alert variant="danger">
            <FormattedMessage id="GENERAL.ERROR" />
          </Alert>
        )}
        <ProgressCard
          numTotal={numTotal}
          numComplete={numComplete}
          loading={loading}
        />
        {(onboardingChecklist ?? [])
          .concat(onboardingChecklistDismissed ?? [])
          ?.map((checkItem) => {
            const { checkName, completionStatus, details, dismissed } =
              checkItem;
            const config = configurations?.[checkName];
            return (
              <CheckCard
                key={checkName}
                checkName={checkName}
                status={completionStatus}
                details={details}
                dismissed={dismissed}
                title={config?.title}
                description={config?.description}
                pageLinks={config?.pageLinks}
                renderDetails={config?.renderDetails}
                collapseWhenPartiallyComplete={
                  config?.collapseWhenPartiallyComplete
                }
                permission={config?.permission}
                moreInfoLink={externalLinksList?.[config?.moreInfoLinkId]}
                onHide={onHide}
                loading={loading}
                updateLoading={checkName === itemLoading}
                updateDisabled={itemLoading && checkName !== itemLoading}
                onUpdateCheck={onUpdateCheck}
              />
            );
          })}
      </Modal.Body>
    </Modal>
  );
}

OnboardingChecklistModal.propTypes = propTypes;
OnboardingChecklistModal.defaultProps = defaultProps;

export default OnboardingChecklistModal;
