import React, { useRef, useEffect } from "react";
import { Link, Switch, Redirect, Route } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { toAbsoluteUrl } from "../../../_augmentt";
import "../../../_augmentt/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import ConfirmUser from "./ConfirmUser";

export default function AuthPage(authProps) {
  const { redirectUrl = false, navSearchParams = false } = authProps;

  useEffect(() => {
    // If a user is redirected to the Login component from any paths other than
    // /auth/login or /error or /logout then they should be brought back there after login
    if (redirectUrl) {
      const navString = `${redirectUrl}${navSearchParams || ""}`;

      localStorage.setItem("redirectUrl", navString);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const year = new Date().getFullYear();

  const captchaRef = useRef(null);

  return (
    <div className="login row row-full-height row-no-padding">
      <div
        className="col-xl-12"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-1.png")})`,
          backgroundSize: "cover",
        }}
      >
        <div
          aria-live="polite"
          aria-atomic="true"
          style={{
            position: "absolute",
            minHeight: "100px",
            top: "1rem",
            right: "2rem",
            width: "300px",
          }}
        />
        <div className="row justify-content-center align-items-center">
          <div className="col-sm-12 col-md-7 col-lg-5" id="authForm">
            <Link to="/" className="login__logo">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/logo-dark.png")}
              />
            </Link>
            <div className="login__wrapper">
              <Switch>
                <Route
                  path="/auth/login"
                  render={(props) => (
                    <Login {...props} captcha={captchaRef.current} />
                  )}
                />
                <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Route
                  path="/auth/change-password"
                  component={ChangePassword}
                />
                <Route path="/auth/confirm" component={ConfirmUser} />
                <Redirect from="/auth" exact to="/auth/login" />
                <Redirect from="/" exact to="/auth/login" />
                <Redirect exact to="/auth/login" />
              </Switch>
            </div>
          </div>
          <div className="login__info">
            <div className="login__copyright">
              &copy; {year} AUGMENTT TECHNOLOGY INC.
            </div>
          </div>
        </div>
      </div>
      <ReCAPTCHA
        ref={captchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        badge="bottomleft"
      />
    </div>
  );
}
