const actionTypes = {
  SetBranding: "user/SET_BRANDING",
};

const initialState = {
  data: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetBranding:
      return { ...state, data: action.payload.branding };

    default:
      return state;
  }
};

export const actions = {
  setBranding: (branding) => ({
    type: actionTypes.SetBranding,
    payload: { branding },
  }),
};
