import React, { useEffect, useState, useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Badge } from "@augmentt-dev/ui-components";
import { EULAContext } from "../../../context/EULAContext";
import useOnboardingChecklist from "../../../hooks/useOnboardingChecklist";
import OnboardingChecklistModal from "./OnboardingChecklistModal";
import { COMPLETION_STATUS, CHECKS_NAMES } from "./constants";
import styles from "./OnboardingChecklist.module.scss";

function OnboardingChecklist() {
  const organizationId = useSelector(({ user }) => user?.data?.organization);

  const redirectedFromLogin = useSelector(
    ({ subheader }) => subheader.redirectedFromLogin,
  );
  const hasValidSubscription = useSelector(
    ({ meta }) => meta.hasValidSubscription,
  );
  const isSuccessfullyLoggedIn = redirectedFromLogin && hasValidSubscription;

  const [eula] = useContext(EULAContext);

  const [showModal, setShowModal] = useState(false);

  const {
    loading,
    itemLoading,
    error,
    data,
    meta,
    requestOnboardingChecklist,
    updateChecklistItem,
  } = useOnboardingChecklist();

  useEffect(() => {
    if (organizationId) {
      requestOnboardingChecklist({ organizationId });
    }
  }, [organizationId, requestOnboardingChecklist]);

  // as a requirement the first time the organization logins after onboarding checklist is deployed we should open the modal
  const hasChecklistBeenViewed = meta?.[CHECKS_NAMES.CHECKLIST_VIEWED_META];
  useEffect(() => {
    if (
      organizationId &&
      isSuccessfullyLoggedIn &&
      hasChecklistBeenViewed === false
    ) {
      const showChecklistFirstTime = async () => {
        // dismiss property so we guarantee we won't open the checklist automatically anymore
        await updateChecklistItem({
          organizationId,
          checkName: CHECKS_NAMES.CHECKLIST_VIEWED_META,
          dismissed: true,
        });
        setShowModal(true);
      };

      showChecklistFirstTime();
    }
  }, [
    hasChecklistBeenViewed,
    updateChecklistItem,
    organizationId,
    isSuccessfullyLoggedIn,
  ]);

  const {
    dismissed: onboardingChecklistDismissed,
    notDismissed: onboardingChecklist,
  } = useMemo(() => {
    const dismissed = [];
    const notDismissed = [];
    data?.forEach((item) => {
      if (item.dismissed) {
        dismissed.push(item);
      } else {
        notDismissed.push(item);
      }
    });
    return { dismissed, notDismissed };
  }, [data]);

  // partially complete items are considered as incomplete in our notifications
  const numTotal = onboardingChecklist?.length ?? 0;
  const numComplete = useMemo(
    () =>
      onboardingChecklist?.filter(
        (item) => item?.completionStatus === COMPLETION_STATUS.COMPLETE,
      )?.length ?? 0,
    [onboardingChecklist],
  );
  const numIncomplete = numTotal - numComplete;

  return (
    <>
      <div className="header__topbar-item">
        <div className="header__topbar-wrapper">
          <button
            id="onboarding-checklist-btn"
            type="button"
            onClick={() => setShowModal(true)}
            className={clsx("btn-transparent", styles.checklistButton)}
          >
            <span className="header__topbar-icon">
              <span
                className={clsx("fas fa-clipboard-list", styles.checklistIcon)}
              />
              {numIncomplete ? (
                <Badge
                  variant="danger"
                  className={styles.badge}
                  data-cy="onboarding-checklist-btn-count"
                >
                  {numIncomplete}
                </Badge>
              ) : null}
            </span>
          </button>
        </div>
      </div>
      {showModal && (
        <OnboardingChecklistModal
          show={showModal && !eula.show} // if eula is being shown hide modal
          onHide={() => setShowModal(false)}
          requestOnboardingChecklist={requestOnboardingChecklist}
          updateChecklistItem={updateChecklistItem}
          loading={loading}
          itemLoading={itemLoading}
          error={error}
          numTotal={numTotal}
          numComplete={numComplete}
          onboardingChecklist={onboardingChecklist}
          onboardingChecklistDismissed={onboardingChecklistDismissed}
        />
      )}
    </>
  );
}

export default OnboardingChecklist;
