import axios from "axios";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;
const brandingUrl = (brandingId) => `${ROOT}/branding/${brandingId}`;
const organizationBrandingUrl = (organizationId) =>
  `${ROOT}/organization/${organizationId}/branding`;

export const getBranding = async (organizationId) => {
  const result = await axios.get(organizationBrandingUrl(organizationId));

  // cache first request of logo image with cors enabled so reports generated by the browser can use it
  const img = new Image();
  img.crossOrigin = "anonymous";
  img.src = result?.data?.logo_light;

  return result;
};

export const createBranding = (organizationId, data) =>
  axios.post(organizationBrandingUrl(organizationId), data);

export const updateBranding = (brandingId, data) =>
  axios.put(brandingUrl(brandingId), data);

export const deleteBranding = (brandingId) =>
  axios.delete(brandingUrl(brandingId));
