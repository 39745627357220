/* eslint-disable react/prop-types, react/destructuring-assignment */
import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import ScrollMenu from "../../../partials/content/ScrollMenu";
import { getCategories } from "../../../crud/app.crud";
import { createApplication } from "../../../crud/elastic.crud";
import { selectStyle } from "../../../../_augmentt/utils/styles";

function AddApplicationModal(props) {
  const { show, onHide, onUpdate, intl, application, merge } = props;
  // Merge is bool, alters some final button text if true and sets application to subscribed on creation

  // Global state
  const selectedCustomer = useSelector(
    ({ customers }) => customers.selectedCustomer,
  );
  const currentUser = useSelector(({ user }) => user.data);

  // Local state
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
  });
  const [status, setStatus] = useState();
  const [categoryOptions, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [riskOptions, setRiskLevels] = useState([]);
  const [selectedRiskLevel, setSelectedRiskLevel] = useState();
  const [statusOptions, setStatusLevels] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState();
  const [productivityOptions, setProductivityLevels] = useState([]);
  const [selectedProductivityLevel, setSelectedProductivityLevel] = useState();

  useEffect(() => {
    // Get categories
    getCategories().then((allCategories) => {
      setCategories(allCategories);

      // Set default selected category if adding from web traffic
      for (let i = 0; i < allCategories.length; i += 1) {
        if (application && application.category === allCategories[i].value) {
          setSelectedCategory(allCategories[i]);
        }
      }
    });

    const statusLevels = [
      {
        value: 0,
        label: intl.formatMessage({
          id: "SAASCONFIG.UNKNOWN",
        }),
      },
      {
        value: 1,
        label: intl.formatMessage({
          id: "SAASCONFIG.RESTRICTED",
        }),
      },
      {
        value: 2,
        label: intl.formatMessage({
          id: "SAASCONFIG.PENDING_REVIEW",
        }),
      },
      {
        value: 3,
        label: intl.formatMessage({
          id: "SAASCONFIG.APPROVED",
        }),
      },
    ];

    let riskLevels;
    let productivityLevels;

    // eslint-disable-next-line no-multi-assign, prefer-const
    riskLevels = productivityLevels = [
      {
        value: 0,
        label: intl.formatMessage({
          id: "SAASCONFIG.UNKNOWN",
        }),
      },
      {
        value: 1,
        label: intl.formatMessage({
          id: "SAASCONFIG.LOW",
        }),
      },
      {
        value: 2,
        label: intl.formatMessage({
          id: "SAASCONFIG.MEDIUM",
        }),
      },
      {
        value: 3,
        label: intl.formatMessage({
          id: "SAASCONFIG.HIGH",
        }),
      },
    ];

    setStatusLevels(statusLevels);
    setSelectedStatus({
      value: 0,
      label: intl.formatMessage({
        id: "SAASCONFIG.UNKNOWN",
      }),
    });
    setRiskLevels(riskLevels);
    setSelectedRiskLevel({
      value: 0,
      label: intl.formatMessage({
        id: "SAASCONFIG.UNKNOWN",
      }),
    });
    setProductivityLevels(productivityLevels);
    setSelectedProductivityLevel({
      value: 0,
      label: intl.formatMessage({
        id: "SAASCONFIG.UNKNOWN",
      }),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const schema = Yup.object({
    name: Yup.string()
      .min(
        2,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_SHORT",
        }),
      )
      .max(
        50,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_LONG",
        }),
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
    url: Yup.string()
      .trim()
      .lowercase()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(https?:\/\/)?([\da-z\.-]+\.[a-z\.]{2,12}|[\d\.]+)([\/:?=&#]{1}[\da-z\.-]+)*[\/\?]?$/,
        intl.formatMessage({
          id: "AUTH.VALIDATION.INVALID_URL",
        }),
      )
      .min(
        2,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_SHORT",
        }),
      )
      .max(
        100,
        intl.formatMessage({
          id: "AUTH.VALIDATION.TOO_LONG",
        }),
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        }),
      ),
  });

  const initialValues = {
    name: "",
    url: application ? application.source : "",
    category: "Unknown",
    risk: 0,
    productivity: 0,
    status: 0,
  };

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem", paddingLeft: "2.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem", paddingLeft: "2.5rem" });
  };

  /**
   * Submit handler
   * @param {*} values
   */
  async function handleSubmit(values) {
    enableLoading();
    // eslint-disable-next-line no-shadow
    const application = {
      companyName: values.name,
      url: values.url,
      risk: values.risk.value,
      approvalStatus: values.status.value,
      category: values.category.label,
      productivity: values.productivity.value,
    };
    // eslint-disable-next-line no-use-before-define
    await addApplication(application);
  }

  /**
   * Add application
   * @param {*} application
   */
  // eslint-disable-next-line no-shadow
  async function addApplication(application) {
    try {
      // If we are doing a QB merge too, mark the application as isSubscribed;
      if (merge) {
        application.isSubscribed = true; // eslint-disable-line no-param-reassign
      }

      const organizationId = currentUser?.Customer?.organization;
      await createApplication(application, selectedCustomer, organizationId);
      // eslint-disable-next-line no-use-before-define
      hide();
      // eslint-disable-next-line no-use-before-define
      update(application);
    } catch (e) {
      console.error(e.message);
      setStatus(
        <>
          <FormattedMessage id="GENERAL.ERROR" />
          <a
            href="https://support.augmentt.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="GENERAL.CONTACT_SUPPORT" />
          </a>
        </>,
      );
    }
    disableLoading();
  }

  const hide = () => {
    onHide();
    setStatus();
  };

  const update = (optionalData) => {
    onUpdate(optionalData);
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={hide}
      onSubmit={update}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <FormattedMessage id="SAASCONFIG.ADD_SAAS_APPLICATION" />
        </Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        initialErrors={schema.isValidSync(initialValues)}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          handleSubmit, // eslint-disable-line no-shadow
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          values,
          errors,
          touched,
        }) => (
          <Form>
            <Modal.Body>
              <div className="row justify-content-center align-items-center">
                <Col md="12">
                  <p>
                    <FormattedMessage
                      id="SAASCONFIG.ADD_APPLICATION_DESC"
                      defaultMessage="SAASCONFIG.ADD_APPLICATION_DESC"
                    />
                  </p>
                  {status && (
                    <div
                      role="alert"
                      className={`alert alert-${errors ? "danger" : "success"}`}
                    >
                      <div className="alert-text">{status}</div>
                    </div>
                  )}
                </Col>
                <Form.Group as={Col} md="12" controlId="name">
                  <Form.Label className="inline">
                    <FormattedMessage id="SAASCONFIG.SAAS_APPLICATION" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="inline"
                    name="name"
                    placeholder={intl.formatMessage({
                      id: "PLACEHOLDER.ENTER_APPLICATION_NAME",
                    })}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && !!errors.name}
                    isValid={touched.name && !errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="url">
                  <Form.Label className="inline">
                    <FormattedMessage id="SAASCONFIG.SAAS_APP_URL" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="inline"
                    name="url"
                    placeholder={intl.formatMessage({
                      id: "PLACEHOLDER.ENTER_APPLICATION_URL",
                    })}
                    value={values.url}
                    onChange={handleChange}
                    onBlur={() => setFieldTouched("url", true)}
                    isInvalid={touched.url && !!errors.url}
                    isValid={touched.url && !errors.url}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.url}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="risk">
                  <Form.Label className="inline">
                    <FormattedMessage id="SAASCONFIG.RISK" />
                  </Form.Label>
                  <Select
                    id="riskSelect"
                    name="risk"
                    className="inline"
                    styles={selectStyle}
                    options={riskOptions}
                    value={
                      values.risk ? values.risk : selectedRiskLevel || undefined
                    }
                    onChange={(value) => setFieldValue("risk", value)}
                    onBlur={() => setFieldTouched("risk", true)}
                    isSearchable={false}
                    components={{ MenuList: ScrollMenu }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.risk}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="appStatus">
                  <Form.Label className="inline">
                    <FormattedMessage id="SAASCONFIG.APPROVAL_STATUS" />
                  </Form.Label>
                  <Select
                    id="statusSelect"
                    name="status"
                    className="inline"
                    styles={selectStyle}
                    options={statusOptions}
                    value={
                      values.status
                        ? values.status
                        : selectedStatus || undefined
                    }
                    onChange={(value) => setFieldValue("status", value)}
                    onBlur={() => setFieldTouched("status", true)}
                    isSearchable={false}
                    components={{ MenuList: ScrollMenu }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.status}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="appCategory">
                  <Form.Label className="inline">
                    <FormattedMessage id="SAASCONFIG.CATEGORY" />
                  </Form.Label>
                  <Select
                    id="categorySelect"
                    name="category"
                    className="inline"
                    styles={selectStyle}
                    options={categoryOptions}
                    value={selectedCategory || undefined}
                    onChange={(value) => setFieldValue("category", value)}
                    getOptionValue={(value) => value}
                    onBlur={() => setFieldTouched("category", true)}
                    placeholder={intl.formatMessage({
                      id: "PLACEHOLDER.SELECT_CATEGORY",
                    })}
                    components={{ MenuList: ScrollMenu }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.category}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="productivity">
                  <Form.Label className="inline">
                    <FormattedMessage id="SAASCONFIG.PRODUCTIVITY" />
                  </Form.Label>
                  <Select
                    id="productivitySelect"
                    name="productivity"
                    className="inline"
                    styles={selectStyle}
                    options={productivityOptions}
                    value={
                      values.productivity
                        ? values.productivity
                        : selectedProductivityLevel || undefined
                    }
                    onChange={(value) => setFieldValue("productivity", value)}
                    onBlur={() => setFieldTouched("productivity", true)}
                    isSearchable={false}
                    components={{ MenuList: ScrollMenu }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.productivity}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button id="closeModalBtn" onClick={hide} variant="light">
                <FormattedMessage id="GENERAL.CANCEL" />
              </Button>
              <Button
                onClick={handleSubmit} // eslint-disable-line react/jsx-no-bind
                id="addApplicationSubmit"
                variant="primary"
                className={`btn-elevate adduser__btn-primary ${clsx({
                  "spinner spinner--right spinner--md spinner--light": loading,
                })}`}
                style={loadingButtonStyle}
              >
                <FormattedMessage
                  id={
                    merge ? "QUICKBOOKS_MODAL.MERGE_AND_SAVE" : "GENERAL.SUBMIT"
                  }
                />
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default injectIntl(AddApplicationModal);
