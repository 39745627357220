/* eslint-disable react/prefer-stateless-function */
import React from "react";

export default class MenuItemSeparator extends React.Component {
  render() {
    return (
      <li className="menu__separator">
        <span />
      </li>
    );
  }
}
