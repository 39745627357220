// Create a export as CSV/XLSX react component for reports, let it handle the buttons, logic for exporting etc

import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { getOrg } from "../../../../app/crud/orgs.crud";
import AddEmployeeModal from "../../../../app/pages/home/modals/AddEmployeeModal";
import ImportEmployeesModal from "../../../../app/pages/home/modals/ImportEmployeesModal";
import * as licenses from "../../../ducks/licenses";
import * as table from "../../../ducks/table";
import { EMPLOYEES_TABLE_KEY } from "../../../../app/pages/home/Employees/utils";

function AddEmployeesButton() {
  const dispatch = useDispatch();

  const currentUser = useSelector(({ user }) => user.data);
  const selectedCustomer = useSelector(
    ({ customers }) => customers.selectedCustomer,
  );
  const customersInDropdown = useSelector(({ customers }) => customers.data);

  const [modalAddEmployeeShow, setModalAddEmployeeShow] = useState(false);
  const [modalImportEmployeesShow, setModalImportEmployeesShow] =
    useState(false);

  const onAddEmployeeClick = () => {
    setModalAddEmployeeShow(true);
  };

  const onImportEmployeesClick = () => {
    setModalImportEmployeesShow(true);
  };

  const modalClose = () => {
    setModalAddEmployeeShow(false);
    setModalImportEmployeesShow(false);
  };

  // On update of employees, due to auto assign, we need to reupdate licences from the org call
  const onUpdate = async () => {
    if (currentUser.Customer) {
      const { data } = await getOrg(currentUser.Customer.Organization.id);
      if (data.licenses) {
        dispatch(licenses.actions.setLicenses(data.licenses));
      }
      dispatch(
        table.actions.fetchTable({
          tableKey: EMPLOYEES_TABLE_KEY,
          page: table.INITIAL_STATE.page,
          sizePerPage: table.INITIAL_STATE.sizePerPage,
        }),
      );
    }
  };

  // Render button
  return (
    <>
      <Dropdown drop="down">
        <Dropdown.Toggle
          variant="light"
          id="employeeActionsBtn"
          // disabled={isEmployeesLoading}
        >
          <FormattedMessage id="ADMINISTRATION.ADD_EMPLOYEES" />
        </Dropdown.Toggle>
        <Dropdown.Menu
          id="employeeActionsMenu"
          className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim"
        >
          <ul className="nav margin-t-10 margin-b-10">
            <li className={clsx("nav__item")} data-toggle="collapse">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <span
                onClick={() => onAddEmployeeClick()}
                className={clsx("nav__link")}
              >
                <span className="nav__link nav__link-text">
                  <FormattedMessage id="ADMINISTRATION.ADD_EMPLOYEE" />
                </span>
              </span>
            </li>
            <li className={clsx("nav__item")} data-toggle="collapse">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <span
                onClick={() => onImportEmployeesClick()}
                className={clsx("nav__link")}
              >
                <span className="nav__link nav__link-text">
                  <FormattedMessage id="ADMINISTRATION.BULK_IMPORT_EMPLOYEES" />
                </span>
              </span>
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>

      <AddEmployeeModal
        show={modalAddEmployeeShow}
        onHide={modalClose}
        onUpdate={onUpdate}
        customer={selectedCustomer || customersInDropdown[0]}
        customers={customersInDropdown}
      />
      <ImportEmployeesModal
        show={modalImportEmployeesShow}
        onHide={modalClose}
        onUpdate={onUpdate}
        customers={customersInDropdown}
        user={currentUser}
      />
    </>
  );
}

export default injectIntl(AddEmployeesButton);
