/* eslint-disable react/prop-types, react/destructuring-assignment */
import React, { useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import clsx from "clsx";
import Amplify, { Auth } from "aws-amplify";
import awsConfig from "./aws-exports";

function ConfirmUser(props) {
  const { intl, location } = props;

  const { user } = location.state;

  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "3.5rem",
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState();
  const [errors, setErrors] = useState(false);

  const num1 = React.createRef();
  const num2 = React.createRef();
  const num3 = React.createRef();
  const num4 = React.createRef();
  const num5 = React.createRef();
  const num6 = React.createRef();

  Amplify.configure(awsConfig);

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  function handleChange(e) {
    switch (e.target.id) {
      case "num1":
        num2.current.focus();
        break;
      case "num2":
        num3.current.focus();
        break;
      case "num3":
        num4.current.focus();
        break;
      case "num4":
        num5.current.focus();
        break;
      case "num5":
        num6.current.focus();
        break;
      default:
        break;
    }
  }

  async function resendCode() {
    setSubmitting(true);
    try {
      await Auth.resendSignUp(user.email);
      setSubmitting(false);
      setErrors(false);
      setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.CODE_SENT" }));
    } catch (e) {
      setErrors(true);
      setSubmitting(false);
      setStatus(
        intl.formatMessage({ id: "AUTH.VALIDATION.PROBLEM_REQUESTING_CODE" }),
      );
      console.error(e);
    }
  }

  async function confirmSignUp() {
    const code = `${num1.current.value}${num2.current.value}${num3.current.value}${num4.current.value}${num5.current.value}${num6.current.value}`;

    setSubmitting(true);
    enableLoading();

    try {
      await Auth.confirmSignUp(user.email, code);

      await Auth.signIn(user.email, user.password);

      setErrors(false);
      setSubmitting(false);
      disableLoading();

      props.userHasAuthenticated(true);
    } catch (e) {
      setErrors(true);
      setSubmitting(false);
      disableLoading();
      if (e.code === "CodeMismatchException") {
        setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.CODE_INVALID" }));
      } else {
        setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.CODE_EXPIRED" }));
      }
      console.error(e);
    }
  }

  return (
    <div className="grid__item grid__item--fluid  grid__item--order-tablet-and-mobile-1  login__wrapper">
      <div className="login__body">
        <div className="login__form">
          <div className="login__title">
            <h3>
              <FormattedMessage id="AUTH.CONFIRMUSER.TITLE" />
            </h3>
          </div>
          <Form className="form" id="verifyCode">
            {status && (
              <div
                role="alert"
                className={`alert alert-${errors ? "danger" : "success"}`}
              >
                <div className="alert-text">{status}</div>
              </div>
            )}
            <div className="row justify-content-center align-items-center">
              <Form.Group as={Col} sm="1" md="1">
                <Form.Control
                  type="text"
                  id="num1"
                  maxLength={1}
                  autoFocus
                  onChange={handleChange} // eslint-disable-line react/jsx-no-bind
                  ref={num1}
                />
              </Form.Group>
              <Form.Group as={Col} sm="1" md="1">
                <Form.Control
                  type="text"
                  id="num2"
                  maxLength={1}
                  onChange={handleChange} // eslint-disable-line react/jsx-no-bind
                  ref={num2}
                />
              </Form.Group>
              <Form.Group as={Col} sm="1" md="1">
                <Form.Control
                  type="text"
                  id="num3"
                  maxLength={1}
                  onChange={handleChange} // eslint-disable-line react/jsx-no-bind
                  ref={num3}
                />
              </Form.Group>
              <Form.Group as={Col} sm="1" md="1">
                <Form.Control
                  type="text"
                  id="num4"
                  maxLength={1}
                  onChange={handleChange} // eslint-disable-line react/jsx-no-bind
                  ref={num4}
                />
              </Form.Group>
              <Form.Group as={Col} sm="1" md="1">
                <Form.Control
                  type="text"
                  id="num5"
                  maxLength={1}
                  onChange={handleChange} // eslint-disable-line react/jsx-no-bind
                  ref={num5}
                />
              </Form.Group>
              <Form.Group as={Col} sm="1" md="1">
                <Form.Control type="text" id="num6" maxLength={1} ref={num6} />
              </Form.Group>
            </div>

            <div className="confirm_actions">
              <Button
                id="resendCodeBtn"
                onClick={resendCode} // eslint-disable-line react/jsx-no-bind
                variant="light"
                disabled={isSubmitting}
              >
                Request Code
              </Button>

              <Button
                id="confirmBtn"
                onClick={confirmSignUp} // eslint-disable-line react/jsx-no-bind
                className={`btn btn-primary btn-elevate ${clsx({
                  "spinner spinner--right spinner--md spinner--light": loading,
                })}`}
                style={loadingButtonStyle}
                disabled={isSubmitting}
              >
                <FormattedMessage id="GENERAL.CONFIRM" />
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (user) => ({
  user,
});

export default injectIntl(connect(mapStateToProps)(ConfirmUser));
