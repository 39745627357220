import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import ImportSettingsModal from "../../../../app/pages/home/modals/ImportSettingsModal";
import Spinner from "../../../../app/partials/content/Spinner";
import { getIntegratedEmployeesMetaData } from "../../../../app/crud/integration.crud";

function ImportSettingsButton() {
  const [modalAddEmployeeShow, setModalAddEmployeeShow] = useState(false);
  const [employeesMetaData, setEmployeesMetaData] = useState(null);
  const [loading, setLoading] = useState(false);

  const selectedIntegration = useSelector(
    ({ integrations }) => integrations.selectedIntegration,
  );
  const selectedIntegrationType =
    selectedIntegration?.IntegrationApplication?.application_name ||
    "Office365";

  const selectedCustomer = useSelector(
    ({ customers }) => customers.selectedCustomer,
  );
  const customersInDropdown = useSelector(({ customers }) => customers.data);
  const activatedCustomer = selectedCustomer || customersInDropdown[0];

  useEffect(() => {
    if (activatedCustomer?.id) {
      getEmployeesMetaData(); // eslint-disable-line no-use-before-define
    }
  }, [activatedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const getEmployeesMetaData = async () => {
    setLoading(true);
    try {
      const employeesMeta = await getIntegratedEmployeesMetaData(
        activatedCustomer?.id,
        selectedIntegrationType,
      );
      setLoading(false);
      setEmployeesMetaData(employeesMeta?.data);
    } catch (err) {
      setLoading(false);
      console.error(`getIntegratedEmployeesMetaData error`, err);
    }
  };

  const onAddEmployeeClick = () => {
    setModalAddEmployeeShow(true);
  };

  const modalClose = () => {
    setModalAddEmployeeShow(false);
  };

  // Render button
  return (
    <>
      <Button
        onClick={() => onAddEmployeeClick()}
        variant="light"
        disabled={loading}
      >
        {loading && <Spinner size="sm" />}
        {!loading && (
          <>
            <LazyLoadImage
              src="/media/logos/apps/O365.png"
              style={{ width: 20, height: 20 }}
              className="mr-3"
            />
            <FormattedMessage id="ADMINISTRATION.IMPORT_SETTINGS" />
          </>
        )}
      </Button>
      {modalAddEmployeeShow && (
        <ImportSettingsModal
          show={modalAddEmployeeShow}
          onHide={modalClose}
          domainOptions={employeesMetaData?.domains || []}
          rolesOptions={employeesMetaData?.roles || []}
          groupOptions={employeesMetaData?.groups || []}
          licenseOptions={employeesMetaData?.licenseType || []}
          usersOption={employeesMetaData?.users || []}
          selectedIntegrationType={selectedIntegrationType}
          customer={activatedCustomer}
        />
      )}
    </>
  );
}

export default injectIntl(ImportSettingsButton);
