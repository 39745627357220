import jsPDF from "jspdf";
import { applyPlugin } from "jspdf-autotable";
import {
  getCurrentDateLongFormat,
  getNormalizedCustomerName,
} from "../../../util/string.utils";

applyPlugin(jsPDF);

function employeesPDF() {
  function loadImage(url) {
    return new Promise((resolve) => {
      if (url.indexOf("base64") > -1) {
        resolve(url);
      } else {
        // load from url
        const img = new Image();
        img.onload = () => resolve(img);
        img.src = url;
      }
    });
  }

  return (props) => {
    const { intl, data, branding } = props; // SEE ExportWidget for params

    loadImage(branding).then((logo) => {
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF("p", "mm", "a3");
      const imgProps = pdf.getImageProperties(logo);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const options = { align: "center", maxWidth: "80" };

      const ratio = imgProps.width / imgProps.height;

      // Add logo. Note if the logo is a portrait mode its going to look weird,
      pdf.addImage(
        logo,
        "PNG",
        10,
        10,
        pdfWidth / (imgProps.width > 500 || ratio < 2 ? 7 : 4),
        pdfHeight / (imgProps.height > 200 || ratio < 2 ? 7 : 4),
        undefined,
        "SLOW",
      );

      // Title
      const pdfTitle = document
        .getElementById("am_subheader")
        .getElementsByClassName("subheader__title")[0]?.innerText;

      pdf.setFontSize(17);
      pdf.setFont("helvetica", "bold");
      pdf.text(pdfTitle, 150, 18.5, options);

      const companyName = document
        .getElementById("companySelect")
        .getElementsByTagName("div")[3]?.innerText;

      const noData = intl.formatMessage({
        id: "GENERAL.NO_DATA",
      });

      const employeeArray = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const employee of data) {
        employeeArray.push(Object.values(employee));
      }
      pdf.autoTable({
        head: [data?.length ? Object.keys(data[0]) : []],
        body: employeeArray,
        theme: "striped",
        startY: 45,
        headStyles: {
          fillColor: [45, 41, 86],
          textColor: 255,
          font: "Helvetica",
        },
        bodyStyles: {
          font: "Helvetica",
          cellPadding: 3,
          lineWidth: 0.1,
        },
        columnStyles: {
          0: { cellWidth: 25 },
          1: { cellWidth: 30 },
          2: { cellWidth: 70 },
          3: { cellWidth: 25 },
          4: { cellWidth: 35 },
          5: { cellWidth: 30 },
          6: { cellWidth: 30 },
          7: { cellWidth: 25 },
        },
      });

      // If no data, display no data
      if (!data?.length) {
        pdf.text(noData, 150, 130, { align: "center", maxWidth: "150" });
      }
      pdf.save(
        ` ${getNormalizedCustomerName(companyName)}_${
          document
            .getElementById("am_subheader")
            .getElementsByClassName("subheader__title")[0]?.innerText
        } -${getCurrentDateLongFormat()}.pdf`,
      );
    });
  };
}

export default employeesPDF;
