/* eslint-disable react/prop-types, react/destructuring-assignment */
import React, { useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import clsx from "clsx";
import Amplify, { Auth, Cache } from "aws-amplify";
import awsConfig from "./aws-exports";
import * as customers from "../../../_augmentt/ducks/customers";
import * as user from "../../../_augmentt/ducks/user";
import * as userPermissions from "../../../_augmentt/ducks/userPermissions";
import { getUserById, toggleMFA } from "../../crud/users.crud";

function ChangePassword(props) {
  const { intl, history } = props;

  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2rem",
  });
  // eslint-disable-next-line no-shadow
  const currentUser = useSelector(({ user }) => user.data);
  const dispatch = useDispatch();

  Amplify.configure(awsConfig);

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2rem" });
  };

  async function handleSubmit(values, setStatus, setSubmitting) {
    const cognitoUser = currentUser;

    enableLoading();
    try {
      await Auth.completeNewPassword(
        cognitoUser, // The Cognito User Object
        values.newPassword, // The new password
        cognitoUser.challengeParam.requiredAttributes,
      );

      // Set session in cache to get be able to get token from interceptor
      const now = new Date();
      Cache.setItem("Session", now, {
        expires: now.setHours(now.getHours() + 1),
      });

      const userInfo = await getUserById(
        cognitoUser.challengeParam.userAttributes.email,
      );
      // Set user info in redux store
      dispatch(user.actions.setUser(userInfo.data.userData));

      // Set user permissions and customer access in redux store
      dispatch(
        userPermissions.actions.setUserPermissions({
          customers: userInfo.data.customers,
          permissions: userInfo.data.permissions,
        }),
      );
      dispatch(
        userPermissions.actions.setOwnerChargeBeeID(
          userInfo.data.ownerChargebeeid,
        ),
      );

      // Reset customers in redux store
      dispatch(customers.actions.setCustomers([]));

      history.push("/");
      await toggleMFA(userInfo.data);
    } catch (e) {
      console.error(e);
      setStatus(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.PROBLEM" },
          { name: values.newPassword },
        ),
      );
    }
    setSubmitting(false);
    disableLoading();
  }

  return (
    <div className="grid__item grid__item--fluid  grid__item--order-tablet-and-mobile-1">
      <div className="login__body">
        <div className="login__form">
          <div className="login__title">
            <h3>
              <FormattedMessage id="AUTH.CHANGEPASSWORD.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{ newPassword: "", confirmPassword: "" }}
            validate={(values) => {
              const errors = {};

              if (!values.newPassword) {
                errors.newPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              } else if (!values.confirmPassword) {
                errors.confirmPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              } else if (values.newPassword !== values.confirmPassword) {
                errors.newPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.PASSWORD_MISMATCH",
                });
                errors.confirmPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.PASSWORD_MISMATCH",
                });
              } else if (
                // eslint-disable-next-line no-useless-escape
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/.test(
                  values.newPassword,
                )
              ) {
                errors.newPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_PASSWORD",
                });
              } else if (
                values.newPassword.includes(
                  currentUser.challengeParam.userAttributes.email,
                )
              ) {
                errors.newPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.PASSWORD_MATCHES_EMAIL",
                });
              } else if (values.newPassword.length < 8) {
                errors.newPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.PASSWORD_LENGTH",
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              handleSubmit(values, setStatus, setSubmitting);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit, // eslint-disable-line no-shadow
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className="form">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group">
                  <TextField
                    type="password"
                    label="New Password"
                    margin="normal"
                    fullWidth
                    name="newPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    label="Confirm Password"
                    margin="normal"
                    fullWidth
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword,
                    )}
                  />
                </div>

                <div className="login__actions">
                  <Link to="/auth">
                    <Button
                      id="backbtn"
                      variant="secondary"
                      className="btn-elevate login__btn-secondary"
                    >
                      Back
                    </Button>
                  </Link>

                  <Button
                    id="confirmBtn"
                    variant="primary"
                    type="submit"
                    className={`btn-elevate login__btn-primary ${clsx({
                      "spinner spinner--right spinner--md spinner--light":
                        loading,
                    })}`}
                    style={loadingButtonStyle}
                    disabled={isSubmitting}
                  >
                    Confirm
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line no-shadow
const mapStateToProps = (user) => ({
  user,
});

export default injectIntl(connect(mapStateToProps)(ChangePassword));
