// NOTE THIS IS LEGACY, WE SHOULD REMOVE THIS AND REPLACE WITH featureFlags especially for optimize hiding purposes

// This is used to hide features on the client. Currently used for module level.
const actionTypes = {
  SetDiscover: "flags/SET_DISCOVER",
  SetOptimize: "flags/SET_OPTIMIZE",
  SetEngage: "flags/SET_ENGAGE",
};

// We are disabling optimize and engage by default. Change this as needed in the future
const initialState = {
  discover: true,
  optimize: false,
  engage: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetDiscover:
      return { ...state, discover: action.payload.discover };
    case actionTypes.SetOptimize:
      return { ...state, optimize: action.payload.optimize };
    case actionTypes.SetEngage:
      return { ...state, engage: action.payload.engage };
    default:
      return state;
  }
};

export const actions = {
  setDiscover: (discover) => ({
    type: actionTypes.SetDiscover,
    payload: { discover },
  }),
  setOptimize: (optimize) => ({
    type: actionTypes.SetOptimize,
    payload: { optimize },
  }),
  setEngage: (engage) => ({ type: actionTypes.SetEngage, payload: { engage } }),
};
