const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
const COGNITO_USER_POOL = process.env.REACT_APP_COGNITO_USER_POOL;
const COGNITO_WEB_CLIENT = process.env.REACT_APP_COGNITO_WEB_CLIENT;
const COGNITO_IDENTITY_POOL = process.env.REACT_APP_IDENTITY_POOL;

const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: COGNITO_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: COGNITO_USER_POOL,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: COGNITO_WEB_CLIENT,
    // OPTIONAL - Amazon Cognito Identity Pool ID
    identityPoolId: COGNITO_IDENTITY_POOL,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_SRP_AUTH",
  },
  Storage: {
    region: COGNITO_REGION,
  },
};

const awsConfig = config;

export default awsConfig;
