/* eslint-disable react/prop-types, react/destructuring-assignment */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { FormattedMessage, useIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import Switch from "react-switch";
import Uploads from "../../../app/partials/layout/Uploads";
import UserProfile from "../../../app/partials/layout/UserProfile";
import NotificationsPanel from "../../../app/partials/layout/NotificationsPanel";
import OnboardingChecklist from "../../../app/partials/layout/OnboardingChecklist";
import SessionTimeoutModal from "../../../app/pages/home/modals/SessionTimeoutModal";
import { signOut, verifySession } from "../../../app/util/auth.util";
import * as sessionModal from "../../ducks/sessionModal";

function Topbar(props) {
  const { currentUser, history } = props;
  const dispatch = useDispatch();
  const intl = useIntl();

  // check if tokens are valid on mount and on every 5min
  useEffect(() => {
    verifySession(dispatch, intl);
    const interval = setInterval(
      () => verifySession(dispatch, intl),
      5 * 60 * 1000,
    );
    return () => {
      clearInterval(interval);
    };
  }, [dispatch, intl]);

  const handleSiteView = async () => {
    const sitePath =
      window.location.hostname.indexOf("localhost") > -1
        ? "https://app.develop.augmentt.com"
        : `https://${window.location.hostname}`;

    await signOut(dispatch);

    if (currentUser.amazon_id === process.env.REACT_APP_TRIAL_USERNAME) {
      // Logout of the trial account and go to login page
      window.open(`${sitePath}/auth/login`, "_top");
    } else {
      // logout and login with the trial account
      window.open(`${sitePath}/auth/login?trial=true`, "_top");
    }
  };

  useIdleTimer({
    element: document,
    debounce: 250,
    events: ["keydown", "mousedown", "MSPointerDown"],
    timeout: 1000 * 60 * 60,
    promptBeforeIdle: 1000 * 60,
    onIdle: () => history.push("/logout"),
    onPrompt: (event, idleTimer) => {
      dispatch(
        sessionModal.actions.openSessionModal({
          description: intl.formatMessage({
            id: "AUTH.SESSIONTIMEOUT.INACTIVE",
          }),
          refresh: true,
          idleTimer,
        }),
      );
    },
  });

  return (
    <div className="header__topbar">
      <div id="trialToggle">
        <span
          className="font-bolder"
          style={{
            color:
              currentUser.amazon_id === process.env.REACT_APP_TRIAL_USERNAME
                ? "#FFFFFF"
                : "rgba(255,255,255,0.3)",
          }}
        >
          <FormattedMessage id="GENERAL.TEST_SITE" />
        </span>
        <Switch
          onChange={handleSiteView}
          checked={
            currentUser.amazon_id !== process.env.REACT_APP_TRIAL_USERNAME
          }
          height={15}
          width={35}
          handleDiameter={18}
          onColor="#2F4B81"
          offColor="#E5EAEE"
          onHandleColor="#3399FF"
          boxShadow="0px 1px 3px rgba(0, 0, 0, 0.3)"
          checkedIcon={false}
          uncheckedIcon={false}
          className="switch"
          aria-label="view-site-toggle"
        />
        <span
          className="font-bolder"
          style={{
            color:
              currentUser.amazon_id !== process.env.REACT_APP_TRIAL_USERNAME
                ? "#FFFFFF"
                : "rgba(255,255,255,0.3)",
          }}
        >
          <FormattedMessage id="GENERAL.LIVE_SITE" />
        </span>
      </div>

      <Uploads />

      <OnboardingChecklist />

      <NotificationsPanel />

      <UserProfile showAvatar showHi showBadge currentUser={currentUser} />

      <SessionTimeoutModal history={history} />
    </div>
  );
}

export default withRouter(Topbar);
