import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./BetaTag.module.scss";

function BetaTag() {
  return (
    <span className={styles.base}>
      <FormattedMessage id="GENERAL.BETA" />
    </span>
  );
}

export default BetaTag;
