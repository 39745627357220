import axios from "axios";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;

export const EMAIL_URL = `${ROOT}/email/`;
export const FILE_URL = `${ROOT}/file/`;
export const CUSTOMER_URL = `${ROOT}/customer/`;
export const S3_TOKEN_URL = `${ROOT}/tools/s3/token`;

export function getFiles(customerId) {
  return axios.get(`${CUSTOMER_URL + customerId}/file`);
}

export function getStagingKey() {
  return axios.get(`${FILE_URL}stage`);
}

export function getS3File(key, action) {
  return axios.post(S3_TOKEN_URL, {
    action,
    key,
  });
}

export function getS3FileUploadURL(key, isFile) {
  return axios.post(S3_TOKEN_URL, {
    action: isFile ? "write" : "staging",
    key,
  });
}

export function uploadFile(file, customerId) {
  const date = new Date();
  const uploadDate = date.toISOString();
  return axios.post(`${CUSTOMER_URL + customerId}/file`, {
    file_name: file.file_name,
    extension: file.extension,
    vendor: file.vendor,
    size: file.size,
    upload_date: uploadDate,
  });
}

export function deleteFile(id) {
  return axios.delete(FILE_URL + id, { file_id: id });
}

export function getVendors() {
  return axios.get(`${FILE_URL}vendor`);
}

export function getEmails(customerId) {
  return axios.get(`${CUSTOMER_URL + customerId}/email`);
}

/**
 * Used by Download Agent Page
 * @returns signedURL for downloading the msi, error on ErrorResponse.
 */
export function getBrowserAgentDownloadUrl() {
  return axios.get(`${CUSTOMER_URL}download_agent`);
}
