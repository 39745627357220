/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
import { Toast } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { ToastContext } from "../../context/ToastContext";

function ToastNotification(props) {
  const { intl } = props;

  const [toastInfo, setToastInfo] = useContext(ToastContext);
  const [toastShow, setToastShow] = useState(false);

  useEffect(() => {
    if (toastInfo.show) {
      setToastShow(true);
      // Reset show to prevent multiple pop ups
      setToastInfo({ type: toastInfo.type, show: false });
    }
  }, [toastInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  /* eslint-disable no-nested-ternary */
  let toastText =
    toastInfo.type === "createdCAP"
      ? intl.formatMessage({
          id: "SECURE.CREATED_CAP_TOAST",
        })
      : toastInfo.type === "missingSubscription"
        ? intl.formatMessage({
            id: "AUTH.MISSING_SUBSCRIPTION",
          })
        : toastInfo.type === "addedToSubscriptionManager"
          ? intl.formatMessage({
              id: "SAASCONFIG.ADDED_TO_SUBSCRIPTION_MANAGER",
            })
          : toastInfo.type === "removedFromSubscriptionManager"
            ? intl.formatMessage({
                id: "SAASCONFIG.REMOVED_FROM_SUBSCRIPTION_MANAGER",
              })
            : toastInfo.type === "fileUploaded"
              ? intl.formatMessage({
                  id: "SETUP.FILE_UPLOADED_WAIT",
                })
              : toastInfo.type === "saaslistUpdated"
                ? intl.formatMessage({
                    id: "SAASCONFIG.SAAS_LIST_UPDATED",
                  })
                : toastInfo.type === "refreshWarning"
                  ? intl.formatMessage({
                      id: "GENERAL.PAGE_REFRESH_WARNING",
                    })
                  : toastInfo.type === "vendorNotSupported"
                    ? intl.formatMessage({
                        id: "SETUP.VENDOR_NOT_SUPPORTED",
                      })
                    : toastInfo.type === "psaTicketSent"
                      ? intl.formatMessage({
                          id: "ENGAGE.PSA_TICKET_SENT",
                        })
                      : toastInfo.type === "uploadInProgress"
                        ? intl.formatMessage({
                            id: "GENERAL.UPLOAD_IN_PROGRESS",
                          })
                        : toastInfo.type === "uploadComplete"
                          ? intl.formatMessage({
                              id: "GENERAL.UPLOAD_COMPLETE",
                            })
                          : toastInfo.type === "uploadFailed"
                            ? intl.formatMessage({
                                id: "GENERAL.UPLOAD_FAILED",
                              })
                            : toastInfo.type === "uploadCanceled"
                              ? intl.formatMessage({
                                  id: "GENERAL.UPLOAD_CANCELED",
                                })
                              : toastInfo.type === "changeMfaStatus"
                                ? intl.formatMessage({
                                    id: "GENERAL.CHANGE_MFA_STATUS",
                                  })
                                : "";
  /* eslint-enable no-nested-ternary */

  if (toastText === "" && toastInfo?.type) {
    // check if type is actually an id for an INTL message
    try {
      toastText = intl.formatMessage({ id: toastInfo.type });
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Toast
      style={{
        position: "fixed",
        top: 140,
      }}
      onClose={() => setToastShow(false)}
      show={toastShow}
      delay={5000}
      autohide
    >
      <Toast.Body>{toastText}</Toast.Body>
    </Toast>
  );
}

export default injectIntl(ToastNotification);
