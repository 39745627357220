import { Alert, OverlayTrigger, Toast, Tooltip } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@augmentt-dev/ui-components";
import clsx from "clsx";
import moment from "moment";
import { delay } from "../../../utils/utils";
import {
  createTask,
  getTask,
  OFFICE365,
  pollIntegrationTaskStatus,
} from "../../../../app/crud/integration.crud";
import * as customersDuck from "../../../ducks/customers";
import styles from "./IntegrationRefreshButton.module.scss";
import useCustomerIntegration from "../../../../app/hooks/useCustomerIntegration";

const DELAY_TIME = 10 * 1000; // 10s

/**
 * Removes the already mapped customer from the list of populating data on redux store
 */
const removeMappedCustomer = (dispatch, customerId) => {
  dispatch(customersDuck.actions.removeSyncingCustomer(customerId));
};

function IntegrationRefreshButton() {
  const dispatch = useDispatch();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [prevCustomer, setPrevCustomer] = useState(undefined);
  const message = errorMessage || successMessage;
  const cleanMessages = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  const intl = useIntl();

  const polling = useRef({});

  const selectedCustomer = useSelector(
    ({ customers }) => customers?.selectedCustomer,
  );

  const currentUser = useSelector(({ user }) => user?.data);

  // select ms integration from integrations
  const selectedIntegration = useSelector(({ integrations }) =>
    integrations?.integrations?.find(
      (integration) =>
        integration?.IntegrationApplication?.application_name === "Office365",
    ),
  );

  const inEmployeesPage = window.location.pathname.match(/employees/);
  // fetch integration for customer
  useCustomerIntegration({
    selectedCustomerId: selectedCustomer?.id,
    defaultSelection: inEmployeesPage ? OFFICE365 : null,
  });

  const hasRequiredFields = useMemo(
    () =>
      currentUser?.id &&
      selectedCustomer?.id &&
      selectedIntegration?.id &&
      selectedIntegration?.IntegrationApplication?.application_name ===
        "Office365" &&
      (String(selectedIntegration?.Customer?.id) ===
        String(selectedCustomer?.id) ||
        String(selectedIntegration?.mappedCspRequester) ===
          String(selectedCustomer?.id)),
    [currentUser, selectedIntegration, selectedCustomer],
  );

  const pollTask = useCallback(
    async (taskId) => {
      cleanMessages();

      try {
        await delay(DELAY_TIME); // wait few seconds before polling so task can be created
        const { promise, cancel } = await pollIntegrationTaskStatus(
          selectedCustomer?.id,
          taskId,
          {
            retries: 360, // 1hr polling, in reality result should come much faster
            timeout: DELAY_TIME,
          },
        );
        polling.current[selectedCustomer?.id] = { cancel };
        const result = await promise;

        // if result is null event was cancelled
        if (result !== null) {
          await delay(DELAY_TIME); // wait remaining jobs to finish
          setSuccessMessage(
            intl.formatMessage({
              id: "INTEGRATION.SUCCESS_REFRESHING",
            }),
          );
        }
      } catch (err) {
        console.error(err);
        setErrorMessage(
          intl.formatMessage({
            id: "INTEGRATION.ERROR_REFRESHING",
          }),
        );
      }
    },
    [selectedCustomer, intl],
  );

  const getTaskByName = async (customerId, taskShortName) => {
    let taskId = null;
    try {
      const response = await getTask(customerId, {
        taskShortName,
      });
      taskId = response?.data?.taskId;
    } catch (err) {
      console.error(err);
    }
    return taskId;
  };

  const getInProgressTask = useCallback(async () => {
    setIsRefreshing(true);

    let taskId = await getTaskByName(
      selectedCustomer?.id,
      "M365_DISCOVER_TASK",
    );
    // check bulk task if no individual discover task is in progress
    if (!taskId) {
      taskId = await getTaskByName(
        selectedCustomer?.id,
        "M365_BULK_DISCOVER_TASK",
      );
    }

    if (taskId) {
      await pollTask(taskId);
    }
    setIsRefreshing(false);
    removeMappedCustomer(dispatch, selectedCustomer?.id);
  }, [dispatch, pollTask, selectedCustomer]);

  const refreshIntegration = useCallback(async () => {
    setIsRefreshing(true);
    cleanMessages();

    const parameters = {
      integrationId: selectedIntegration?.id,
    };

    let taskId;
    try {
      const response = await createTask(
        selectedCustomer?.id,
        selectedIntegration?.integration_application,
        "M365_DISCOVER_TASK",
        currentUser?.id,
        parameters,
      );
      taskId = response?.data?.taskId;
      if (!taskId) {
        throw new Error("Failed to retrieve taskId");
      }

      await pollTask(taskId);
    } catch (err) {
      console.error(err);
      setErrorMessage(
        intl.formatMessage({
          id: "INTEGRATION.ERROR_REFRESHING",
        }),
      );
    }

    setIsRefreshing(false);
  }, [pollTask, currentUser, selectedIntegration, selectedCustomer, intl]);

  const cancelPolling = useCallback(async (customerId) => {
    let retries = 0;
    let cancelled = false;
    while (!cancelled || retries === 3) {
      if (polling?.current?.[customerId]?.cancel) {
        polling.current[customerId].cancel();
        polling.current[customerId].cancel = null;
        cancelled = true;
      } else {
        // task was not even created yet wait few seconds
        await delay(DELAY_TIME); // eslint-disable-line no-await-in-loop
      }
      retries += 1;
    }
  }, []);

  useEffect(() => {
    if (prevCustomer !== selectedCustomer?.id && hasRequiredFields) {
      setPrevCustomer(selectedCustomer?.id);

      // check if there is a task in progress for this customer
      if (selectedCustomer?.id) {
        getInProgressTask();
      }
    }
  }, [
    prevCustomer,
    selectedCustomer?.id,
    hasRequiredFields,
    getInProgressTask,
    cancelPolling,
  ]);

  // Cancel polling on customer change
  useEffect(() => {
    if (prevCustomer && prevCustomer !== selectedCustomer?.id) {
      cancelPolling(prevCustomer);
    }
  }, [prevCustomer, selectedCustomer?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Cancel polling on component unmount
  useEffect(
    () => () => {
      if (prevCustomer) {
        cancelPolling(prevCustomer);
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!hasRequiredFields) {
    return null;
  }

  return (
    <>
      {message && (
        <Toast className={styles.toast}>
          <Alert
            variant={errorMessage ? "danger" : "success"}
            className={styles.alert}
            onClose={cleanMessages}
            dismissible
          >
            {message}
          </Alert>
        </Toast>
      )}

      <div className="d-flex align-items-center">
        <span className={clsx(styles.lastUpdated, "mr-2")}>
          <FormattedMessage
            id="GENERAL.LAST_UPDATED_DATE"
            values={{
              date: moment(selectedIntegration?.lastSynchUTCDate).format(
                "h:mm A z MMM. D, YYYY",
              ),
            }}
          />
        </span>
        <OverlayTrigger
          rootClose
          trigger={["hover", "focus"]}
          placement="left"
          show={isRefreshing ? undefined : false}
          overlay={
            <Tooltip>
              <FormattedMessage id="INTEGRATION.REFRESHING_WARNING" />
            </Tooltip>
          }
        >
          <div>
            <Button
              variant="light"
              className={styles.refreshBtn}
              disabled={isRefreshing}
              onClick={refreshIntegration}
            >
              <i
                className={clsx("fa fa-sync-alt", isRefreshing && "fa-spin")}
              />
            </Button>
          </div>
        </OverlayTrigger>
      </div>
    </>
  );
}

export default IntegrationRefreshButton;
