import axios from "axios";

const ROOT = process.env.REACT_APP_AUGMENTT_API_URL;

export const SECURE_CHECK_URL = `${ROOT}/secure/check`;
export const SECURE_POSTURE_URL = `${ROOT}/secure/posture`;
export const SECURE_POSTURE_BASELINE_URL = `${ROOT}/secure/posture/baseline`;
export const SECURE_MFA_URL = `${ROOT}/secure/mfa`;
export const SECURE_LICENSE_URL = `${ROOT}/secure/license`;
export const SECURE_THREATS_URL = `${ROOT}/secure/threat`;
export const SECURE_SUMMARY_URL = `${ROOT}/secure/summary`;
export const SECURE_SETTING_URL = `${ROOT}/secure/setting`;

export function fetchSecurityCheckData(customerId, checkType) {
  return axios.get(`${SECURE_CHECK_URL}/${checkType}`, {
    params: {
      customerId,
    },
  });
}

export function fetchSecurityPostureData(customerId) {
  return axios.get(`${SECURE_POSTURE_URL}/${customerId}`);
}

export function getMfaReportData(
  customerId,
  employeeFilter,
  licenseFilter,
  roleFilter,
  mfaStatusFilter,
  mfaRegistrationFilter,
  authenticationTypeFilter,
) {
  return axios.get(`${SECURE_MFA_URL}/${customerId}`, {
    params: {
      employeeFilter: employeeFilter || undefined,
      licenseFilter: licenseFilter || undefined,
      roleFilter: roleFilter || undefined,
      mfaStatusFilter: mfaStatusFilter || undefined,
      mfaRegistrationFilter: mfaRegistrationFilter || undefined,
      authenticationTypeFilter: authenticationTypeFilter || undefined,
    },
  });
}

export function sendPostureBaselineData(customerId, payload) {
  return axios.post(`${SECURE_POSTURE_BASELINE_URL}/${customerId}`, payload);
}

export function sendTemplateBaselineData(customerId, payload) {
  return axios.put(`${SECURE_POSTURE_BASELINE_URL}/${customerId}`, payload);
}

export function getLicenseReportData(customerId, dateRange) {
  return axios.get(`${SECURE_LICENSE_URL}/${customerId}`, {
    params: {
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    },
  });
}

export function getThreatReportData(customerId, dateRange) {
  return axios.get(`${SECURE_THREATS_URL}/${customerId}`, {
    params: {
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    },
  });
}

export function getSummaryReportData(customerId, dateRange) {
  return axios.get(`${SECURE_SUMMARY_URL}/${customerId}`, {
    params: {
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    },
  });
}

export function getSecurityPostureInstructions(customerId, tableId) {
  return axios.get(`${ROOT}/customer/${customerId}/instruction/${tableId}`);
}

export function updateSecurityPostureInstructions(
  customerId,
  tableId,
  payload,
) {
  return axios.put(
    `${ROOT}/customer/${customerId}/instruction/${tableId}`,
    payload,
  );
}

export function getSecurityCheckTaskStatus(check, taskId) {
  return axios.get(`${SECURE_CHECK_URL}/${check}/task/${taskId}/status`);
}

export function getSecureCheckSettings(customerId) {
  return axios.get(`${SECURE_SETTING_URL}?customerId=${customerId}`);
}

export function updateSecureCheckSettings(settingId, payload) {
  return axios.put(`${SECURE_SETTING_URL}/${settingId}`, payload);
}
