/* eslint-disable react/prop-types, react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { isSyncPath } from "../../utils/utils";
import * as subheader from "../../ducks/subheader";
import AddApplicationButton from "./components/AddApplicationButton";
import HowAlertsWorkButton from "./components/HowAlertsWorkButton";
import DatePicker from "../../../app/partials/layout/DatePicker";
import AddEmployeesButton from "./components/AddEmployeesButton";
import IntegrationsButton from "./components/IntegrationsButton";
import CustomizeAuditButton from "./components/CustomizeAuditButton";
import ExportWidget from "../../../app/widgets/ExportWidget";
import ExportLicenseWidget from "../../../app/widgets/ExportLicenseWidget";
import { LayoutContextConsumer } from "../LayoutContext";
import ImportSettingsButton from "./components/ImportSettingsButton";
import IntegrationRefreshButton from "./components/IntegrationRefreshButton";
import {
  EmployeesIntegrationToggleEventEmitter,
  EMPLOYEES_INTEGRATION_TOGGLE_EVENT,
} from "../../../app/pages/home/Employees/EmployeesIntegrationToggleEventEmitter";
import CreatePostureTemplateButton from "../../../app/pages/home/secure/posture_templates/CreatePostureTemplateButton";
import ExportDiscoverComplianceWidget from "../../../app/widgets/ExportDiscoverComplianceWidget";
import BetaTag from "../../../app/partials/content/BetaTag";

const MicrosoftOffice = "Office365";

function SubHeader(props) {
  const {
    subheaderCssClasses,
    subheaderContainerCssClasses,
    subheaderMobileToggle,
    isChildLoading,
  } = props;

  const dispatch = useDispatch();

  // eslint-disable-next-line no-shadow
  const featureFlags = useSelector(({ featureFlags }) => featureFlags.data);
  const scheduledReportsFlags =
    featureFlags?.find((flag) => flag.feature === "scheduled-reports") || {};
  const isSecureCheckSettingsEnabled =
    featureFlags?.find((flag) => flag.feature === "secure-checks-settings")
      ?.enabled || false;

  const selectedCustomer = useSelector(
    ({ customers }) => customers.selectedCustomer,
  );
  const selectedCustomerId = selectedCustomer?.id;
  const permissions = useSelector(
    ({ userPermissions }) => userPermissions.data.permissions,
  );
  const showSecurityChecksPage = useSelector(
    ({ secure }) => secure.showSecurityChecksPage,
  );

  // START FEATURE FLAG LOGIC
  const [reportingFeatureFlag, setReportingFeatureFlag] = useState(false); // disabled by default
  const autoIgnoreFeature =
    featureFlags?.find((item) => item.feature === "auto-ignore") || {};

  const employeeEventEmitter = (data) => {
    // eslint-disable-next-line no-use-before-define
    setShowImportSettingsButton(
      data.selectedIntegrationType === MicrosoftOffice,
    );
  };

  const [showImportSettingsButton, setShowImportSettingsButton] =
    useState(false); // disabled by default

  useEffect(() => {
    EmployeesIntegrationToggleEventEmitter.on(
      EMPLOYEES_INTEGRATION_TOGGLE_EVENT,
      employeeEventEmitter,
    );
    return () => {
      EmployeesIntegrationToggleEventEmitter.off(
        EMPLOYEES_INTEGRATION_TOGGLE_EVENT,
        employeeEventEmitter,
      );
    };
  });

  useEffect(() => {
    try {
      const reportingEnabled =
        featureFlags?.find((ff) => {
          if (ff.feature === "reporting") {
            if (ff.enabled) {
              return true;
            }
          }
          return false;
        }) || false;

      setReportingFeatureFlag(reportingEnabled.enabled);
    } catch (e) {
      console.error("ERROR, couldn't assign feature flag", e);
    }
  }, [featureFlags]);
  // END FEATURE FLAG

  const startOfDay = { hour: 0, minute: 0, second: 0, millisecond: 0 };
  const endOfDay = { hour: 23, minute: 59, second: 59, millisecond: 999 };

  const onContactSales = async () => {
    window.location = "mailto:sales@augmentt.com?subject=Licensing Inquiry";
  };

  const howAlertWorkCondition =
    scheduledReportsFlags.enabled &&
    window.location.pathname.match(/(scheduled-reports)/);

  const customizeAuditCondition =
    isSecureCheckSettingsEnabled &&
    window.location.pathname.match(/security-posture/) &&
    permissions.secure === "manage";
  return (
    <div
      id="am_subheader"
      className={`subheader ${subheaderCssClasses} grid__item`}
    >
      <div className={`container ${subheaderContainerCssClasses}`}>
        <div className="subheader__main">
          {subheaderMobileToggle && (
            <button
              type="button"
              className="subheader__mobile-toggle subheader__mobile-toggle--left"
              id="am_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <LayoutContextConsumer>
            {({ subheader: { title, beta } }) => (
              <>
                <h3 className="subheader__title">{title}</h3>
                {beta && <BetaTag />}
              </>
            )}
          </LayoutContextConsumer>
        </div>
        <div className="subheader__toolbar">
          <div className="subheader__wrapper">
            {isSyncPath() && <IntegrationRefreshButton />}

            {howAlertWorkCondition && <HowAlertsWorkButton />}
            {customizeAuditCondition && <CustomizeAuditButton />}
            {(window.location.pathname.match(/discovery/) ||
              window.location.pathname.match(/discover\/risk/)) && (
              <DatePicker
                showCustomRangeLabel={false}
                dateRanges={{
                  "This Month (Past 30 days)": [
                    moment().subtract(1, "month").set(startOfDay),
                    moment().set(endOfDay),
                  ],
                  "Last Month (30-60 days ago)": [
                    moment().subtract(2, "month").set(startOfDay),
                    moment().subtract(1, "month").set(endOfDay),
                  ],
                  "2 Months Ago (60-90 days ago)": [
                    moment().subtract(3, "month").set(startOfDay),
                    moment().subtract(2, "month").set(endOfDay),
                  ],
                }}
              />
            )}

            {window.location.pathname.match(/employees/) &&
              autoIgnoreFeature?.enabled &&
              showImportSettingsButton && <ImportSettingsButton />}

            {window.location.pathname.match(/employees/) && (
              <AddEmployeesButton />
            )}

            {/* If your page needs to support exporting. put your path below, and do the redux exportData implementation, see examples */}
            {(window.location.pathname.match(/discovery/) ||
              window.location.pathname.match(/employees/)) && <ExportWidget />}

            {window.location.pathname.match(/mfa/) && selectedCustomerId && (
              <ExportWidget />
            )}

            {window.location.pathname.match(/discover\/risk/) &&
              selectedCustomerId && <ExportDiscoverComplianceWidget />}

            {window.location.pathname.match(/licensing-report(\/)?$/) && (
              <div style={{ marginRight: selectedCustomerId ? "0em" : "6em" }}>
                <DatePicker
                  showCustomRangeLabel
                  dateRanges={{
                    "Last Month": [
                      moment()
                        .utc()
                        .endOf("month")
                        .subtract(2, "months")
                        .endOf("month"),
                      moment().subtract(1, "months").endOf("month"),
                    ],
                    "2 Months Ago": [
                      moment()
                        .utc()
                        .endOf("month")
                        .subtract(3, "months")
                        .endOf("month"),
                      moment().subtract(2, "months").endOf("month"),
                    ],
                    "3 Months Ago": [
                      moment()
                        .utc()
                        .endOf("month")
                        .subtract(4, "months")
                        .endOf("month"),
                      moment().subtract(3, "months").endOf("month"),
                    ],
                  }}
                />

                {selectedCustomerId && <ExportLicenseWidget />}
              </div>
            )}

            {window.location.pathname.match(/applications/) && (
              <AddApplicationButton />
            )}
            {window.location.pathname.match(/engage/) && (
              <IntegrationsButton isChildLoading={isChildLoading} />
            )}
            {/* For secure pages show integration selector, but only show microsoft office */}
            {window.location.pathname.match(/secure/) && (
              <IntegrationsButton
                isChildLoading={isChildLoading}
                integrationsAllowed={[MicrosoftOffice]}
                isSecure
              />
            )}
            {/* Secure Threat report has date picker */}
            {window.location.pathname.match(/threats/) && (
              <>
                <DatePicker
                  showCustomRangeLabel={false}
                  dateRanges={{
                    "Last 1 Month": [
                      moment().subtract(1, "month").set(startOfDay),
                      moment().set(endOfDay),
                    ],
                    "Last 2 Months": [
                      moment().subtract(2, "month").set(startOfDay),
                      moment().set(endOfDay),
                    ],
                    "Last 3 Months": [
                      moment().subtract(3, "month").set(startOfDay),
                      moment().set(endOfDay),
                    ],
                  }}
                />
                {reportingFeatureFlag === true ? <ExportWidget /> : ""}
              </>
            )}
            {/* Secure summary report has date picker */}
            {window.location.pathname.match(/summary/) && (
              <>
                <DatePicker
                  showCustomRangeLabel={false}
                  dateRanges={{
                    "Last 1 Month": [
                      moment().subtract(1, "month").set(startOfDay),
                      moment().set(endOfDay),
                    ],
                    "Last 2 Months": [
                      moment().subtract(2, "month").set(startOfDay),
                      moment().set(endOfDay),
                    ],
                    "Last 3 Months": [
                      moment().subtract(3, "month").set(startOfDay),
                      moment().set(endOfDay),
                    ],
                  }}
                />
                {reportingFeatureFlag === true ? <ExportWidget /> : ""}
              </>
            )}
            {/* Secure summary report has date picker */}
            {window.location.pathname.match(/alerts/) && (
              <>
                <Button
                  onClick={() =>
                    dispatch(
                      subheader.actions.setGlobalValue({
                        showEventSettingsModal: true,
                      }),
                    )
                  }
                  id="eventSettingsBtn"
                  variant="light"
                >
                  <FormattedMessage id="THREAT_ALERTS.EVENT_SETTINGS" />
                </Button>

                <DatePicker
                  showCustomRangeLabel={false}
                  dateRanges={{
                    "Last 48 Hours": [
                      moment().subtract(2, "day").set(startOfDay),
                      moment().set(endOfDay),
                    ],
                    "Last 1 Month": [
                      moment().subtract(1, "month").set(startOfDay),
                      moment().set(endOfDay),
                    ],
                    "Last 2 Months": [
                      moment().subtract(2, "month").set(startOfDay),
                      moment().set(endOfDay),
                    ],
                    "Last 3 Months": [
                      moment().subtract(3, "month").set(startOfDay),
                      moment().set(endOfDay),
                    ],
                  }}
                />
              </>
            )}
            {window.location.pathname.match(/licenses/) && (
              <Button
                onClick={() => onContactSales()}
                id="contactBtn"
                variant="primary"
              >
                <FormattedMessage id="GENERAL.CONTACT_SALES" />
              </Button>
            )}
            {window.location.pathname.match(/security-posture/) &&
              selectedCustomerId &&
              !showSecurityChecksPage && <ExportWidget />}
            {window.location.pathname.match(/posture-templates$/) && (
              <CreatePostureTemplateButton />
            )}

            {window.location.pathname.match(/policy-templates/) && (
              <Button
                variant="primary"
                onClick={() =>
                  dispatch(
                    subheader.actions.setGlobalValue({ createNewPolicy: true }),
                  )
                }
              >
                <FormattedMessage id="SECURE.CREATE_NEW" />
              </Button>
            )}

            {window.location.pathname.match(/microsoft-csp/) && (
              <Button
                variant="light"
                onClick={() =>
                  dispatch(
                    subheader.actions.setGlobalValue({
                      showGdapMagicLinkModal: true,
                    }),
                  )
                }
              >
                <FormattedMessage id="INTEGRATION.CSP.CREATE_MAGIC_LINK" />
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      <FormattedMessage id="INTEGRATION.CSP.MAGIC_LINK_INFO" />
                    </Tooltip>
                  }
                >
                  <i className="fas fa-info-circle ml-2 font-color-2" />
                </OverlayTrigger>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(SubHeader);
