/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
// polyfills imports
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// css imports (should come before components)
import "./index.scss"; // Standard version
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-phone-input-2/lib/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_augmentt/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_augmentt/_assets/plugins/flaticon/flaticon.css";
import "./_augmentt/_assets/plugins/flaticon2/flaticon.css";
import "./_augmentt/_assets/plugins/sofiapro/sofiapro.css";
import "@augmentt-dev/ui-components/dist/ui-components.css";

// component imports
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import axios from "axios";
import { Auth, Cache } from "aws-amplify";
import { setupWorker } from "msw";
import handlers from "./_augmentt/__mocks__/mswHandlers";
import { setupAxios } from "./_augmentt";
import store, { persistor } from "./app/store/store";
import App from "./App";

/* Starts development mock server */
if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_MOCK_APIS === "true"
) {
  const worker = setupWorker(...handlers);
  worker.start({
    onUnhandledRequest: "bypass",
  });
}

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

async function getToken() {
  // Refresh session in Cache. Set expiry to 1 hour from now.
  const now = new Date();
  Cache.setItem("Session", now, { expires: now.setHours(now.getHours() + 1) });

  // Get current session
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  return token;
}

// Interceptor to add Authorization header + token on all API requests

axios.interceptors.request.use(
  (config) => {
    // Cancel token being sent on Signup or if session in cache is expired/non-existent
    const session = Cache.getItem("Session");

    if (
      config.url.includes("signup") ||
      config.url.includes("sso") ||
      config.url.includes("amazonaws") ||
      config.url.includes("tools/cognito/user-status") ||
      config.url.includes("tools/cognito/invite-resend") ||
      !session
    ) {
      if (!config.cancelToken) {
        const { CancelToken } = axios;
        let cancel;
        if (cancel) {
          cancel();
        }
        // eslint-disable-next-line no-param-reassign
        config.cancelToken = new CancelToken((c) => {
          cancel = c;
        });
      }
      return config;
    }
    // Else get token
    return getToken()
      .then((token) => {
        config.headers.Authorization = token; // eslint-disable-line no-param-reassign
        return Promise.resolve(config);
      })
      .catch(() => {});
  },
  (error) => Promise.reject(error),
);

setupAxios(axios, store);

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <App persistor={persistor} basename={PUBLIC_URL} />
  </Provider>,
);
