/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as builder from "../../ducks/builder";
import Brand from "../brand/Brand";
import Menu from "./Menu";
import KTOffcanvas from "../../_assets/js/offcanvas";

class AsideLeft extends React.Component {
  asideOffCanvasRef = React.createRef();

  componentDidMount() {
    // eslint-disable-next-line
    const ktoffConvas = new KTOffcanvas(
      this.asideOffCanvasRef.current,
      this.props.menuCanvasOptions, // eslint-disable-line react/destructuring-assignment
    );
  }

  render() {
    const {
      currentUser,
      asideClassesFromConfig,
      disableScroll,
      htmlClassService,
    } = this.props;

    return (
      <>
        <button type="button" className="aside-close" id="aside_close_btn">
          <i className="la la-close" />
        </button>
        <div
          id="aside"
          ref={this.asideOffCanvasRef}
          className={`aside ${asideClassesFromConfig} grid__item grid grid--desktop grid--hor-desktop`}
        >
          <Brand />
          <div
            id="aside_menu_wrapper"
            className="aside-menu-wrapper grid__item grid__item--fluid"
          >
            {disableScroll && (
              <Menu
                htmlClassService={htmlClassService}
                currentUser={currentUser}
              />
            )}
            {!disableScroll && (
              <PerfectScrollbar
                options={{ wheelSpeed: 2, wheelPropagation: false }}
              >
                <Menu
                  htmlClassService={htmlClassService}
                  currentUser={currentUser}
                />
              </PerfectScrollbar>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, "aside.self.display") === false,
  disableScroll:
    builder.selectors.getConfig(store, "aside.menu.dropdown") === "true" ||
    false,
  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: "aside",
    toString: true,
  }),
  menuCanvasOptions: {
    baseClass: "aside",
    overlay: true,
    closeBy: "aside_close_btn",
    toggleBy: {
      target: "aside_mobile_toggler",
      state: "header-mobile__toolbar-toggler--active",
    },
  },
});

export default connect(mapStateToProps)(AsideLeft);
